import AlertService from "../../../common/services/alert.service";
import ErrorHandler from "../../../common/services/error.handler";
import {
    CREATE_LABEL_ERROR,
    CREATE_LABEL_REQUEST,
    CREATE_LABEL_SUCCESS,
    DESTROY_ENTITY_ERROR,
    DESTROY_ENTITY_REQUEST,
    DESTROY_ENTITY_SUCCESS,
    GET_ENTITY_ERROR,
    GET_ENTITY_REQUEST,
    GET_ENTITY_SUCCESS,
    RECEIVE_LABELS_ERROR,
    RECEIVE_LABELS_REQUEST,
    RECEIVE_LABELS_SUCCESS,
    RECEIVE_TAGS_REQUEST,
    CREATE_TAG_REQUEST,
    UPDATE_TAG_REQUEST,
    RECEIVE_USERS_DATA_ERROR,
    RECEIVE_USERS_DATA_REQUEST,
    RECEIVE_USERS_DATA_SUCCESS,
    REMOVE_LABEL_ERROR,
    REMOVE_LABEL_REQUEST,
    REMOVE_LABEL_SUCCESS,
    SET_EMPTY_ENTITY_REQUEST,
    SET_LEAD_ID,
    UPDATE_ENTITY_ERROR,
    UPDATE_ENTITY_REQUEST,
    UPDATE_ENTITY_SUCCESS,
    UPDATE_LABEL_ERROR,
    UPDATE_LABEL_REQUEST,
    UPDATE_LABEL_SUCCESS,
    UPDATE_TAG_SUCCESS,
    CREATE_TAG_SUCCESS,
    REMOVE_TAG_SUCCESS,
    RECEIVE_TAGS_ERROR,
    UPDATE_TAG_ERROR,
    CREATE_TAG_ERROR,
    REMOVE_TAG_ERROR, RECEIVE_TAGS_SUCCESS, REMOVE_TAG_REQUEST,
} from "../../../common/constants";

export const receiveUsersDataRequest = () => {
    return {
        type: RECEIVE_USERS_DATA_REQUEST,
    };
};

export const receiveLabelsRequest = data => {
    return {
        type: RECEIVE_LABELS_REQUEST,
        data,
    };
};

export const receiveTagsRequest = data => {
    return {
        type: RECEIVE_TAGS_REQUEST,
        data,
    };
};

export const updateLabelRequest = (id, data, cb) => {
    return {
        type: UPDATE_LABEL_REQUEST,
        id,
        data,
        cb,
    };
};

export const updateTagRequest = (id, data, cb) => {
    return {
        type: UPDATE_TAG_REQUEST,
        id,
        data,
        cb,
    };
};

export const removeLabelRequest = (id) => {
    return {
        type: REMOVE_LABEL_REQUEST,
        id,
    };
};

export const removeTagRequest = (id) => {
    return {
        type: REMOVE_TAG_REQUEST,
        id,
    };
};

export const createLabelRequest = data => {
    return {
        type: CREATE_LABEL_REQUEST,
        data,
    };
};

export const createTagRequest = data => {
    return {
        type: CREATE_TAG_REQUEST,
        data,
    };
};

export const getEntityRequest = (id, cb) => {
    return {
        type: GET_ENTITY_REQUEST,
        id,
        cb,
    };
};

export const updateEntityRequest = (deleteMode , action_types, id, data, router, cb) => {
    return {
        type: UPDATE_ENTITY_REQUEST,
        deleteMode,
        action_types,
        id,
        data,
        router,
        cb,
    };
};

export const destroyEntityRequest = (id, data) => {
    return {
        type: DESTROY_ENTITY_REQUEST,
        id,
        data,
    };
};

export const setEmptyEntityRequest = () => {
    return {
        type: SET_EMPTY_ENTITY_REQUEST,
        entity: {}
    };
};

export const receiveUsersDataSuccess = users => {
    return {
        type: RECEIVE_USERS_DATA_SUCCESS,
        users: users.data,
    };
};

export const receiveLabelsSuccess = labels => {
    return {
        type: RECEIVE_LABELS_SUCCESS,
        labels: labels.data,
    };
};

export const updateLabelSuccess = updatedLabel => {
    AlertService.success(updatedLabel.data.message);
    return {
        type: UPDATE_LABEL_SUCCESS,
        labels: [],
    };
};

export const createLabelSuccess = createdLabel => {
    AlertService.success(createdLabel.data.message);
    return {
        type: CREATE_LABEL_SUCCESS,
        labels: [],
    };
};

export const deleteLabelSuccess = deletedLabel => {
    AlertService.success(deletedLabel.data.message);
    return {
        type: REMOVE_LABEL_SUCCESS,
        labels: [],
    };
};

export const receiveTagsSuccess = tags => {

    return {
        type: RECEIVE_TAGS_SUCCESS,
        tags: tags.data,
    };
};

export const updateTagSuccess = updatedTag => {
    AlertService.success(updatedTag.data.message);
    return {
        type: UPDATE_TAG_SUCCESS,
        tags: [],
    };
};

export const createTagSuccess = createdTag => {
    AlertService.success(createdTag.data.message);
    return {
        type: CREATE_TAG_SUCCESS,
        tags: [],
    };
};

export const deleteTagSuccess = deletedTag => {
    AlertService.success(deletedTag.data.message);
    return {
        type: REMOVE_TAG_SUCCESS,
        tags: [],
    };
};

export const getEntitySuccess = entity => {
    return {
        type: GET_ENTITY_SUCCESS,
        entity: entity.data,
    };
};

export const updateEntitySuccess = (entity, cb) => {
    cb && cb();
    AlertService.success("ENTITY UPDATED SUCCESSFULLY");
    return {
        type: UPDATE_ENTITY_SUCCESS,
        entity: entity.data,
    };
};

export const destroyEntitySuccess = entity => {
    AlertService.success(entity.data.message);
    return {
        type: DESTROY_ENTITY_SUCCESS,
        entity: {},
    };
};

export const receiveUsersDataError = error => {
    ErrorHandler.do(error);
    return {
        type: RECEIVE_USERS_DATA_ERROR,
        error,
    };
};

export const receiveLabelsError = error => {
    ErrorHandler.do(error);
    return {
        type: RECEIVE_LABELS_ERROR,
        error,
    };
};

export const updateLabelError = error => {
    ErrorHandler.do(error);
    return {
        type: UPDATE_LABEL_ERROR,
        error,
    };
};

export const createLabelError = error => {
    ErrorHandler.do(error);
    return {
        type: CREATE_LABEL_ERROR,
        error,
    };
};

export const deleteLabelError = error => {
    ErrorHandler.do(error);
    return {
        type: REMOVE_LABEL_ERROR,
        error,
    };
};

export const receiveTagsError = error => {
    ErrorHandler.do(error);
    return {
        type: RECEIVE_TAGS_ERROR,
        error,
    };
};

export const updateTagError = error => {
    ErrorHandler.do(error);
    return {
        type: UPDATE_TAG_ERROR,
        error,
    };
};

export const createTagError = error => {
    ErrorHandler.do(error);
    return {
        type: CREATE_TAG_ERROR,
        error,
    };
};

export const deleteTagError = error => {
    ErrorHandler.do(error);
    return {
        type: REMOVE_TAG_ERROR,
        error,
    };
};

export const getEntityError = error => {
    ErrorHandler.do(error);
    return {
        type: GET_ENTITY_ERROR,
        error,
    };
};

export const updateEntityError = error => {
    ErrorHandler.do(error);
    return {
        type: UPDATE_ENTITY_ERROR,
        error,
    };
};

export const destroyEntityError = error => {
    ErrorHandler.do(error);
    return {
        type: DESTROY_ENTITY_ERROR,
        error,
    };
};

export const setLeadID = leadId => {
    return {
        type: SET_LEAD_ID,
        leadId,
    };
};