import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
    QUERY_OBJECTS_DELETE_CONFIG_REQUEST,
    QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST,
    QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST,
    QUERY_OBJECTS_GET_RESULTS_REQUEST,
    QUERY_OBJECTS_RECEIVE_CONFIGS_REQUEST,
    QUERY_OBJECTS_SAVE_CONFIG_REQUEST,
} from "../../../common/constants";
import {
    receiveQueryObjectsConfigsSuccess,
    saveQueryObjectsConfigSuccess,
    saveQueryObjectsConfigsError,
    receiveQueryObjectsConfigsError,
    deleteQueryObjectsConfigsError,
    deleteQueryObjectsConfigsSuccess,
    receiveQueryObjectsResultsSuccess,
    receiveQueryObjectsResultsError,
    getPrevResultsSuccess,
    getPrevResultsError,
    getNextResultsSuccess, getNextResultsError,
} from "../actions";
import AlertService from "../../../common/services/alert.service";

const LIMIT = 100;

function* receiveQueryObjectsConfigs() {
    try {
        const queryObjectsConfigs = yield call(ApiService.get2, "/query-objects/query-configs");
        yield put(receiveQueryObjectsConfigsSuccess(queryObjectsConfigs));
    } catch (error) {
        yield put(receiveQueryObjectsConfigsError(error));
    }
}

function* saveQueryObjectsConfig(action) {
    try {
        const config = yield call(ApiService.post2, "/query-objects/query-configs", action.config);
        if (config) {
            yield put(saveQueryObjectsConfigSuccess());
            yield receiveQueryObjectsConfigs();
        } else {
            AlertService.error("SOMETHING IS WRONG");
        }
    } catch (error) {
        yield put(saveQueryObjectsConfigsError(error));
    }
}

function* deleteQueryObjectsConfig(action) {
    try {
        yield call(ApiService.delete2, `/query-objects/query-configs/${action.id}`, action.id);
        yield put(deleteQueryObjectsConfigsSuccess());
        yield receiveQueryObjectsConfigs();
    } catch (error) {
        yield put(deleteQueryObjectsConfigsError(error));
    }
}

function* receiveQueryObjectsResult(action) {
    const config = action.config;
    try {
        const result = yield call(ApiService.post2, "/query-objects/selection", { config, limit: LIMIT });
        yield put(receiveQueryObjectsResultsSuccess(result));
    } catch (error) {
        yield put(receiveQueryObjectsResultsError(error));
    }
}

function* receivePrevQueryObjectsResultList(action) {
    const config = action.config;
    try {
        const result = yield call(ApiService.post2, "/query-objects/selection/limited-results", {
            config,
            limit: LIMIT,
            direction: -1,
            itemId: action.prevItem.object_id,
        });
        yield put(getPrevResultsSuccess(result));
    } catch (error) {
        yield put(getPrevResultsError(error));
    }
}

function* receiveNextQueryObjectsResultList(action) {
    const config = action.config;
    try {
        const result = yield call(ApiService.post2, "/query-objects/selection/limited-results", {
            config,
            limit: LIMIT,
            direction: 1,
            itemId: action.lastItem.object_id,
        });
        yield put(getNextResultsSuccess(result));
    } catch (error) {
        yield put(getNextResultsError(error));
    }
}

export default function* actionQueryObjects() {
    yield takeLatest(QUERY_OBJECTS_SAVE_CONFIG_REQUEST, saveQueryObjectsConfig);
    yield takeLatest(QUERY_OBJECTS_RECEIVE_CONFIGS_REQUEST, receiveQueryObjectsConfigs);
    yield takeLatest(QUERY_OBJECTS_DELETE_CONFIG_REQUEST, deleteQueryObjectsConfig);
    yield takeLatest(QUERY_OBJECTS_GET_RESULTS_REQUEST, receiveQueryObjectsResult);
    yield takeLatest(QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST, receivePrevQueryObjectsResultList);
    yield takeLatest(QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST, receiveNextQueryObjectsResultList);
}


