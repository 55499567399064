import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FieldEditor from "../../../../common/components/editor/field";
import SearchEntity from "./SearchEntity";
import { INPUT } from "../../../../common/constants/field-types";
import { selectEntity } from "../../../leadgen-head-menu/selectors";
import { selectLeadgen } from "../../selectors";
import SearchObjects from "./SearchObjects";
import Button from "@material-ui/core/Button";

const driveDialog = ({ classes, onChange, files, entitiesList, objectsList, entitiesListChange, objectsListChange, onClose, onSave }) => {
    return (
        <div className={classes.root}>
            <div className={classes.fileNamesContainer}>
                {files && files.map((file, index) => {
                    return (
                        <div className={classes.fileContainer} key={index}>
                            <FieldEditor
                                type={INPUT}
                                name="filename"
                                label="Filename"
                                onChange={e => onChange("filename", index, e)}
                                value={file.name || ""}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={classes.box}>
                <SearchEntity
                    onChange={e => entitiesListChange(e)}
                    propsEntitiesList={entitiesList}
                />
                <SearchObjects
                    onChange={e => objectsListChange(e)}
                    propsObjectsList={objectsList}
                />
            </div>
            <div className={classes.footer}>
                <Button onClick={onSave}>Save</Button>
                <Button onClick={onClose}>Cancel</Button>
            </div>
        </div>
    );
};

const styles = theme => ({
    root: {
        margin: "15px",
        height: "95%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    form: {
        width: "100%",
    },
    box: {
        width: "100%",
        height: "87%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    fileContainer: {
        height: "95px",
    },
    fileNamesContainer: {
        height: "100%",
        padding: "10px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

const mapStateToProps = state => ({
    entity: selectEntity(state),
    leadgen: selectLeadgen(state),
});

driveDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(driveDialog));