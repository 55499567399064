import React, { PureComponent } from "react";
import { connect } from "react-redux";
import update from "react-addons-update";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AlertService from "../../common/services/alert.service";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dropzone from "react-dropzone";
// import { savePDFRequest, sendPDFRequest, unlinkPDFRequest, savePDFAndEntityRequest } from "./actions";
import { savePDFRequest, sendPDFRequest, unlinkPDFRequest } from "./actions";
import Head from "./components/head";
import Main from "./components/main";
import Footer from "./components/footer";
import DialogWrapper from "../../common/components/dialog/";
import Spinner from "../../common/components/spinner";
import { selectPDF } from "./selectors";
import { SELECT } from "../../common/constants/field-types";
import FieldEditor from "../../common/components/editor/field";

class ImportPDF extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    fullList: true,
    lastImportDate: new Date(),
    isDead: "",
    open: false,
    pdfData: {},
    noTypeWarning: false,
  };

  componentDidMount() {
    const { importPDF } = this.props;
    const { parsedPDFData } = importPDF;
    this.setState({
      pdfData: parsedPDFData,
    });
  }

  componentDidUpdate(prevProps) {
    const { importPDF } = this.props;
    const { parsedPDFData, objectsForCheckbox } = importPDF;
    if (prevProps.importPDF.parsedPDFData !== parsedPDFData) {
      this.setState({
        pdfData: { ...parsedPDFData, objectsForCheckbox },
      });
    }
  }

  onDrop = (acceptedFiles) => {
    const { dispatch, router } = this.props;
    const formData = new FormData();

    const type = acceptedFiles[0].type.split("/").slice(-1)[0];

    if (type.toLowerCase() !== "pdf")
      return AlertService.warning("not correct file format");

    const fileName = acceptedFiles[0].name;
    formData.append("pdf", acceptedFiles[0], fileName);

    dispatch(sendPDFRequest(formData, router));
  };

  handleChange = (type, { target }) => {
    this.setState({ [type]: target.value });
  };

  handleChangeEntityType = ({ target }) => {
    const { pdfData } = this.state;
    const newData = update(pdfData, {
      entity: { entity_type: { $set: target.value } },
    });
    this.setState({
      pdfData: newData,
    });
    if (pdfData !== "") {
      this.setState({
        noTypeWarning: false,
      });
    }
  };

  handleSave = () => {
    const { dispatch, router, importPDF } = this.props;
    // const { fileForDriveImport, parsedPDFData } = importPDF;
    const { fileForDriveImport } = importPDF;
    const { fullList, lastImportDate, isDead, pdfData } = this.state;
    const unitsForSave = [];
    const { object, entity, objectsForCheckbox } = pdfData;
    if (objectsForCheckbox && objectsForCheckbox.length) {
      objectsForCheckbox.forEach((object) => {
        object.units.forEach((unit) => {
          if (unit.isSelected) {
            unitsForSave.push(unit);
          }
        });
      });
    }

    delete pdfData.objectsForCheckAndUpdate;
    delete pdfData.objectsForCheckbox;

    if (fileForDriveImport.id) {
      // dispatch(checkPDFUsageRequest(fileForDriveImport.id))

      // const { entity_type } = entity;
      // if (!entity.id) {
      //     if (entity_type) {
      //         dispatch(
      //             savePDFRequest(
      //                 {
      //                     fileForDriveImport,
      //                     fullList,
      //                     ...pdfData,
      //                     entity: {
      //                         ...pdfData.entity,
      //                         is_dead: Boolean(isDead),
      //                         last_import_date: lastImportDate || pdfData.entity.last_import_date,
      //                     },
      //                 },
      //                 router,
      //             ),
      //         );
      //         this.setState({
      //             fullList: true,
      //             lastImportDate: null,
      //             isDead: "",
      //             open: false,
      //             pdfData: {},
      //         });
      //     } else {
      //         this.setState({
      //             noTypeWarning: true,
      //         });
      //     }
      // } else {

      if (entity) {
        dispatch(
          savePDFRequest(
            {
              fileForDriveImport,
              fullList,
              ...pdfData,
              unitsForSave,
              entity: {
                ...pdfData.entity,
                is_dead: Boolean(isDead),
                last_import_date:
                  lastImportDate || pdfData.entity.last_import_date,
              },
            },
            router
          )
        );
        this.setState({
          fullList: true,
          lastImportDate: null,
          isDead: "",
          open: false,
          pdfData: {},
        });
      } else if (object) {
        // console.log('object ', object);
        // let owners = [];
        // if (object.nonResidentOwnersInDB) owners = [...owners, ...object.nonResidentOwnersInDB];
        // if (object.nonResidentOwnersNotInDB) owners = [...owners, ...object.nonResidentOwnersNotInDB];
        // if (object.residentOwnersInDB) owners = [...owners, ...object.residentOwnersInDB];
        // if (object.residentOwnersNotInDB) owners = [...owners, ...object.residentOwnersNotInDB];


        dispatch(
          savePDFRequest(
            {
              fileForDriveImport,
              ...pdfData,
              // owners,
              object: {
                ...pdfData.object,
                last_import_date:
                  lastImportDate || pdfData.object.last_import_date,
              },
            },
            router
          )
        );
        this.setState({
          fullList: true,
          lastImportDate: null,
          isDead: "",
          open: false,
          pdfData: {},
        });
      }
    }
  };

  handleCancel = () => {
    const { dispatch, importPDF } = this.props;
    const { fileForDriveImport } = importPDF;
    dispatch(unlinkPDFRequest({ fileForDriveImport }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenDialog = () => {
    this.setState({ open: true });
  };

  handleSelectObject = (e, object, unit) => {
    const { pdfData } = this.state;
    const { objectsForCheckbox } = pdfData;
    if (e && object) {
      const { target } = e;
      for (let i = 0; i < objectsForCheckbox.length; i++) {
        if (object.id === objectsForCheckbox[i].id) {
          objectsForCheckbox[i].isSelected = target.checked;
          objectsForCheckbox[i].units.map((unit) => {
            unit.isSelected = target.checked;
          });
          return this.setState({
            objectsForCheckbox: [...objectsForCheckbox],
          });
        }
      }
    } else if (e && unit) {
      const { target } = e;
      for (let i = 0; i < objectsForCheckbox.length; i++) {
        objectsForCheckbox[i].units.forEach((un) => {
          if (un.object_id === unit.object_id && un.temp_id === unit.temp_id) {
            un.isSelected = target.checked;
          }
        });
        const unitsAmountArr = objectsForCheckbox[i].units.filter(
          (un) => un.isSelected === true
        );
        if (unitsAmountArr.length === objectsForCheckbox[i].units.length) {
          objectsForCheckbox[i].isSelected = target.checked;
        } else {
          objectsForCheckbox[i].isSelected = !target.checked;
        }
        return this.setState({
          objectsForCheckbox: [...objectsForCheckbox],
        });
      }
    }
  };

  render() {
    const { classes, importPDF } = this.props;
    const { isFetching, parsedPDFData } = importPDF;
    const { fullList, lastImportDate, isDead, open, pdfData, noTypeWarning } =
      this.state;
    const { entity_type } =
      pdfData && pdfData.entity ? pdfData && pdfData.entity : "";

    const {
      object,
      entity,
      statistic,
      type,
      linkedObjects,
      objectsForUnlink,
      objectsForLink,
      objectsForSaveAndLink,
      objectsForCheckAndUpdate,
    } = parsedPDFData || {};

    const diffImportDatesWarning =
      entity &&
      entity.last_import_date &&
      lastImportDate &&
      new Date(entity.last_import_date) - new Date(lastImportDate);

    const warning = noTypeWarning && (
      <Typography className={classes.errorText} variant="subtitle1">
        {`Please choose entity type`}
      </Typography>
    );

    return (
      <Grid container>
        <Grid item xs={12} className={classes.item}>
          <Paper className={classes.content}>
            {Object.keys(parsedPDFData).length && entity ? (
              <div className={classes.body}>
                <Head
                  fullList={fullList}
                  lastImportDate={lastImportDate}
                  isDead={isDead}
                  entity={entity}
                  statistic={statistic}
                  type={type}
                  onChange={this.handleChange}
                />
                <Main
                  fullList={fullList}
                  objectsForUnlink={objectsForUnlink}
                  objectsForLink={objectsForLink}
                  linkedObjects={linkedObjects}
                  objectsForSaveAndLink={objectsForSaveAndLink}
                  objectsForCheckAndUpdate={objectsForCheckAndUpdate}
                  objectsForCheckbox={pdfData.objectsForCheckbox}
                  selectObjsWithUnitsToSave={this.handleSelectObject}
                />
                <Footer
                  show={Boolean(entity && entity.id)}
                  onSave={this.handleOpenDialog}
                  onCancel={this.handleCancel}
                />
              </div>
            ) : Object.keys(parsedPDFData).length && object ? (
              <div className={classes.body}>
                <Head
                  fullList={fullList}
                  lastImportDate={lastImportDate}
                  object={object}
                  statistic={statistic}
                  onChange={this.handleChange}
                />
                <Main
                  nonResidentOwnersInDB={object.nonResidentOwnersInDB}
                  nonResidentOwnersNotInDB={object.nonResidentOwnersNotInDB}
                  residentOwnersInDB={object.residentOwnersInDB}
                  residentOwnersNotInDB={object.residentOwnersNotInDB}
                />
                <Footer
                  show={Boolean(object && object.id)}
                  onSave={this.handleOpenDialog}
                  onCancel={this.handleCancel}
                />
              </div>
            ) : !isFetching ? (
              <Dropzone onDrop={this.onDrop} multiple={false}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div {...getRootProps()} className={classes.dragWrapper}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <Typography
                        className={`${classes.wrapperText} ${classes.dragWrapperIsActive}`}
                        variant="subtitle1"
                      >
                        Drop files here...
                      </Typography>
                    ) : (
                      <Typography
                        className={classes.wrapperText}
                        variant="subtitle1"
                      >
                        Try dropping some files here, or click to select files
                        to upload.
                      </Typography>
                    )}
                  </div>
                )}
              </Dropzone>
            ) : (
              <div className={classes.wrapperSpinner}>
                <Spinner />
              </div>
            )}
          </Paper>
        </Grid>
        <DialogWrapper
          onClose={this.handleClose}
          open={open}
          title="Save information"
          buttonText="confirm"
          handleClick={this.handleSave}
        >
          {open && entity ? (
            <div>
              {!lastImportDate ? (
                <Typography className={classes.warningText} variant="h6">
                  You are trying to import without Import Date
                </Typography>
              ) : (
                entity.id &&
                diffImportDatesWarning >= 0 && (
                  <Typography className={classes.warningText} variant="h6">
                    You are trying to import an older file
                  </Typography>
                )
              )}
              {fullList && entity && entity.id ? (
                <Typography className={classes.confirmText} variant="subtitle1">
                  {`Are you sure, you want to update "${
                    (entity && entity.entity_name) || ""
                  }" and unlink ${objectsForUnlink.length} objects?`}
                </Typography>
              ) : entity && entity.id ? (
                <Typography className={classes.confirmText} variant="subtitle1">
                  {`Are you sure, you want to update "${
                    (entity && entity.entity_name) || ""
                  }"?`}
                </Typography>
              ) : (
                <div>
                  <Typography
                    className={classes.confirmText}
                    variant="subtitle1"
                  >
                    {`You are trying to add new entity. Please choose entity type:`}
                  </Typography>
                  {warning}
                  <FieldEditor
                    type={SELECT}
                    name="entityType"
                    label="Select entity type"
                    onChange={(e) => this.handleChangeEntityType(e)}
                    value={{
                      current: entity_type || "",
                      options: [
                        { value: "person", label: "person" },
                        { value: "company", label: "company" },
                      ],
                      none: "n/a",
                    }}
                  />
                </div>
              )}
            </div>
          ) : open && object && (
            <div>
              {!lastImportDate ? (
                <Typography className={classes.warningText} variant="h6">
                  You are trying to import without Import Date
                </Typography>
              ) : (
                object.id &&
                diffImportDatesWarning >= 0 ? (
                  <Typography className={classes.warningText} variant="h6">
                    You are trying to import an older file
                  </Typography>
                ) : object && object.id ? (
                  <Typography className={classes.confirmText} variant="subtitle1">
                    Are you sure, you want to update current object?
                  </Typography>
                ) :
                  <Typography className={classes.confirmText} variant="subtitle1"
                  >
                    Are you sure, you want to create new object?
                  </Typography>
              )}
            </div>
          )}
        </DialogWrapper>
      </Grid>
    );
  }
}

export const styles = (theme) => ({
  item: {
    width: "100%",
    display: "flex",
    marginTop: 18,
    flexDirection: "column",
  },
  warningText: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
    color: "red",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    padding: 24,
    flexDirection: "column",
    height: "calc(100% - 24px)",
  },
  confirmText: {
    marginTop: 20,
  },
  errorText: {
    color: theme.palette.secondary.error,
    textAlign: "center",
  },
  content: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 2}px)`,
  },
  dragWrapper: {
    display: "flex",
    height: "inherit",
    outline: "none",
    cursor: "pointer",
  },
  dragWrapperIsActive: {
    backgroundColor: "#f5f5f5",
  },
  wrapperText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: 16,
    border: "2px dashed #999",
    borderRadius: 4,
  },
  wrapperSpinner: {
    display: "flex",
    height: "inherit",
    justifyContent: "center",
    alignItems: "center",
  },
});

const mapStateToProps = (state) => ({
  importPDF: selectPDF(state),
});

export default connect(mapStateToProps)(withStyles(styles)(ImportPDF));
