import { combineReducers } from "redux";
import {
    CREATE_LABEL_ERROR,
    CREATE_LABEL_SUCCESS,
    DESTROY_ENTITY_ERROR,
    DESTROY_ENTITY_SUCCESS,
    GET_ENTITY_ERROR,
    GET_ENTITY_SUCCESS,
    RECEIVE_LABELS_ERROR,
    RECEIVE_LABELS_SUCCESS,
    RECEIVE_USERS_DATA_ERROR,
    RECEIVE_USERS_DATA_SUCCESS,
    REMOVE_LABEL_ERROR,
    REMOVE_LABEL_SUCCESS,
    SET_LEAD_ID,
    UPDATE_ENTITY_ERROR,
    UPDATE_ENTITY_SUCCESS,
    UPDATE_LABEL_ERROR,
    UPDATE_LABEL_SUCCESS,
    UPDATE_TAG_SUCCESS,
    CREATE_TAG_SUCCESS,
    REMOVE_TAG_SUCCESS,
    RECEIVE_TAGS_SUCCESS,
    RECEIVE_TAGS_ERROR,
    UPDATE_TAG_ERROR,
    CREATE_TAG_ERROR,
    REMOVE_TAG_ERROR, SET_EMPTY_ENTITY_REQUEST,
} from "../../../common/constants";

const receiveUsers = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_USERS_DATA_SUCCESS:
            return action.users;

        default:
            return state;
    }
};

const headMenuError = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_USERS_DATA_ERROR:
        case RECEIVE_LABELS_ERROR:
        case UPDATE_LABEL_ERROR:
        case CREATE_LABEL_ERROR:
        case GET_ENTITY_ERROR:
        case UPDATE_ENTITY_ERROR:
        case DESTROY_ENTITY_ERROR:
        case REMOVE_LABEL_ERROR:
        case RECEIVE_TAGS_ERROR:
        case UPDATE_TAG_ERROR:
        case CREATE_TAG_ERROR:
        case REMOVE_TAG_ERROR:
            return action.error;

        default:
            return state;
    }
};

const receiveLabels = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_LABELS_SUCCESS:
        case UPDATE_LABEL_SUCCESS:
        case CREATE_LABEL_SUCCESS:
        case REMOVE_LABEL_SUCCESS:
            return action.labels;

        default:
            return state;
    }
};

const receiveTags = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_TAGS_SUCCESS:
        case UPDATE_TAG_SUCCESS:
        case CREATE_TAG_SUCCESS:
        case REMOVE_TAG_SUCCESS:
            return action.tags;

        default:
            return state;
    }
};

const setEntity = (state = {}, action) => {
    switch (action.type) {
        case GET_ENTITY_SUCCESS:
        case UPDATE_ENTITY_SUCCESS:
        case SET_EMPTY_ENTITY_REQUEST:
            return action.entity;

        default:
            return state;
    }
};

const setLeadId = (state = {}, action) => {
    switch (action.type) {
        case SET_LEAD_ID:
            return action.leadId;

        default:
            return state;
    }
};

const responseDestroyEntity = (state = {}, action) => {
    switch (action.type) {
        case DESTROY_ENTITY_SUCCESS:
            return {
                deletedEntityResponse: action.response,
                entityIsDeleted: true,
            };

        default:
            return state;
    }
};

const entity = combineReducers({
    receiveUsers,
    receiveLabels,
    receiveTags,
    setEntity,
    setLeadId,
    responseDestroyEntity,
    headMenuError,
});

export default entity;
