import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import AccountCircleRounded from "@material-ui/icons/AccountCircleRounded";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class RecentQueries extends Component {

    render() {
        const { classes, resentQueriesExpanded, onChange } = this.props;
        const initialArr = Array.apply(null, { length: 20 }).map(Number.call, Number);

        return (
            <React.Fragment>
                <ExpansionPanel className={classes.wrapper} defaultExpanded={true} expanded={resentQueriesExpanded}
                                onChange={onChange}>
                    <ExpansionPanelSummary
                        className={classes.headWrapper}
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant="h6">
                            Recent Queries
                        </Typography>
                    </ExpansionPanelSummary>
                    <Divider className={classes.divider}/>
                    <div className={classes.body}>
                        {initialArr.map(i => (
                            <div key={i} className={classes.row}>
                                <div className={classes.container}>
                                    <AccountCircleRounded/>
                                    <Typography className={classes.color} variant="body2">
                                        query name
                                    </Typography>
                                    <Typography className={classes.color} variant="body2">
                                        07-09-2020
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button
                            className={classes.button}
                            onClick={() => console.log("button Load query")}
                            size="small"
                        >
                            Load query
                        </Button>
                    </div>
                </ExpansionPanel>
            </React.Fragment>
        );
    }
}

RecentQueries.propTypes = {};

const styles = theme => ({
    wrapper: {
        width: "97%",
    },
    row: {
        padding: 8,
    },
    color: {
        color: theme.palette.primary.grayFont,
    },
    headWrapper: {
        height: 56,
        backgroundColor: theme.palette.primary[500],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    divider: {
        width: "100%",
        backgroundColor: theme.palette.primary.divider,
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        borderRadius: 0,
        color: theme.palette.secondary["A400"],
        width: "100%",
        padding: 0,
        height: "30px",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 20,
    },
    body: {
        maxHeight: "370px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

export default withStyles(styles)(RecentQueries);
