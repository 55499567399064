import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { CollapsePanel } from "../../common/components/panel";
import { getLeadsDataRequest } from "./actions";
import {
    createLabelRequest,
    receiveLabelsRequest,
    receiveUsersDataRequest,
    removeLabelRequest,
    updateLabelRequest,
    receiveTagsRequest,
    createTagRequest,
    updateTagRequest,
    removeTagRequest,
} from "../leadgen-head-menu/actions";
import Lead from "./components/Lead";
import DialogWrapper from "../../common/components/dialog";
import LabelForm from "./components/LabelForm";
import Spinner from "../../common/components/spinner";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { getDifferenceInDays } from "../../common/helpers/getter";
import FieldEditor from "../../common/components/editor/field";
import { DATE } from "../../common/constants/field-types";
import { selectLeads } from "./selectors";
import { selectEntity } from "../leadgen-head-menu/selectors";

class Leads extends Component {
    state = {
        leadsBy: "me",
        queryLeads: { responsible_person_type: "current" },
        openType: "label",
        open: false,
        openNotes: false,
        notesToShow: {},
        filterLabel: null,
        fromDate: null,
        toDate: null,
        deleteMode: false,
        selectedLabel: {},
        selectedTag: {},
    };

    get labelOptions() {
        const { entity } = this.props;
        const { currentLabels } = entity;
        return currentLabels.map(label => {
            return {
                value: label.id,
                label: label.name,
            };
        });
    }

    get tagOptions() {
        const { entity } = this.props;
        const { currentTags } = entity;
        return currentTags.map(tag => {
            return {
                value: tag.id,
                label: tag.name,
            };
        });
    }

    initialState = () => {
        const { dispatch } = this.props;
        const { queryLeads } = this.state;
        dispatch(getLeadsDataRequest(queryLeads));
    };

    componentWillMount() {
        // responsible: current/all/user, id: (if not current user)
        this.initialState();
    }

    getUsers = () => {
        const { dispatch } = this.props;
        // const flag = "skipAdmins";
        // dispatch(receiveUsersDataRequest(flag));
        dispatch(receiveUsersDataRequest());
    };

    getLabels = () => {
        const { dispatch } = this.props;
        dispatch(receiveLabelsRequest());
    };

    getTags = () => {
        const { dispatch } = this.props;
        dispatch(receiveTagsRequest());
    };

    getLeads = (value, leadsBy) => {
        const { dispatch } = this.props;
        const query =
            value === "all"
                ? { responsible_person_type: "all" }
                : { responsible_person_type: "user", id: value };
        this.setState({ leadsBy, queryLeads: query });
        dispatch(getLeadsDataRequest(query));
    };

    prepareLabels = labels => {
        const o = {};
        for (let i = 0; i < labels.length; i++) {
            o[labels[i]] = o[labels[i]] ? (o[labels[i]] += 1) : 1;
        }
        return o;
    };

    getPreparedData = (leads, entity, labelName) => {
        const { currentLeads } = leads;
        const { currentLabels } = entity;
        const filteredLeads =
            labelName && currentLeads.length
                ? currentLeads.filter(
                ({ entity }) => entity.label[0] && entity.label[0].name === labelName,
                )
                : currentLeads;

        const { fromDate, toDate } = this.state;
        const unscheduledLeads = [];
        const todayLeads = [];
        const overdueLeads = [];
        const thisWeekLeads = [];
        const nextWeekLeads = [];
        const fromDateToDateLeads = [];
        const curDay = new Date().getDay();
        filteredLeads &&
        filteredLeads.forEach(lead => {
            if (!lead.entity.schedule) return unscheduledLeads.push(lead);

            const diff = getDifferenceInDays(lead.entity.schedule);

            if (diff === 0) todayLeads.push(lead);
            if (diff < 0) overdueLeads.push(lead);
            if (diff >= 0 && diff <= 7 - curDay) thisWeekLeads.push(lead);
            if (diff >= 7 - curDay && diff <= 14 - curDay) nextWeekLeads.push(lead);
            if (
                fromDate &&
                toDate &&
                moment(fromDate) < moment(lead.entity.schedule) &&
                moment(toDate) > moment(lead.entity.schedule)
            ) {
                fromDateToDateLeads.push(lead);
            }
        });

        const filteredLabelsByLeads =
            currentLeads && currentLeads.length
                ? currentLeads
                    .map(({ entity }) => entity.label[0] && entity.label[0].name)
                    .filter(label => label)
                : currentLabels || [];

        const labelNames = this.prepareLabels(filteredLabelsByLeads);

        const preparedLabels = currentLabels
            ? currentLabels.map(({ name }) => {
                return { name, count: labelNames[name] || 0 };
            })
            : [];

        return {
            unscheduledLeads,
            todayLeads,
            overdueLeads,
            thisWeekLeads,
            nextWeekLeads,
            fromDateToDateLeads,
            preparedLabels,
        };
    };

    handleOpenLabelDialog = (openType, type, label, tag) => {
        if (openType === "label") {
            this.setState({
                open: true,
                openType: openType,
                label: type === "edit" ? label : { name: "" },
                deleteMode: type === "delete",
            });
        }
        if (openType === "tag") {
            this.setState({
                open: true,
                openType: openType,
                tag: type === "editTag" ? tag : { name: "" },
                deleteMode: type === "deleteTag",
            });
        }
    };

    handleCloseLabelDialog = () => {
        this.setState({ open: false });
    };

    handleCloseTagDialog = () => {
        this.setState({ open: false });
    };

    handleOpenNotes = notesToShow => {
        notesToShow && this.setState({ openNotes: true, notesToShow });
    };

    handleCloseNotes = () => {
        this.setState({ openNotes: false, notesToShow: {} });
    };

    handleChange = ({ target }) => {
        const { entity } = this.props;
        const { currentLabels } = entity;
        const { label, deleteMode } = this.state;
        let selectedLabelForDelete = {};
        if (deleteMode === true) {
            selectedLabelForDelete = currentLabels.find((label) => label.id === target.value);
        }
        this.setState({
            label: { ...label, name: target.value },
            selectedLabel: { id: selectedLabelForDelete.id, name: selectedLabelForDelete.name },
        });
    };

    handleChangeTags = ({ target }) => {
        const { entity } = this.props;
        const { currentTags } = entity;
        const { tag, deleteMode } = this.state;
        let selectedTagForDelete = {};
        if (deleteMode === true) {
            selectedTagForDelete = currentTags.find((tag) => tag.id === target.value);
        }
        this.setState({
            tag: { ...tag, name: target.value },
            selectedTag: { id: selectedTagForDelete.id, name: selectedTagForDelete.name },
        });
    };

    handleChangeFromToDate = (type, { target }) => {
        this.setState({ [type]: target.value });
    };

    handleSave = () => {
        const { dispatch } = this.props;
        const { label, tag, openType } = this.state;
        if (openType === "tag") {
            const { id, name } = tag || {};

            if (id) {
                dispatch(updateTagRequest(id, { name }));
            } else if (name) {
                dispatch(createTagRequest({ name }));
            }

            this.setState({ label: null, open: false });
        }
        const { id, name } = label || {};

        if (id) {
            dispatch(updateLabelRequest(id, { name }));
        } else if (name) {
            dispatch(createLabelRequest({ name }));
        }

        this.setState({ label: null, open: false });
    };

    handleDelete = () => {
        const { dispatch } = this.props;
        const { selectedLabel, selectedTag } = this.state;

        if (Object.keys(selectedLabel).length > 0) {
            const { id } = selectedLabel;

            dispatch(removeLabelRequest(id));
            this.setState({ label: null, selectedLabel: null, open: false });
        } else if (selectedTag) {
            const { id } = selectedTag;

            dispatch(removeTagRequest(id));
            this.setState({ tag: null, selectedTag: null, open: false });
        }

    };

    handleFilterLabelMenu = name => {
        this.setState({ filterLabel: name });
    };

    render() {
        const { classes, leads, entity } = this.props;
        const { currentLeads, isFetchingLeads } = leads;
        const { users, currentLabels, currentTags } = entity;

        const {
            leadsBy,
            openType,
            open,
            openNotes,
            notesToShow,
            label,
            tag,
            filterLabel,
            fromDate,
            toDate,
            deleteMode,
        } = this.state;

        const {
            unscheduledLeads,
            todayLeads,
            overdueLeads,
            thisWeekLeads,
            nextWeekLeads,
            fromDateToDateLeads,
            preparedLabels,
        } = this.getPreparedData(leads, entity, filterLabel);

        return (
            <Grid container>
                {isFetchingLeads ? (
                    <div className={classes.spinner}>
                        <Spinner/>
                    </div>
                ) : (
                    <Grid item xs={12} className={classes.item}>
                        <Paper className={classes.content}>
                            <div className={classes.header}>
                                <PopupState variant="popover" popupId="popup-label-filter-menu">
                                    {popupState => (
                                        <React.Fragment>
                                            <Button
                                                onClick={() => {
                                                    this.getLabels();
                                                }}
                                                className={classes.headerButtonMenu}
                                            >
                        <span {...bindTrigger(popupState)}>
                          {`Select Label (${filterLabel || "all"})`}
                        </span>
                                            </Button>
                                            {preparedLabels && (
                                                <Menu {...bindMenu(popupState)} classes={{ paper: classes.menu }}>
                                                    {preparedLabels.map(({ name, count }) => (
                                                        <MenuItem
                                                            key={name}
                                                            onClick={() => {
                                                                popupState.close();
                                                                this.handleFilterLabelMenu(name);
                                                            }}
                                                        >
                                                            {`${name} (${count})`}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.handleFilterLabelMenu(null);
                                                        }}
                                                    >
                                                        {`show all (${
                                                            currentLeads ? currentLeads.length : 0
                                                        })`}
                                                    </MenuItem>
                                                </Menu>
                                            )}
                                        </React.Fragment>
                                    )}
                                </PopupState>
                                <PopupState
                                    variant="popover"
                                    popupId="popup-responsible-person-menu"
                                >
                                    {popupState => (
                                        <React.Fragment>
                                            <Button
                                                onClick={this.getUsers}
                                                className={classes.headerButtonMenu}
                                            >
                        <span
                            {...bindTrigger(popupState)}
                        >{`Show leads for ${leadsBy}`}</span>
                                            </Button>
                                            {users && (
                                                <Menu {...bindMenu(popupState)}>
                                                    {users.map(
                                                        ({ id, email, name, surname, img_url }) => (
                                                            <MenuItem
                                                                key={id}
                                                                onClick={() => {
                                                                    popupState.close();
                                                                    this.getLeads(id, name || email);
                                                                }}
                                                                className={classes.menuItem}
                                                            >
                                                                <Avatar alt="responsible person" src={img_url}>
                                                                    <Person/>
                                                                </Avatar>
                                                                <ListItemText
                                                                    primary={`${name || ""} ${surname || ""}`}
                                                                />
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                    <MenuItem
                                                        className={classes.menuLastItem}
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.getLeads("all", "everyone");
                                                        }}
                                                    >
                                                        all
                                                    </MenuItem>
                                                    )
                                                </Menu>
                                            )}
                                        </React.Fragment>
                                    )}
                                </PopupState>
                                <PopupState variant="popover" popupId="popup-label-menu">
                                    {popupState => (
                                        <React.Fragment>
                                            <Button
                                                onClick={() => {
                                                    this.getLabels();
                                                }}
                                                className={classes.headerButtonMenu}
                                            >
                                                <span {...bindTrigger(popupState)}>Label menu</span>
                                            </Button>
                                            {currentLabels && currentLabels.length > 0 ? (
                                                <Menu {...bindMenu(popupState)} classes={{ paper: classes.menu }}>
                                                    <MenuItem
                                                        className={classes.labelsButton}
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.handleOpenLabelDialog("label", "add");
                                                        }}
                                                    >
                                                        Add new label
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.labelsButton}
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.handleOpenLabelDialog("label", "delete");
                                                        }}
                                                    >
                                                        Remove label
                                                    </MenuItem>
                                                    {currentLabels.map(({ id, name }) => (
                                                        <MenuItem
                                                            key={id}
                                                            onClick={() => {
                                                                popupState.close();
                                                                this.handleOpenLabelDialog("label", "edit", {
                                                                    id,
                                                                    name,
                                                                });
                                                            }}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            ) : (
                                                <Menu {...bindMenu(popupState)} classes={{ paper: classes.menu }}>
                                                    <MenuItem
                                                        className={classes.labelsButton}
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.handleOpenLabelDialog("label", "add");
                                                        }}
                                                    >
                                                        Add new label
                                                    </MenuItem>
                                                </Menu>
                                            )}
                                        </React.Fragment>
                                    )}
                                </PopupState>
                                <PopupState variant="popover" popupId="popup-tag-menu">
                                    {popupState => (
                                        <React.Fragment>
                                            <Button
                                                onClick={() => {
                                                    this.getTags();
                                                }}
                                                className={classes.headerButtonMenu}
                                            >
                                                <span {...bindTrigger(popupState)}>Tag menu</span>
                                            </Button>
                                            {currentTags && currentTags.length > 0 ? (
                                                <Menu {...bindMenu(popupState)} classes={{ paper: classes.menu }}>
                                                    <MenuItem
                                                        className={classes.labelsButton}
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.handleOpenLabelDialog("tag", "addTag");
                                                        }}
                                                    >
                                                        Add new tag
                                                    </MenuItem>
                                                    <MenuItem
                                                        className={classes.labelsButton}
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.handleOpenLabelDialog("tag", "deleteTag");
                                                        }}
                                                    >
                                                        Remove tag
                                                    </MenuItem>
                                                    {currentTags.map(({ id, name }) => (
                                                        <MenuItem
                                                            key={id}
                                                            onClick={() => {
                                                                popupState.close();
                                                                this.handleOpenLabelDialog("tag", "editTag", {}, {
                                                                    id,
                                                                    name,
                                                                });
                                                            }}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            ) : (
                                                <Menu {...bindMenu(popupState)} classes={{ paper: classes.menu }}>
                                                    <MenuItem
                                                        className={classes.labelsButton}
                                                        onClick={() => {
                                                            popupState.close();
                                                            this.handleOpenLabelDialog("tag", "addTag");
                                                        }}
                                                    >
                                                        Add new tag
                                                    </MenuItem>
                                                </Menu>
                                            )}
                                        </React.Fragment>
                                    )}
                                </PopupState>

                            </div>
                            <div className={classes.wrapperCP}>
                                <div className={classes.wrapperFromToDate}>
                                    <div className={classes.fieldDate}>
                                        <FieldEditor
                                            type={DATE}
                                            name="fromDate"
                                            label="FROM"
                                            onChange={e => this.handleChangeFromToDate("fromDate", e)}
                                            value={fromDate}
                                        />
                                    </div>
                                    <div className={classes.fieldDate}>
                                        <FieldEditor
                                            type={DATE}
                                            name="toDate"
                                            label="TO"
                                            onChange={e => this.handleChangeFromToDate("toDate", e)}
                                            value={toDate}
                                        />
                                    </div>
                                </div>
                                <CollapsePanel
                                    header={`Leads unscheduled (${unscheduledLeads.length})`}
                                    open={false}
                                >
                                    <div className={classes.leadsContainer}>
                                        {unscheduledLeads.length ? (
                                            unscheduledLeads.map((lead, index) => (
                                                <Lead
                                                    onOpen={this.handleOpenNotes}
                                                    key={index}
                                                    cb={this.initialState}
                                                    {...lead}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="subtitle1">NO DATA</Typography>
                                        )}
                                    </div>
                                </CollapsePanel>
                                <CollapsePanel
                                    header={`Leads overdue (${overdueLeads.length})`}
                                    open={false}
                                >
                                    <div className={classes.leadsContainer}>
                                        {overdueLeads.length ? (
                                            overdueLeads.map((lead, index) => (
                                                <Lead
                                                    onOpen={this.handleOpenNotes}
                                                    key={index}
                                                    cb={this.initialState}
                                                    {...lead}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="subtitle1">NO DATA</Typography>
                                        )}
                                    </div>
                                </CollapsePanel>
                                <CollapsePanel
                                    header={`Leads today (${todayLeads.length})`}
                                    open
                                >
                                    <div className={classes.leadsContainer}>
                                        {todayLeads.length ? (
                                            todayLeads.map((lead, index) => (
                                                <Lead
                                                    onOpen={this.handleOpenNotes}
                                                    key={index}
                                                    cb={this.initialState}
                                                    {...lead}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="subtitle1">NO DATA</Typography>
                                        )}
                                    </div>
                                </CollapsePanel>
                                <CollapsePanel
                                    header={`Leads this week (${thisWeekLeads.length})`}
                                    open={false}
                                >
                                    <div className={classes.leadsContainer}>
                                        {thisWeekLeads.length ? (
                                            thisWeekLeads.map((lead, index) => (
                                                <Lead
                                                    onOpen={this.handleOpenNotes}
                                                    key={index}
                                                    cb={this.initialState}
                                                    {...lead}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="subtitle1">NO DATA</Typography>
                                        )}
                                    </div>
                                </CollapsePanel>
                                <CollapsePanel
                                    header={`Leads next week (${nextWeekLeads.length})`}
                                    open={false}
                                >
                                    <div className={classes.leadsContainer}>
                                        {nextWeekLeads.length ? (
                                            nextWeekLeads.map((lead, index) => (
                                                <Lead
                                                    onOpen={this.handleOpenNotes}
                                                    key={index}
                                                    cb={this.initialState}
                                                    {...lead}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="subtitle1">NO DATA</Typography>
                                        )}
                                    </div>
                                </CollapsePanel>
                                <CollapsePanel
                                    header={`Leads from Date - to Date (${fromDateToDateLeads.length})`}
                                    open={false}
                                >
                                    <div className={classes.leadsContainer}>
                                        {fromDateToDateLeads.length ? (
                                            fromDateToDateLeads.map((lead, index) => (
                                                <Lead
                                                    onOpen={this.handleOpenNotes}
                                                    key={index}
                                                    cb={this.initialState}
                                                    {...lead}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="subtitle1">NO DATA</Typography>
                                        )}
                                    </div>
                                </CollapsePanel>
                            </div>
                        </Paper>
                    </Grid>
                )}
                {openType === "label" && deleteMode === false && (<DialogWrapper
                    open={open}
                    onClose={this.handleCloseLabelDialog}
                    handleClick={this.handleSave}
                    title="Add/Edit Label"
                    buttonText="Save"
                >
                    <LabelForm label={label} deleteMode={deleteMode} onChange={this.handleChange}/>
                </DialogWrapper>)}
                {openType === "label" && deleteMode === true && (<DialogWrapper
                    open={open}
                    onClose={this.handleCloseLabelDialog}
                    handleClick={this.handleDelete}
                    title="Select label to delete"
                    buttonText="Remove label"
                >
                    <LabelForm
                        label={label}
                        deleteMode={deleteMode}
                        onChange={this.handleChange}
                        labelOptions={this.labelOptions}
                    />
                </DialogWrapper>)}
                {openType === "tag" && deleteMode === false && (<DialogWrapper
                    open={open}
                    onClose={this.handleCloseTagDialog}
                    handleClick={this.handleSave}
                    title="Add/Edit Tag"
                    buttonText="Save"
                >
                    <LabelForm tag={tag} deleteMode={deleteMode} onChange={this.handleChangeTags}/>
                </DialogWrapper>)}
                {openType === "tag" && deleteMode === true && (<DialogWrapper
                    open={open}
                    onClose={this.handleCloseLabelDialog}
                    handleClick={this.handleDelete}
                    title="Select tag to delete"
                    buttonText="Remove tag"
                >
                    <LabelForm
                        tag={tag}
                        deleteMode={deleteMode}
                        onChange={this.handleChangeTags}
                        tagOptions={this.tagOptions}
                    />
                </DialogWrapper>)}
                <DialogWrapper open={openNotes} onClose={this.handleCloseNotes}>
                    <div className={classes.wrapperNotes}>
                        <Typography variant="subtitle1">
                            {notesToShow.title || ""}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            dangerouslySetInnerHTML={{ __html: notesToShow.body }}
                        />
                    </div>
                </DialogWrapper>
            </Grid>
        );
    }
}

export const styles = theme => ({
    item: {
        width: "100%",
        display: "flex",
        marginTop: 18,
        flexDirection: "column",
    },
    spinner: {
        marginLeft: 15,
    },
    menuItem: {
        display: "flex",
        width: 270,
    },
    wrapperNotes: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        minWidth: 160,
        minHeight: 240,
    },
    menuLastItem: {
        display: "flex",
        justifyContent: "center",
        width: 270,
    },
    leadsContainer: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gridGap: "1em",
    },
    content: {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 2}px)`,
    },
    wrapperCP: {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 2 + 50}px)`,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    header: {
        height: 56,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: theme.palette.primary[500],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    headerButtonMenu: {
        height: "inherit",
    },
    wrapperFromToDate: {
        display: "flex",
        padding: "10px 10px 15px 10px",
    },
    fieldDate: {
        width: 180,
        margin: "0 10px",
    },
    labelsButton: {
        display: "flex",
        justifyContent: "center",
        "&:hover": {
            backgroundColor: theme.palette.primary[400],
        },
    },
    menu: {
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

const mapStateToProps = state => ({
    leads: selectLeads(state),
    entity: selectEntity(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Leads));
