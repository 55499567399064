import moment from "moment";
import "moment/locale/en-gb";

moment.locale("en-gb");

export const getAge = ({ birth_date, birth_year }) =>
  (birth_date && moment().diff(birth_date, "years")) ||
  (birth_year && moment().diff(moment([birth_year]), "years")) ||
  "";

export const getDifferenceInDays = date => {
  // except hours
  const ms = new Date(date) - new Date();
  const day = 1000 * 60 * 60 * 24;
  return Math.ceil(ms / day);
};

export const getDateFromNow = date => {
  const d = new Date(date);
  const res = getDifferenceInDays(date);

  const formatedDate = moment(d)
    .format("LL")
    .toLowerCase();

  if (res === 0) return { label: "Today", date: formatedDate, colorType: "in" };
  else if (res === 1)
    return { label: "Tomorrow", date: formatedDate, colorType: "in" };
  else if (res === -1)
    return { label: "Yesterday", date: formatedDate, colorType: "ago" };

  // d.setDate(d.getDate() + 1);

  const str = moment(d, "YYYYMMDD").fromNow();

  if (str.includes("in")) {
    const formatedLabel = str
      .split(" ")
      .slice(1)
      .join(" ");

    return {
      label: `${formatedLabel} from now`,
      date: formatedDate,
      colorType: "in"
    };
  }

  return { label: str, date: formatedDate, colorType: "ago" };
};

export const getPersonTitle = gender => {
  switch (gender) {
    case "M":
      return "Mr.";
    case "V":
      return "Ms.";
    default:
      return "";
  }
};

export const getPersonName = (person, withAge) => {
  const { nick_name, first_name, last_name, prefix, initials, gender } = person;
  const personPrefix = prefix ? prefix : "";
  const age = getAge(person);
  const title = getPersonTitle(gender);
  let firstName = nick_name || first_name || "";
  const newInitials = initials
    ? first_name
      ? initials.replace(first_name[0].toUpperCase() + ".", "")
      : initials
    : "";

  if (personPrefix && personPrefix !== "") {
    firstName = first_name;
    return `${title} ${
      firstName ? `${firstName} ` : ""
    } ${newInitials} ${personPrefix} ${last_name || ""} ${
      age && withAge ? `(${age})` : ""
    }`.trim();
  } else {
    return `${title} ${firstName ? `${firstName} ` : ""} ${
      nick_name ? initials : newInitials
    } ${last_name || ""} ${age && withAge ? `(${age})` : ""}`.trim();
  }
};

export const getLeadPercentage = ({ index = 1, length = 1 }) => {
  const percentage = (1 - index / length) * 100;
  return index ? percentage.toFixed(2) : null;
};
