import React from "react";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface CollapsePanelProps {
    header?: string,
    headBlock?: React.HTMLAttributes<HTMLDivElement>,
    headClassName?: string,
    panelClassName?: string,
    wrapperHeadClassName?: string,
    panelDetailsClassName?: string,
    children?: React.ReactNode,
    open?: boolean,
    showExpandIcon?: boolean,
}

const CollapsePanel: React.FC<CollapsePanelProps> = ({
                                                         header,
                                                         headBlock,
                                                         headClassName,
                                                         panelClassName,
                                                         wrapperHeadClassName,
                                                         panelDetailsClassName,
                                                         children,
                                                         open,
                                                         showExpandIcon,
                                                     }) => {
    return (
        <ExpansionPanel className={panelClassName} defaultExpanded={open}>
            <ExpansionPanelSummary
                className={wrapperHeadClassName}
                expandIcon={showExpandIcon ? <ExpandMoreIcon/> : null}
            >
                <div className={headClassName}>
                    {header && (
                        <Typography variant="subtitle1" component="div">
                            {header}
                        </Typography>
                    )}
                    {headBlock}
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={panelDetailsClassName}>
                {children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default CollapsePanel;
