import React from 'react';
import 'react-phone-number-input/style.css';
import 'react-responsive-ui/style.css';
import './style.css';
import ReactPhoneInput from 'react-phone-number-input/react-responsive-ui';

interface PhoneEditorProps {
    onChange: (value: any) => void,
    value: string,
    placeholder: string,
    country: string
}

const PhoneEditor: React.FC<PhoneEditorProps> = ({onChange, value, placeholder, country}) => {
    return (
        <ReactPhoneInput
            placeholder={placeholder}
            value={value}
            country={country}
            onChange={onChange}
        />
    );
};

PhoneEditor.propTypes = {};

export default PhoneEditor;
