import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import People from "@material-ui/icons/People";
import DisplayEntity from "../common/DisplayEntity";
import DialogWrapper from "../../../../common/components/dialog";
import { EntityEditor } from "../../../../common/components/editor/entity";
import deepPurple from "@material-ui/core/colors/deepPurple";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Search from "../common/Search";
import { getPersonName } from "../../../../common/helpers/getter";
import { crossSign } from "../../../../common/constants/symbols";
import { updateEntityRequest } from "../../actions";
import { validate, validateKadID } from "../../../../common/helpers/validator";
import {
  getObjectsAmount,
  leadgenObjectsByOwnerRequest,
} from "../../../leadgen/actions/objects";
import { selectEntity } from "../../selectors";
import { selectLeadgen } from "../../../leadgen/selectors";
import { leadgenDependentEntitiesRequest } from "../../../leadgen/actions/dependentEntities";

class AssetManager extends Component {
  state = {
    action_types: [],
    managers: [],
    anchorElAssetManager: null,
    managerIndex: null,
    managerForUpdate: {},
    openDialog: {
      editAssetManager: false,
      deleteAssetManager: false,
      addAssetManager: false,
    },
  };

  componentDidUpdate(prevProps) {
    const { leadgen } = this.props;
    const { managers } = leadgen;

    if (prevProps.leadgen.managers !== managers) {
      this.setState({
        managers: managers,
      });
    }
  }

  getAvatarName = (company_name, last_name, first_name, all_names) =>
    `${
      last_name
        ? last_name[0].toUpperCase()
        : company_name
        ? company_name[0].toUpperCase()
        : ""
    }${
      (first_name ? first_name[0].toUpperCase() : "") ||
      (all_names ? all_names[0].toUpperCase() : "")
    }`;

  handleOpenMenu = (e) => {
    this.setState({ anchorElAssetManager: e.currentTarget });
  };

  handleCloseMenu = (personIndex) => {
    this.setState({ anchorElAssetManager: null, [personIndex]: null });
  };

  handleAddPerson = (person) => {
    const { managers } = this.state;
    const persons = managers || [];
    persons[0] = person;
    this.setState({ managers: persons });
  };

  handleOpenDialog = (actionType) => () => {
    const { openDialog, managers } = this.state;

    const newState = {
      openDialog: { ...openDialog, [actionType]: true },
      anchorElAssetManager: null,
    };

    if (actionType === "addAssetManager") {
      const newManagers = managers || [];
      newManagers.unshift({});
      newState.managers = newManagers;
    }

    this.setState(newState);
  };

  handleCloseDialog = (actionType, personType) => () => {
    const { openDialog, managers } = this.state;
    managers.shift();
    const newState = {
      openDialog: { ...openDialog, [actionType]: false },
      managers: managers,
    };

    if (personType) newState[personType] = null;

    this.setState(newState);
  };

  handleDisplayItem = (personIndex, index) => {
    this.setState({ [personIndex]: index });
  };

  handleChange =
    (index) =>
    (name, { target }, deleteMode, deletedItem) => {
      const { managers, action_types } = this.state;
      let newItems = managers;
      const {
        addressesForDelete,
        phoneNumbersForDelete,
        emailsForDelete,
        socialsForDelete,
      } = newItems[index];

      const default_type = "general_fields";
      if(!deleteMode) {
        switch (name) {
          case "phone_numbers":
          case "addresses":
          case "socials":
          case "emails":
            return this.setState({
              action_types: action_types.includes(name)
                ? action_types
                : [name, ...action_types],
              managerForUpdate: {
                ...newItems[index],
                [name]: target.value,
              },
            });
          default:
            this.setState({
              action_types: action_types.includes(default_type)
                ? action_types
                : [name, ...action_types],
              managerForUpdate: {
                ...newItems[index],
                [name]: target.value,
              },
            });
            break;
        }
      }

      if (name === "phone_numbers" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],

          managerForUpdate: {
            ...newItems[index],
            [name]: target.value,
            phoneNumbersForDelete: [
              deletedItem[0].id,
              ...phoneNumbersForDelete,
            ],
          },
        });
      }
      if (name === "addresses" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],
          managerForUpdate: {
            ...newItems[index],
            [name]: target.value,
            addressesForDelete: [deletedItem[0].id, ...addressesForDelete],
          },
        });
      }
      if (name === "socials" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],
          managerForUpdate: {
            ...newItems[index],
            [name]: target.value,
            socialsForDelete: [deletedItem[0].id, ...socialsForDelete],
          },
        });
      }
      if (name === "emails" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],
          managerForUpdate: {
            ...newItems[index],
            [name]: target.value,
            emailsForDelete: [deletedItem[0].id, ...emailsForDelete],
          },
        });
      }
      const link =
        "https://kadaster-on-line.kadaster.nl/selectie.asp?identificatie=NL.IMKAD.Persoon.";

      const kvkLink =
        "https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer=";

      if (name === "kadaster_personal_number") {
        this.setState({
          action_types: action_types,
          managerForUpdate: {
            ...newItems[index],
            [name]: target.value,
            kadaster_url: target.value ? link + target.value : null,
          },
        });
        return null;
      }

      if (name === "chamber_commerce_number") {
        return this.setState({
          action_types: action_types,
          managerForUpdate: {
            ...newItems[index],
            [name]: target.value,
            chamber_commerce_url: target.value ? kvkLink + target.value : null,
          },
        });
      }

      newItems[index] = { ...newItems[index], [name]: target.value };
      this.setState({
        contacts: newItems,
        action_types: action_types.includes(default_type)
          ? action_types
          : [default_type, ...action_types],
        managerForUpdate: {
          ...newItems[index],
          [name]: target.value,
        },
      });

      newItems[index] = { ...newItems[index], [name]: target.value };

      this.setState({
        managers: newItems,
        action_types: action_types.includes(default_type)
          ? action_types
          : [default_type, ...action_types],
        managerForUpdate: {
          ...newItems[index],
          [name]: target.value,
        },
      });
    };

  handleValidate = async (entityType, entity) => {
    const isValidated = validate(entityType, { ...entity });
    let isExistedKadID = false;
    const kad_id = entity.kadaster_personal_number;

    if (kad_id) {
      await validateKadID(
        "entity",
        { kad_id, id: entity.id },
        (data) => (isExistedKadID = data)
      );
    }

    return isValidated && !isExistedKadID;
  };

  handleSave = (actionType, personType, index) => async () => {
    const { dispatch, currentEntity, router } = this.props;
    const { entity } = currentEntity;
    const { openDialog, managers, managerForUpdate, action_types } = this.state;
    const currentType = "managers";
    let deleteMode = false;
    let managersUpdateId = null;
    let newManagerId = null;
    const newState = {
      openDialog: { ...openDialog, [actionType]: false },
    };

    if (personType) newState[personType] = null;

    switch (actionType) {
      case "addAssetManager":
        newManagerId = managers[0].id;
        if (
          !(await this.handleValidate(managers[0].entity_type, {
            ...managers[0],
          }))
        ) {
          return;
        } else {
          newState.managers = managers;
          break;
        }

      case "deleteAssetManager":
        managersUpdateId = managers[index].id;
        deleteMode = true;
        managers.splice(index, 1);
        newState.managers = managers;
        break;

      case "editAssetManager":
        managersUpdateId = managers[index].id;
        if (
          !(await this.handleValidate(managers[index].entity_type, {
            ...managers[index],
          }))
        ) {
          return;
        } else break;

      default:
        return;
    }

    this.setState(newState);

    if (actionType === "editAssetManager") {
      const index = action_types.indexOf(currentType);
      action_types.slice(index, 1);
      return (
        dispatch(
          updateEntityRequest(
            deleteMode,
            action_types,
            managerForUpdate.id,
            { ...managerForUpdate },
            router,
            () => dispatch(leadgenDependentEntitiesRequest(entity.id))
          )
        ) &&
        dispatch(leadgenObjectsByOwnerRequest(entity.id)) &&
        dispatch(getObjectsAmount(entity.id))
      );
    } else {
      return (
        dispatch(
          updateEntityRequest(
            deleteMode,
            [currentType],
            entity.id,
            { ...entity, managersUpdateId, newManagerId },
            router,
            () => dispatch(leadgenDependentEntitiesRequest(entity.id))
          )
        ) &&
        dispatch(leadgenObjectsByOwnerRequest(entity.id)) &&
        dispatch(getObjectsAmount(entity.id))
      );
    }
  };

  render() {
    const { classes, validationMode, deleteLinkedCompany } = this.props;

    const { managers, anchorElAssetManager, openDialog, managerIndex } =
      this.state;

    const managersIsEmpty = !!managers.length;

    return (
      <div>
        <div
          className={classes.wrapperAvatar}
          onClick={(e) => this.handleOpenMenu(e)}
        >
          <Avatar
            className={managersIsEmpty ? classes.avatarName : classes.image}
          >
            <People />
          </Avatar>

          <ListItemText
            className={classes.itemText}
            primary={
              <Typography className={classes.text} variant="body2">
                Asset Managers
              </Typography>
            }
            secondary={
              <span>
                {managersIsEmpty
                  ? managers.length === 1
                    ? managers[0].company_name
                      ? managers[0].company_name.length > 15
                        ? managers[0].company_name.slice(0, 15) + "..."
                        : managers[0].company_name
                      : getPersonName(managers[0], true)
                    : managers
                        .map(
                          ({
                            company_name,
                            last_name,
                            first_name,
                            all_names,
                          }) =>
                            this.getAvatarName(
                              company_name,
                              last_name,
                              first_name,
                              all_names
                            )
                        )
                        .toString()
                  : "n/a"}
                {managersIsEmpty &&
                  managers.length === 1 &&
                  managers[0].is_dead && <span>({crossSign})</span>}
              </span>
            }
          />
        </div>
        <Menu
          id={anchorElAssetManager}
          anchorEl={anchorElAssetManager}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transitionDuration={0}
          disableAutoFocusItem
          className={classes.menu}
          open={Boolean(anchorElAssetManager)}
          onClose={() => this.handleCloseMenu("managerIndex")}
          MenuListProps={{
            className: managersIsEmpty
              ? classes.menuList
              : classes.emptyMenuList,
          }}
        >
          {managersIsEmpty && managerIndex === null ? (
            <div>
              {managers.map(
                (
                  {
                    company_name,
                    last_name,
                    first_name,
                    initials,
                    all_names,
                    is_dead,
                  },
                  index
                ) => (
                  <div key={index}>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() =>
                        this.handleDisplayItem("managerIndex", index)
                      }
                    >
                      <Avatar className={classes.menuItemName}>
                        {this.getAvatarName(
                          company_name,
                          last_name,
                          first_name,
                          all_names
                        ) || <Person />}
                      </Avatar>
                      <ListItemText
                        className={classes.itemText}
                        primary={
                          <span>
                            {`${last_name || company_name || ""} ${
                              first_name || initials || ""
                            }`.trim() || "n/a"}
                            {is_dead ? <span>({crossSign})</span> : ""}
                          </span>
                        }
                      />
                    </MenuItem>
                  </div>
                )
              )}
              <MenuItem
                className={classes.menuLastItem}
                onClick={this.handleOpenDialog("addAssetManager")}
              >
                add new manager
              </MenuItem>
            </div>
          ) : (
            <DisplayEntity
              buttons={
                !managersIsEmpty
                  ? [
                      {
                        btnText: "add new manager",
                        handler: this.handleOpenDialog("addAssetManager"),
                      },
                    ]
                  : [
                      {
                        btnText: "edit",
                        handler: this.handleOpenDialog("editAssetManager"),
                      },
                      {
                        btnText: "unlink",
                        handler: this.handleOpenDialog("deleteAssetManager"),
                      },
                    ]
              }
              entity={managers[managerIndex]}
            />
          )}
        </Menu>
        <DialogWrapper
          open={openDialog.editAssetManager}
          onClose={this.handleCloseDialog("editAssetManager", "managerIndex")}
          handleClick={this.handleSave(
            "editAssetManager",
            "managerIndex",
            managerIndex
          )}
          title="Edit Asset Manager"
          buttonText="Save"
        >
          <EntityEditor
            validationMode={validationMode}
            deleteLinkedCompany={deleteLinkedCompany}
            entityType={
              managers[managerIndex] ? managers[managerIndex].entity_type : null
            }
            editableEntity={managers[managerIndex] || {}}
            onChange={this.handleChange(managerIndex)}
          />
        </DialogWrapper>
        <DialogWrapper
          open={openDialog.deleteAssetManager}
          onClose={this.handleCloseDialog("deleteAssetManager", "managerIndex")}
          handleClick={this.handleSave(
            "deleteAssetManager",
            "managerIndex",
            managerIndex
          )}
          title="Unlink Asset Manager"
          buttonText="Unlink"
        >
          <Typography className={classes.deleteBlock} variant="subtitle1">
            Are you sure you want to unlink
            {managers[managerIndex]
              ? " " +
                `${
                  managers[managerIndex].first_name ||
                  managers[managerIndex].initials ||
                  ""
                } ${managers[managerIndex].last_name || ""}`.trim()
              : ""}
            ?
          </Typography>
        </DialogWrapper>
        <DialogWrapper
          open={openDialog.addAssetManager}
          onClose={this.handleCloseDialog("addAssetManager", "managerIndex")}
          handleClick={this.handleSave(
            "addAssetManager",
            "managerIndex",
            managerIndex
          )}
          title="Add Asset Manager"
          buttonText="Save"
        >
          <div className={classes.wrapper}>
            <Search addPerson={(entity) => this.handleAddPerson(entity)} />
            {openDialog.addAssetManager &&
            managers[0] &&
            Object.keys(managers[0]).length ? (
              <div className={classes.entityEditorWrapper}>
                <EntityEditor
                  validationMode={validationMode}
                  entityType={managers[0] ? managers[0].entity_type : null}
                  editableEntity={managers[0] || {}}
                  onChange={this.handleChange(0)}
                />
              </div>
            ) : null}
          </div>
        </DialogWrapper>
      </div>
    );
  }
}

AssetManager.propTypes = {};

const styles = (theme) => ({
  wrapper: {
    minWidth: 550,
  },
  entityEditorWrapper: {
    marginTop: 20,
  },
  wrapperAvatar: {
    display: "flex",
    padding: 5,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.14)",
      cursor: "pointer",
    },
  },
  text: {
    fontStyle: "italic",
  },
  deleteBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
  menu: {
    top: 56,
  },
  menuList: {
    padding: "8px 0 0 0",
  },
  emptyMenuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuLastItem: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 8,
  },
  menuItem: {
    padding: 16,
  },
  itemText: {
    marginRight: 0,
  },
  iconButton: {
    padding: 6,
  },
  image: {
    width: 45,
    height: 45,
    backgroundColor: theme.palette.primary[400],
  },
  avatarName: {
    width: 45,
    height: 45,
    backgroundColor: lightGreen[700],
  },
  menuItemName: {
    width: 45,
    height: 45,
    backgroundColor: deepPurple[500],
  },
});

const mapStateToProps = (state) => ({
  currentEntity: selectEntity(state),
  leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(
  withStyles(styles)(React.memo(AssetManager))
);
