import ErrorHandler from "../../../common/services/error.handler";
import AlertService from "../../../common/services/alert.service";
import {
    QUERY_OBJECTS_SAVE_CONFIG_REQUEST,
    QUERY_OBJECTS_SAVE_CONFIG_SUCCESS,
    QUERY_OBJECTS_SAVE_CONFIG_ERROR,

    QUERY_OBJECTS_RECEIVE_CONFIGS_REQUEST,
    QUERY_OBJECTS_RECEIVE_CONFIGS_SUCCESS,
    QUERY_OBJECTS_RECEIVE_CONFIGS_ERROR,

    QUERY_OBJECTS_DELETE_CONFIG_REQUEST,
    QUERY_OBJECTS_DELETE_CONFIG_SUCCESS,
    QUERY_OBJECTS_DELETE_CONFIG_ERROR,

    QUERY_OBJECTS_GET_RESULTS_REQUEST,
    QUERY_OBJECTS_GET_RESULTS_SUCCESS,
    QUERY_OBJECTS_GET_RESULTS_ERROR,

    QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST,
    QUERY_OBJECTS_GET_PREV_RESULTS_LIST_SUCCESS,
    QUERY_OBJECTS_GET_PREV_RESULTS_LIST_ERROR,

    QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST,
    QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_SUCCESS,
    QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_ERROR,
} from "../../../common/constants";

export const receiveQueryObjectsConfigsRequest = () => {
    return {
        type: QUERY_OBJECTS_RECEIVE_CONFIGS_REQUEST,
    };
};

export const saveQueryObjectsConfigRequest = config => {
    return {
        type: QUERY_OBJECTS_SAVE_CONFIG_REQUEST,
        config,
    };
};

export const deleteQueryObjectsConfigRequest = id => {
    return {
        type: QUERY_OBJECTS_DELETE_CONFIG_REQUEST,
        id,
    };
};

export const receiveQueryObjectsResultsRequest = ({ config }) => {
    return {
        type: QUERY_OBJECTS_GET_RESULTS_REQUEST,
        config,
    };
};

export const getPrevResultsRequest = prevItem => {
    return {
        type: QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST,
        prevItem,
    };
};

export const getNextResultsRequest = lastItem => {
    return {
        type: QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST,
        lastItem,
    };
};

export const saveQueryObjectsConfigSuccess = () => {
    AlertService.success("Config successfully saved");
    return {
        type: QUERY_OBJECTS_SAVE_CONFIG_SUCCESS,
    };
};

export const receiveQueryObjectsConfigsSuccess = queryObjectsConfigs => {
    return {
        type: QUERY_OBJECTS_RECEIVE_CONFIGS_SUCCESS,
        queryObjectsConfigs: queryObjectsConfigs.data,
    };
};

export const deleteQueryObjectsConfigsSuccess = () => {
    AlertService.success("QUERY CONFIG DELETED");
    return {
        type: QUERY_OBJECTS_DELETE_CONFIG_SUCCESS,
    };
};

export const receiveQueryObjectsResultsSuccess = result => {
    return {
        type: QUERY_OBJECTS_GET_RESULTS_SUCCESS,
        result: result.data,
    };
};

export const getPrevResultsSuccess = result => {
    return {
        type: QUERY_OBJECTS_GET_PREV_RESULTS_LIST_SUCCESS,
        result: result.data,
    };
};

export const getNextResultsSuccess = result => {
    return {
        type: QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_SUCCESS,
        result: result.data,
    };
};

export const saveQueryObjectsConfigsError = error => {
    ErrorHandler.do(error);
    return {
        type: QUERY_OBJECTS_SAVE_CONFIG_ERROR,
        error,
    };
};

export const receiveQueryObjectsConfigsError = error => {
    ErrorHandler.do(error);
    return {
        type: QUERY_OBJECTS_RECEIVE_CONFIGS_ERROR,
        error,
    };
};

export const deleteQueryObjectsConfigsError = error => {
    ErrorHandler.do(error);
    return {
        type: QUERY_OBJECTS_DELETE_CONFIG_ERROR,
        error,
    };
};

export const receiveQueryObjectsResultsError = error => {
    ErrorHandler.do(error);
    return {
        type: QUERY_OBJECTS_GET_RESULTS_ERROR,
        error,
    };
};

export const getNextResultsError = error => {
    ErrorHandler.do(error);
    return {
        type: QUERY_OBJECTS_GET_PREV_RESULTS_LIST_ERROR,
        error,
    };
};

export const getPrevResultsError = error => {
    ErrorHandler.do(error);
    return {
        type: QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_ERROR,
        error,
    };
};
