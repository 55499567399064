import moment from 'moment';
import 'moment/locale/en-gb';

moment.locale('en-gb');

export const formatDateAndTime = date =>
  moment(date).format('HH:mm, DD-MM-YYYY');

export const formatDateAndTimeTable = date =>
  moment(date).format('DD-MM-YYYY/HH:mm');

export const formatDate = date => moment(date).format('YYYY-MM-DD');

export const formatImportDate = date => moment(date).format('DD-MM-YYYY');

export const formatBirthDate = date => moment(date).format('LL');

export const formatTime = date => moment(date).format('HH:mm');
