import React from "react";
import { withStyles } from "@material-ui/core/styles";

interface ContainerProps {
  classes: {
    cell: string;
    space: string;
  };
  leftColumn: any;
  rightColumn: any;
}

const Container: React.FC<ContainerProps> = ({
  classes,
  leftColumn,
  rightColumn,
}) => {

  return !rightColumn ? (
    <div>
      {leftColumn &&
        leftColumn.map((Item, index) => (
          <React.Fragment key={index}>{Item}</React.Fragment>
        ))}
    </div>
  ) : (
    <React.Fragment>
      <div className={classes.cell}>
        {leftColumn &&
          leftColumn.map((Item, index) => (
            <React.Fragment key={index}>{Item}</React.Fragment>
          ))}
      </div>
      <div className={classes.space} />
      <div className={classes.cell}>
        {rightColumn &&
          rightColumn.map((Item, index) => (
            <React.Fragment key={index}>{Item}</React.Fragment>
          ))}
      </div>
    </React.Fragment>
  );
};

const styles = (theme) => ({
  cell: {
    display: "table-cell",
    width: "45%",
  },
  space: {
    display: "table-cell",
    width: "10%",
  },
});

export default withStyles(styles)(Container);
