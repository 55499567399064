import { put, takeLatest, call } from 'redux-saga/effects';
import ApiService from '../../../common/services/api.service';
import {
  fetchLeadgenOwnersSuccess,
  leadgenEmptyOwnersSuccess,
  fetchLeadgenOwnersError,
  leadgenEmptyOwnersError,
} from '../actions/owners';
import {
  GET_LEADGEN_OWNERS_REQUEST,
  SET_EMPTY_LEADGEN_OWNERS_REQUEST,
} from '../../../common/constants';

function* fetchLeadgenOwners(action) {
  try {
    const owners = yield call(ApiService.post2, '/entity/data', action.ids);
    yield put(fetchLeadgenOwnersSuccess(owners));
  } catch (error) {
    yield put(fetchLeadgenOwnersError(error));
  }
}

function* setLeadgenEmptyOwners() {
  try {
    const owners = yield call(ApiService.post2, '/entity/data', '', []);
    yield put(leadgenEmptyOwnersSuccess(owners));
  } catch (error) {
    yield put(leadgenEmptyOwnersError(error));
  }
}

export default function* actionLeadgenOwners() {
  yield takeLatest(GET_LEADGEN_OWNERS_REQUEST, fetchLeadgenOwners);
  yield takeLatest(SET_EMPTY_LEADGEN_OWNERS_REQUEST, setLeadgenEmptyOwners);
}
