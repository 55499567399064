import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Item from "./Item";
import Container from "../../../../common/components/editor/entity/Container";
import { formatBirthDate } from "../../../../common/formats/date";
import { customFormatPhoneNumber } from "../../../../common/formats/phone-number";
import { getAge } from "../../../../common/helpers/getter";

const DisplayEntity = ({ classes, entity = {}, buttons = [] }) => {
  const httpVariable = "http://";
  const httpsVariable = "https://";
  const ftpVariable = "ftp://";
  let currentEntityUrl = "";
  let displayingEntityUrl = "";

  if (entity.url && entity.url.includes(httpVariable)) {
    currentEntityUrl = entity.url;
  } else if (entity.url && entity.url.includes(httpsVariable)) {
    currentEntityUrl = entity.url;
  } else if (entity.url && entity.url.includes(ftpVariable)) {
    currentEntityUrl = entity.url;
  } else if (!entity.url) {
    currentEntityUrl = "";
  } else {
    currentEntityUrl =
      `${httpsVariable}${entity.url}` ||
      `${httpVariable}${entity.url}` ||
      `${ftpVariable}${entity.url}`;
  }

  if (currentEntityUrl && currentEntityUrl.length > 25) {
    displayingEntityUrl = `${entity.url.substr(0, 15)}...`;
  } else if (!(currentEntityUrl && entity.url)) {
    displayingEntityUrl = "link to...";
  }
  return (
    <React.Fragment>
      {!!Object.keys(entity).length ? (
        <div>
          {entity && entity.entity_type === "company" ? (
            <Container
              classes={{ space: classes.space, cell: classes.cell }}
              leftColumn={[
                <Item title="Company:" text={entity.company_name || "n/a"} />,
                <Item
                  title="Kad. Objects:"
                  text={entity.kadaster_objects_amount || "n/a"}
                />,
                <Item
                  title="K.P.N:"
                  text={entity.kadaster_personal_number || "n/a"}
                />,
                <Item
                  title="Kad. Link:"
                  text={
                    entity.kadaster_url ? (
                      <a
                        href={entity.kadaster_url || "/"}
                        title={"Kadaster link"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                      >
                        link to...
                      </a>
                    ) : (
                      "n/a"
                    )
                  }
                />,
                <Item
                  title="Kvk Number:"
                  text={entity.chamber_commerce_number || "n/a"}
                />,
                <Item
                  title="KvK Link:"
                  text={
                    entity.chamber_commerce_url ? (
                      <a
                        href={entity.chamber_commerce_url || "/"}
                        title={"KvK link"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                      >
                        link to...
                      </a>
                    ) : (
                      "n/a"
                    )
                  }
                />,
                <Item
                  title="Blacklisted:"
                  text={
                    entity.blacklisted === undefined ||
                    entity.blacklisted === null
                      ? "n/a"
                      : entity.blacklisted
                      ? "yes"
                      : "no"
                  }
                />
              ]}
            />
          ) : (
            <Container
              classes={{ space: classes.space, cell: classes.cell }}
              leftColumn={[
                <Item title="First name:" text={entity.first_name || "n/a"} />,
                <Item title="Prefix:" text={entity.prefix || "n/a"} />,
                <Item title="Last name:" text={entity.last_name || "n/a"} />,
                <Item title="Initials:" text={entity.initials || "n/a"} />,
                <Item title="All names:" text={entity.all_names || "n/a"} />,
                <Item title="Nick name:" text={entity.nick_name || "n/a"} />,
                <Item title="Gender:" text={entity.gender || "n/a"} />,
                <Item title="Age:" text={getAge(entity) || "n/a"} />,
                <Item
                  title="Blacklisted:"
                  text={
                    entity.blacklisted === undefined ||
                    entity.blacklisted === null
                      ? "n/a"
                      : entity.blacklisted
                      ? "yes"
                      : "no"
                  }
                />
              ]}
              rightColumn={[
                <Item
                  title="D.o.B.:"
                  text={
                    entity.birth_date
                      ? formatBirthDate(entity.birth_date)
                      : "n/a"
                  }
                />,
                <Item title="Y.o.B.:" text={entity.birth_year || "n/a"} />,
                <Item title="P.o.B.:" text={entity.birth_place || "n/a"} />,
                <Item
                  title="Kad. objects:"
                  text={entity.kadaster_objects_amount || "n/a"}
                />,
                <Item
                  title="K.P.N:"
                  text={entity.kadaster_personal_number || "n/a"}
                />,
                <Item
                  title="Kad. Link:"
                  text={
                    entity.kadaster_url ? (
                      <a
                        href={entity.kadaster_url || "/"}
                        title={"Kadaster link"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                      >
                        link to...
                      </a>
                    ) : (
                      "n/a"
                    )
                  }
                />,
                <Item
                  title="Link:"
                  text={
                    <a
                      href={currentEntityUrl || "/"}
                      title={"Link"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.link}
                    >
                      {displayingEntityUrl || entity.url}
                    </a>
                  }
                />,

                <Item
                  title="Alive:"
                  text={
                    entity.is_dead === undefined || entity.is_dead === null
                      ? "n/a"
                      : entity.is_dead
                      ? "no"
                      : "yes"
                  }
                />
              ]}
            />
          )}

          <Divider className={classes.divider} />
          <Typography className={classes.title} variant="body2">
            Address
          </Typography>
          {entity && entity.addresses && entity.addresses.length ? (
            entity.addresses.map(
              (
                { type, street_name, city_name, number, number_add, postcode },
                index
              ) => (
                <div className={classes.wrapper} key={index}>
                  <Container
                    classes={{ space: classes.space, cell: classes.cell }}
                    leftColumn={[
                      <Item title="Type:" text={type || "n/a"} />,
                      <Item
                        title="Street:"
                        text={
                          `${street_name || ""} ${number || ""} ${number_add ||
                            ""}`.trim() || "n/a"
                        }
                      />
                    ]}
                    rightColumn={[
                      <Item title="City:" text={city_name || "n/a"} />,
                      <Item title="Postcode:" text={postcode || "n/a"} />
                    ]}
                  />
                </div>
              )
            )
          ) : (
            <Container leftColumn={[<Item title="n/a" text={""} />]} />
          )}
          <Divider className={classes.divider} />
          <Typography className={classes.title} variant="body2">
            Phone Numbers
          </Typography>
          {entity && entity.phone_numbers && entity.phone_numbers.length ? (
            entity.phone_numbers.map(({ type, number = "" }, index) => (
              <div className={classes.wrapper} key={index}>
                <Container
                  classes={{ space: classes.space, cell: classes.cell }}
                  leftColumn={[<Item title="Type:" text={type || "n/a"} />]}
                  rightColumn={[
                    <Item
                      title="Number:"
                      text={
                        <a
                          href={`tel:${number || ""}`}
                          title={"Tel"}
                          className={classes.link}
                        >
                          {customFormatPhoneNumber(
                            number.includes("+316")
                              ? [1, 3, 2, 3]
                              : [2, 3, 2, 2],
                            number,
                            "+31"
                          ) || "n/a"}
                        </a>
                      }
                    />
                  ]}
                />
              </div>
            ))
          ) : (
            <Container leftColumn={[<Item title="n/a" text={""} />]} />
          )}
          <Divider className={classes.divider} />
          <Typography className={classes.title} variant="body2">
            Emails
          </Typography>
          {entity && entity.emails && entity.emails.length ? (
            entity.emails.map(({ type, email }, index) => (
              <div className={classes.wrapper} key={index}>
                <Container
                  classes={{ space: classes.space, cell: classes.cell }}
                  leftColumn={[<Item title="Type:" text={type || "n/a"} />]}
                  rightColumn={[<Item title="Email:" text={email || "n/a"} />]}
                />
              </div>
            ))
          ) : (
            <Container leftColumn={[<Item title="n/a" text={""} />]} />
          )}
          <Divider className={classes.divider} />
          <Typography className={classes.title} variant="body2">
            Social Media
          </Typography>
          {entity && entity.socials && entity.socials.length ? (
            entity.socials.map(({ type, url }, index) => (
              <div className={classes.wrapper} key={index}>
                <Container
                  classes={{ space: classes.space, cell: classes.cell }}
                  leftColumn={[
                    <Item
                      title={
                        <a
                          href={url}
                          title={url || ""}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          {type ? type.toUpperCase() : url || "n/a"}
                        </a>
                      }
                    />
                  ]}
                />
              </div>
            ))
          ) : (
            <Container leftColumn={[<Item title="n/a" text={""} />]} />
          )}
        </div>
      ) : (
        <div />
      )}
      {buttons.map(({ btnText, handler, styles = {} }, index) => (
        <Button
          key={index}
          onClick={handler}
          style={{ width: `calc(100% / ${buttons.length || 1}`, ...styles }}
          className={classes.buttom}
          size="small"
        >
          {btnText}
        </Button>
      ))}
    </React.Fragment>
  );
};

DisplayEntity.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  cell: {
    display: "table-cell",
    width: "initial"
  },
  space: {
    display: "table-cell",
    width: "initial"
  },
  wrapper: {
    margin: "10px 0"
  },
  buttom: {
    borderRadius: 0
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  divider: {
    marginTop: 6,
    marginBottom: 6
  },
  title: {
    paddingLeft: 10
  }
});

export default withStyles(styles)(DisplayEntity);
