import axios from "axios";
import UserService from "./user.service";

const { REACT_APP_API_HOST, REACT_APP_API_PORT } = process.env;

if (!REACT_APP_API_HOST || !REACT_APP_API_PORT)
    throw new Error("Please ensure you have setup your .env file");

export const API_HOST = `${REACT_APP_API_HOST}:${REACT_APP_API_PORT}/api/v1`;

const getHeaders = () => {
    return {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };
};

// todo with routs and axios Interceptors
const cbOnError = error => {
    const { response } = error;
    if (response.status === 401) {
        UserService.logout();
        window.location.href =
            `http://${window.location.host}/login` ||
            `https://${window.location.host}/login`;
    }
};

class ApiService {
    static get2(uri) {
        return axios.get(`${API_HOST}${uri}`, getHeaders()).catch(cbOnError);
    }

    // static get2(uri, params) {
    //     const config = { ...getHeaders(), params };
    //     return axios.get(`${API_HOST}${uri}`, config).catch(cbOnError);
    // }

    static post2(uri, data) {
        return axios.post(`${API_HOST}${uri}`, data, getHeaders()).catch(cbOnError);
    }

    static put2(uri, data) {
        return axios.put(`${API_HOST}${uri}`, data, getHeaders()).catch(cbOnError);
    }

    static delete2(uri) {
        return axios.delete(`${API_HOST}${uri}`, getHeaders()).catch(cbOnError);
    }
}

export default ApiService;
