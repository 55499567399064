import ErrorHandler from "../../../common/services/error.handler";
import {
    SEARCH_RECEIVE_BY_PHONE_REQUEST,
    SEARCH_RECEIVE_BY_PHONE_SUCCESS,
    SEARCH_RECEIVE_BY_PHONE_ERROR,
} from "../../../common/constants";

export const receiveByPhoneRequest = data => {
    return {
        type: SEARCH_RECEIVE_BY_PHONE_REQUEST,
        data,
    };
};

export const receiveByPhoneSuccess = resByPhone => {
    return {
        type: SEARCH_RECEIVE_BY_PHONE_SUCCESS,
        resByPhone: resByPhone.data,
    };
};

export const receiveByPhoneError = (query, error) => {
    ErrorHandler.do(error);
    return {
        type: SEARCH_RECEIVE_BY_PHONE_ERROR,
        query,
        error,
    };
};
