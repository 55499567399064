import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { formatPrice } from "../../../../common/formats/price";
import { euroSign } from "../../../../common/constants/symbols";

const Head = ({ classes, objects }) => {
    return (
        <React.Fragment>
            <Typography className={classes.tableCell} variant="body2">
        <span className={classes.headInner}>
          <span>Objects</span>
          <br/>
          <span>{(objects && objects.count) || "n/a"}</span>
        </span>
            </Typography>
            <Typography className={classes.tableCell} variant="body2">
        <span className={classes.headInner}>
          <span>Total</span>
          <br/>
          <span>{objects && objects.totalSize ? objects.totalSize + " m2" : "n/a"}</span>
        </span>
            </Typography>
            <Typography className={classes.tableCell} variant="body2">
        <span className={classes.headInner}>
          <span>Housing</span>
          <br/>
          <span>{objects && objects.houseSize ? objects.houseSize + " m2" : "n/a"}</span>
        </span>
            </Typography>
            <Typography className={classes.tableCell} variant="body2">
        <span className={classes.headInner}>
          <span>Other</span>
          <br/>
          <span>{objects && objects.otherSize ? objects.otherSize + " m2" : "n/a"}</span>
        </span>
            </Typography>
            <Typography className={classes.tableCell} variant="body2">
        <span className={classes.headInner}>
          <span>Value</span>
          <br/>
          <span>
            {objects && objects.totalPrice ? (
                <span>
                {euroSign}
                    <span>{formatPrice(objects.totalPrice)}</span>
              </span>
            ) : (
                "n/a"
            )}
          </span>
        </span>
            </Typography>
        </React.Fragment>
    );
};

Head.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Head;
