import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FilterMenu from "./components/filter-menu";
import Button from "@material-ui/core/Button";
import RecentQueries from "./components/filter-menu/recent-queries/RecentQueries";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import Save from "@material-ui/icons/SaveRounded";
import Delete from "@material-ui/icons/DeleteRounded";
import FieldEditor from "../../common/components/editor/field";
import { INPUT, SELECT } from "../../common/constants/field-types";
import {
    deleteQueryObjectsConfigRequest,
    receiveQueryObjectsConfigsRequest,
    saveQueryObjectsConfigRequest,
} from "./actions";
import { selectQueryObjects } from "./selectors";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditResultsMenu from "./components/filter-menu/edit-results-menu/EditResultsMenu";
import { receiveQueryObjectsResultsRequest } from "./actions";
import ResultsContainer from "./components/results/ResultsContainer";
import CircularProgress from "@material-ui/core/CircularProgress";

class QueryObjects extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = this.emptyState;

    get emptyState() {
        return {
            id: null,
            name: "",
            locationFilter: {
                cities: [],
                postcodes: [],
                streets: [],
            },
            objectFilter: {
                linkedEditMode: false,
                G0Switch: false,
                A1Switch: false,
                G0: "",
                A1: "",
                ownersAmountLessThen: "",
                ownersAmountMoreThen: "",
                unitsAmountLessThen: "",
                unitsAmountMoreThen: "",
                unitsSizeLessThen: "",
                unitsSizeMoreThen: "",
                objectIncludesFunction: "",
                objectExcludesFunction: "",
                priceLessThen: "",
                priceMoreThen: "",
            },
            propertyFilter: {},
            selectedQueryId: "",
            configExpanded: true,
            resentQueriesExpanded: true,
            queryMenuExpanded: true,
            openMenu: false,
        };
    };

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(receiveQueryObjectsConfigsRequest());
    }

    initialState = queryConfig => {
        this.setState({ ...queryConfig });
    };

    saveQueryObjectsConfig = () => {
        const { dispatch } = this.props;
        const queryConfig = this.state;
        const id = queryConfig && queryConfig.id ? queryConfig.id : null;
        dispatch(saveQueryObjectsConfigRequest({ ...queryConfig, id }));
    };

    removeQueryObjectsConfig = () => {
        const { dispatch } = this.props;
        const queryConfig = this.state;
        const id = queryConfig && queryConfig.id ? queryConfig.id : null;
        dispatch(deleteQueryObjectsConfigRequest(id));
    };

    handleAddCities = ({ cityName, cityCode, section, plotNumber }, index) => {
        cityCode = cityCode ? cityCode : "";
        section = section ? section : "";
        plotNumber = plotNumber ? plotNumber : "";
        const { locationFilter } = this.state;
        const { cities } = locationFilter;
        let allCities = [];
        if (index === "") {
            allCities = [ { cityName, cityCode, section, plotNumber }, ...cities ];
        } else {
            const filteredCities = cities.filter((city, cityIndex) => cityIndex !== index);
            allCities = [ { cityName, cityCode, section, plotNumber }, ...filteredCities ];
        }

        this.setState({
            locationFilter: {
                ...locationFilter,
                cities: allCities,
            },
            openSearchByCity: false,
        });
    };

    toggleMode = (fieldBlock, fieldName) => {
        const { objectFilter } = this.state;
        const {
            linkedEditMode,
            G0Switch,
            A1Switch,
        } = objectFilter;
        if (fieldBlock === "objectFilter") {
            if (fieldName === "linkedEditMode") {
                this.setState({
                    objectFilter: {
                        ...objectFilter,
                        linkedEditMode: !linkedEditMode,
                    },
                });
            }
            if (fieldName === "G0Switch") {
                this.setState({
                    objectFilter: {
                        ...objectFilter,
                        G0Switch: !G0Switch,
                        G0: !G0Switch ? "G0" : "",
                        A1Switch: false,
                        A1: "",
                    },
                });
            }
            if (fieldName === "A1Switch") {
                this.setState({
                    objectFilter: {
                        ...objectFilter,
                        A1Switch: !A1Switch,
                        A1: !A1Switch ? "A" : "",
                        G0Switch: false,
                        G0: "",
                    },
                });
            }
        }
    };

    handleChange = (fieldName, e) => {
        const { target } = e;
        if (fieldName === "name") {
            this.setState({
                name: target.value,
            });
        }
        if (fieldName === "configsList") {
            const { queryObjects } = this.props;
            const { queryObjectsConfigs } = queryObjects;
            const currentConfig = queryObjectsConfigs.find(config => config.id === target.value);

            this.setState({
                selectedQueryId: target.value,
            });
            if (target.value === null) {
                this.initialState(this.emptyState);
            } else {
                this.initialState(currentConfig);
            }
        }
    };

    handleBlocksChange = (blockName, fieldName, e, index) => {
        const { objectFilter, locationFilter } = this.state;
        const { streets, postcodes } = locationFilter;

        if (blockName === "objectFilter") {
            const { target: { value } } = e;
            this.setState({
                objectFilter: {
                    ...objectFilter,
                    [fieldName]: value,
                },
            });
        }

        if (blockName === "locationFilter") {
            if (fieldName === "postcode") {
                if (index === "") {
                    this.setState({
                        locationFilter: {
                            ...locationFilter,
                            postcodes: [ e, ...postcodes ],
                        },
                    });
                } else {
                    const filteredPostcodes = postcodes.filter((postcode, postcodeIndex) => postcodeIndex !== index);
                    this.setState({
                        locationFilter: {
                            ...locationFilter,
                            postcodes: [ e, ...filteredPostcodes ],
                        },
                    });
                }

            }
        }
        if (fieldName === "street") {
            if (index === "") {
                this.setState({
                    locationFilter: {
                        ...locationFilter,
                        streets: [ e, ...streets ],
                    },
                });
            } else {
                const filteredStreets = streets.filter((street, streetIndex) => streetIndex !== index);
                this.setState({
                    locationFilter: {
                        ...locationFilter,
                        streets: [ e, ...filteredStreets ],
                    },
                });
            }
        }
    };

    handleClearFilterProperty = (type, index) => {
        const { locationFilter } = this.state;
        const { postcodes, streets, cities } = locationFilter;
        if (type === "city") {
            cities.splice(index, 1);
            this.setState({
                locationFilter: {
                    ...locationFilter,
                    cities: [ ...cities ],
                },
            });
        }
        if (type === "postcode") {
            postcodes.splice(index, 1);
            this.setState({
                locationFilter: {
                    ...locationFilter,
                    postcodes: [ ...postcodes ],
                },
            });
        }
        if (type === "street") {
            streets.splice(index, 1);
            this.setState({
                locationFilter: {
                    ...locationFilter,
                    streets: [ ...streets ],
                },
            });
        }
    };

    clearForm = () => {
        this.setState(this.emptyState);
    };

    handleExpanded = (type) => {
        const { configExpanded, resentQueriesExpanded, queryMenuExpanded } = this.state;
        if (type === "resentQueries") {
            this.setState({
                resentQueriesExpanded: !resentQueriesExpanded,
            });
        }

        if (type === "config") {
            this.setState({
                configExpanded: !configExpanded,
            });
        }
        if (type === "queryMenu") {
            this.setState({
                queryMenuExpanded: !queryMenuExpanded,
            });
        }
    };

    handleOpenMenu = () => {
        const { openMenu } = this.state;
        this.setState({
            openMenu: !openMenu,
        });
    };

    handleRunQuery = () => {
        const { dispatch } = this.props;
        const { name, locationFilter, objectFilter, propertyFilter } = this.state;
        const queryConfig = {
            name, locationFilter, objectFilter, propertyFilter,
        };
        dispatch(receiveQueryObjectsResultsRequest({ config: queryConfig }));
    };

    render() {
        const {
            name,
            selectedQueryId,
            configExpanded,
            resentQueriesExpanded,
            queryMenuExpanded,
            openMenu,
            objectFilter,
            locationFilter,
        } = this.state;
        const { classes, queryObjects, router } = this.props;
        const { queryObjectsOptions, requestResults, countedResult } = queryObjects;

        return (
            <Grid container
                  className={classNames(classes.container, {
                      [classes.container2]: openMenu,
                  })}
            >
                <Grid item xs={2} className={classes.item}>
                    <RecentQueries
                        resentQueriesExpanded={resentQueriesExpanded}
                        onChange={() => {
                            this.handleExpanded("resentQueries");
                        }}
                    />
                </Grid>
                <Grid item xs={7} className={classes.item}>
                    <ExpansionPanel className={classes.content} defaultExpanded={true} expanded={configExpanded}
                                    onChange={() => {
                                        this.handleExpanded("config");
                                    }}>
                        <ExpansionPanelSummary
                            className={classes.header}
                            expandIcon={<ExpandMoreIcon/>}
                        >

                            <Grid item xs={6}>
                                <div className={classes.headerText}>
                                    <Typography variant="h6">Query Objects</Typography>
                                    <Typography variant="caption">configuration form</Typography>
                                </div>
                            </Grid>
                        </ExpansionPanelSummary>

                        <div className={classes.wrapper}>
                            <div className={classes.headRow}>
                                <div className={classes.headRowInput}>
                                    <FieldEditor
                                        type={INPUT}
                                        name="name"
                                        label="CONFIG NAME"
                                        onChange={e => this.handleChange("name", e)}
                                        value={name}
                                    />
                                </div>
                            </div>
                            <FilterMenu
                                objectFilter={objectFilter}
                                locationFilter={locationFilter}
                                handleAddCities={this.handleAddCities}
                                onChange={this.handleChange}
                                onBlocksChange={this.handleBlocksChange}
                                toggleMode={this.toggleMode}
                                clearFilterProperty={this.handleClearFilterProperty}
                            />
                        </div>
                    </ExpansionPanel>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    <ExpansionPanel className={classes.query} defaultExpanded={true} expanded={queryMenuExpanded}
                                    onChange={() => {
                                        this.handleExpanded("queryMenu");
                                    }}>
                        <ExpansionPanelSummary
                            className={classes.queryMenuHeader}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <div className={classes.headerText}>
                                <Typography variant="h6">Query Objects Menu</Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <div className={classes.subHeader}>
                            <Grid item xs={6}>
                                <div>
                                    <IconButton
                                        title="Clear config form"
                                        onClick={this.clearForm}
                                        className={classes.iconButtonLeft}
                                    >
                                        <Clear/>
                                    </IconButton>
                                    <IconButton
                                        title="Save Query Config"
                                        onClick={this.saveQueryObjectsConfig}
                                        className={classes.iconButtonRight}
                                    >
                                        <Save/>
                                    </IconButton>
                                    <IconButton
                                        title="Delete Query Config"
                                        onClick={this.removeQueryObjectsConfig}
                                        className={classes.iconButtonRight}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                {requestResults && (<CircularProgress size={20} className={classes.spinning}/>)}
                                <Button className={classes.button}
                                        onClick={this.handleOpenMenu}
                                >
                                    <span>edit results menu</span>
                                </Button>
                            </Grid>
                        </div>
                        <div className={classes.runWrapper}>
                            <FieldEditor
                                type={SELECT}
                                name="queryList"
                                label="Select query"
                                onChange={e => this.handleChange("configsList", e)}

                                value={{
                                    current: selectedQueryId || "",
                                    options: queryObjectsOptions || [],
                                    none: "n/a",
                                }}
                            />
                            <Button
                                className={classes.queryButton}
                                onClick={this.handleRunQuery}
                            >
                                Run query
                            </Button>
                        </div>
                    </ExpansionPanel>
                </Grid>
                {countedResult.length > 0 &&
                (<Grid item xs={12} className={classes.item2}>
                    <ResultsContainer
                        router={router}
                        configExpanded={configExpanded}
                        resentQueriesExpanded={resentQueriesExpanded}
                        queryMenuExpanded={queryMenuExpanded}
                        handleRunQuery={this.handleRunQuery}
                    />
                </Grid>)}
                <EditResultsMenu openMenu={openMenu}/>
            </Grid>
        );
    }
}

export const styles = theme => ({
    spinning: {
        position: "absolute",
        marginTop: 14,
        marginLeft: 15,
    },
    runWrapper: {
        display: "flex",
        justifyContent: "spaceBetween",
        padding: "10px 20px 20px",
    },
    container: {
        display: "flex",
        justifyContent: "spaceBetween",
    },
    container2: {
        display: "flex",
        justifyContent: "spaceBetween",
        width: "88%",
    },
    iconButtonLeft: {
        marginLeft: 9,
    },
    iconButtonRight: {
        marginRight: 4,
    },
    header: {
        height: 56,
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary[500],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    subHeader: {
        padding: "5px 0",
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary[400],
    },
    headerText: {
        textAlign: "center",
    },
    queryMenuHeader: {
        height: 56,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.primary[500],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    headRow: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    headRowInput: {
        width: "30%",
        minWidth: 140,
    },
    wrapper: {
        height: "485px",
        padding: "0 15px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    wrapperLarge: {
        height: "690px",
        padding: "0 15px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    wrapperMiddle: {
        height: "530px",
        padding: "0 15px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    wrapperMini: {
        height: "280px",
        padding: "0 15px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    item: {
        width: "100%",
        maxHeight: "465px",
        display: "flex",
        marginTop: 18,
        flexDirection: "column",
    },
    item2: {
        width: "100%",
        // maxHeight: "800px",
        display: "flex",
        marginTop: 18,
        flexDirection: "column",
    },
    query: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
    },
    queryText: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    queryButton: {
        height: "56px",
        width: "50%",
        marginLeft: "20px",
        marginTop: "10px",
        borderRadius: "4px",
        backgroundColor: "#a9d08d",
        "&:hover": {
            backgroundColor: "#94bf82",
        },
    },
    button: {
        margin: "7px 8px 5px 55px",
    },
    content: {
        maxHeight: "100%",
        width: "99%",
    },
    resultsWrapper: {
        maxHeight: 745,
        // width: "400px",
        // overflow: "hidden",
        // overflowY: "auto",
        // "&::-webkit-scrollbar": {
        //     width: "6px",
        //     backgroundColor: "#f0f0f0",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     backgroundColor: "#ccc",
        //     borderRadius: "4px",
        // },
    },
});

const mapStateToProps = state => ({
    queryObjects: selectQueryObjects(state),
});

export default connect(mapStateToProps)(withStyles(styles)(QueryObjects));
