import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Contacts from "@material-ui/icons/Contacts";
import Person from "@material-ui/icons/Person";
import DisplayEntity from "../common/DisplayEntity";
import MenuItem from "@material-ui/core/MenuItem";
import DialogWrapper from "../../../../common/components/dialog";
import { EntityEditor } from "../../../../common/components/editor/entity";
import deepPurple from "@material-ui/core/colors/deepPurple";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Search from "../common/Search";
import { getPersonName } from "../../../../common/helpers/getter";
import { crossSign } from "../../../../common/constants/symbols";
import { selectEntity } from "../../selectors";
import { selectLeadgen } from "../../../leadgen/selectors";
import { updateEntityRequest } from "../../actions";
import { validate, validateKadID } from "../../../../common/helpers/validator";
import {
  getObjectsAmount,
  leadgenObjectsByOwnerRequest,
} from "../../../leadgen/actions/objects";
import { leadgenDependentEntitiesRequest } from "../../../leadgen/actions/dependentEntities";

class Contact extends Component {
  state = {
    action_types: [],
    contacts: [],
    anchorElContact: null,
    contactIndex: null,
    contactForUpdate: {},
    openDialog: {
      editContact: false,
      addContact: false,
      deleteContact: false,
    },
  };

  componentDidUpdate(prevProps) {
    const { leadgen } = this.props;
    const { contacts } = leadgen;
    if (prevProps.leadgen.contacts !== contacts) {
      this.setState({
        contacts: contacts,
      });
    }
  }

  getAvatarName = (company_name, last_name, first_name, all_names) =>
    `${
      last_name
        ? last_name[0].toUpperCase()
        : company_name
        ? company_name[0].toUpperCase()
        : ""
    }${
      (first_name ? first_name[0].toUpperCase() : "") ||
      (all_names ? all_names[0].toUpperCase() : "")
    }`;

  handleOpenMenu = (e) => {
    this.setState({ anchorElContact: e.currentTarget });
  };

  handleCloseMenu = (personIndex) => {
    this.setState({ anchorElContact: null, [personIndex]: null });
  };

  handleAddPerson = (person) => {
    const { contacts } = this.state;
    const persons = contacts || [];
    persons[0] = person;
    this.setState({ contacts: persons });
  };

  handleOpenDialog = (actionType) => () => {
    const { openDialog, contacts } = this.state;

    const newState = {
      openDialog: { ...openDialog, [actionType]: true },
      anchorElContact: null,
    };

    if (actionType === "addContact") {
      const newContacts = contacts || [];
      newContacts.unshift({});
      newState.contacts = newContacts;
    }
    this.setState(newState);
  };

  handleCloseDialog = (actionType, personType) => () => {
    const { openDialog, contacts } = this.state;
    contacts.shift();
    const newState = {
      openDialog: { ...openDialog, [actionType]: false },
      contacts: contacts,
    };

    if (personType) newState[personType] = null;

    this.setState(newState);
  };

  handleDisplayItem = (personIndex, index) => {
    this.setState({ [personIndex]: index });
  };

  handleChange =
    (index) =>
    (name, { target }, deleteMode, deletedItem) => {
      const { contacts, action_types } = this.state;
      let newItems = contacts;
      const {
        addressesForDelete,
        phoneNumbersForDelete,
        emailsForDelete,
        socialsForDelete,
      } = newItems[index];

      const default_type = "general_fields";
      if(!deleteMode) {
        switch (name) {
          case "phone_numbers":
          case "addresses":
          case "socials":
          case "emails":
            return this.setState({
              action_types: action_types.includes(name)
                ? action_types
                : [ name, ...action_types ],
              contactForUpdate: {
                ...newItems[index],
                [name]: target.value,
              },
            });
          default:
            this.setState({
              action_types: action_types.includes(default_type)
                ? action_types
                : [ name, ...action_types ],
              contactForUpdate: {
                ...newItems[index],
                [name]: target.value,
              },
            });
            break;
        }
      }
      if (name === "phone_numbers" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],

          contactForUpdate: {
            ...newItems[index],
            [name]: target.value,
            phoneNumbersForDelete: [
              deletedItem[0].id,
              ...phoneNumbersForDelete,
            ],
          },
        });
      }
      if (name === "addresses" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],
          contactForUpdate: {
            ...newItems[index],
            [name]: target.value,
            addressesForDelete: [deletedItem[0].id, ...addressesForDelete],
          },
        });
      }
      if (name === "socials" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],
          contactForUpdate: {
            ...newItems[index],
            [name]: target.value,
            socialsForDelete: [deletedItem[0].id, ...socialsForDelete],
          },
        });
      }
      if (name === "emails" && deleteMode && deletedItem[0].id) {
        return this.setState({
          deleteMode: deleteMode,
          action_types: action_types.includes(name)
            ? action_types
            : [name, ...action_types],
          contactForUpdate: {
            ...newItems[index],
            [name]: target.value,
            emailsForDelete: [deletedItem[0].id, ...emailsForDelete],
          },
        });
      }
      const link =
        "https://kadaster-on-line.kadaster.nl/selectie.asp?identificatie=NL.IMKAD.Persoon.";

      const kvkLink =
        "https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer=";

      if (name === "kadaster_personal_number") {
        this.setState({
          action_types: action_types,
          contactForUpdate: {
            ...newItems[index],
            [name]: target.value,
            kadaster_url: target.value ? link + target.value : null,
          },
        });
        return null;
      }

      if (name === "chamber_commerce_number") {
        return this.setState({
          action_types: action_types,
          contactForUpdate: {
            ...newItems[index],
            [name]: target.value,
            chamber_commerce_url: target.value ? kvkLink + target.value : null,
          },
        });
      }

      newItems[index] = { ...newItems[index], [name]: target.value };
      this.setState({
        contacts: newItems,
        action_types: action_types.includes(default_type)
          ? action_types
          : [default_type, ...action_types],
        contactForUpdate: {
          ...newItems[index],
          [name]: target.value,
        },
      });
    };

  handleValidate = async (entityType, entity) => {
    const isValidated = validate(entityType, { ...entity });
    let isExistedKadID = false;
    const kad_id = entity.kadaster_personal_number;

    if (kad_id) {
      await validateKadID(
        "entity",
        { kad_id, id: entity.id },
        (data) => (isExistedKadID = data)
      );
    }

    return isValidated && !isExistedKadID;
  };

  handleSave = (actionType, personType, index) => async () => {
    const { dispatch, currentEntity, router } = this.props;
    const { entity } = currentEntity;
    const { openDialog, contacts, contactForUpdate, action_types } = this.state;
    const currentType = "contacts";
    let deleteMode = false;
    let contactsUpdateId = null;
    let newContactId = null;
    const newState = {
      openDialog: { ...openDialog, [actionType]: false },
    };

    if (personType) newState[personType] = null;

    switch (actionType) {
      case "addContact":
        newContactId = contacts[0].id;
        if (
          !(await this.handleValidate(contacts[0].entity_type, {
            ...contacts[0],
          }))
        ) {
          return;
        } else {
          newState.contacts = contacts;
          break;
        }

      case "deleteContact":
        contactsUpdateId = contacts[index].id;
        contacts.splice(index, 1);
        newState.contacts = contacts;
        deleteMode = true;
        break;

      case "editContact":
        contactsUpdateId = contacts[index].id;
        if (
          !(await this.handleValidate(contacts[index].entity_type, {
            ...contacts[index],
          }))
        ) {
          return;
        } else break;

      default:
        return;
    }

    this.setState(newState);

    if (actionType === "editContact") {
      const index = action_types.indexOf(currentType);
      action_types.slice(index, 1);
      return (
        dispatch(
          updateEntityRequest(
            deleteMode,
            action_types,
            contactForUpdate.id,
            { ...contactForUpdate },
            router,
            () => dispatch(leadgenDependentEntitiesRequest(entity.id))
          )
        ) &&
        dispatch(leadgenObjectsByOwnerRequest(entity.id)) &&
        dispatch(getObjectsAmount(entity.id))
      );
    } else {
      return (
        dispatch(
          updateEntityRequest(
            deleteMode,
            [currentType],
            entity.id,
            { ...entity, contactsUpdateId, newContactId },
            router,
            () => dispatch(leadgenDependentEntitiesRequest(entity.id))
          )
        ) &&
        dispatch(leadgenObjectsByOwnerRequest(entity.id)) &&
        dispatch(getObjectsAmount(entity.id))
      );
    }
  };

  render() {
    const { classes, validationMode, deleteLinkedCompany } = this.props;
    const { contacts, anchorElContact, openDialog, contactIndex } = this.state;
    const contactsIsEmpty = !!contacts.length;
    return (
      <div>
        <div
          className={classes.wrapperAvatar}
          onClick={(e) => this.handleOpenMenu(e)}
        >
          <Avatar
            className={contactsIsEmpty ? classes.avatarName : classes.image}
          >
            <Contacts />
          </Avatar>
          <ListItemText
            className={classes.itemText}
            primary={
              <Typography className={classes.text} variant="body2">
                Contacts
              </Typography>
            }
            secondary={
              <span>
                {contactsIsEmpty
                  ? contacts.length === 1
                    ? contacts[0].company_name
                      ? contacts[0].company_name.length > 15
                        ? contacts[0].company_name.slice(0, 15) + "..."
                        : contacts[0].company_name
                      : getPersonName(contacts[0], true)
                    : contacts
                        .map(
                          ({
                            company_name,
                            last_name,
                            first_name,
                            all_names,
                          }) =>
                            this.getAvatarName(
                              company_name,
                              last_name,
                              first_name,
                              all_names
                            )
                        )
                        .toString()
                  : "n/a"}
                {contactsIsEmpty &&
                  contacts.length === 1 &&
                  contacts[0].is_dead && <span>({crossSign})</span>}
              </span>
            }
          />
        </div>

        <Menu
          id={anchorElContact}
          anchorEl={anchorElContact}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transitionDuration={0}
          disableAutoFocusItem
          className={classes.menu}
          open={Boolean(anchorElContact)}
          onClose={() => this.handleCloseMenu("contactIndex")}
          MenuListProps={{
            className: contactsIsEmpty
              ? classes.menuList
              : classes.emptyMenuList,
          }}
        >
          {contactsIsEmpty && contactIndex === null ? (
            <div>
              {contacts.map(
                (
                  {
                    company_name,
                    last_name,
                    first_name,
                    initials,
                    all_names,
                    is_dead,
                  },
                  index
                ) => (
                  <MenuItem
                    key={index}
                    className={classes.menuItem}
                    onClick={() =>
                      this.handleDisplayItem("contactIndex", index)
                    }
                  >
                    <Avatar className={classes.menuItemName}>
                      {this.getAvatarName(
                        company_name,
                        last_name,
                        first_name,
                        all_names
                      ) || <Person />}
                    </Avatar>
                    <ListItemText
                      className={classes.itemText}
                      primary={
                        <span>
                          {`${last_name || company_name || ""} ${
                            first_name || initials || ""
                          }`.trim() || "n/a"}
                          {is_dead ? <span>({crossSign})</span> : ""}
                        </span>
                      }
                    />
                  </MenuItem>
                )
              )}
              <MenuItem
                className={classes.menuLastItem}
                onClick={this.handleOpenDialog("addContact")}
              >
                add new contact
              </MenuItem>
            </div>
          ) : (
            <DisplayEntity
              buttons={
                !contactsIsEmpty
                  ? [
                      {
                        btnText: "add contact",
                        handler: this.handleOpenDialog("addContact"),
                      },
                    ]
                  : [
                      {
                        btnText: "edit",
                        handler: this.handleOpenDialog("editContact"),
                      },
                      {
                        btnText: "unlink",
                        handler: this.handleOpenDialog("deleteContact"),
                      },
                    ]
              }
              entity={contacts[contactIndex]}
            />
          )}
        </Menu>
        <DialogWrapper
          open={openDialog.editContact}
          onClose={this.handleCloseDialog("editContact", "contactIndex")}
          handleClick={this.handleSave(
            "editContact",
            "contactIndex",
            contactIndex
          )}
          title="Edit contact"
          buttonText="Save"
        >
          <EntityEditor
            validationMode={validationMode}
            entityType={
              contacts[contactIndex] ? contacts[contactIndex].entity_type : null
            }
            editableEntity={contacts[contactIndex] || {}}
            onChange={this.handleChange(contactIndex)}
            deleteLinkedCompany={deleteLinkedCompany}
          />
        </DialogWrapper>
        <DialogWrapper
          open={openDialog.deleteContact}
          onClose={this.handleCloseDialog("deleteContact", "contactIndex")}
          handleClick={this.handleSave(
            "deleteContact",
            "contactIndex",
            contactIndex
          )}
          title="Unlink Contact"
          buttonText="Unlink"
        >
          <Typography className={classes.deleteBlock} variant="subtitle1">
            Are you sure you want to unlink
            {contacts[contactIndex]
              ? " " +
                `${
                  contacts[contactIndex].first_name ||
                  contacts[contactIndex].initials ||
                  ""
                } ${
                  contacts[contactIndex].last_name ||
                  contacts[contactIndex].company_name ||
                  ""
                }`
              : ""}
            ?
          </Typography>
        </DialogWrapper>
        <DialogWrapper
          open={openDialog.addContact}
          onClose={this.handleCloseDialog("addContact", "contactIndex")}
          handleClick={this.handleSave(
            "addContact",
            "contactIndex",
            contactIndex
          )}
          title="Add contact"
          buttonText="Save"
        >
          <div className={classes.wrapper}>
            <Search addPerson={(entity) => this.handleAddPerson(entity)} />
            {openDialog.addContact &&
            contacts[0] &&
            Object.keys(contacts[0]).length ? (
              <div className={classes.entityEditorWrapper}>
                <EntityEditor
                  validationMode={validationMode}
                  entityType={contacts[0] ? contacts[0].entity_type : null}
                  editableEntity={contacts[0] || {}}
                  onChange={this.handleChange(0)}
                />
              </div>
            ) : null}
          </div>
        </DialogWrapper>
      </div>
    );
  }
}

Contact.propTypes = {};

const styles = (theme) => ({
  wrapper: {
    minWidth: 550,
  },
  entityEditorWrapper: {
    marginTop: 20,
  },
  wrapperAvatar: {
    display: "flex",
    padding: 5,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.14)",
      cursor: "pointer",
    },
  },
  text: {
    fontStyle: "italic",
  },
  deleteBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
  menu: {
    top: 56,
  },
  menuList: {
    padding: "8px 0 0 0",
  },
  emptyMenuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuLastItem: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 8,
  },
  menuItem: {
    padding: 16,
  },
  itemText: {
    marginRight: 0,
  },
  iconButton: {
    padding: 6,
  },
  image: {
    width: 45,
    height: 45,
    backgroundColor: theme.palette.primary[400],
  },
  avatarName: {
    width: 45,
    height: 45,
    backgroundColor: lightGreen[700],
  },
  menuItemName: {
    width: 45,
    height: 45,
    backgroundColor: deepPurple[500],
  },
});

const mapStateToProps = (state) => ({
  currentEntity: selectEntity(state),
  leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(
  withStyles(styles)(React.memo(Contact))
);
