import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { formatPrice } from '../../../common/formats/price';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { euroSign } from '../../../common/constants/symbols';
import Spinner from '../../../common/components/spinner';

class ItemWithState extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    isHighlighted: false,
  };

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.setState({ isHighlighted: this.props.isHighlighted });
    }
  }

  removeSelection = () => {
    this.setState({ isHighlighted: false });
  };

  render() {
    const {
      classes,
      label,
      type,
      item,
      header,
      footer,
      isShow,
      query,
    } = this.props;

    if (type === 'loadingObject') {
      return (
        isShow && (
          <React.Fragment>
            <div>{header}</div>
            <Spinner />
          </React.Fragment>
        )
      );
    }
    if (type === 'loadingEntity') {
      return (
        isShow && (
          <React.Fragment>
            <div>{header}</div>
            <Spinner />
          </React.Fragment>
        )
      );
    }
    const { isHighlighted } = this.state;
    const matches = match(label, query);
    const parts = parse(label, matches);

    let block = <div />;

    const getName = ({ last_name, initials, company_name }) =>
      company_name
        ? `(c) ${
            company_name.length > 13
              ? company_name.slice(0, 13) + ' ...'
              : company_name
          }`
        : !last_name && !initials
        ? 'n/a'
        : `${last_name || ''} ${initials || ''}`;

    if (type === 'object') {
      const name = getName(item.owner);
      block = (
        <div className={classes.wrapperColumns}>
          {[
            item.size && item.size + ' m2',
            item.purpose,
            item.unitsCount,
            <React.Fragment>
              {item.price ? euroSign : ''}
              {item.price ? ' ' + formatPrice(item.price) : 'n/a'}
            </React.Fragment>,
            item.owner && name,
          ].map((value, index) => (
            <Typography key={index} className={classes.col} variant="body2">
              {value || 'n/a'}
            </Typography>
          ))}
        </div>
      );
    }
    if (type === 'entity') {
      block = (
        <div className={classes.wrapperColumns}>
          {[
            item.age,
            item.objectCount,
            item.size && item.size + ' m2',
            item.housingPercent,
            item.mixedPercent,
          ].map((value, index) => (
            <Typography key={index} className={classes.col} variant="body2">
              {value || 'n/a'}
            </Typography>
          ))}
        </div>
      );
    }

    const nameLength = parts.map(i => i && i.text && i.text.length).reduce((a, i) => a + i);
    const company_name = item && item.owner && item.owner.company_name ?  item.owner.company_name : '';

    return (
      isShow && (
        <React.Fragment>
          <div onMouseOver={this.removeSelection}>{header}</div>
          <MenuItem
            className={classes.tableColumns}
            selected={isHighlighted}
            component="div"
          >
            <Typography
              className={classes.firstColumn}
              variant="body2"
              title={
                type === 'entity' && company_name
                  ? `${company_name}${
                      item.kadaster_personal_number
                        ? ` (${item.kadaster_personal_number})`
                        : ''
                    }`
                  : item.kadaster_id ||
                    item.kadaster_personal_number ||
                    undefined
              }
            >
              <span className={type === 'entity' ? classes.wrapperParts : {}}>
                {parts.map((part, index) => {
                  return part.highlight ? (
                    <span
                      key={String(index)}
                      className={classes.textPartHighlighting}
                    >
                      {part.text}
                    </span>
                  ) : (
                    <span key={String(index)} className={classes.textPart}>
                      {part.text}
                    </span>
                  );
                })}
              </span>
              {nameLength > 30 && '...'}
              {item.plot && item.plot.index && (
                <span className={classes.number}>{item.plot.index}</span>
              )}
              {item.city && (
                <span className={classes.number}>({item.city})</span>
              )}
            </Typography>
            {block}
          </MenuItem>
          <div onMouseOver={this.removeSelection}>{footer}</div>
        </React.Fragment>
      )
    );
  }
}

const styles = () => ({
  tableColumns: {
    height: 6,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapperColumns: {
    width: '73%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  wrapperParts: {
    display: 'inline-flex',
    width: 260,
    overflow: 'hidden',
  },
  firstColumn: {
    width: '27%',
  },
  col: {
    width: '20%',
    textAlign: 'center',
    fontWeight: 400,
  },
  number: {
    fontWeight: 400,
    marginLeft: 6,
  },
  textPartHighlighting: {
    fontWeight: 500,
  },
  textPart: {
    fontWeight: 400,
    whiteSpace: 'pre',
  },
});

const ItemWithStyles = withStyles(styles)(ItemWithState);

export const Item = (a, b) => <ItemWithStyles {...a} {...b} />;
