import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

const Head = ({ classes, size, price, owner_count, purpose }) => {
  return (
    <div className={classes.root}>
      <Typography className={classnames(classes.col, classes.col1)}>
        {owner_count || 'n/a'}
      </Typography>
      <Typography className={classnames(classes.col, classes.col2)}>
        {size || 'n/a'}
      </Typography>
      <Typography className={classnames(classes.col, classes.col3)}>
        {purpose || 'n/a'}
      </Typography>
      <Typography className={classnames(classes.col, classes.col4)}>
        {price || 'n/a'}
      </Typography>
    </div>
  );
};

Head.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 20,
  },
  col: {
    overflowX: 'hidden',
    display: 'grid',
    gridGap: '1em',
  },
  col1: {
    maxWidth: 80,
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
  },
  col2: {
    maxWidth: 80,
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
  },
  col3: {
    maxWidth: 160,
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
  },
  col4: {
    maxWidth: 100,
    gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
  },
});

export default withStyles(styles)(Head);
