import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ResponsiblePerson from "./components/responsible";
import Label from "./components/label";
import AssetManager from "./components/asset-manager";
import Entity from "./components/entity";
import Contact from "./components/contact";
import Tag from "./components/tag";
import {
    getEntityRequest,
    receiveLabelsRequest,
    receiveTagsRequest,
    receiveUsersDataRequest,
    setEmptyEntityRequest,
    updateEntityRequest,
} from "./actions";
import { getObjectsAmount, leadgenObjectsByOwnerRequest } from "../leadgen/actions/objects";
// import { clearCurrentCompanyRequest, setCurrentCompanyRequest } from "../search/actions/company";
import { clearCurrentCompanyRequest } from "../search/actions/company";
import _ from "lodash";
import Schedule from "./components/schedule";
import { selectEntity } from "./selectors";
import { selectSearch } from "../search/selectors";

class LeadgenHeadMenu extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        anchorElResponsible: null,
        anchorElEntity: null,
        anchorElLabel: null,
        anchorElTag: null,
        validationMode: {
            company: true,
            person: true,
        },
        entity: {},
    };

    componentWillMount() {
        const { dispatch, router } = this.props;
        const { model, id } = router.params;

        if (id && model === "entity")
            dispatch(
                getEntityRequest(id, company_id => {
                    // this.handleGetLinkedCompany(company_id);
                }),
            );
        dispatch(getObjectsAmount(id))
    }

    componentDidMount() {
        const { dispatch, router, currentEntity } = this.props;
        const { entity } = currentEntity;
        const { model, id } = router.params;
        if (id && model === "entity" && entity) {
            this.setState({
                entity: { ...entity },
            });
        } else {
            dispatch(setEmptyEntityRequest());
        }
    }

    componentDidUpdate(prevProps) {
        const { router, dispatch, currentEntity } = this.props;
        const { destroyEntity, entity } = currentEntity;
        const { model, id } = router.params;
        if (currentEntity !== prevProps.currentEntity) {
            if (destroyEntity.entityIsDeleted) {
                router.push("/leadgen");
                dispatch(setEmptyEntityRequest());
            }
            this.setState({
                entity: { ...entity },
            });
        }

        if (id && model === "entity" && prevProps.router.params.id !== id) {
            return dispatch(
                getEntityRequest(id, company_id => {
                    // this.handleGetLinkedCompany(company_id);
                }),
            );
        }

        if (!id || model !== "entity") {
            return this.setState({ entity: {} });
        }

        if (!_.isEqual(this.props, prevProps)) {
            this.initialEntity = _.cloneDeep(this.props.currentEntity.entity);
            this.setState({ entity: _.cloneDeep(this.props.currentEntity.entity) });
        }
    }

    handleGetLinkedCompany = company_id => {
        const { dispatch } = this.props;
        if (company_id) {
            // dispatch(setCurrentCompanyRequest(company_id));
        } else {
            dispatch(clearCurrentCompanyRequest());
        }
    };

    handleOpenMenu = (type, e) => {
        const { dispatch } = this.props;
        type === "anchorElResponsible" && dispatch(receiveUsersDataRequest());
        type === "anchorElLabel" && dispatch(receiveLabelsRequest());
        type === "anchorElTag" && dispatch(receiveTagsRequest());

        this.setState({ [type]: e.currentTarget });
    };

    handleCloseMenu = (type, personIndex) => {
        this.setState({ [type]: null, [personIndex]: null });
    };

    handleChangeEntity = (name, e, deleteMode, typeOfDelete) => {
        const { dispatch, router } = this.props;
        const { entity } = this.state;
        const { tags } = entity;
        const { target } = e;
        let action_types = [];

        if (name === "responsible_person") {
            action_types.push(name);
            this.setState({
                anchorElResponsible: null,
            });

            if (!entity.responsible_person_id && !e.id) {
                return null;
            }
            if (
                !entity.responsible_person_id ||
                entity.responsible_person_id !== e.id
            ) {
                dispatch(
                    updateEntityRequest(false, action_types, entity.id, { [name]: e }, router),
                );
                dispatch(leadgenObjectsByOwnerRequest(entity.id, router));
                dispatch(getObjectsAmount(entity.id));
            }

            return null;
        }

        if (name === "label") {
            action_types.push(name);
            this.setState({ anchorElLabel: null });
            const newLabelId = e.id || null;
            const entityLabelId =  entity.label.length && entity.label[0].EntityLabel.id ? entity.label[0].EntityLabel.id : null;
            if (!(entity.label.length && entity.label[0].id) && !e.id) return null;
            if (!(entity.label.length && entity.label[0].id) ||  entity.label[0].id !== e.id) {
                dispatch(
                    updateEntityRequest(false, action_types, entity.id, {
                        ...entity,
                        [name]: newLabelId,
                        entityLabelId
                    }),
                );
                dispatch(leadgenObjectsByOwnerRequest(entity.id, router));
                dispatch(getObjectsAmount(entity.id));
            }

            return null;
        }

        if (name === "tags") {
            action_types.push(name);
            if (deleteMode && deleteMode === "removeTags" && typeOfDelete === "singleTag") {
                this.setState({ anchorElTag: null });
                dispatch(
                    updateEntityRequest(false, action_types, entity.id, {
                        ...entity,
                        tagForRemoveId: e,
                    }),
                );
            } else if (deleteMode && deleteMode === "removeTags") {
                this.setState({ anchorElTag: null });
                dispatch(
                    updateEntityRequest(false, action_types, entity.id, {
                        ...entity,
                        deleteAllTags: true,
                    }),
                );
            } else {
                const duplicatedTags = tags.filter(tag => e.id === tag.id);
                if (!duplicatedTags.length) {
                    this.setState({ anchorElTag: null });
                    dispatch(
                        updateEntityRequest(false, action_types, entity.id, {
                            ...entity,
                            newTagId: e.id,
                        }),
                    );
                }
            }

            return null;
        }
        this.setState({
            action_types,
            entity: {
                ...entity,
                [name]: target.value,
            },
        });

    };

    deleteLinkedCompany = id => {
        const { entity } = this.state;
        if (id) {
            this.setState({ entity: { ...entity, company_id: null } });
        }
    };

    render() {
        const { classes, currentEntity } = this.props;
        const { users, currentLabels, currentTags } = currentEntity;
        const {
            anchorElResponsible,
            anchorElLabel,
            anchorElTag,
            entity,
            validationMode,
        } = this.state;

        return (
            <div className={classes.root}>
                <Entity
                    validationMode={validationMode}
                    deleteLinkedCompany={this.deleteLinkedCompany}
                />
                <Schedule schedule={entity.schedule}/>
                <Label
                    labels={currentLabels}
                    label={(entity.label && entity.label[0]) || {}}
                    handleClose={labelIndex =>
                        this.handleCloseMenu("anchorElLabel", labelIndex)
                    }
                    handleOpen={e => this.handleOpenMenu("anchorElLabel", e)}
                    handleChange={this.handleChangeEntity}
                    anchorEl={anchorElLabel}
                />
                <Tag
                    tags={currentTags}
                    stateTags={entity.tags || []}
                    handleClose={tagIndex =>
                        this.handleCloseMenu("anchorElTag", tagIndex)
                    }
                    handleOpen={e => this.handleOpenMenu("anchorElTag", e)}
                    handleChange={this.handleChangeEntity}
                    anchorEl={anchorElTag}
                />
                <Contact
                    validationMode={validationMode}
                    deleteLinkedCompany={this.deleteLinkedCompany}
                />
                <AssetManager
                    validationMode={validationMode}
                    deleteLinkedCompany={this.deleteLinkedCompany}
                />
                <ResponsiblePerson
                    users={users}
                    responsiblePerson={entity.responsible_person || {}}
                    handleClose={personIndex =>
                        this.handleCloseMenu("anchorElResponsible", personIndex)
                    }
                    handleOpen={e => this.handleOpenMenu("anchorElResponsible", e)}
                    handleChange={this.handleChangeEntity}
                    anchorEl={anchorElResponsible}
                />
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        display: "flex",
        flex: 4,
        justifyContent: "space-between",
        marginRight: 8,
    },
    wrapperAdditionalInfo: {
        display: "flex",
        alignItems: "center",
    },
});

const mapStateToProps = state => ({
    currentEntity: selectEntity(state),
    search: selectSearch(state),
});

export default connect(mapStateToProps)(withStyles(styles)(LeadgenHeadMenu));
