import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import SearchCity from "./SearchCity";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";

const FilterMenu = ({
                        classes,
                        showBlocks,
                        showSubEntities,
                        showSubObjects,
                        selectCity,
                        removeCity,
                        city,
                        defaultCities,
                        onChange,
                    }) => {
    const switches = [
        {
            title: "Objects",
            value: showBlocks.objects,
            onChange: onChange("blocks", "objects"),
            subSwitches: [
                {
                    subTitle: "byKadasterID",
                    value: showSubObjects.byKadasterID,
                    onChange: onChange("subObjects", "byKadasterID"),
                },
                {
                    subTitle: "byG0",
                    value: showSubObjects.byG0Property,
                    onChange: onChange("subObjects", "byG0Property"),
                },
                {
                    subTitle: "byA1",
                    value: showSubObjects.byA1property,
                    onChange: onChange("subObjects", "byA1property"),
                },
            ],
        },
        {
            title: "Entities",
            value: showBlocks.entities,
            onChange: onChange("blocks", "entities"),
            subSwitches: [
                {
                    subTitle: "byKadasterID",
                    value: showSubEntities.firstGroup === "byKadasterID",
                    onChange: onChange("subEntities", "byKadasterID", "firstGroup"),
                },
                {
                    subTitle: "person",
                    value: showSubEntities.firstGroup === "person",
                    onChange: onChange("subEntities", "person", "firstGroup"),
                },
                {
                    subTitle: "company",
                    value: showSubEntities.firstGroup === "company",
                    onChange: onChange("subEntities", "company", "firstGroup"),
                },
                {
                    subTitle: "owners",
                    value: showSubEntities.secondGroup === "owners",
                    onChange: onChange("subEntities", "owners", "secondGroup"),
                    customColor: true,
                },
                {
                    subTitle: "nonOwners",
                    value: showSubEntities.secondGroup === "nonOwners",
                    onChange: onChange("subEntities", "nonOwners", "secondGroup"),
                    customColor: true,
                },
                {
                    subTitle: "contacts",
                    value: showSubEntities.secondGroup === "contacts",
                    onChange: onChange("subEntities", "contacts", "secondGroup"),
                    customColor: true,
                },
                {
                    subTitle: "managers",
                    value: showSubEntities.secondGroup === "managers",
                    onChange: onChange("subEntities", "managers", "secondGroup"),
                    customColor: true,
                },
                {
                    subTitle: "all",
                    value: showSubEntities.secondGroup === "all",
                    onChange: onChange("subEntities", "all", "secondGroup"),
                    customColor: true,
                },
            ],
        },
        {
            title: "Area Filter",
            value: showBlocks.city,
            onChange: onChange("blocks", "city"),
        },
        {
            title: "Default Cities",
            value: showBlocks.cities,
            onChange: onChange("blocks", "cities"),
        },
    ];

    const byKadasterID = showSubEntities.firstGroup === "byKadasterID";

    return (
        <React.Fragment>
            <div className={classes.row}>
                <div className={classes.container}>
                    <Typography className={classes.queryCity} variant="subtitle1">
                        {city}
                    </Typography>
                </div>
            </div>
            {switches.map(({ title, value, onChange, subSwitches }) => (
                <div key={title} className={classes.row}>
                    <div className={classes.container}>
                        <Typography variant="subtitle1">{title}</Typography>
                        <Switch
                            className={classes.switch}
                            checked={value}
                            onChange={onChange}
                            color="secondary"
                        />
                    </div>
                    {subSwitches && (
                        <div className={classes.subSwitchWrapper}>
                            {subSwitches.map(({ subTitle, value, onChange, customColor }, index) => {
                                return subTitle ? (
                                    <div key={subTitle} className={classes.subContainer}>
                                        <Typography variant="body2">{subTitle}</Typography>
                                        <Switch
                                            className={classes.switch}
                                            checked={value}
                                            onChange={onChange}
                                            classes={
                                                customColor
                                                    ? {
                                                        switchBase: classes.colorSwitchBase,
                                                        checked:
                                                            byKadasterID || !showBlocks.entities
                                                                ? classes.disabled
                                                                : classes.colorChecked,
                                                        bar: classes.colorBar,
                                                        disabled: classes.disabled,
                                                    }
                                                    : {}
                                            }
                                            color="primary"
                                            disabled={
                                                (!showBlocks.entities && title === "Entities") ||
                                                (!showBlocks.objects && title === "Objects") ||
                                                (byKadasterID && customColor)
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div key={index} className={classes.empty}/>
                                );
                            })}
                        </div>
                    )}
                </div>
            ))}
            {(showBlocks.city || showBlocks.cities) && (
                <div className={classes.row}>
                    <div className={classes.container}>
                        <SearchCity selectCity={selectCity}/>
                    </div>
                </div>
            )}
            {defaultCities.map((city, index) => (
                <div key={city} className={classes.row}>
                    <div className={classes.container}>
                        <Typography className={classes.defaultCity} variant="subtitle1">
                            {city}
                        </Typography>
                        {showBlocks.cities && (
                            <IconButton onClick={() => removeCity(index)} className={classes.clearButton}>
                                <Clear fontSize="small"/>
                            </IconButton>
                        )}
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
};

const styles = theme => ({
    colorSwitchBase: {
        "&$colorChecked": {
            color: theme.palette.primary[400],
            "& + $colorBar": {
                backgroundColor: theme.palette.primary[400],
            },
        },
        "&$disabled": {
            color: theme.palette.primary.default,
            "& + $colorBar": {
                opacity: 0.12,
            },
        },
    },
    colorBar: {},
    colorChecked: {},
    disabled: {},
    row: {
        padding: 4,
        marginLeft: 10,
    },
    empty: {
        height: 14,
    },
    queryCity: {
        color: theme.palette.primary[500],
        fontSize: 18,
        textAlign: "center",
        width: "100%",
        marginRight: 10,
    },
    defaultCity: {
        color: theme.palette.primary.grayFont,
        marginLeft: 8,
    },
    switch: {
        display: "flex",
        margin: "-10px 0",
    },
    subSwitchWrapper: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 20,
    },
    button: {
        padding: 8,
        marginRight: 12,
    },
    clearButton: {
        padding: 2,
        marginRight: 8,
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    subContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "1px 0",
    },
});

export default withStyles(styles)(FilterMenu);
