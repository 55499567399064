import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export default withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary[400],
        maxWidth: 220,
        border: '1px solid',
        //@ts-ignore
        borderColor: theme.palette.primary.divider,
    },
}))(Tooltip);
