import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AccountCircleRounded from "@material-ui/icons/AccountCircleRounded";

const RowActivity = ({
                         classes,
                         date,
                         object,
                         phoneNumber,
                         owner,
                         notes,
                         imgUrl,
                     }) => (
    <Grid className={classes.root} item xs={12}>
        <Grid container>
            <Grid item xs={6}>
                <Grid container>
                    <Grid className={classes.head} item xs={12}>
                        <Typography className={classes.date} variant="body2">
                            {date}
                        </Typography>
                        <Typography variant="body2">{object}</Typography>
                        <div className={classes.phoneWrapper}>
                            {imgUrl}
                            <Typography className={classes.space} variant="body2">
                                {phoneNumber}
                            </Typography>
                        </div>
                        <Typography variant="body2">{owner}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.column} item xs={12}>
                <Grid container>
                    <Grid className={classes.textCenter} item xs={2}>
                        <AccountCircleRounded/>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className={classes.textCenter} variant="body2">
                            {notes}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

RowActivity.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = () => ({
    root: {
        marginTop: 16,
    },
    space: {
        marginLeft: 10,
    },
    phoneWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
    head: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#fff",
        height: 30,
    },
    date: {
        fontStyle: "italic",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        backgroundColor: "#fff",
        // marginTop: 16,
        height: 40,
    },
    textCenter: {
        display: "flex",
        justifyContent: "center",
    },
    subheading: {
        height: 24,
        display: "flex",
        justifyContent: "center",
    },
});

export default withStyles(styles)(RowActivity);
