import { call, put, takeLatest, delay } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  savePDFError,
  savePDFSuccess,
  sendPDFError,
  sendPDFSuccess,
  unlinkPDFSuccess,
  unlinkPDFError,
  checkPDFUsageRequest,
  checkPDFUsageSuccess,
  checkPDFUsageError,
  setEmptyFileData,
} from "../actions";

import {
  CANCEL_PDF_REQUEST,
  // SAVE_PDF_AND_NEW_ENTITY_REQUEST,
  SAVE_PDF_REQUEST,
  SEND_PDF_REQUEST,
  CHECK_PDF_USAGE_REQUEST,
} from "../../../common/constants";
import AlertService from "../../../common/services/alert.service";

function* sendPDFData(action) {
  try {
    const pdfData = yield call(ApiService.post2, "/upload/pdf", action.data);
    yield put(sendPDFSuccess(pdfData.data, action.router));
  } catch (error) {
    yield put(sendPDFError(error, action.router));
  }
}

function* savePDFData(action) {
  try {
    const pdfData = yield call(
      ApiService.post2,
      "/upload/save_pdf_data",
      action.data
    );
    yield put(savePDFSuccess(pdfData));
    yield put(checkPDFUsageRequest(pdfData.data.PDFid, action.router));
  } catch (error) {
    yield put(savePDFError(error, action.router));
  }
}

// function* savePDFDataAndNewEntity(action) {
//     try {
//         const pdfData = yield call(
//             ApiService.post2,
//             "/upload/save_pdf_save_entity",
//             action.data,
//         );
//         yield put(savePDFSuccess(pdfData));
//     } catch (error) {
//         yield put(savePDFError(error, action.router));
//     }
// }

function* checkPDFUsage(action) {
  try {
    let result;
    while (!result) {
      result = yield call(ApiService.post2, "/upload/checkpdf", {
        pdfId: action.pdfId,
      });
      if (result.data.canUse) {
        AlertService.success(result.data.message);
        if (result.data.entity_id) action.router.push(`/leadgen/entity/${result.data.entity_id}`);
        if (result.data.object_id) action.router.push(`/leadgen/object/${result.data.object_id}`);
        yield put(setEmptyFileData({}));
        break;
      } else {
        if (result.data.timeout) {
          yield delay(result.data.timeout);
          yield put(checkPDFUsageRequest(action.pdfId, action.router));
        }
      }
      yield put(checkPDFUsageSuccess(result.data.message));
    }
  } catch (error) {
    yield put(checkPDFUsageError(error));
  }
}

function* unlinkFileFromServer(action) {
  try {
    const pdfData = yield call(
      ApiService.post2,
      "/upload/unlink_pdf",
      action.pdfData
    );
    yield put(unlinkPDFSuccess(pdfData));
  } catch (error) {
    yield put(unlinkPDFError(error, action.router));
  }
}

export default function* actionPDF() {
  yield takeLatest(SEND_PDF_REQUEST, sendPDFData);
  yield takeLatest(SAVE_PDF_REQUEST, savePDFData);
  // yield takeLatest(SAVE_PDF_AND_NEW_ENTITY_REQUEST, savePDFDataAndNewEntity);
  yield takeLatest(CANCEL_PDF_REQUEST, unlinkFileFromServer);
  yield takeLatest(CHECK_PDF_USAGE_REQUEST, checkPDFUsage);
}
