import React from "react";
import {Link} from "react-router";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

interface NotFoundPageProps {
    classes: {
        root: string,
        icon: string,
        link: string,
    }
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({classes}) => (
    <div className={classes.root}>
        <Typography component="h2" variant="display4">
            404
        </Typography>
        <Link to={"/dashboard"} className={classes.link}>
            <Button>
                <SvgIcon className={classes.icon} color="primary">
                    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
                </SvgIcon>
            </Button>
        </Link>
    </div>
);

const styles = theme => ({
    root: {
        display: "flex",
        width: "100%",
        marginLeft: 20,
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 2}px)`,
        "justify-content": "center",
        "align-items": "center",
    },
    icon: {
        fontSize: 85,
    },
    link: {
        display: "flex",
        marginLeft: 10,
        textDecoration: "none",
    },
});

export default withStyles(styles)(NotFoundPage);
