import { createSelector } from 'reselect';

const newEntityState = state => {
  if (state && state.newEntity) {
    return state.newEntity;
  }
  return null;
};

const newEntitySelector = createSelector(
  [newEntityState],
  state => state, //return newEntity
);

const createNewEntitySelector = createSelector(
  [newEntitySelector],
  state => state.createEntity,
);

const createNewObjectSelector = createSelector(
  [newEntitySelector],
  state => state.createObject,
);

export const createEntity = createSelector(
  [createNewEntitySelector, createNewObjectSelector],
  (createEntity, createObject) => {
    if (createEntity && createEntity.create.entity) {
      return {
        type: 'entity',
        create: createEntity.create.entity,
        id: createEntity.create.entity.id,
      };
    }

    if (createObject && createObject.create.object) {
      return {
        type: 'object',
        create: createObject.create.object,
        id: createObject.create.object.id,
      };
    }
  },
);
