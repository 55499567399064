import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Spinner from "../../../../common/components/spinner";
import { selectSearch } from "../../../search/selectors";
import { selectEntity } from "../../../leadgen-head-menu/selectors";

const LeadgenNoData = ({ classes, search, currentEntity }) => {
    const { processing } = search;
    const { destroyEntity } = currentEntity;

    if (processing) {
        return <Spinner/>;
    }
    else if(destroyEntity.entityIsDeleted) {
        return (
            <div className={classes.root}>
                <Typography
                    variant="h4">{"ENTITY WAS SUCCESSFULLY REMOVED!"}</Typography>
                <Typography
                    variant="h4">{`Open the menu, search or LPS calculation to continue`}</Typography>
            </div>
        );
    }
    else {
        return (
            <div className={classes.root}>
                <Typography
                    variant="h4">{"NO DATA"}</Typography>
                <Typography
                    variant="h4">{`Please click on the  "Leadgen" tab one more time or recalculate LPS profile`}</Typography>
            </div>
        );
    }
};

LeadgenNoData.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string,
};

const styles = () => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: 500,
    },
});

const mapStateToProps = state => ({
    search: selectSearch(state),
    currentEntity: selectEntity(state),
});

export default connect(mapStateToProps)(withStyles(styles)(LeadgenNoData));
