import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const Label = ({
                   classes,
                   handleOpen,
                   handleClose,
                   handleChange,
                   anchorEl,
                   labels,
                   label = {},
               }) => {
    const open = Boolean(anchorEl);

    const labelIsEmpty = !!Object.keys(label).length;

    return (
        <div>
            <div className={classes.wrapperAvatar} onClick={handleOpen}>
                <ListItemText
                    className={classes.itemText}
                    primary={
                        <Typography className={classes.text} variant="body2">
                            Label
                        </Typography>
                    }
                    secondary={labelIsEmpty ? `${label.name || ""}` : "n/a"}
                />
            </div>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transitionDuration={0}
                disableAutoFocusItem
                className={classes.menu}
                open={open}
                onClose={handleClose}
            >
                {labels && (
                    <div>
                        {labels.map(({ id, name }) => (
                            <MenuItem
                                key={id}
                                className={classes.menuItem}
                                onClick={() =>
                                    handleChange("label", {
                                        id,
                                        name,
                                    })
                                }
                            >
                                <ListItemText className={classes.itemText} primary={name}/>
                            </MenuItem>
                        ))}
                        <MenuItem
                            className={classes.menuLastItem}
                            onClick={() => handleChange("label", {})}
                        >
                            set empty / remove
                        </MenuItem>
                    </div>
                )}
            </Menu>
        </div>
    );
};

Label.propTypes = {};

const styles = theme => ({
    link: {
        textDecoration: "none",
    },
    menuItem: {
        height: 35,
    },
    wrapperAvatar: {
        display: "flex",
        height: 45,
        padding: 5,
        borderRadius: 4,
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.14)",
            cursor: "pointer",
        },
    },
    text: {
        fontStyle: "italic",
    },
    menu: {
        top: 56,
    },
    menuLastItem: {
        display: "flex",
        justifyContent: "center",
    },
    itemText: {
        marginRight: 0,
    },
    iconButton: {
        padding: 6,
    },
    image: {
        width: 45,
        height: 45,
        backgroundColor: theme.palette.primary[400],
    },
});

export default withStyles(styles)(React.memo(Label));
