import { all } from "redux-saga/effects";
import actionLeadgenNotes from "./notes";
import actionLeadgenObjects from "./objects";
import actionLeadgenOwners from "./owners";
import actionDependentEntities from "./dependentEntities";
import actionGoogleDrive from "./drive";

export default function* actionLeadgen() {
    yield all([
        actionLeadgenNotes(),
        actionLeadgenObjects(),
        actionLeadgenOwners(),
        actionDependentEntities(),
        actionGoogleDrive(),
    ]);
}
