import { createSelector } from "reselect";

const leadgenState = (state) => {
  if (state && state.leadgen) {
    return state.leadgen;
  }
  return null;
};

const leadgenSelector = createSelector(
  [leadgenState],
  (state) => state /**return leadgen**/
);

/*objects*/
const leadgenObjectsSelector = createSelector(
  [leadgenSelector],
  (state) => state.objects
);

const leadgenObjectsReceiveSelector = createSelector(
  [leadgenObjectsSelector],
  (state) => state.receiveObjects
);

const leadgenEditableObjectSelector = createSelector(
  [leadgenObjectsSelector],
  (state) => state.editableObject
);

const leadgenObjectsRequestSelector = createSelector(
  [leadgenObjectsSelector],
  (state) => state.requestObjects
);
const leadgenObjectsInfoRequestSelector = createSelector(
  [leadgenObjectsSelector],
  (state) => state.receiveObjectsInfo
);
/*objects*/

/*dependentEntities*/
const leadgenDependentEntitiesSelector = createSelector(
  [leadgenSelector],
  (state) => state.dependentEntities
);

const leadgenDependentEntitiesReceiveSelector = createSelector(
  [leadgenDependentEntitiesSelector],
  (state) => state.receiveDependentEntities
);
/*dependentEntities*/

/*owners*/
const leadgenOwnersSelector = createSelector(
  [leadgenSelector],
  (state) => state.owners
);

const leadgenOwnersReceiveSelector = createSelector(
  [leadgenOwnersSelector],
  (state) => state.receiveOwners
);
/*owners*/

/*notes*/
const leadgenNotesSelector = createSelector(
  [leadgenSelector],
  (state) => state.notes
);

const leadgenNotesReceiveSelector = createSelector(
  [leadgenNotesSelector],
  (state) => state.receiveNotes
);
/*notes*/

/*maps*/
const leadgenMapsSelector = createSelector(
  [leadgenSelector],
  (state) => state.maps
);

const leadgenMapsReceiveSelector = createSelector(
  [leadgenMapsSelector],
  (state) => state.showAddress
);
/*maps*/

/*drive*/
const leadgenDriveSelector = createSelector(
  [leadgenSelector],
  (state) => state.drive
);

const leadgenImportedDriveFileSelector = createSelector(
  [leadgenDriveSelector],
  (state) => state.importDriveFiles
);
/*drive*/

export const selectLeadgen = createSelector(
  [
    leadgenSelector,
    leadgenObjectsReceiveSelector,
    leadgenEditableObjectSelector,
    leadgenObjectsRequestSelector,
    leadgenObjectsInfoRequestSelector,
    leadgenDependentEntitiesReceiveSelector,
    leadgenOwnersReceiveSelector,
    leadgenNotesReceiveSelector,
    leadgenMapsReceiveSelector,
    leadgenDriveSelector,
    leadgenImportedDriveFileSelector,
  ],
  (
    leadgen,
    receiveObjects,
    editableObject,
    requestObjects,
    objectsInfo,
    receiveDependentEntities,
    receiveOwners,
    receiveNotes,
    showAddress,
    drive,
    importDriveFiles
  ) => {
    const objects = receiveObjects ? receiveObjects : [];
    const objectIDs = objects ? objects.map((object) => object.object_id) : [];
    const managers =
      receiveDependentEntities &&
      receiveDependentEntities.entities &&
      receiveDependentEntities.entities.managersData
        ? receiveDependentEntities.entities.managersData
        : [];
    const contacts =
      receiveDependentEntities &&
      receiveDependentEntities.entities &&
      receiveDependentEntities.entities.contactsData
        ? receiveDependentEntities.entities.contactsData
        : [];
    const headMenuManagers =
      receiveDependentEntities &&
      receiveDependentEntities.formattedEntities &&
      receiveDependentEntities.formattedEntities.managersDataForHeadMenu
        ? receiveDependentEntities.formattedEntities.managersDataForHeadMenu
        : [];
    const headMenuMContacts =
      receiveDependentEntities &&
      receiveDependentEntities.formattedEntities &&
      receiveDependentEntities.formattedEntities.contactsDataForHeadMenu
        ? receiveDependentEntities.formattedEntities.contactsDataForHeadMenu
        : [];
    const owners = receiveOwners ? receiveOwners : [];
    const notes = receiveNotes ? receiveNotes : [];
    const updatedAt = notes && notes.updated_at ? notes.updated_at : "";
    const body = notes && notes.body ? notes.body : "";
    const title = notes && notes.title ? notes.title : "";
    const noteAuthor = receiveNotes ? receiveNotes.noteAuthor : {};
    const name = noteAuthor && noteAuthor.name ? noteAuthor.name : "";
    const surname = noteAuthor && noteAuthor.surname ? noteAuthor.surname : "";
    const selectedAddress = showAddress ? showAddress : "";
    const objectsAmount =
      objectsInfo && objectsInfo.objectsAmount ? objectsInfo.objectsAmount : 0;
    const firstId =
      objectsInfo && objectsInfo.firstId ? objectsInfo.firstId : "";
    const lastId = objectsInfo && objectsInfo.lastId ? objectsInfo.lastId : "";

    return {
      objects,
      objectIDs,
      requestObjects,
      objectsAmount,
      firstId,
      lastId,
      managers,
      contacts,
      headMenuManagers,
      headMenuMContacts,
      owners,
      notes,
      noteAuthor,
      updatedAt,
      body,
      title,
      name,
      surname,
      selectedAddress,
      editableObject,
      drive,
      importDriveFiles,
    };
  }
);
