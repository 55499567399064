import {
    RUN_PROCESSING_LPS_REQUEST,
    FINISH_PROCESSING_LPS_SUCCESS,
    RECEIVE_LPS_CONFIGS_SUCCESS,
    CHECK_BEST_LEAD_SUCCESS,
    FINISH_PROCESSING_LPS_ERROR,
    RECEIVE_LPS_CONFIGS_ERROR,
    CHECK_BEST_LEAD_REQUEST,
    PREV_LPS_ITEM,
    NEXT_LPS_ITEM,
    SET_LPS_ITEM_INDEX,
    SHOW_ASSIGNED,
    SHOW_SCHEDULED,
    RECALCULATE_DEFAULT_PROFILE,
    SET_CURRENT_USER_ID,
    FIND_LEAD_FLAG,
    SKIP_UNASSIGNED_LEADS_IN_LPS_RATING,
    EDIT_LPS_CONFIGS_SUCCESS,
} from "../../../common/constants";
import UserService from "../../../common/services/user.service";

const initialState = {
    lpsConfigs: [],
    isFetching: false,
    result: JSON.parse(UserService.LPC),
    indexLPSItem: -1,
    assigned: false,
    scheduled: false,
    isCalculated: false,
    selectedUserIds: [],
};

const lps = (state = initialState, action) => {
    switch (action.type) {
        case RUN_PROCESSING_LPS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FINISH_PROCESSING_LPS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                indexLPSItem: -1,
                result: action.result,
            };
        case FINISH_PROCESSING_LPS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };

        case RECEIVE_LPS_CONFIGS_SUCCESS:
            return {
                ...state,
                lpsConfigs: action.lpsConfigs,
            };

        case EDIT_LPS_CONFIGS_SUCCESS:
            return {
                ...state,
                updatedConfig: action.updatedConfig,
            };

        case RECEIVE_LPS_CONFIGS_ERROR:
            return {
                ...state,
                error: action.error,
            };

        case CHECK_BEST_LEAD_REQUEST:
            return {
                ...state,
                isFetching: true,
            };

        case CHECK_BEST_LEAD_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };

        case SET_LPS_ITEM_INDEX:
            return {
                ...state,
                indexLPSItem: action.indexLPSItem,
            };

        case NEXT_LPS_ITEM:
            return {
                ...state,
            };

        case PREV_LPS_ITEM:
            return {
                ...state,
            };

        case SHOW_ASSIGNED:
            return {
                ...state,
                assigned: action.assigned,
            };

        case SHOW_SCHEDULED:
            return {
                ...state,
                scheduled: action.scheduled,
            };

        case SKIP_UNASSIGNED_LEADS_IN_LPS_RATING:
            return {
                ...state,
                isSkipped: action.isSkipped,
            };

        case RECALCULATE_DEFAULT_PROFILE:
            return {
                ...state,
                isCalculated: action.isCalculated,
            };

        case SET_CURRENT_USER_ID:
            return {
                ...state,
                selectedUserIds: action.selectedUserIds,
            };

        case FIND_LEAD_FLAG:
            return {
                ...state,
                findLeadFlag: action.findLeadFlag,
            };

        default:
            return state;
    }
};

export default lps;
