import ErrorHandler from "../../../common/services/error.handler";
import React from "react";
import {
    GET_CURRENT_USER_ERROR, GET_CURRENT_USER_REQUEST,
    GET_CURRENT_USER_SUCCESS,
    RECEIVE_USER_ERROR,
    RECEIVE_USER_REQUEST,
    RECEIVE_USER_SUCCESS,
} from "../../../common/constants";
// import { handleSuccess } from "../handlers";
import AlertService from "../../../common/services/alert.service";
import Typography from "@material-ui/core/Typography";

export const receiveUserRequest = (message, currentUser, router, cb) => {
    return {
        type: RECEIVE_USER_REQUEST,
        message,
        currentUser,
        router,
        cb
    };
};

export const receiveUserSuccess = (router, cb) => {
    return {
        type: RECEIVE_USER_SUCCESS,
        router,
        cb
    };
};

export const receiveUserError = (message, error) => {
    AlertService.error(
        <Typography style={{ color: "inherit" }} variant="h6">
            Access is denied
        </Typography>,
    );
    ErrorHandler.do(error);
    return {
        type: RECEIVE_USER_ERROR,
        message,
        error,
    };
};

export const getCurrentUserRequest = () => {
    return {
        type: GET_CURRENT_USER_REQUEST,
    };
};

export const getCurrentUserSuccess = (user) => {
   return {
        type: GET_CURRENT_USER_SUCCESS,
        user: user,
    };
};

export const getCurrentUserError = (message, error) => {
    AlertService.error(
        <Typography style={{ color: "inherit" }} variant="h6">
            Access is denied
        </Typography>,
    );
    ErrorHandler.do(error);
    return {
        type: GET_CURRENT_USER_ERROR,
        message,
        error,
    };
};
