import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Maps from "../maps";
import Drive from "../drive";
import { selectLeadgen } from "../../selectors";
import { selectLeads } from "../../../leads/selectors";
import { selectEntity } from "../../../leadgen-head-menu/selectors";

class GoogleTools extends PureComponent {
    static propTypes = { classes: PropTypes.object.isRequired };

    state = { value: 0, markers: [], subValue: 3 };

    componentDidUpdate(prevProps) {
        const { currentEntity } = this.props;
        const { setLeadId } = currentEntity;
        if (setLeadId !== prevProps.currentEntity.setLeadId) {
            this.setState({
                value: 0,
            });
        }
    }

    getAddresses = objects => {
        return objects.map(({ street, units, city = "", object_id }) => {
            return {
                city,
                address:
                    street !== "mixed"
                        ? street || ""
                        : !!units.length
                        ? `${units[0].address.street_name || ""} ${units[0].address
                            .number || ""}`.trim()
                        : "",
            };
        });
    };

    handleChange = (e, value) => {
        e.preventDefault();
        this.setState({ value });
    };

    setFoundAddresses = markers => {
        this.setState({ markers });
    };

    render() {
        const { classes, leadgen } = this.props;
        const { objects, selectedAddress } = leadgen;
        const { value, markers } = this.state;
        const addresses = this.getAddresses(objects);

        return (
            <div className={classes.root}>
                <div className={classes.tabs}>
                    <Tab
                        onClick={e => this.handleChange(e, 0)}
                        label="Maps"
                        className={value === 0 ? classes.selectedTab : classes.tab}
                        fullWidth
                    />
                    <Tab
                        onClick={e => this.handleChange(e, 1)}
                        label="Info"
                        className={value === 1 ? classes.selectedTab : classes.tab}
                        fullWidth
                    />
                    <Tab
                        onClick={e => this.handleChange(e, 2)}
                        label="Drive"
                        className={value === 2 ? classes.selectedTab : classes.tab}
                        fullWidth
                    />
                </div>
                {value === 0 && (
                    <Maps
                        markers={markers}
                        addresses={addresses}
                        selectedAddress={selectedAddress ? { ...selectedAddress } : null}
                        setFoundAddresses={this.setFoundAddresses}
                    />
                )}
                {value === 1 && <div>Page Two</div>}
                {value === 2 && (
                    <Drive/>
                )}
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        height: "inherit",
    },
    tabs: {
        display: "flex",
    },
    tab: {
        borderBottom: `3px solid ${theme.palette.primary[500]}`,
        color: theme.palette.primary[500],
    },
    selectedTab: {
        borderBottom: `3px solid ${theme.palette.secondary.lightGreen600}`,
        color: theme.palette.primary[500],
        opacity: 1,
    },
});

const mapStateToProps = state => ({
    leadgen: selectLeadgen(state),
    leads: selectLeads(state),
    currentEntity: selectEntity(state),
});

export default connect(mapStateToProps)(withStyles(styles)(GoogleTools));
