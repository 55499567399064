import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  receiveCompanySuccess,
  receiveCompanyError,
  setCurrentCompanySuccess
} from "../actions/company";
import {
  SEARCH_RECEIVE_COMPANY_REQUEST,
  SET_QUERY_COMPANY_REQUEST
} from "../../../common/constants";

function* searchReceiveCompany(action) {
  try {
    const company = yield call(
      ApiService.post2,
      "/search/company_by",
      action.query
    );
    yield put(receiveCompanySuccess(company));
  } catch (error) {
    yield put(receiveCompanyError(error));
  }
}

function* searchReceiveQueryCompany({ fieldType }, action) {
  try {
    const queryCompany = yield call(ApiService.post2, "/search/company_by", {
      query: action.id,
      fieldType
    });
    yield put(setCurrentCompanySuccess(queryCompany));
  } catch (error) {
    yield put(receiveCompanyError(error));
  }
}

const TYPE = "by_company_id";

export default function* actionSearchByCompany() {
  yield takeLatest(SEARCH_RECEIVE_COMPANY_REQUEST, searchReceiveCompany);
  yield takeLatest(SET_QUERY_COMPANY_REQUEST, searchReceiveQueryCompany, {
    fieldType: TYPE
  });
}
