import { createSelector } from "reselect";

const leadsState = state => {
    if (state && state.login) {
        return state.login;
    }
    return null;
};

const userSelector = createSelector(
    [ leadsState ],
    state => state, //return login
);
const receiveUserSelector = createSelector(
    [ userSelector ],
    state => state.receive,
);

export const selectUser = createSelector(
    [
        userSelector,
        receiveUserSelector,
    ],
    (user, receive) => {
        const userData = user && receive ? receive : {};
        return {
            userData
        };
    },
);
