import React, { PureComponent } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  EntityEditor,
  ObjectEditor,
} from "../../common/components/editor/entity";
import { createNewEntityRequest, createNewObjectRequest } from "./actions";
import { validate, validateKadID } from "../../common/helpers/validator";
import { createEntity } from "./selectors";

type AddNewEntityProps = {
  classes: any;
  createdEntity: any;
  dispatch: any;
  router: any;
};

type AddNewEntityState = {
  entity: {
    prefix?: string | null;
    company_name?: string;
    chamber_commerce_url?: string | null;
    chamber_commerce_number?: string;
    last_name?: string;
    first_name?: string;
    initials?: string;
    all_names?: string;
    nick_name?: string;
    birth_date?: Date | string;
    birth_year?: string;
    birth_place?: string;
    blacklisted?: boolean | string;
    is_dead?: boolean | string;
    gender?: string;
    kadaster_personal_number?: string;
    kadaster_objects_amount?: string;
    kadaster_url?: string | null;
    url?: string;
    company_id?: string;
    drive_folder_id?: string;
    addresses?: any;
    phone_numbers?: any;
    emails?: any;
    socials?: any;
    kadaster_id?: string | null;
    isBlacklisted?: string | null;
  };
  entityType: string;
  validationMode: {
    object: boolean;
    company: boolean;
    person: boolean;
  };
};

class AddNewEntity extends PureComponent<AddNewEntityProps, AddNewEntityState> {
  state = {
    entity: {
      kadaster_id: "",
      prefix: "",
      company_name: "",
      chamber_commerce_url: "",
      chamber_commerce_number: "",
      last_name: "",
      first_name: "",
      initials: "",
      all_names: "",
      nick_name: "",
      birth_date: "",
      birth_year: "",
      birth_place: "",
      blacklisted: "",
      is_dead: "",
      gender: "",
      kadaster_personal_number: "",
      kadaster_objects_amount: "",
      kadaster_url: "",
      url: "",
      company_id: "",
      drive_folder_id: "",
      addresses: [],
      phone_numbers: [],
      emails: [],
      socials: [],
      isBlacklisted: "",
    },
    entityType: "person",
    validationMode: {
      object: false,
      company: false,
      person: false,
    },
  };

  componentDidUpdate(prevProps) {
    const { createdEntity } = this.props;
    if (this.props !== prevProps) {
      const { type, id } = createdEntity;
      this.props.router.push(`/leadgen/${type}/${id}`);
    }
  }

  handleChange = (name: string, { target }) => {
    const { entity } = this.state;
    const link =
      "https://kadaster-on-line.kadaster.nl/selectie.asp?identificatie=NL.IMKAD.Persoon.";
    const kvkLink = "https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer=";

    let value = target.value;

    if (name === "price") value = Number(value);
    if (name === "kadaster_personal_number") {
      return this.setState({
        entity: {
          ...entity,
          [name]: value,
          kadaster_url: value ? link + value : null,
        },
      });
    }
    if (name === "chamber_commerce_number") {
      return this.setState({
        entity: {
          ...entity,
          [name]: value,
          chamber_commerce_url: value ? kvkLink + value : null,
        },
      });
    }
    this.setState({ entity: { ...entity, [name]: value } });
  };

  handleSave = async () => {
    const { entity, entityType, validationMode } = this.state;
    const { dispatch } = this.props;
    const isValidated = validate(entityType, { ...entity });

    let isExistedKadID = false;
    const kad_id =
      entityType === "object"
        ? entity.kadaster_id
        : entity.kadaster_personal_number;

    if (kad_id) {
      await validateKadID(
        entityType === "object" ? "object" : "entity",
        { kad_id },
        (data) => (isExistedKadID = data)
      );
    }
    switch (entityType) {
      case "object":
        return isValidated && !isExistedKadID
          ? dispatch(createNewObjectRequest(entity))
          : this.setState({
              validationMode: { ...validationMode, object: true },
            });
      case "company":
        return isValidated && !isExistedKadID
          ? dispatch(
              createNewEntityRequest({ ...entity, entity_type: entityType })
            )
          : this.setState({
              validationMode: { ...validationMode, company: true },
            });
      case "person":
        return isValidated && !isExistedKadID
          ? dispatch(
              createNewEntityRequest({ ...entity, entity_type: entityType })
            )
          : this.setState({
              validationMode: { ...validationMode, person: true },
            });

      default:
        return false;
    }
  };

  handleSelectType = (e, entityType) => {
    entityType &&
      this.setState({
        entityType,
        entity: {},
        validationMode: { object: false, company: false, person: false },
      });
  };

  render() {
    const { classes } = this.props;
    const { entity, entityType, validationMode } = this.state;

    return (
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8} className={classes.item}>
          <Paper>
            <div className={classes.innerEditor}>
              <ToggleButtonGroup
                value={entityType}
                exclusive
                onChange={this.handleSelectType}
                className={classes.toggleButtonGroup}
              >
                <ToggleButton value="person">person</ToggleButton>
                <ToggleButton value="company">company</ToggleButton>
                <ToggleButton value="object">object</ToggleButton>
              </ToggleButtonGroup>
              {entityType === "object" ? (
                //@ts-ignore
                <ObjectEditor
                  validationMode={validationMode.object}
                  editableObject={entity}
                  //@ts-ignore
                  onChange={this.handleChange}
                />
              ) : (
                <EntityEditor
                  validationMode={validationMode}
                  entityType={entityType}
                  editableEntity={entity}
                  onChange={this.handleChange}
                />
              )}
            </div>
            <Button onClick={this.handleSave} className={classes.button}>
              save
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    );
  }
}

const styles = (theme) => ({
  innerEditor: {
    padding: 24,
  },
  toggleButtonGroup: {
    display: "flex",
    width: 240,
    marginBottom: 24,
  },
  button: {
    width: "100%",
    borderRadius: 0,
    height: 50,
  },
  item: {
    display: "flex",
    marginTop: 18,
    flexDirection: "column",
  },
});

const mapStateToProps = (state) => ({
  createdEntity: createEntity(state),
});
//@ts-ignore
export default connect(mapStateToProps)(withStyles(styles)(AddNewEntity));
