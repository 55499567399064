export const formatInitials = (initials = '', points = false) => {
  if (!initials) return '';
  const reg = RegExp(/[^a-zA-Z]/, 'g');
  const a = initials.replace(reg, '');

  const res = a.split('');
  res.push('');
  return initials
    ? points
      ? res.join('.').toUpperCase()
      : res.join('').toUpperCase()
    : '';
};
