import { combineReducers } from "redux";
import {
    CREATE_NEW_OBJECT_ERROR,
    CREATE_NEW_OBJECT_SUCCESS,
} from "../../../common/constants";

const createNewObject = (state = {}, action) => {
    switch (action.type) {
        case CREATE_NEW_OBJECT_SUCCESS:
            return action.newObject;

        default:
            return state;
    }
};

const createNewObjectError = (state = {}, action) => {
    switch (action.type) {
        case CREATE_NEW_OBJECT_ERROR:
            return action.error;

        default:
            return state;
    }
};

const createObject = combineReducers({
    create: createNewObject,
    error: createNewObjectError,
});

export default createObject;
