import ErrorHandler from "../../../common/services/error.handler";
import AlertService from "../../../common/services/alert.service";
import {
  DELETE_LPS_CONFIGS_ERROR,
  DELETE_LPS_CONFIGS_REQUEST,
  DELETE_LPS_CONFIGS_SUCCESS,
  EDIT_LPS_CONFIGS_ERROR,
  EDIT_LPS_CONFIGS_REQUEST,
  EDIT_LPS_CONFIGS_SUCCESS,
  FINISH_PROCESSING_LPS_ERROR,
  FINISH_PROCESSING_LPS_SUCCESS,
  NEXT_LPS_ITEM,
  PREV_LPS_ITEM,
  RECALCULATE_DEFAULT_PROFILE,
  RECEIVE_LPS_CONFIGS_ERROR,
  RECEIVE_LPS_CONFIGS_REQUEST,
  RECEIVE_LPS_CONFIGS_SUCCESS,
  RUN_PROCESSING_LPS_REQUEST,
  SET_CURRENT_USER_ID,
  SET_LPS_ITEM_INDEX,
  SHOW_ASSIGNED,
  SHOW_SCHEDULED,
  FIND_LEAD_FLAG,
  SKIP_UNASSIGNED_LEADS_IN_LPS_RATING,
  CHECK_LPS_USAGE_REQUEST,
  CHECK_LPS_USAGE_SUCCESS,
  CHECK_LPS_USAGE_ERROR,
} from "../../../common/constants";

export const CheckLpsUsageRequest = (needAlertFlag = false) => {
  return {
    type: CHECK_LPS_USAGE_REQUEST,
    needAlertFlag,
  };
};

export const countLPSDataRequest = (query, router) => {
  // AlertService.info("LPS process started");
  return {
    type: RUN_PROCESSING_LPS_REQUEST,
    query,
    router,
  };
};

export const receiveLPSConfigsRequest = () => {
  return {
    type: RECEIVE_LPS_CONFIGS_REQUEST,
  };
};

export const editLPSConfigsRequest = (data) => {
  return {
    type: EDIT_LPS_CONFIGS_REQUEST,
    data,
  };
};

export const deleteLPSConfigsRequest = (id) => {
  return {
    type: DELETE_LPS_CONFIGS_REQUEST,
    id,
  };
};

export const checkLpsUsageSuccess = (canUseLps) => {
  AlertService.info(canUseLps);
  return {
    type: CHECK_LPS_USAGE_SUCCESS,
    canUseLps,
  };
};

export const finishCountLPSDataSuccess = (result) => {
  AlertService.success("LPS process completed");
  return {
    type: FINISH_PROCESSING_LPS_SUCCESS,
    result: result,
  };
};

export const receiveLPSConfigsSuccess = (lpsConfigs) => {
  return {
    type: RECEIVE_LPS_CONFIGS_SUCCESS,
    lpsConfigs: lpsConfigs.data,
  };
};

export const editLPSConfigsSuccess = (lpsConfigs) => {
  AlertService.success("LPS CONFIG UPDATED");
  return {
    type: EDIT_LPS_CONFIGS_SUCCESS,
    updatedConfig: lpsConfigs,
  };
};

export const deleteLPSConfigsSuccess = () => {
  AlertService.success("LPS CONFIG DELETED");
  return {
    type: DELETE_LPS_CONFIGS_SUCCESS,
  };
};

export const checkLpsUsageError = (error) => {
  ErrorHandler.do(error);
  return {
    type: CHECK_LPS_USAGE_ERROR,
    error,
  };
};

export const finishCountLPSDataError = (error) => {
  ErrorHandler.do(error);
  return {
    type: FINISH_PROCESSING_LPS_ERROR,
    error,
  };
};

export const receiveLPSConfigsError = (error) => {
  ErrorHandler.do(error);
  return {
    type: RECEIVE_LPS_CONFIGS_ERROR,
    error,
  };
};

export const editLPSConfigsError = (error) => {
  ErrorHandler.do(error);
  return {
    type: EDIT_LPS_CONFIGS_ERROR,
    error,
  };
};

export const deleteLPSConfigsError = (error) => {
  ErrorHandler.do(error);
  return {
    type: DELETE_LPS_CONFIGS_ERROR,
    error,
  };
};

export const nextLPSItem = (router) => {
  return {
    type: NEXT_LPS_ITEM,
    router,
  };
};

export const setLPSItem = (index, router) => {
  return {
    type: SET_LPS_ITEM_INDEX,
    indexLPSItem: index,
    router,
  };
};

export const prevLPSItem = (router) => {
  return {
    type: PREV_LPS_ITEM,
    router,
  };
};

export const showAssigned = (assigned) => {
  return {
    type: SHOW_ASSIGNED,
    assigned,
  };
};

export const showScheduled = (scheduled) => {
  return {
    type: SHOW_SCHEDULED,
    scheduled,
  };
};

export const skipUnassignedLeadsFlag = (isSkipped) => {
  return {
    type: SKIP_UNASSIGNED_LEADS_IN_LPS_RATING,
    isSkipped,
  };
};

export const calcDefaultProfile = (isCalculated) => {
  return {
    type: RECALCULATE_DEFAULT_PROFILE,
    isCalculated,
  };
};

export const setSelectedUsers = (selectedUserIds) => {
  return {
    type: SET_CURRENT_USER_ID,
    selectedUserIds,
  };
};

export const findLeadFlag = (findLead) => {
  return {
    type: FIND_LEAD_FLAG,
    findLeadFlag: findLead,
  };
};
