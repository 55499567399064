import { all } from "redux-saga/effects";
import actionAdminUsers from "./modules/admin/sagas";
import actionLeadgen from "./modules/leadgen/sagas/";
import actionNewEntity from "./modules/add-new-entity/sagas";
import actionLeadgenHeadMenu from "./modules/leadgen-head-menu/sagas";
import actionLeads from "./modules/leads/sagas";
import actionLogin from "./modules/login/sagas";
import actionSearch from "./modules/search/sagas";
import actionPDF from "./modules/importPDF/sagas";
import actionQueryObjects from "./modules/query-objects/sagas";

export default function* rootSaga() {
    yield all([
        actionAdminUsers(),
        actionNewEntity(),
        actionLeadgen(),
        actionLeadgenHeadMenu(),
        actionLeads(),
        actionLogin(),
        actionSearch(),
        actionPDF(),
        actionQueryObjects(),
    ]);
}
