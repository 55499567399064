import { combineReducers } from "redux";
import {
    SEARCH_RECEIVE_STREET_REQUEST,
    SEARCH_SET_EMPTY_STREET_REQUEST,
    SEARCH_RECEIVE_STREET_SUCCESS,
    SEARCH_RECEIVE_STREET_LIST_SUCCESS,
    SEARCH_RECEIVE_STREET_ERROR,
    SEARCH_SET_EMPTY_STREET_ERROR,
    SEARCH_RECEIVE_STREET_LIST_ERROR,
} from "../../../common/constants";

const requestByStreet = (state = false, action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_STREET_REQUEST:
        case SEARCH_SET_EMPTY_STREET_REQUEST:
            return true;
        case SEARCH_RECEIVE_STREET_SUCCESS:
        case SEARCH_RECEIVE_STREET_ERROR:
        case SEARCH_SET_EMPTY_STREET_ERROR:
            return false;
        default:
            return state;
    }
};

const receiveByStreet = (state = [], action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_STREET_SUCCESS:
            return action.street;

        default:
            return state;
    }
};

const receiveStreetList = (state = [], action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_STREET_LIST_SUCCESS:
            return action.street;

        default:
            return state;
    }
};

const errorByStreet = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_STREET_ERROR:
        case SEARCH_SET_EMPTY_STREET_ERROR:
        case SEARCH_RECEIVE_STREET_LIST_ERROR:
            return action.error;

        default:
            return state;
    }
};

const street = combineReducers({
    requestByStreet,
    receiveByStreet,
    receiveStreetList,
    errorByStreet,
});

export default street;
