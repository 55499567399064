import { put, takeLatest, call } from "redux-saga/effects";
import {
  adminGetUsersSuccess,
  adminConnectToDriveSuccess,
  adminGetUsersError,
  adminCreateUsersError,
  adminUpdateUsersError,
  adminDeleteUsersError,
  adminConnectToDriveError
} from "../actions/user";
import ApiService from "../../../common/services/api.service";
import AlertService from "../../../common/services/alert.service";
import {
  GET_ADMIN_USERS_REQUEST,
  CREATE_ADMIN_USER_REQUEST,
  UPDATE_ADMIN_USER_REQUEST,
  DELETE_ADMIN_USER_REQUEST,
  GET_ADMIN_DRIVE_REQUEST
} from "../../../common/constants";

function* getAdminUsers() {
  try {
    const users = yield call(ApiService.get2, "/user/log");
    yield put(adminGetUsersSuccess(users));
  } catch (error) {
    yield put(adminGetUsersError(error));
  }
}

function* createAdminNewUser(action) {
  try {
    const newUser = yield call(ApiService.post2, "/user", action.user);
    if (newUser) {
      AlertService.success("USER SUCCESSFULLY CREATED");
    } else {
      AlertService.error("SORRY, SOMETHING WRONG");
    }

    const users = yield call(ApiService.get2, "/user/log");
    yield put(adminGetUsersSuccess(users));
  } catch (error) {
    yield put(adminCreateUsersError(error));
  }
}

function* updateAdminUserData(action) {
  try {
    const updatedUser = yield call(
      ApiService.put2,
      `/user/${action.id}`,
      action.user
    );
    if (updatedUser) {
      AlertService.success("USER SUCCESSFULLY UPDATED");
    } else {
      AlertService.error("SORRY, SOMETHING WRONG");
    }

    const users = yield call(ApiService.get2, "/user/log");
    yield put(adminGetUsersSuccess(users));
  } catch (error) {
    yield put(adminUpdateUsersError(error));
  }
}

function* deleteAdminUserData(action) {
  try {
    const deletedUser = yield call(ApiService.delete2, `/user/${action.id}`);
    if (deletedUser) {
      AlertService.success("USER SUCCESSFULLY DELETED");
    } else {
      AlertService.error("SORRY, SOMETHING WRONG");
    }

    const users = yield call(ApiService.get2, "/user/log");
    yield put(adminGetUsersSuccess(users));
  } catch (error) {
    yield put(adminDeleteUsersError(error));
  }
}

function* driveConnect(action) {
  try {
    const driveAuthUrl = yield call(ApiService.get2, "/user/drive/auth");

    yield put(adminConnectToDriveSuccess(driveAuthUrl));
  } catch (error) {
    yield put(adminConnectToDriveError(error));
  }
}

export default function* actionAdminUsers() {
  yield takeLatest(GET_ADMIN_USERS_REQUEST, getAdminUsers);
  yield takeLatest(CREATE_ADMIN_USER_REQUEST, createAdminNewUser);
  yield takeLatest(UPDATE_ADMIN_USER_REQUEST, updateAdminUserData);
  yield takeLatest(DELETE_ADMIN_USER_REQUEST, deleteAdminUserData);
  yield takeLatest(GET_ADMIN_DRIVE_REQUEST, driveConnect);
}
