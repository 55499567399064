import { createSelector } from "reselect";

const importPDFState = (state) => {
  if (state && state.importPDF) {
    return state.importPDF;
  }
  return null;
};

const importedPDFSelector = createSelector(
  [importPDFState],
  (state) => state // return importPDF
);
const parsedPDFDataSelector = createSelector(
  [importedPDFSelector],
  (state) => state.parsePDF
);

const savePDFSelector = createSelector(
  [importedPDFSelector],
  (state) => state.savePDF
);

export const selectPDF = createSelector(
  [importedPDFSelector, parsedPDFDataSelector, savePDFSelector],
  (importPDF, parsePDF, savePDF) => {
    const pdfData = parsePDF.pdfData ? parsePDF.pdfData : {};
    const isFetching =
      parsePDF && parsePDF.isFetching ? parsePDF.isFetching : false;
    const parsedPDFData =
      pdfData && pdfData.parsedResult ? pdfData.parsedResult : {};
    const fileForDriveImport = pdfData && pdfData.pdf ? pdfData.pdf : {};
    const objectsForCheckbox = parsedPDFData && parsedPDFData.objectsForCheckAndUpdate ? parsedPDFData.objectsForCheckAndUpdate.map(object => {
      let newObj = {
        ...object,
        isSelected: false,
        units: [],
      };
      if(object.units) {
        object.units.map(unit => {
          const newUnit = {
            ...unit,
            isSelected: false,
            temp_id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
          }
          newObj.units.push(newUnit);
        })
      }


      return newObj
    }) : [];
    return {
      pdfData,
      objectsForCheckbox,
      isFetching,
      parsedPDFData,
      fileForDriveImport,
      savePDF,
    };
  }
);
