import ErrorHandler from "../../../common/services/error.handler";
import {
  SEARCH_RECEIVE_PERSON_REQUEST,
  SEARCH_SET_EMPTY_PERSON_REQUEST,
  SEARCH_RECEIVE_PERSON_SUCCESS,
  SEARCH_SET_EMPTY_PERSON_SUCCESS,
  SEARCH_RECEIVE_PERSON_ERROR,
  SEARCH_SET_EMPTY_PERSON_ERROR
} from "../../../common/constants";

export const receivePersonRequest = query => {
  return {
    type: SEARCH_RECEIVE_PERSON_REQUEST,
    query
  };
};

export const setEmptyPersonRequest = () => {
  return {
    type: SEARCH_SET_EMPTY_PERSON_REQUEST
  };
};

export const receivePersonSuccess = person => {
  return {
    type: SEARCH_RECEIVE_PERSON_SUCCESS,
    person: person.data
  };
};

export const setEmptyPersonSuccess = person => {
  return {
    type: SEARCH_SET_EMPTY_PERSON_SUCCESS,
    person: person.data
  };
};

export const receivePersonError = (query, error) => {
  ErrorHandler.do(error);
  return {
    type: SEARCH_RECEIVE_PERSON_ERROR,
    query,
    error
  };
};

export const setEmptyPersonError = error => {
  ErrorHandler.do(error);
  return {
    type: SEARCH_SET_EMPTY_PERSON_ERROR,
    error
  };
};
