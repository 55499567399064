import { put, takeLatest, call } from 'redux-saga/effects';
import ApiService from '../../../common/services/api.service';
import {
  leadgenPutNotesSuccess,
  leadgenPutNotesError,
  removeNoteSuccess,
  leadgenRemoveNoteError,
  leadgenGetNotesSuccess,
  leadgenGetNotesError,
} from "../actions/notes";
import {
  GET_LEADGEN_NOTES_REQUEST,
  PUT_LEADGEN_NOTES_REQUEST,
  REMOVE_LEADGEN_NOTE_REQUEST,
} from '../../../common/constants';

function* receiveLeadgenNotes(action) {
  try {
    const notes = yield call(ApiService.post2, '/notes/data', action.query);
    yield put(leadgenGetNotesSuccess(notes));
  } catch (error) {
    yield put(leadgenGetNotesError(error));
  }
}

function* putLeadgenNotes(action) {
  try {
    const notes = yield call(ApiService.put2, `/notes/${action.id}`, action.data);
    yield put(leadgenPutNotesSuccess(notes));
  } catch (error) {
    yield put(leadgenPutNotesError(error));
  }
}

function* removeLeadgenNote(action) {
  try {
    const note = yield call(ApiService.delete2, `/notes/${action.id}`);
    yield put(removeNoteSuccess(note));
  } catch (error) {
    yield put(leadgenRemoveNoteError(error));
  }
}

export default function* actionLeadgenNotes() {
  yield takeLatest(GET_LEADGEN_NOTES_REQUEST, receiveLeadgenNotes);
  yield takeLatest(PUT_LEADGEN_NOTES_REQUEST, putLeadgenNotes);
  yield takeLatest(REMOVE_LEADGEN_NOTE_REQUEST, removeLeadgenNote);
}

/**
 * get data - [get|create|delete|update]_users_[list|item]_request
 * get success - get_users_[list|item]_success
 * get error - get_users_[list|item]_error
 */
