import React from "react";
import { default as ReactAlert } from "react-s-alert";

class AlertService {
    static get defaultOptions() {
        return {
            timeout: "none",
        };
    }

    static info(message, options = {}) {
        this.alert("info", message, options);
    }

    static warning(message, options = {}) {
        this.alert("warning", message, options);
    }

    static error(message, options = {}) {
        this.alert("error", message, options);
    }

    static showError(errorObject) {
        this.error(
            <div>
                <h4>{errorObject.error}</h4>
                <div>{errorObject.message}</div>
            </div>,
        );
    }

    static success(message, options = {}) {
        this.alert("success", message, options);
    }

    static alert(type, message, options = {}) {
        ReactAlert[type](message, {
            ...this.defaultOptions,
            customFields: options,
        });
    }
  }

export default AlertService;
