import AlertService from "../../../common/services/alert.service";
import ErrorHandler from "../../../common/services/error.handler";
import {
    GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST,
    GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_REQUEST,
    GET_LEADGEN_NEXT_OBJECTS_BY_OWNER_REQUEST,
    GET_LEADGEN_PREV_OBJECTS_BY_OWNER_REQUEST,
    GET_LEADGEN_OBJECT_DATA_REQUEST,
    GET_LEADGEN_EDIT_OBJECT_DATA_REQUEST,
    DELETE_LEADGEN_OBJECT_REQUEST,
    SET_EMPTY_LEADGEN_OBJECTS_REQUEST,
    PUT_LEADGEN_OBJECT_REQUEST,
    GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_SUCCESS,
    GET_LEADGEN_OBJECTS_BY_OWNER_SUCCESS,
    GET_LEADGEN_OBJECT_DATA_SUCCESS,
    GET_LEADGEN_EDIT_OBJECT_DATA_SUCCESS,
    DELETE_LEADGEN_OBJECT_SUCCESS,
    SET_LEADGEN_EMPTY_OBJECTS_SUCCESS,
    PUT_LEADGEN_OBJECT_SUCCESS,
    GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_ERROR,
    GET_LEADGEN_OBJECTS_BY_OWNER_ERROR,
    GET_LEADGEN_OBJECT_DATA_ERROR,
    GET_LEADGEN_EDIT_OBJECT_DATA_ERROR,
    DELETE_LEADGEN_OBJECT_ERROR,
    SET_LEADGEN_EMPTY_OBJECTS_ERROR,
    PUT_LEADGEN_OBJECT_ERROR,
} from "../../../common/constants";

export const leadgenObjectsByOwnerRequest = (id) => {
    return {
        type: GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST,
        id,
    };
};

export const getObjectsAmount = (id, flag) => {
    return {
        type: GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_REQUEST,
        id,
        flag
    };
};

export const leadgenPrevObjectsByOwnerRequest = (id, firstItem) => {
    return {
        type: GET_LEADGEN_PREV_OBJECTS_BY_OWNER_REQUEST,
        id,
        firstItem,
    };
};

export const leadgenNextObjectsByOwnerRequest = (id, lastItem) => {
    return {
        type: GET_LEADGEN_NEXT_OBJECTS_BY_OWNER_REQUEST,
        id,
        lastItem,
    };
};

export const leadgenObjectDataRequest = id => {
    return {
        type: GET_LEADGEN_OBJECT_DATA_REQUEST,
        id,
    };
};

export const leadgenEditObjectDataRequest = id => {
    return {
        type: GET_LEADGEN_EDIT_OBJECT_DATA_REQUEST,
        id,
    };
};

export const leadgenDeleteObjectRequest = (id, router, cb) => {
    return {
        type: DELETE_LEADGEN_OBJECT_REQUEST,
        id,
        router,
        cb,
    };
};
//todo
export const leadgenEmptyObjectsRequest = (id, data) => {
    return {
        type: SET_EMPTY_LEADGEN_OBJECTS_REQUEST,
        id,
        data,
    };
};

export const leadgenUpdateObjectRequest = (action_types, { object_id, ...data }) => {
    return {
        type: PUT_LEADGEN_OBJECT_REQUEST,
        action_types,
        id: object_id ? object_id : data.id,
        data,
    };
};

export const leadgenObjectsAmountSuccess = objectsInfo => {
    return {
        type: GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_SUCCESS,
        objectsInfo: objectsInfo.data,
    };
};

export const leadgenObjectsByOwnerSuccess = objects => {
    return {
        type: GET_LEADGEN_OBJECTS_BY_OWNER_SUCCESS,
        objects: objects.data,
    };
};

export const leadgenObjectDataSuccess = objects => {
    return {
        type: GET_LEADGEN_OBJECT_DATA_SUCCESS,
        objects: objects.data,
    };
};

export const leadgenEditObjectDataSuccess = object => {
    return {
        type: GET_LEADGEN_EDIT_OBJECT_DATA_SUCCESS,
        object: object.data,
    };
};

export const leadgenDeleteObjectSuccess = cb => {
    cb && cb();
    return {
        type: DELETE_LEADGEN_OBJECT_SUCCESS,
    };
};

export const leadgenEmptyObjectsSuccess = objects => {
    return {
        type: SET_LEADGEN_EMPTY_OBJECTS_SUCCESS,
        objects: objects.data,
    };
};
//todo
export const leadgenUpdateObjectSuccess = () => {
    AlertService.success("UPDATED SUCCESSFULLY");
    return {
        type: PUT_LEADGEN_OBJECT_SUCCESS,
    };
};

export const leadgenObjectsByOwnerError = (id, error) => {
    return {
        type: GET_LEADGEN_OBJECTS_BY_OWNER_ERROR,
        id,
        error,
    };
};

export const leadgenObjectsAmountError = (error) => {
    return {
        type: GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_ERROR,
        error,
    };
};

export const leadgenObjectDataError = (id, error) => {
    ErrorHandler.do(error);
    return {
        type: GET_LEADGEN_OBJECT_DATA_ERROR,
        id,
        error,
    };
};

export const leadgenEditObjectDataError = (id, error) => {
    ErrorHandler.do(error);
    return {
        type: GET_LEADGEN_EDIT_OBJECT_DATA_ERROR,
        id,
        error,
    };
};

export const leadgenDeleteObjectError = (id, error) => {
    ErrorHandler.do(error);
    return {
        type: DELETE_LEADGEN_OBJECT_ERROR,
        id,
        error,
    };
};

export const leadgenEmptyObjectsError = (id, error) => {
    ErrorHandler.do(error);
    return {
        type: SET_LEADGEN_EMPTY_OBJECTS_ERROR,
        id,
        error,
    };
};

export const leadgenUpdateObjectError = (id, error) => {
    ErrorHandler.do(error);
    return {
        type: PUT_LEADGEN_OBJECT_ERROR,
        id,
        error,
    };
};
