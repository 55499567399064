import ErrorHandler from "../../../common/services/error.handler";
import AlertService from "../../../common/services/alert.service";
import {
    CREATE_NEW_ENTITY_REQUEST,
    CREATE_NEW_OBJECT_REQUEST,
    CREATE_NEW_ENTITY_SUCCESS,
    CREATE_NEW_OBJECT_SUCCESS,
    CREATE_NEW_ENTITY_ERROR,
    CREATE_NEW_OBJECT_ERROR,
} from "../../../common/constants";

export const createNewEntityRequest = data => {
    return {
        type: CREATE_NEW_ENTITY_REQUEST,
        data,
    };
};

export const createNewEntitySuccess = newEntity => {
    AlertService.success("Created New Entity");

    return {
        type: CREATE_NEW_ENTITY_SUCCESS,
        newEntity: newEntity.data,
    };
};

export const createNewEntityError = (error, router) => {
    ErrorHandler.do(error, router);
    return {
        type: CREATE_NEW_ENTITY_ERROR,
        error,
    };
};

export const createNewObjectRequest = data => {
    return {
        type: CREATE_NEW_OBJECT_REQUEST,
        data,
    };
};

export const createNewObjectSuccess = newObject => {
    AlertService.success("Created New Object");
    return {
        type: CREATE_NEW_OBJECT_SUCCESS,
        newObject: newObject.data,
    };
};

export const createNewObjectError = error => {
    ErrorHandler.do(error);
    return {
        type: CREATE_NEW_OBJECT_ERROR,
        error,
    };
};
