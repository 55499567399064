import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

const styles = theme => ({
  label: {
    maxWidth: 220,
    padding: "2px 6px",
    backgroundColor: theme.palette.primary[400],
    border: "1px solid",
    borderColor: theme.palette.primary.divider,
    borderRadius: 4
  },
  labelCity: {
    fontStyle: "italic"
  }
});

const getFullAddress = ({ city = "", address = "" }) =>
  `${city} ${address}`.trim();

export default withScriptjs(
  withGoogleMap(
    withStyles(styles)(props => {
      const gm = window.google.maps;
      const {
        classes,
        addresses,
        selectedAddress,
        markers,
        setFoundAddresses
      } = props;
      const point = new gm.Point(-5, 10);
      const { geocode } = new gm.Geocoder();

      const defaultCenterAmsterdam = {
        lat: 52.3679843,
        lng: 4.903561399999944
      };

      if (!addresses.length)
        return (
          <GoogleMap defaultZoom={12} defaultCenter={defaultCenterAmsterdam} />
        );

      const positions = [];
      const center = markers[0] || {};
      const currentAddress = selectedAddress || addresses[0];
      const fullAddress = getFullAddress(currentAddress);

      const setMapRef = element => {
        if (!element) {
          return;
        }

        let sv = element.getStreetView();
        sv.setPosition(center.pos);
      };

      if (!markers.length || getFullAddress(center) !== fullAddress) {
        geocode(
          {
            address: fullAddress
          },
          (results, status) => {
            if (status === "OK") {
              const pos = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              };
              positions.push({
                city: currentAddress.city,
                address: currentAddress.address,
                pos
              });

              setFoundAddresses(positions);
            }
          }
        );
      }

      return (
        <React.Fragment>
          {!!markers.length &&
            ((
              <GoogleMap
                ref={setMapRef}
                defaultZoom={20}
                defaultCenter={defaultCenterAmsterdam}
                center={center.pos}
                options={{ streetViewControl: true }}
              >
                {markers.map(({ pos, address, city }, index) => (
                  <MarkerWithLabel
                    key={index}
                    position={pos}
                    labelAnchor={point}
                    onClick={() => {}}
                  >
                    <div className={classes.label}>
                      <Typography
                        variant="caption"
                        className={classes.labelCity}
                      >
                        {city}
                      </Typography>
                      <Typography variant="caption">{address}</Typography>
                    </div>
                  </MarkerWithLabel>
                ))}
              </GoogleMap>
            ) || <div />)}
        </React.Fragment>
      );
    })
  )
);
