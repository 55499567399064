import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import { receiveCitySuccess, receiveCityError } from "../actions/city";
import { SEARCH_RECEIVE_CITY_REQUEST } from "../../../common/constants";

function* searchReceiveCity(action) {
    try {
        const city = yield call(ApiService.post2, "/search/city", action.query);
        yield put(receiveCitySuccess(city));
    } catch (error) {
        yield put(receiveCityError(error));
    }
}

export default function* actionSearchByCity() {
    yield takeLatest(SEARCH_RECEIVE_CITY_REQUEST, searchReceiveCity);
}
