import { combineReducers } from "redux";
import {
  SEARCH_RECEIVE_SETTINGS_SUCCESS,
  SEARCH_EDIT_SETTINGS_SUCCESS,
  SEARCH_RECEIVE_SETTINGS_ERROR,
  SEARCH_EDIT_SETTINGS_ERROR
} from "../../../common/constants";

const receiveSettings = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_SETTINGS_SUCCESS:
    case SEARCH_EDIT_SETTINGS_SUCCESS:
      return action.settings;

    default:
      return state;
  }
};

const errorSettings = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_SETTINGS_ERROR:
    case SEARCH_EDIT_SETTINGS_ERROR:
      return action.error;

    default:
      return state;
  }
};

const settings = combineReducers({
  receiveSettings,
  error: errorSettings
});

export default settings;
