import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { receiveCityRequest } from "../actions/city";
import _ from "lodash";
import { selectSearch } from "../selectors";

let city;

class SearchCity extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    focus: true,
  };

  state = {
    query: "",
    limit: 10,
  };

  runQuery = (query) => {
    const { dispatch, router } = this.props;
    dispatch(
      receiveCityRequest(
        {
          query,
        },
        router
      )
    );
  };

  debounce = _.debounce((args) => this.runQuery(args), 300);

  handleSuggestionsFetchRequested = ({ value }) => {
    this.debounce(value);
    this.runQuery(value);
  };

  handleChange = (e, { newValue, method }) => {
    if (method === "type")
      this.setState({
        query: newValue,
      });

    if (method === "click" || method === "enter")
      this.handleKeyPress({ key: method });
  };

  setCity = (c) => {
    city = c;
  };

  handleKeyPress = ({ key }) => {
    const { selectCity } = this.props;

    if (key === "enter" || key === "click") {
      selectCity(city);
      this.setState({
        query: "",
      });
    }
  };

  render() {
    const { classes, search, focus, error } = this.props;
    const { receiveCity } = search;
    const { query, limit } = this.state;

    return (
      <div className={classes.root}>
        <Autosuggest
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => {}}
          suggestions={receiveCity ? receiveCity.slice(0, limit) : []}
          getSuggestionValue={({ city }) => {
            this.setCity(city);
            return city;
          }}
          renderInputComponent={({ classes, ref, ...other }) => {
            return (
              <TextField
                variant="outlined"
                label="Select City"
                fullWidth
                error={error}
                InputProps={{
                  inputRef: (node) => {
                    node && focus && node.focus();
                    ref(node);
                  },
                  classes: {
                    input: classes.input,
                  },
                }}
                {...other}
              />
            );
          }}
          renderSuggestion={({ city }, { query, isHighlighted }) => {
            const matches = match(city, query);
            const parts = parse(city, matches);

            return (
              <MenuItem
                className={classes.item}
                selected={isHighlighted}
                component="div"
              >
                <Typography variant="body2">
                  {parts.map((part, index) => {
                    return part.highlight ? (
                      <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                      </span>
                    ) : (
                      <strong key={String(index)} style={{ fontWeight: 400 }}>
                        {part.text}
                      </strong>
                    );
                  })}
                </Typography>
              </MenuItem>
            );
          }}
          inputProps={{
            classes,
            placeholder: "Search",
            value: query,
            onChange: this.handleChange,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={({ containerProps, children }) => (
            <Paper {...containerProps} square>
              {children && <div className={classes.children}>{children}</div>}
            </Paper>
          )}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    height: 60,
    marginRight: 10,
    marginBottom: 2,
    flexGrow: 1,
  },
  item: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  container: {
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 2,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  children: {
    maxHeight: 400,
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
});

const mapStateToProps = (state) => ({
  search: selectSearch(state),
});

export default connect(mapStateToProps)(withStyles(styles)(SearchCity));
