import { combineReducers } from "redux";
import {
    SEARCH_RECEIVE_CITY_SUCCESS,
    SEARCH_RECEIVE_CITY_ERROR,
    SET_QUERY_CITY,
} from "../../../common/constants";

const receiveCity = (state = [], action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_CITY_SUCCESS:
            return action.city;

        default:
            return state;
    }
};

const errorFetchCity = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_CITY_ERROR:
            return action.error;

        default:
            return state;
    }
};

const setQueryCity = (state = "", action) => {
    switch (action.type) {
        case SET_QUERY_CITY:
            return action.queryCity;

        default:
            return state;
    }
};

const city = combineReducers({
    receiveCity,
    error: errorFetchCity,
    setQueryCity,
});

export default city;
