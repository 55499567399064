import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GoogleMapsContainer from './GoogleMapsContainer';

const { REACT_APP_API_KEY } = process.env;

const Maps = ({ classes, ...props }) => {
  return (
    <GoogleMapsContainer
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div />}
      containerElement={<div className={classes.container} />}
      mapElement={<div className={classes.map} />}
      {...props}
    />
  );
};

Maps.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  map: { height: '100%', borderRadius: '0 0 4px 4px' },
  container: {
    height: 'calc(100% - 48px)',
  },
});

export default withStyles(styles)(Maps);
