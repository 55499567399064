import { createSelector } from "reselect";

const adminState = state => {
  if (state && state.admin) {
    return state.admin;
  }
  return null;
};

const adminSelector = createSelector(
  [adminState],
  state => state //return admin
);

const adminReceiveUsersSelector = createSelector(
  [adminSelector],
  state => state.receive
);

const adminReceiveDriveUrlSelector = createSelector(
  [adminSelector],
  state => state.receiveDriveAuthUrl
);

export const selectAdmin = createSelector(
  [adminSelector, adminReceiveUsersSelector, adminReceiveDriveUrlSelector],
  (admin, receive, receiveDriveAuthUrl) => {
    const users = receive ? receive : [];
    const authUrl = receiveDriveAuthUrl ? receiveDriveAuthUrl : "";
    return { admin, users, authUrl };
  }
);
