import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SelectEditor from "./select";
import FilterMenu from "./filter-menu";
import LPSMenu from "./lps-menu";
import { parseQueryStreet, parseQueryName } from "../../common/helpers/parser";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Sync from "@material-ui/icons/SyncRounded";
import Save from "@material-ui/icons/SaveRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { receiveByNameRequest } from "./actions/name";
import { receiveByStreetRequest } from "./actions/street";
import { receiveByPhoneRequest } from "./actions/phone";
import {
  editSettingsRequest,
  receiveSettingsRequest,
} from "./actions/settings";
import { receiveCityRequest } from "./actions/city";
import {
  countLPSDataRequest,
  CheckLpsUsageRequest,
  receiveLPSConfigsRequest,
} from "./actions/lps";
import _ from "lodash";
import { selectSearch } from "./selectors";
import { receiveUsersDataRequest } from "../leadgen-head-menu/actions";

class Search extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    menuType: "filter",
    configName: "",
    cityName: this.ALL,
    leadID: null,
    query: "",
    objectsLimit: 5,
    entitiesLimit: 5,
    openDrawer: false,
    openSearchBar: false,
    queryCity: "Amsterdam",
    showSearchBlocks: {
      objects: true,
      entities: true,
      city: false,
      cities: false,
    },
    ////////////////////////////////////////////////////////////////////////////
    // part of this.state
    // showSearchSubEntities: {
    //   firstGroup: 'person' || 'company',
    //   secondGroup: 'owners' || 'nonOwners' || 'contacts' || 'managers',
    // },
    ////////////////////////////////////////////////////////////////////////////
    showSearchSubEntities: {
      firstGroup: "person",
      secondGroup: "all",
    },
    showSearchSubObjects: {
      byKadasterID: false,
      byG0Property: false,
      byA1property: false,
    },
    openPanelsByCities: [],
    activeCity: "all",
  };

  get ALL() {
    return "All";
  }
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(receiveSettingsRequest());
    dispatch(receiveLPSConfigsRequest());
    dispatch(receiveUsersDataRequest());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search.getSettings !== this.props.search.getSettings) {
      this.setState({ ...this.props.search.getSettings });
    }
  }

  runQueries = ({ type, state, query }) => {
    const { dispatch } = this.props;
    const isCompany = state.showSearchSubEntities.firstGroup === "company";

    if (query.length < 2) return null;

    switch (type) {
      case "objectsLimit":
        dispatch(
          receiveByStreetRequest({
            city: state.queryCity,
            filters: state.showSearchSubObjects,
            limit: state.objectsLimit,
            ...parseQueryStreet(query),
          })
        );
        break;
      case "entitiesLimit":
        dispatch(
          receiveByNameRequest({
            filters: state.showSearchSubEntities,
            limit: state.entitiesLimit,
            ...parseQueryName(query, isCompany),
          })
        );
        break;
      default:
        dispatch(
          receiveByStreetRequest({
            city: state.queryCity,
            filters: state.showSearchSubObjects,
            limit: state.objectsLimit,
            ...parseQueryStreet(query),
          })
        );
        dispatch(
          receiveByNameRequest({
            filters: state.showSearchSubEntities,
            limit: state.entitiesLimit,
            ...parseQueryName(query, isCompany),
          })
        );
        dispatch(
          receiveByPhoneRequest({
            limit: state.entitiesLimit,
            query,
          })
        );
        break;
    }
  };

  debounce = _.debounce((args) => this.runQueries(args), 1000);

  handleQuery = (query, { type, num }) => {
    const state = Object.assign({}, this.state);
    const newState = {
      query,
      openSearchBar: true,
    };

    if (type) {
      state[type] = state[type] + num;
      newState[type] = state[type];
    }

    this.debounce({ type, state, query });

    this.setState(newState);
  };

  handleToggleFocus = (value) => {
    this.setState({ openSearchBar: value });
  };

  handleChange =
    (block, key, group) =>
    ({ target: { checked } }) => {
      const { showSearchBlocks, showSearchSubEntities, showSearchSubObjects } =
        this.state;

      if (block === "blocks") {
        return this.setState({
          showSearchBlocks:
            key === "city"
              ? { ...showSearchBlocks, city: checked, cities: false }
              : key === "cities"
              ? { ...showSearchBlocks, city: false, cities: checked }
              : { ...showSearchBlocks, [key]: checked },
        });
      }

      if (block === "subEntities" && key === "byKadasterID") {
        return this.setState({
          showSearchSubEntities: {
            ...showSearchSubEntities,
            [group]: key,
            secondGroup: "all",
          },
        });
      }

      if (block === "subEntities") {
        return this.setState({
          showSearchSubEntities: { ...showSearchSubEntities, [group]: key },
        });
      }

      if (block === "subObjects") {
        return this.setState({
          showSearchSubObjects: { ...showSearchSubObjects, [key]: checked },
        });
      }
    };

  getQuery = (query, currentItem) => {
    const { router } = this.props;
    const id = currentItem.owner.id;

    if (id) {
      router.push(`/leadgen/entity/${id}`);
    } else router.push(`/leadgen/object/${currentItem.object_id}`);

    this.setState({ query, openSearchBar: false });
  };

  setCity = (city) => {
    const { showSearchBlocks, openPanelsByCities } = this.state;

    if (showSearchBlocks.city) {
      this.props.dispatch(receiveCityRequest(city));
      this.setState({ queryCity: city });
    } else if (showSearchBlocks.cities) {
      this.setState({
        openPanelsByCities: [...new Set([...openPanelsByCities, city])],
      });
    }
  };

  removeCity = (index) => {
    const { openPanelsByCities } = this.state;

    openPanelsByCities.splice(index, 1);

    this.setState({
      openPanelsByCities: [...openPanelsByCities],
    });
  };

  handleSaveSettings = () => {
    const { dispatch, router } = this.props;
    const {
      queryCity,
      showSearchBlocks,
      showSearchSubEntities,
      showSearchSubObjects,
      openPanelsByCities,
    } = this.state;

    dispatch(
      editSettingsRequest(
        {
          queryCity,
          showSearchBlocks,
          showSearchSubEntities,
          showSearchSubObjects,
          openPanelsByCities,
        },
        router
      )
    );
  };
  handleRunLPSCounter = () => {
    const { dispatch, search, router } = this.props;
    const { lpsConfig } = search;

    const { configName, cityName } = this.state;
    const config = lpsConfig.find((i) => i.name === configName);
    const city = cityName === this.ALL ? "" : cityName;

    config && dispatch(countLPSDataRequest({ config, city }, router));
  };

  handleSelectType = (e, menuType) => {
    const { dispatch } = this.props;
    menuType &&
      this.setState({
        menuType,
      });
    if (menuType === "lps") {
      dispatch(CheckLpsUsageRequest(true));
    }
  };

  handleSelectConfig = ({ target }) => {
    this.setState({ configName: target.value, cityName: this.ALL });
  };

  handleSelectCity = ({ target }) => {
    this.setState({ cityName: target.value });
  };

  handleGoTo = (lead_id) => {
    const { router } = this.props;
    if (!lead_id) return null;

    this.setState({ leadID: lead_id });
    router.push(`/leadgen/entity/${lead_id}`);
  };

  render() {
    const { classes, router, search, isLeadgen, toggleMenu, openMenu } =
      this.props;
    const {
      query,
      queryCity,
      showSearchBlocks,
      showSearchSubEntities,
      showSearchSubObjects,
      openPanelsByCities,
      objectsLimit,
      entitiesLimit,
      openSearchBar,
      menuType,
      configName,
      cityName,
    } = this.state;

    const {
      objectsByStreet,
      isFetchingObjects,
      fullEntitiesList,
      isFetchingEntities,
      processing,
    } = search;

    return (
      <div className={openSearchBar ? classes.rootOpen : classes.rootClose}>
        <div
          className={classNames(classes.content, {
            [classes.contentShift]: openMenu,
          })}
        >
          <SelectEditor
            showBlocks={showSearchBlocks}
            showSubEntities={showSearchSubEntities}
            showSubObjects={showSearchSubObjects}
            isLeadgen={isLeadgen}
            isFetchingObjects={isFetchingObjects}
            isFetchingEntities={isFetchingEntities}
            query={query}
            openSearchBar={openSearchBar}
            toggleFocus={this.handleToggleFocus}
            objectsLimit={objectsLimit}
            entitiesLimit={entitiesLimit}
            getQuery={this.getQuery}
            openDialog={toggleMenu}
            handleQuery={this.handleQuery}
            objects={objectsByStreet || []}
            entities={fullEntitiesList || []}
          />
        </div>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={openMenu}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <ToggleButtonGroup
            value={menuType}
            exclusive
            onChange={this.handleSelectType}
            className={classes.toggleButtonGroup}
          >
            <ToggleButton className={classes.toggleButton} value="filter">
              filter
            </ToggleButton>
            <ToggleButton className={classes.toggleButton} value="lps">
              lps
            </ToggleButton>
          </ToggleButtonGroup>
          {menuType === "filter" && (
            <div>
              <Button
                className={classes.button}
                onClick={this.handleSaveSettings}
              >
                <span className={classes.buttonText}>save</span>
                <Save />
              </Button>
              <FilterMenu
                city={queryCity}
                selectCity={this.setCity}
                removeCity={this.removeCity}
                showBlocks={showSearchBlocks}
                showSubEntities={showSearchSubEntities}
                showSubObjects={showSearchSubObjects}
                defaultCities={openPanelsByCities}
                onChange={this.handleChange}
              />
            </div>
          )}
          {menuType === "lps" && (
            <div>
              <Button
                className={classes.button}
                onClick={this.handleRunLPSCounter}
                disabled={processing}
              >
                <span className={classes.buttonText}>run</span>
                <Sync className={processing ? classes.progress : null} />
                <CircularProgress size={0} className={classes.spinning} />
              </Button>
              <LPSMenu
                ALL={this.ALL}
                configName={configName}
                cityName={cityName}
                onSelectConfig={this.handleSelectConfig}
                onSelectCity={this.handleSelectCity}
                goTo={this.handleGoTo}
                router={router}
                processing={processing}
              />
            </div>
          )}
        </Drawer>
      </div>
    );
  }
}

const drawerRightWidth = 220;

export const styles = (theme) => ({
  progress: {
    animation: "mui-progress-circular-rotate 2s reverse linear infinite",
  },
  spinning: {
    color: "rgba(0, 0, 0, 0)",
    position: "absolute",
  },
  rootOpen: {
    flex: 2.5,
    paddingRight: 8,
    transition: theme.transitions.create("flex", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  rootClose: {
    flex: 1,
    paddingRight: 8,
    transition: theme.transitions.create("flex", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerRightWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerRightWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerRightWidth,
  },
  button: {
    borderRadius: 0,
    color: theme.palette.secondary["A400"],
    width: "100%",
    padding: 0,
    height: "30px",
  },
  buttonText: {
    margin: "0 4px",
  },
  toggleButtonGroup: {
    borderRadius: 0,
    marginBottom: 2,
  },
  toggleButton: {
    borderRadius: 0,
    width: "50%",
  },
});

const mapStateToProps = (state) => ({
  search: selectSearch(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Search));
