import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { INPUT, SELECT } from "../../../constants/field-types";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Search from "@material-ui/icons/Search";
import Cancel from "@material-ui/icons/CancelOutlined";
import FieldEditor from "../../editor/field";
import Container from "./Container";
import PostCodeApiService from "../../../../common/services/postcode-api.service";
import AlertService from "../../../services/alert.service";

type PostcodeFormProps = {
  classes: {
    button: string;
    buttonGroup: string;
    divider: string;
    iconSearch: string;
    container: string;
    rightColumn: string;
    inputWrapper: string;
  };
};

type PostcodeFormState = {
  postcode: string;
  number: string;
  addresses: any;
  currentValue: null | "";
};

class PostcodeForm extends PureComponent<PostcodeFormProps, PostcodeFormState> {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    postcode: "",
    number: "",
    addresses: [],
    currentValue: null,
  };

  getValue = ({ city = {}, street, number, addition, letter }) =>
    //@ts-ignore
    `${city.label || ""} ${street || ""} ${number || ""} ${addition || ""}${
      letter || ""
    }`.trim();

  handleChange = (type, { target }) => {
    //@ts-ignore
    const { onSelect } = this.props;
    const { addresses } = this.state;
    if (type === "addresses") {
      const {
        purpose,
        postcode,
        city,
        letter,
        addition,
        number,
        year,
        street,
      } = addresses[target.value];
      onSelect({
        target: {
          value: {
            purpose,
            postcode,
            //@ts-ignore
            city: city.label,
            number,
            street_name: street,
            built_year: year,
            number_add: `${addition || ""}${letter || ""}`,
          },
        },
      });
      return this.setState({
        currentValue: target.value,
      });
    }
    //@ts-ignore
    this.setState({
      [type]: target.value.replace(/[^A-Za-z0-9]/g, "").toUpperCase(),
    });
  };

  handleCancel = () => {
    this.setState({
      postcode: "",
      number: "",
      addresses: [],
      currentValue: null,
    });
  };

  handleGetPostcodeData = async () => {
    const { postcode, number } = this.state;
    await PostCodeApiService.get(
      { postcode, number },
      ({ data }) => this.setState({ addresses: data._embedded.addresses }),
      (e) =>
        AlertService.error(
          "No such postcode or something went wrong!",
          e.message
        )
    );
  };

  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { postcode, number, addresses, currentValue } = this.state;

    return (
      <React.Fragment>
        <div className={classes.container}>
          <Container
            leftColumn={[
              <FieldEditor
                type={INPUT}
                name="postcode"
                label="Postcode"
                value={postcode}
                //@ts-ignore
                onChange={(e) => this.handleChange("postcode", e)}
              />,
            ]}
            rightColumn={[
              <div className={classes.rightColumn}>
                <div className={classes.inputWrapper}>
                  <FieldEditor
                    type={INPUT}
                    name="number"
                    label="Number"
                    value={number}
                    //@ts-ignore
                    onChange={(e) => this.handleChange("number", e)}
                  />
                </div>
                <div className={classes.buttonGroup}>
                  <Button
                    onClick={this.handleGetPostcodeData}
                    className={classes.button}
                  >
                    <Search className={classes.iconSearch} />
                  </Button>
                  <Button
                    onClick={this.handleCancel}
                    className={classes.button}
                  >
                    <Cancel className={classes.iconSearch} />
                  </Button>
                </div>
              </div>,
            ]}
          />
        </div>
        {addresses.length ? (
          <FieldEditor
            type={SELECT}
            name="addresses"
            label="Addresses"
            //@ts-ignore
            onChange={(e) => this.handleChange("addresses", e)}
            value={
              {
                current: currentValue !== null ? currentValue : "",
                options: addresses.map((a, index) => ({
                  value: index,
                  label: this.getValue(a),
                })),
              } || {}
            }
          />
        ) : null}
        <Divider className={classes.divider} />
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  divider: {
    width: "100%",
    backgroundColor: theme.palette.primary.divider,
    marginTop: 10,
  },
  container: {
    display: "table",
    width: "100%",
  },
  rightColumn: {
    display: "flex",
    alignItems: "flex-end",
  },
  iconSearch: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  inputWrapper: {
    marginRight: 24,
    width: "100%",
  },
  button: {
    minWidth: 40,
    padding: 0,
    borderRadius: 0,
  },
  buttonGroup: {
    display: "flex",
    height: 54,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
});

// @ts-ignore
export default withStyles(styles)(PostcodeForm);
