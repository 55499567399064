import React, { Component } from "react";
import { connect } from "react-redux";
import UserTable from "./components/user-table";
import DialogWrapper from "../../common/components/dialog";
import {
  UserDataDialog,
  DeleteUserDialog,
  UserLogsTable,
} from "./components/user-form";
import { selectAdmin } from "./selectors";
import { selectSearch } from "../search/selectors";
import { selectUser } from "../login/selectors";
import {
  adminUsersRequest,
  adminCreateUserRequest,
  adminUpdateUserRequest,
  adminDeleteUserRequest,
  adminConnectToDriveRequest,
} from "./actions/user";

class Admin extends Component {
  state = {
    openAddUserDialog: false,
    openEditMenuDialog: false,
    openDetailsActivity: false,
    openConfirmDeleteDialog: false,
    mutableUser: {},
    removableUser: {},
    userDetails: {},
    user: {},
    ipsForDelete: [],
    ipsForUpdate: [],
  };

  constructor(props) {
    super(props);
    const { userData } = props.user;
    if (userData && userData.role !== "User") {
      props.dispatch(adminUsersRequest());
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user, dispatch } = this.props;
    const { userData } = user;
    if (userData !== prevProps.user.userData && userData.role !== "User") {
      dispatch(adminUsersRequest());
    }
  }

  handleChange = (item) => (key, e) => {
    const { lps } = this.props;
    const { currentOptions } = lps;
    let { value, checked } = e.target;
    const user = this.state[item];
    if (key === "user_ip_whitelist" && e.ipsForUpdate && e.ipsForUpdate.length) {
      return this.setState({
        [item]: {
          ...user,
          ipsForUpdate: e.ipsForUpdate,
        },
      });
    }

    if (key === "user_ip_whitelist" && e.ipsForDelete && e.ipsForDelete.length) {
      return this.setState({
        [item]: {
          ...user,
          ipsForDelete: e.ipsForDelete,
        },
      });
    }

    if (key === "default_lps_profile") {
      const lpsConfigName = currentOptions.find(
        (item) => item.value === value
      ).label;
      return this.setState({
        [item]: {
          ...user,
          [key]: { id: value, name: lpsConfigName },
          default_lps_profile_id: value,
        },
      });
    }

    value = key === "ip_restriction" ? checked : value;

    this.setState({ [item]: { ...user, [key]: value } });
  };

  handleOpen = (dialogType, index) => {
    const { admin } = this.props;
    const { users } = admin;
    const nextState = { [dialogType]: true };

    if (dialogType === "openEditMenuDialog") {
      nextState.mutableUser = users[index];
    }
    if (dialogType === "openConfirmDeleteDialog") {
      nextState.removableUser = users[index];
    }
    if (dialogType === "openDetailsActivity") {
      nextState.userDetails = users[index];
    }

    this.setState(nextState);
  };

  handleClose = (dialogType) => {
    const nextState = { [dialogType]: false };

    if (dialogType === "openDetailsActivity") {
      nextState.userDetails = {};
    }

    this.setState(nextState);
  };

  handleAddUser = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    dispatch(adminCreateUserRequest(user));
    this.setState({ user: {}, openAddUserDialog: false });
  };
  handleEditUser = () => {
    const { mutableUser } = this.state;
    const { dispatch } = this.props;
    const { logs, id, ...data } = mutableUser;
    dispatch(adminUpdateUserRequest(id, data));
    this.setState({ mutableUser: {}, openEditMenuDialog: false });
  };
  handleDeleteUser = () => {
    const { removableUser } = this.state;
    const { dispatch } = this.props;
    const { id } = removableUser;
    dispatch(adminDeleteUserRequest(id));
    this.setState({ removableUser: {}, openConfirmDeleteDialog: false });
  };

  handleConnectToDrive = () => {
    const { dispatch } = this.props;
    dispatch(adminConnectToDriveRequest());
  };

  render() {
    const { admin, lps } = this.props;
    const { users, authUrl } = admin;

    if (authUrl) {
      window.open(authUrl, "_blank");
    }
    const { currentOptions } = lps;

    const {
      openAddUserDialog,
      openEditMenuDialog,
      openDetailsActivity,
      openConfirmDeleteDialog,
      user,
      mutableUser,
      removableUser,
      userDetails,
    } = this.state;

    return (
      <div>
        {users && (
          <UserTable
            onConnectToDrive={this.handleConnectToDrive}
            onAddUser={() => this.handleOpen("openAddUserDialog")}
            onEditUser={(index) => this.handleOpen("openEditMenuDialog", index)}
            onDeleteUser={(index) =>
              this.handleOpen("openConfirmDeleteDialog", index)
            }
            onShowUserLogs={(index) =>
              this.handleOpen("openDetailsActivity", index)
            }
            users={users && users.data}
          />
        )}
        <DialogWrapper
          open={openAddUserDialog}
          onClose={() => this.handleClose("openAddUserDialog")}
          handleClick={this.handleAddUser}
          title="Add user"
          buttonText="Create new user"
        >
          <UserDataDialog
            user={user}
            onChange={this.handleChange("user")}
            currentOptions={currentOptions}
          />
        </DialogWrapper>
        <DialogWrapper
          open={openEditMenuDialog}
          onClose={() => this.handleClose("openEditMenuDialog")}
          handleClick={this.handleEditUser}
          title="Edit menu"
          buttonText="Save settings"
        >
          <UserDataDialog
            user={mutableUser}
            onChange={this.handleChange("mutableUser")}
            currentOptions={currentOptions}
          />
        </DialogWrapper>
        <DialogWrapper
          open={openDetailsActivity}
          onClose={() => this.handleClose("openDetailsActivity")}
          title={`Activity details ${userDetails.email}`}
        >
          <UserLogsTable user={userDetails} />
        </DialogWrapper>
        <DialogWrapper
          open={openConfirmDeleteDialog}
          onClose={() => this.handleClose("openConfirmDeleteDialog")}
          handleClick={this.handleDeleteUser}
          title="Confirm delete"
          buttonText="Delete"
        >
          <DeleteUserDialog user={removableUser} />
        </DialogWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  admin: selectAdmin(state),
  lps: selectSearch(state),
  user: selectUser(state),
});

export default connect(mapStateToProps)(Admin);
