import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { selectQueryObjects } from "../../selectors";
import PropTypes from "prop-types";
import ArrowBack from "@material-ui/icons/ArrowBackRounded";
import ArrowForward from "@material-ui/icons/ArrowForwardRounded";
import { getNextResultsRequest, getPrevResultsRequest } from "../../actions";

class ResultsContainer extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };
    state = {
        countedResult: [],
        firstId: "",
        lastId: "",
        receiveResults: [],
        configExpanded: true,
        resentQueriesExpanded: true,
        queryMenuExpanded: true,
    };

    get columns() {
        return (
            [
                {
                    Header: "Object",
                    accessor: "address",
                    Cell: data => data || "n/a",
                },
                {
                    Header: "Index",
                    accessor: "plot_index",
                    Cell: data => data || "n/a",
                },
                {
                    Header: "Size",
                    accessor: "full_size",
                    Cell: data => data || "n/a",
                },
                {
                    Header: "Units",
                    accessor: "units_amount",
                    Cell: data => data || "n/a",
                },
                {
                    Header: "Value",
                    accessor: "value",
                    Cell: data => data || "n/a",
                },
            ]
        );
    }

    componentDidMount() {
        const { countedResult, firstId, lastId } = this.props.queryObjects;
        this.setState({
            countedResult: [ ...countedResult ],
            firstId: firstId,
            lastId: lastId,
        });
    }

    componentDidUpdate(prevProps) {
        const { countedResult, firstId, lastId } = this.props.queryObjects;
        if (countedResult !== prevProps.queryObjects.countedResult) {
            this.setState({
                countedResult: [ ...countedResult ],
                firstId: firstId,
                lastId: lastId,
            });
        }
    }

    showPrevObjects = () => {
        const { dispatch } = this.props;
        const { countedResult } = this.state;
        const firstItem = countedResult[0];
        dispatch(getPrevResultsRequest(firstItem));
    };

    showNextObjects = () => {
        const { dispatch } = this.props;
        const { countedResult } = this.state;
        const lastItem = countedResult[countedResult.length - 1];
        dispatch(getNextResultsRequest(lastItem));
    };

    render() {
        const { classes, configExpanded, resentQueriesExpanded, queryMenuExpanded } = this.props;
        const { countedResult, firstId, lastId } = this.state;
        const columns = this.columns;

        return (
            <Paper className={classes.resultsWrapper}>
                <div className={classes.header}>
                    <Grid item xs={3}/>
                    <Grid item xs={6}>
                        <div className={classes.headerText}>
                            <Typography variant="h6">Results</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={2}/>
                    <Grid item xs={1}>
                        <div>
                            <IconButton
                                title="Import to Excel"
                            >
                                <SaveAltIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                </div>
                <div
                    className={(!configExpanded && !resentQueriesExpanded && !queryMenuExpanded) ? classes.wrapperLarge :
                        (!configExpanded && !resentQueriesExpanded && queryMenuExpanded) ? classes.wrapperMiddle :
                            classes.wrapperMini}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map(({ Header, headProps }, index) => (
                                    <TableCell key={index} align="left" {...headProps}>
                                        <Typography variant="body2" gutterBottom>
                                            {Header}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {countedResult.map((row, rowIndex) => {
                                return (
                                    <React.Fragment key={rowIndex}>
                                        <TableRow>
                                            {columns.map(({ accessor, Cell }, index) => (
                                                (accessor === "address") ?
                                                    <TableCell key={index}>
                                                        {(accessor && (
                                                            <Typography variant="body2" gutterBottom>
                                                                <a
                                                                    href={`leadgen/object/${row.object_id}`}
                                                                    component="button"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className={classes.link}
                                                                >
                                                                    {Cell(row[accessor])}
                                                                </a>
                                                            </Typography>
                                                        )) ||
                                                        Cell(rowIndex)}
                                                    </TableCell>
                                                    :
                                                    <TableCell key={index}>
                                                        {(accessor && (
                                                            <Typography variant="body2" gutterBottom>
                                                                {Cell(row[accessor])}
                                                            </Typography>
                                                        )) ||
                                                        Cell(rowIndex)}
                                                    </TableCell>
                                            ))}
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div className={classes.buttonGroup}>
                    <IconButton
                        disabled={countedResult.some(obj => obj.id === firstId)}
                        title="Prev"
                        onClick={this.showPrevObjects}
                    >
                        <ArrowBack/>
                    </IconButton>
                    <IconButton
                        disabled={countedResult.some(obj => obj.id === lastId)}
                        title="Next"
                        onClick={this.showNextObjects}
                    >
                        <ArrowForward/>
                    </IconButton>
                </div>
            </Paper>
        );
    }
}

export const styles = theme => ({
    header: {
        height: 56,
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary[500],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    headerText: {
        textAlign: "center",
    },
    wrapperLarge: {
        height: "641px",
        padding: "0 15px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    wrapperMiddle: {
        height: "449px",
        padding: "0 15px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    wrapperMini: {
        height: "250px",
        // padding: "0 15px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    resultsWrapper: {
        maxHeight: 745,
        // width: "400px",
        // overflow: "hidden",
        // overflowY: "auto",
        // "&::-webkit-scrollbar": {
        //     width: "6px",
        //     backgroundColor: "#f0f0f0",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     backgroundColor: "#ccc",
        //     borderRadius: "4px",
        // },
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary[700],
        "&:hover": {
            color: theme.palette.primary[500],
        },
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0 10px",
    },
});

const mapStateToProps = state => ({
    queryObjects: selectQueryObjects(state),
});

export default connect(mapStateToProps)(withStyles(styles)(ResultsContainer));

