import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getDateFromNow } from "../../../../common/helpers/getter";

const Schedule = ({ classes, schedule }) => {
    const { label, date, colorType } = schedule ? getDateFromNow(schedule) : {};
    return (
        (schedule && (
            <div
                className={classNames(
                    classes.root,
                    colorType === "ago" ? classes.colorAgo : classes.colorIn,
                )}
            >
                <Typography color="inherit" variant="h6">
                    {label || ""}
                </Typography>
                <Typography color="inherit" variant="body2">
                    {date || ""}
                </Typography>
            </div>
        )) || (
            <Typography className={classes.root} variant="body2">
                No Due Date
            </Typography>
        )
    );
};

Schedule.propTypes = {};

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    colorAgo: {
        color: theme.palette.secondary.callEnd,
    },
    colorIn: {
        color: "rgba(0, 0, 0, 0.87)",
    },
});

export default withStyles(styles)(React.memo(Schedule));
