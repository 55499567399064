import { combineReducers } from "redux";
import {
  GET_LEADGEN_NOTES_SUCCESS,
  PUT_LEADGEN_NOTES_SUCCESS,
  REMOVE_LEADGEN_NOTE_REQUEST,
  GET_LEADGEN_NOTES_ERROR,
  PUT_LEADGEN_NOTES_ERROR,
  REMOVE_LEADGEN_NOTE_ERROR
} from "../../../common/constants";

const receiveNotes = (state = {}, action) => {
  switch (action.type) {
    case GET_LEADGEN_NOTES_SUCCESS:
    case PUT_LEADGEN_NOTES_SUCCESS:
    case REMOVE_LEADGEN_NOTE_REQUEST:
      return action.notes;

    default:
      return state;
  }
};

const errorFetchNotes = (state = {}, action) => {
  switch (action.type) {
    case GET_LEADGEN_NOTES_ERROR:
    case PUT_LEADGEN_NOTES_ERROR:
    case REMOVE_LEADGEN_NOTE_ERROR:
      return action.error;

    default:
      return state;
  }
};

const notes = combineReducers({
  receiveNotes,
  error: errorFetchNotes
});

export default notes;
