import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NoteEditor from "./NoteEditor";
import { leadgenPutNotesRequest } from "../../actions/notes";
import { formatDateAndTime } from "../../../../common/formats/date";
import { selectLeadgen } from "../../selectors";
import { selectEntity } from "../../../leadgen-head-menu/selectors";

class Notes extends PureComponent {
    static propTypes = { classes: PropTypes.object.isRequired };
    state = {
        editMode: false,
        title: "",
        body: "",
    };

    componentDidUpdate(prevProps) {
        const { notes } = this.props.leadgen;
        const { body, title } = notes || {};
        if (notes !== prevProps.leadgen.notes) {
            this.setState({ body, title });
        }
    }

    handleChange = (type, data) => this.setState({ [type]: data });

    toggleMode = () => {
        const { editMode } = this.state;
        this.setState({ editMode: !editMode });
    };

    handleSave = () => {
        const { dispatch, leadgen, currentEntity } = this.props;
        const { notes } = leadgen;
        if (!notes) return null;
        const { body, title } = this.state;

        dispatch(
            leadgenPutNotesRequest(notes.id || null, {
                body,
                title: title,
                entity_id: (notes && notes.entity_id) ? notes.entity_id : currentEntity.entity.id,
            }),
        );
    };

    render() {
        const { classes, currentEntity, leadgen } = this.props;
        const { notes, updatedAt, name, surname, noteAuthor } = leadgen;
        const { blacklisted } = currentEntity;
        const { editMode, body, title } = this.state;

        return (
            <div className={blacklisted ? classes.rootBlacklisted : classes.root}>
                <Grid container>
                    <Grid className={classes.head} item xs={12}>
                        <Typography className={classes.title} variant="h6">
                            Notes
                        </Typography>
                        <div className={classes.wrapper}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={editMode}
                                        onChange={this.toggleMode}
                                        color="secondary"
                                    />
                                }
                                labelPlacement="start"
                                label="Edit mode"
                            />
                            {notes && updatedAt && (
                                <Typography variant="body2">{`Last modified at ${formatDateAndTime(
                                    updatedAt,
                                )} by ${(noteAuthor && name) || ""} ${(noteAuthor && surname) ||
                                ""}`}</Typography>
                            )}
                        </div>
                    </Grid>
                    <div className={classes.editor}>
                        <NoteEditor
                            body={body}
                            title={title}
                            onSave={this.handleSave}
                            onChange={this.handleChange}
                            disabled={!editMode}
                        />
                    </div>
                </Grid>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        display: "flex",
        backgroundColor: theme.palette.primary[400],
    },
    rootBlacklisted: {
        display: "flex",
        backgroundColor: theme.palette.primary[600],
    },
    wrapper: {
        display: "flex",
        alignItems: "center",
    },
    editor: {
        display: "flex",
        justifyContent: "center",
        padding: 20,
        width: "100%",
    },
    head: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#fff",
    },
    title: {
        display: "flex",
        alignItems: "center",
        height: 34,
    },
});

const mapStateToProps = state => ({
    leadgen: selectLeadgen(state),
    currentEntity: selectEntity(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Notes));
