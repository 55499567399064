import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Avatar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { CallRounded, EmailOutlined, Person } from "@material-ui/icons";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import Head from "./Head";
import Schedule from "../../../../common/components/schedule";

import { formatBirthDate } from "../../../../common/formats/date";
import { customFormatPhoneNumber } from "../../../../common/formats/phone-number";
import {
  receiveUsersDataRequest,
  updateEntityRequest,
} from "../../../leadgen-head-menu/actions";
import { showAddress } from "../../actions/maps";
import { fetchLeadgenOwnersRequest } from "../../actions/owners";
import { leadgenDependentEntitiesRequest } from "../../actions/dependentEntities";
import { selectLeadgen } from '../../selectors';
import { selectEntity } from "../../../leadgen-head-menu/selectors";
import { crossSign } from "../../../../common/constants/symbols";

import kadIcon from "../../../../common/img/kadaster.jpeg";
import instaIcon from "../../../../common/img/instagram.png";
import inIcon from "../../../../common/img/linkedin.png";
import fbIcon from "../../../../common/img/facebook.png";
import fwIcon from "../../../../common/img/twitter.png";

const Owners = ({
  classes,
  objectIDs,
  owners,
  onSelectOwner,
  onSwitchHeadOwner,
  ownerID,
  dispatch,
  router,
  currentEntity,
}) => {
  const getIconByType = (type) => {
    switch (type) {
      case "instagram":
        return instaIcon;
      case "twitter":
        return fwIcon;
      case "linkedin":
        return inIcon;
      case "facebook":
        return fbIcon;

      default:
        return null;
    }
  };

  const handleQuickSetDate = (days) => {
    const { entity } = currentEntity;
    const { id } = entity;
    const newDate = new Date(new Date().setHours(0,0,0,0));
    newDate.setDate(newDate.getDate() + days);
    const action_types = ["save date"];
    ownerID &&
      dispatch(
        updateEntityRequest(
          false,
          action_types,
          ownerID,
          { schedule: newDate, current_entity_id: id },
          router,
          () => {
            dispatch(fetchLeadgenOwnersRequest(objectIDs, id));
            dispatch(leadgenDependentEntitiesRequest(id));
          }
        )
      );

    return null;
  };

  const getUsers = () => dispatch(receiveUsersDataRequest());
  const showAddressOnMaps = (address) => dispatch(showAddress(address));
  const handleSaveStakeholder = ({ type, schedule }, responsible_person_id) => {
    const { entity } = currentEntity;
    const { id } = entity;
    const action_types = [type];
    switch (type) {
      case "save date":
        ownerID &&
          dispatch(
            updateEntityRequest(
              false,
              action_types,
              ownerID,
              { schedule, current_entity_id: id },
              router,
              () => {
                dispatch(fetchLeadgenOwnersRequest(objectIDs, id));
                dispatch(leadgenDependentEntitiesRequest(id));
              }
            )
          );

        return null;
      case "choose responsibility":
        responsible_person_id &&
          ownerID &&
          dispatch(
            updateEntityRequest(
              false,
              action_types,
              ownerID,
              {
                responsible_person_id,
                current_entity_id: id,
              },
              router,
              () => {
                dispatch(fetchLeadgenOwnersRequest(objectIDs, id));
                dispatch(leadgenDependentEntitiesRequest(id));
              }
            )
          );

        return null;
      case "remove all":
        ownerID &&
          dispatch(
            updateEntityRequest(
              false,
              action_types,
              ownerID,
              {
                responsible_person_id: null,
                schedule: null,
                current_entity_id: id,
              },
              router,
              () => {
                dispatch(fetchLeadgenOwnersRequest(objectIDs, id));
                dispatch(leadgenDependentEntitiesRequest(id));
              }
            )
          );

        return null;
      case "remove date":
        ownerID &&
          dispatch(
            updateEntityRequest(
              false,
              action_types,
              ownerID,
              {
                schedule: null,
                current_entity_id: id,
              },
              router,
              () => {
                dispatch(fetchLeadgenOwnersRequest(objectIDs, id));
                dispatch(leadgenDependentEntitiesRequest(id));
              }
            )
          );

        return null;

      default:
        return null;
    }
  };
  const owner = owners.length && owners.filter(({ id }) => id === ownerID)[0];
  const { blacklisted, users } = currentEntity;

  return (
    <React.Fragment>
      <Grid container>
        <Grid className={classes.head} item xs={12}>
          <Grid container>
            <Grid
              className={blacklisted ? classes.headBlacklisted : classes.head}
              item
              xs={4}
            >
              <Typography className={classes.firstCellHead} variant="h6">
                Stakeholders
              </Typography>
            </Grid>
            <Grid
              className={blacklisted ? classes.headBlacklisted : classes.head}
              item
              xs={8}
            >
              <Head classes={classes} objects={owner ? owner.objects : {}} />
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Grid container>
            <Grid className={classes.title} item xs={4}>
              <List className={classes.list}>
                {owners &&
                  owners.length > 0 &&
                  owners.map(
                    (
                      {
                        name,
                        company_name,
                        id,
                        kadaster_url,
                        is_dead,
                        socials,
                        responsible_person,
                        schedule,
                      },
                      index
                    ) => (
                      <div
                        key={index}
                        className={classNames(
                          id === ownerID
                            ? blacklisted
                              ? classes.activeBlacklisted
                              : classes.active
                            : classes.default
                        )}
                      >
                        <div
                          onClick={() => onSelectOwner(id)}
                          className={classes.wrapperDefault}
                        >
                          <Schedule
                            handleSave={handleSaveStakeholder}
                            handleQuickSave={handleQuickSetDate}
                            owner={{ schedule, responsible_person }}
                          />
                          <PopupState
                            variant="popover"
                            popupId="popup-responsible-person-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <div onClick={getUsers}>
                                  <Avatar
                                    alt="responsible person avatar"
                                    src={
                                      responsible_person
                                        ? responsible_person.img_url
                                        : null
                                    }
                                    className={
                                      responsible_person
                                        ? classes.avatar
                                        : classes.defaultAvatar
                                    }
                                    {...bindTrigger(popupState)}
                                  >
                                    <Person />
                                  </Avatar>
                                </div>
                                {users && (
                                  <Menu {...bindMenu(popupState)}>
                                    {users.map(
                                      ({
                                        id,
                                        // email,
                                        name,
                                        surname,
                                        prefix,
                                        img_url,
                                      }) => (
                                        <MenuItem
                                          key={id}
                                          onClick={() => {
                                            popupState.close();
                                            handleSaveStakeholder(
                                              {
                                                type: "choose responsibility",
                                              },
                                              id
                                            );
                                          }}
                                          className={classes.menuItem}
                                        >
                                          <Avatar
                                            alt="responsible person"
                                            src={img_url}
                                          >
                                            <Person />
                                          </Avatar>
                                          <ListItemText
                                            primary={`${name || ""} ${
                                              prefix || ""
                                            } ${surname || ""}`}
                                          />
                                        </MenuItem>
                                      )
                                    )}
                                    <MenuItem
                                      className={classes.menuLastItem}
                                      onClick={() => {
                                        popupState.close();
                                        handleSaveStakeholder({
                                          type: "remove all",
                                        });
                                      }}
                                    >
                                      remove responsibility & date
                                    </MenuItem>
                                  </Menu>
                                )}
                              </React.Fragment>
                            )}
                          </PopupState>
                        </div>
                        <ListItem
                          className={classes.item}
                          onClick={() => onSelectOwner(id)}
                          onDoubleClick={() => onSwitchHeadOwner(id)}
                          button
                        >
                          <Typography>
                            {name || company_name}
                            {is_dead ? <span>({crossSign})</span> : ""}
                          </Typography>
                        </ListItem>
                        <div className={classes.wrapperDefault}>
                          <a
                            href={kadaster_url || "/"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.link}
                          >
                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className={classes.imgLink}
                              src={kadIcon}
                              alt="kadaster.nl"
                            />
                          </a>
                          {socials &&
                            socials.length > 0 &&
                            socials.map(({ type, url }, index) => (
                              <a
                                key={index}
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                              >
                                <img
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className={classes.imgLink}
                                  src={getIconByType(type)}
                                  alt={type}
                                />
                              </a>
                            ))}
                        </div>
                      </div>
                    )
                  )}
              </List>
            </Grid>
            <Grid
              className={
                blacklisted ? classes.cardOwnerBlacklisted : classes.cardOwner
              }
              item
              xs={8}
            >
              {owner ? (
                <React.Fragment>
                  <div className={classes.listWrapper}>
                    <List className={classes.list}>
                      <ListItem className={classes.listItem}>
                        <ListItemText secondary="Age" />
                        <Typography>{owner.age || "n/a"}</Typography>
                        <Typography>
                          {(owner.birth_date &&
                            formatBirthDate(owner.birth_date)) ||
                            owner.birth_year ||
                            "n/a"}
                        </Typography>
                      </ListItem>
                      {owner.phone_numbers && owner.phone_numbers.length > 0 && (
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            secondary="Phone Numbers"
                          />
                          {owner.phone_numbers.map(
                            ({ type = "", number = "" }, index) => (
                              <div
                                key={index}
                                className={classes.wrapperDefault}
                              >
                                <CallRounded className={classes.iconCall} />
                                {type && (
                                  <Typography className={classes.contactsText}>
                                    {type}
                                  </Typography>
                                )}
                                <Typography className={classes.contactsText}>
                                  <a
                                    href={`tel:${number}`}
                                    title={"Tel"}
                                    className={classes.link}
                                  >
                                    {customFormatPhoneNumber(
                                      number.includes("+316")
                                        ? [1, 3, 2, 3]
                                        : [2, 3, 2, 2],
                                      number,
                                      "+31"
                                    ) || "n/a"}
                                  </a>
                                </Typography>
                              </div>
                            )
                          )}
                        </ListItem>
                      )}
                      {owner.emails.length > 0 && (
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            secondary="Emails"
                          />
                          {owner.emails.map(({ type, email }, index) => (
                            <div key={index} className={classes.wrapperDefault}>
                              <EmailOutlined />
                              {type && (
                                <Typography className={classes.contactsText}>
                                  {type}
                                </Typography>
                              )}
                              <Typography className={classes.contactsText}>
                                {email}
                              </Typography>
                            </div>
                          ))}
                        </ListItem>
                      )}
                    </List>
                    {owner.addresses.length > 0 && (
                      <List className={classes.list}>
                        {owner.addresses.map(
                          ({ type, city, address, postcode }, index) => (
                            <ListItem
                              key={index + postcode}
                              className={classes.listItem}
                            >
                              <ListItemText
                                className={classes.listItemText}
                                secondary={`${type || ""} Address`.trim()}
                              />
                              {(city || address || postcode) && (
                                <Typography
                                  className={classes.addressLink}
                                  onClick={() =>
                                    showAddressOnMaps({ city, address })
                                  }
                                >
                                  {city && (
                                    <span>
                                      {city}
                                      <br />
                                    </span>
                                  )}
                                  {address && (
                                    <span>
                                      {address}
                                      <br />
                                    </span>
                                  )}
                                  {postcode && (
                                    <span>
                                      {postcode}
                                      <br />
                                    </span>
                                  )}
                                </Typography>
                              )}
                            </ListItem>
                          )
                        )}
                      </List>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Owners.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  menuItem: {
    display: "flex",
    width: 270,
  },
  menuLastItem: {
    display: "flex",
    justifyContent: "center",
    width: 270,
  },
  iconCall: {
    color: theme.palette.secondary.call,
  },
  avatar: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.primary[400],
    "&:hover": {
      width: 26,
      height: 26,
      border: "2px solid transparent",
      backgroundColor: "transparent",
    },
  },
  defaultAvatar: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.primary[400],
    "&:hover": {
      color: theme.palette.primary[500],
    },
  },
  cardOwner: {
    backgroundColor: theme.palette.primary[400],
  },
  cardOwnerBlacklisted: {
    backgroundColor: theme.palette.primary[600],
  },
  active: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 33,
    width: "100%",
    backgroundColor: theme.palette.primary[400],
    "&:hover": {
      backgroundColor: theme.palette.primary[500],
      color: "black",
    },
  },
  activeBlacklisted: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 33,
    width: "100%",
    backgroundColor: theme.palette.primary[600],
    "&:hover": {
      backgroundColor: theme.palette.primary[700],
      color: "black",
    },
  },
  default: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 33,
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary[500],
      color: "black",
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    minHeight: "inherit",
    padding: 4,
    cursor: "pointer",
  },
  imgLink: {
    marginTop: 4,
    height: 20,
    width: 20,
    "&:hover": {
      width: 16,
      height: 16,
      border: "2px solid transparent",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  addressLink: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  divider: {
    width: "100%",
    backgroundColor: "rgba(224, 224, 224, 1)",
  },
  listWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapperDefault: {
    display: "flex",
    alignItems: "center",
  },
  contactsText: {
    marginLeft: 12,
  },
  list: {
    padding: 0,
    width: "100%",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  listItemText: {
    marginRight: 0,
  },
  head: {
    display: "flex",
    height: 56,
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  headBlacklisted: {
    display: "flex",
    height: 56,
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: theme.palette.primary[700],
  },
  firstCellHead: {
    fontSize: 26,
    display: "flex",
    width: "100%",
    marginLeft: 16,
  },
  title: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});

const mapStateToProps = (state) => ({
  leadgen: selectLeadgen(state),
  currentEntity: selectEntity(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Owners));
