import ErrorHandler from "../../../common/services/error.handler";
import {
    SEARCH_RECEIVE_STREET_REQUEST,
    SEARCH_RECEIVE_STREET_LIST_REQUEST,
    SEARCH_RECEIVE_STREET_SUCCESS,
    SEARCH_RECEIVE_STREET_LIST_SUCCESS,
    SEARCH_RECEIVE_STREET_ERROR,
    SEARCH_RECEIVE_STREET_LIST_ERROR,
} from "../../../common/constants";

export const receiveByStreetRequest = (query) => {
    return {
        type: SEARCH_RECEIVE_STREET_REQUEST,
        query,
    };
};

export const receiveStreetListRequest = (query) => {
    return {
        type: SEARCH_RECEIVE_STREET_LIST_REQUEST,
        query,
    };
};

export const receiveByStreetSuccess = street => {
    return {
        type: SEARCH_RECEIVE_STREET_SUCCESS,
        street: street.data,
    };
};

export const receiveStreetListSuccess = street => {
    return {
        type: SEARCH_RECEIVE_STREET_LIST_SUCCESS,
        street: street.data,
    };
};

export const receiveByStreetError = (query, error) => {
    ErrorHandler.do(error);
    return {
        type: SEARCH_RECEIVE_STREET_ERROR,
        query,
        error,
    };
};
export const receiveStreetListError = (query, error) => {
    ErrorHandler.do(error);
    return {
        type: SEARCH_RECEIVE_STREET_LIST_ERROR,
        query,
        error,
    };
};
