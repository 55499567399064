import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const storeLauncher = state => {
    const result = createStore(
        rootReducer,
        state,
        composeWithDevTools(applyMiddleware(sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);

    return result;
};

export default storeLauncher;
