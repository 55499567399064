import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const Item = ({ classes, title, text }) => {
    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <Typography variant="body2" color="textSecondary">
                    {title}
                </Typography>
            </div>
            <div>
                <Typography variant="body2">{text}</Typography>
            </div>
        </div>
    );
};

Item.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    container: {
        display: "flex",
        paddingLeft: 20,
        paddingRight: 20,
    },
    title: {
        marginRight: 15,
    },
});

export default withStyles(styles)(Item);
