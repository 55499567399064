import React from "react";
import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withStyles } from "@material-ui/core/styles";
import { INPUT } from "../../../../common/constants/field-types";
import FieldEditor from "../../../../common/components/editor/field";
import Typography from "@material-ui/core/Typography";

const NoteEditor = ({ classes, disabled, onChange, onSave, body, title }) => {
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {!disabled ? (
                    <FieldEditor
                        onChange={({ target }) => onChange("title", target.value)}
                        type={INPUT}
                        label="Title"
                        value={title}
                        onBlur={onSave}
                    />
                ) : (
                    <Typography variant="h6">
                        {title || "please input title..."}
                    </Typography>
                )}
            </div>

            <CKEditor
                editor={ClassicEditor}
                data={body || ""}
                config={{
                    toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "insertTable",
                        "|",
                        "undo",
                        "redo",
                    ],
                }}
                onInit={editor => {
                }}
                onBlur={onSave}
                onChange={(e, editor) => onChange("body", editor.getData())}
                disabled={disabled}
            />
        </div>
    );
};

NoteEditor.propTypes = { classes: PropTypes.object.isRequired };

const styles = theme => ({
    root: {
        width: "94%",
    },
    title: {
        width: "50%",
        marginBottom: 10,
    },
});

export default withStyles(styles)(NoteEditor);
