import { createSelector } from "reselect";

const queryObjectsState = state => {
    if (state && state.queryObjects) {
        return state.queryObjects;
    }
    return null;
};

const queryObjectsSelector = createSelector(
    [ queryObjectsState ],
    state => state, //return queryObjects
);
const queryObjectsReceiveSelector = createSelector(
    [ queryObjectsSelector ],
    state => state.receiveConfigs,
);
const queryObjectsRequestResultsSelector = createSelector(
    [ queryObjectsSelector ],
    state => state.requestResults,
);
const queryObjectsReceiveResultsSelector = createSelector(
    [ queryObjectsSelector ],
    state => state.receiveResults,
);

export const selectQueryObjects = createSelector(
    [
        queryObjectsSelector,
        queryObjectsReceiveSelector,
        queryObjectsRequestResultsSelector,
        queryObjectsReceiveResultsSelector,
    ],
    (queryObjects, receiveConfigs, requestResults, receiveResults) => {
        const countedResult = receiveResults && receiveResults.countedResult ? receiveResults.countedResult : [];
        const firstId = receiveResults && receiveResults.firstId ? receiveResults.firstId : "";
        const lastId = receiveResults && receiveResults.lastId ? receiveResults.lastId : "";
        const queryObjectsConfigs = receiveConfigs ? receiveConfigs : [];
        const queryObjectsOptions = queryObjectsConfigs.map(config => ({
            value: config.id,
            label: config.name,
        }));

        return {
            queryObjectsConfigs,
            queryObjectsOptions,
            requestResults,
            receiveResults,
            countedResult,
            firstId,
            lastId,
        };
    },
);
