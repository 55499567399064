import { createSelector } from "reselect";

const entityState = state => {
    if (state && state.entity) {
        return state.entity;
    }
    return null;
};

const entitySelector = createSelector(
    [ entityState ],
    state => state, //return entity
);

const entityGetUserSelector = createSelector(
    [ entitySelector ],
    state => state.receiveUsers,
);

const entityGetLabelsSelector = createSelector(
    [ entitySelector ],
    state => state.receiveLabels,
);

const entityGetTagsSelector = createSelector(
    [ entitySelector ],
    state => state.receiveTags,
);

const entitySetEntitySelector = createSelector(
    [ entitySelector ],
    state => state.setEntity,
);

const entitySetLeadIdSelector = createSelector(
    [ entityState ],
    state => state.setLeadId,
);
const entityDestroyEntitySelector = createSelector(
    [ entitySelector ],
    state => state.responseDestroyEntity,
);

export const selectEntity = createSelector(
    [
        entityGetUserSelector,
        entityGetLabelsSelector,
        entityGetTagsSelector,
        entitySetEntitySelector,
        entitySetLeadIdSelector,
        entityDestroyEntitySelector,
    ],
    (receiveUsers, receiveLabels, receiveTags, setEntity, setLeadId, destroyEntity) => {
        const currentEntity = setEntity ? setEntity : {};
        const notes = setEntity && setEntity.notes && setEntity.notes[0] ? setEntity.notes[0] : {};
        const updatedAt = notes && notes.updated_at ? notes.updated_at : "";
        const body = notes && notes.body ? notes.body : "";
        const title = notes && notes.title ? notes.title : "";
        const noteAuthor = notes ? notes.noteAuthor : {};
        const name = noteAuthor && noteAuthor.name ? noteAuthor.name : "";
        const surname = noteAuthor && noteAuthor.surname ? noteAuthor.surname : "";
        const users = receiveUsers ? receiveUsers : [];
        // const objects = setEntity && setEntity.objects ? setEntity.objects : [];
        const userOptions = users.map(i => ({
            value: i.id,
            label: i.surname ? `${i.name} ${i.surname}` : `${i.name}`,
        }));
        const linkedCompanyId =
            setEntity && setEntity.company_id ? setEntity.company_id : "";
        const entity = setEntity ? setEntity : [];

        const blacklisted = entity ? Boolean(entity.blacklisted) : "";
        const currentLabels = receiveLabels ? receiveLabels : [];
        const preparedLabels = currentLabels
            ? currentLabels.map(({ name }) => {
                return { name, count: receiveLabels[name] || 0 };
            })
            : [];

        const currentTags = receiveTags ? receiveTags : [];
        const entityTags = setEntity && setEntity.tags ? setEntity.tags : [];
        // const objectIDs = objects ? objects.map(object => object.id) : []

        return {
            currentEntity,
            users,
            userOptions,
            preparedLabels,
            currentLabels,
            currentTags,
            entityTags,
            linkedCompanyId,
            entity,
            notes,
            updatedAt,
            noteAuthor,
            body,
            title,
            name,
            surname,
            setLeadId,
            destroyEntity,
            blacklisted,
            // objects,
            // objectIDs
        };
    },
);
