import React, {PureComponent} from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type alertDialogProps = {
    classes: {
        error: string,
        info: string,
        root: string,
        success: string,
        warning: string,
    },
    message: string,
    condition: string,
    customFields: any,
};

type alertDialogState = {
    open: boolean,
}


class AlertDialog extends PureComponent<alertDialogProps, alertDialogState> {
    state = {
        open: true,
    };

    handleClick = () => {
        this.setState({open: true});
    };

    handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }


        this.setState({open: false});
    };

    render() {
        const {classes, message, condition, customFields} = this.props;
        const {open} = this.state;

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={open}
                    autoHideDuration={customFields.duration || 4000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        className: classNames(classes[condition], classes.root),
                    }}
                    message={<div id="message-id">{message}</div>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

const styles = {
    root: {
        paddingTop: 7,
        color: '#fff',
    },
    success: {
        backgroundColor: '#7cb342',
    },
    error: {
        backgroundColor: '#d32f2f',
    },
    info: {
        backgroundColor: '#607d9f',
    },
    warning: {
        backgroundColor: '#f9a825',
    },
};

export default withStyles(styles)(AlertDialog);
