import ErrorHandler from "../../../common/services/error.handler";
import {
    SEARCH_RECEIVE_BY_NAME_REQUEST,
    SEARCH_RECEIVE_BY_NAME_SUCCESS,
    SEARCH_RECEIVE_BY_NAME_ERROR,
} from "../../../common/constants";

export const receiveByNameRequest = query => {
    return {
        type: SEARCH_RECEIVE_BY_NAME_REQUEST,
        query,
    };
};
export const receiveByNameSuccess = resByName => {
    return {
        type: SEARCH_RECEIVE_BY_NAME_SUCCESS,
        resByName: resByName.data,
    };
};

export const receiveByNameError = (query, error) => {
    ErrorHandler.do(error);
    return {
        type: SEARCH_RECEIVE_BY_NAME_ERROR,
        query,
        // error,
    };
};
