import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  receivePersonSuccess,
  setEmptyPersonSuccess,
  receivePersonError,
  setEmptyPersonError
} from "../actions/person";
import {
  SEARCH_RECEIVE_PERSON_REQUEST,
  SEARCH_SET_EMPTY_PERSON_REQUEST
} from "../../../common/constants";

function* searchReceivePerson(action) {
  try {
    const person = yield call(ApiService.post2, "/search/entity", action.query);
    yield put(receivePersonSuccess(person));
  } catch (error) {
    yield put(receivePersonError(error));
  }
}

function* searchSetEmptyPerson(action) {
  try {
    const person = yield call(ApiService.post2, "/search/entity");
    yield put(setEmptyPersonSuccess(person));
  } catch (error) {
    yield put(setEmptyPersonError(error));
  }
}
export default function* actionSearchByPerson() {
  yield takeLatest(SEARCH_RECEIVE_PERSON_REQUEST, searchReceivePerson);
  yield takeLatest(SEARCH_SET_EMPTY_PERSON_REQUEST, searchSetEmptyPerson);
}
