export const parseQueryStreet = query => {
    let queryElems = query.split(" ");
    let street_name = "";
    let number = queryElems[queryElems.length - 1];

    if (queryElems.length >= 2 && !isNaN(Number(number))) {
        queryElems.pop();
        street_name = queryElems.join(" ");
        return { street_name, number };
    }

    if (!isNaN(Number(query))) return { street_name: "", number: query };

    return { street_name: queryElems.join(" "), number: "" };
};
export const parseQueryName = (query, isCompany) => {
    const queryElems = query.split(" ");
    const otherName = queryElems[queryElems.length - 1];

    if (queryElems.length < 2) return { name: query };

    const isNumber = !isNaN(Number(otherName));

    if (!isNumber && isCompany) return { name: queryElems.join(" ") };

    if (isNumber) {
        queryElems.pop();
        return { name: queryElems.join(" ") };
    }

    queryElems.pop();
    return { name: queryElems.join(" "), otherName };
};
export const parseGoogleData = data => {
    const { accessToken, profileObj } = data;
    const { imageUrl, familyName, givenName, email } = profileObj;
    return {
        token: accessToken,
        email: email,
        img_url: imageUrl,
        name: givenName,
        surname: familyName,
    };
};
