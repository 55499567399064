import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { formatPrice } from '../../../../common/formats/price';
import { euroSign } from '../../../../common/constants/symbols';
import classnames from 'classnames';

const TotalHead = ({
  classes,
  houseSize,
  otherSize,
  totalPrice,
  totalSize,
  showTitle,
}) => {
  return (
    <div className={classes.root}>
      <Typography className={classnames(classes.col, classes.col1)}>
        <span>
          {showTitle && (
            <span>
              Total
              <br />
            </span>
          )}

          <span>{totalSize ? totalSize + ' m2' : 'n/a'}</span>
        </span>
      </Typography>
      <Typography className={classnames(classes.col, classes.col2)}>
        <span>
          {showTitle && (
            <span>
              Housing
              <br />
            </span>
          )}
          <span>{houseSize ? houseSize + ' m2' : 'n/a'}</span>
        </span>
      </Typography>
      <Typography className={classnames(classes.col, classes.col3)}>
        <span>
          {showTitle && (
            <span>
              Other
              <br />
            </span>
          )}
          <span>{otherSize ? otherSize + ' m2' : 'n/a'}</span>
        </span>
      </Typography>
      <Typography className={classnames(classes.col, classes.col4)}>
        <span>
          {showTitle && (
            <span>
              Value
              <br />
            </span>
          )}
          {totalPrice ? (
            <span>
              {euroSign}
              <span>{formatPrice(totalPrice)}</span>
            </span>
          ) : (
            'n/a'
          )}
        </span>
      </Typography>
    </div>
  );
};

TotalHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

TotalHead.defaultProps = {
  showTitle: true,
};

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 20,
  },
  col: {
    overflowX: 'hidden',
    display: 'grid',
    gridGap: '1em',
  },
  col1: {
    maxWidth: 80,
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
  },
  col2: {
    maxWidth: 80,
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
  },
  col3: {
    maxWidth: 160,
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
  },
  col4: {
    maxWidth: 100,
    gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
  },
});

export default withStyles(styles)(TotalHead);
