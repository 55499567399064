import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const Rss = ({ classes }) => (
    <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h6">
                    Rss NEWS
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">Headlines clickable links</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">FD</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">Vastgoed Journaal</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">Parool</Typography>
            </Grid>
        </Grid>
    </div>
);

Rss.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = () => ({
    root: {
        display: "flex",
        padding: 10,
    },
    title: {
        height: 34,
        display: "flex",
        justifyContent: "center",
    },
});

export default withStyles(styles)(Rss);
