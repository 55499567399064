import React, {PureComponent} from "react";
import {withStyles} from "@material-ui/core/styles";
import {getDifferenceInDays} from "../../helpers/getter";
import Button from "@material-ui/core/Button";
import DialogWrapper from "../dialog";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import Calendar from "react-calendar";
import TodayOutlined from "@material-ui/icons/TodayOutlined";

type PureComponentProps = {
    owner: {
        schedule: string,
        responsible_person: {
            email: string,
            img_url?: string | null,
            name: string,
            surname: string,
            schedule: string,
            id: string,
        }
    },
    classes: {
        button: string,
        buttonQuick: string,
        calendar: string,
        calendarIcon: string,
        defaultAvatar: string,
        quickOptionsTitle: string,
        warningMessage: string,
        wrapperButtons: string,
        wrapperQuickOptions: string,
        wrapperScheduleAvatar: string,
    },
    // handleSave: () => void,
    handleSave: any,
    // handleQuickSave: () => void,
    handleQuickSave: any,
}

type PureComponentState = {
    open: boolean,
    schedule: any
}


class Schedule extends PureComponent<PureComponentProps, PureComponentState> {
    state = {
        open: false,
        schedule: new Date(),
    };

    quickScheduleOptions = [
        [
            {label: "tomorrow", value: 1},
            {label: "1 week", value: 7},
            {label: "1 month", value: 7 * 4},
        ],
        [
            {label: "2 days", value: 2},
            {label: "2 weeks", value: 7 * 2},
            {label: "3 months", value: 7 * 12},
        ],
        [
            {label: "3 days", value: 3},
            {label: "3 weeks", value: 7 * 3},
            {label: "6 months", value: 7 * 24},
        ],
    ];

    handleOpenDialog = () => {
        this.setState({open: true});
    };
    handleCloseDialog = () => {
        this.setState({open: false});
    };
    handleSetDate = schedule => {
        this.setState({schedule});
    };

    getScheduleColor = s => {
        const days = s && getDifferenceInDays(s);
        switch (true) {
            case days === null || days === undefined:
                return {color: "#d3d3d3"};
            case days < 0:
                return {color: "#fd382f"};
            case days >= 0 && days < 15:
                return {color: "#7cb342"};
            case days >= 15 && days < 183:
                return {color: "#000"};
            case days >= 183 && days <= 365:
                return {color: "#ff0"};
            case days > 365 && days <= 730:
                return {color: "#f9c424"};
            case days > 730:
                return {color: "#f90"};

            default:
                return {color: "#d3d3d3"};
        }
    };

    render() {
        const {owner, classes, handleSave, handleQuickSave} = this.props;
        const {open, schedule} = this.state;
        const color = this.getScheduleColor(owner.schedule);

        return (
            <React.Fragment>
                <TodayOutlined
                    style={color}
                    onClick={this.handleOpenDialog}
                    className={classes.calendarIcon}
                />
                <DialogWrapper
                    open={open}
                    onClose={this.handleCloseDialog}
                    handleClick={() => {
                        handleSave({type: "save date", schedule});
                        this.handleCloseDialog();
                        this.handleSetDate(new Date());
                    }}
                    title="Schedule menu"
                    buttonText="Save"
                >
                    <div className={classes.wrapperScheduleAvatar}>
                        <Typography>
                            Responsible:{" "}
                            {owner.responsible_person
                                ? `${owner.responsible_person.name || ""} ${owner.responsible_person.surname ||
                                ""}`.trim()
                                : ""}
                        </Typography>
                        <Avatar
                            alt="responsible person"
                            // @ts-ignore
                            src={owner.responsible_person ? owner.responsible_person.img_url : null}
                            className={classes.defaultAvatar}
                        >
                            <Person/>
                        </Avatar>
                    </div>
                    <Calendar
                        className={`${classes.calendar}`}
                        tileClassName={`${classes.calendar}`}
                        onChange={this.handleSetDate}
                        value={schedule}
                    />
                    <Typography className={classes.quickOptionsTitle} variant="subtitle1">
                        Quick (re)schedule options
                    </Typography>
                    <div className={classes.wrapperQuickOptions}>
                        {this.quickScheduleOptions.map((row, index) => (
                            <div className={classes.wrapperButtons} key={index}>
                                {row.map(({label, value}) => (
                                    <Button
                                        key={label}
                                        className={classes.buttonQuick}
                                        onClick={() => {
                                            handleQuickSave(value);
                                            this.handleCloseDialog();
                                            this.handleSetDate(new Date());
                                        }}
                                    >
                                        {label}
                                    </Button>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className={classes.wrapperButtons}>
                        <Button
                            className={classes.button}
                            onClick={() => {
                                this.handleCloseDialog();
                                handleSave({type: "remove date"});
                            }}
                        >
                            Remove Date
                        </Button>
                        <Button
                            className={classes.button}
                            onClick={() => {
                                this.handleCloseDialog();
                                handleSave({type: "remove all"});
                            }}
                        >
                            Remove Date & Responsibility
                        </Button>
                    </div>
                </DialogWrapper>
            </React.Fragment>
        );
    }
}

const styles = theme => ({
    warningMessage: {
        marginTop: 15,
    },
    wrapperButtons: {
        width: 300,
        display: "flex",
    },
    wrapperQuickOptions: {
        borderLeft: "1px solid #a0a096",
        borderTop: "1px solid #a0a096",
    },
    quickOptionsTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 50,
        width: "100%",
    },
    button: {
        height: 50,
        width: "100%",
        borderRadius: 0,
    },
    buttonQuick: {
        width: "100%",
        borderRight: "1px solid #a0a096",
        borderBottom: "1px solid #a0a096",
        borderRadius: 0,
        fontSize: "0.8rem",
    },
    defaultAvatar: {
        margin: "5px 0 5px 5px",
        backgroundColor: theme.palette.primary[400],
    },
    calendarIcon: {
        margin: 4,
        "&:hover": {
            color: "#fff !important",
        },
    },
    calendar: {
        width: 300,
        fontFamily: "Roboto",
    },
    wrapperScheduleAvatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
});

export default withStyles(styles)(Schedule);
