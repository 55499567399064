export const formatPrice = price =>
  String(price)
    .match(/.{1,1}/g)
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    .join(' ')
    .match(/.{1,1}/g)
    .reverse()
    .join('');
