import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
    receiveObjectSuccess,
    setEmptyObjectSuccess,
    receiveObjectError,
    setEmptyObjectError,
} from "../actions/object";
import {
    SEARCH_RECEIVE_OBJECT_REQUEST,
    SEARCH_SET_EMPTY_OBJECT_REQUEST,
} from "../../../common/constants";

function* searchReceiveObject(action) {
    try {
        const object = yield call(ApiService.post2, "/search/object", action.query);
        // console.log('object ', object.data);
        yield put(receiveObjectSuccess(object));
    } catch (error) {
        yield put(receiveObjectError(error));
    }
}

function* searchSetEmptyObject(action) {
    try {
        const object = yield call(ApiService.post2, "/search/object", action.query);
        yield put(setEmptyObjectSuccess(object));
    } catch (error) {
        yield put(setEmptyObjectError(error));
    }
}

export default function* actionSearchByObject() {
    yield takeLatest(SEARCH_RECEIVE_OBJECT_REQUEST, searchReceiveObject);
    yield takeLatest(SEARCH_SET_EMPTY_OBJECT_REQUEST, searchSetEmptyObject);
}
