import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import classNames from "classnames";
import { Link } from "react-router";
import { selectLeads } from "../../../modules/leads/selectors";
import { selectUser } from "../../../modules/login/selectors";
import { checkBestLeadRequest } from "../../../modules/leads/actions";

type SideBarProps = {
  classes: {
    active: string;
    default: string;
    itemText: string;
    link: string;
    list: string;
  };
  path: any;
  leads: {
    currentLeads: any;
    driveFolderId: string;
    driveFolderList: any;
    freeLeadId: any;
    isFetchingLeads: boolean;
    leads: {
      error: any;
      freeLead: any;
      receive: { isFetching: boolean };
      receiveDriveFolderData: any;
    };
  };
  user: any;
};

const sidebarItems = [
  // {
  //   to: '/dashboard',
  //   title: 'Dashboard',
  // },
  {
    to: "/leadgen",
    title: "Leadgen",
  },
  {
    to: "/leads",
    title: "Leads",
  },
  {
    to: "/add-new",
    title: "Add New",
  },
  {
    to: "/import-pdf",
    title: "Import PDF",
  },
  {
    to: "/admin",
    title: "Admin",
  },
  {
    to: "/lps-form",
    title: "LPS Form",
  },
  {
    to: "/query-objects",
    title: "Query Objects",
  },
];

class Sidebar extends Component<SideBarProps> {
  leadgenBestLead = () => {
    // @ts-ignore
    const { dispatch, router, leads } = this.props;
    const { freeLeadId } = leads;
    const currentItems = [
      {
        to: freeLeadId ? `/leadgen/entity/${freeLeadId}` : "/leadgen",
        title: "Leadgen",
      },
      {
        to: "/add-new",
        title: "Add New",
      },
      {
        to: "/import-pdf",
        title: "Import PDF",
      },
      {
        to: "/leads",
        title: "Leads",
      },
      {
        to: "/query-objects",
        title: "Query Objects",
      },
      {
        to: "/admin",
        title: "Admin",
      },
      {
        to: "/lps-form",
        title: "LPS Form",
      },
    ];

    const isLeadgen = currentItems[0];

    if (isLeadgen) {
      dispatch(checkBestLeadRequest(router));
    }
  };

  render() {
    const { classes, path, leads, user } = this.props;
    const { userData } = user;
    const { freeLeadId } = leads;
    const currentItems = [
      {
        to: freeLeadId ? `/leadgen/entity/${freeLeadId}` : "/leadgen",
        title: "Leadgen",
      },
      {
        to: "/add-new",
        title: "Add New",
      },
      {
        to: "/import-pdf",
        title: "Import PDF",
      },
      {
        to: "/leads",
        title: "Leads",
      },
      {
        to: "/query-objects",
        title: "Query Objects",
      },
      {
        to: "/admin",
        title: "Admin",
      },
      {
        to: "/lps-form",
        title: "LPS Form",
      },
    ];

    const leadgenItem = currentItems.shift();
    // @ts-ignore
    const { to, title } = leadgenItem;
    const items = [...currentItems];
    if (userData && userData.role !== "Administrator") {
      items.splice(items.length - 3, 3);
    }

    return (
      <List className={classes.list}>
        <Link className={classes.link} to={to} key={to}>
          <ListItem
            className={classNames(
              path.includes(to) ? classes.active : classes.default
            )}
            button
            onClick={this.leadgenBestLead}
          >
            <ListItemText className={classes.itemText} primary={title} />
          </ListItem>
        </Link>
        {items.map(({ to, title }) => (
          <Link className={classes.link} to={to} key={to}>
            <ListItem
              className={classNames(
                path.includes(to) ? classes.active : classes.default
              )}
              button
            >
              <ListItemText className={classes.itemText} primary={title} />
            </ListItem>
          </Link>
        ))}
      </List>
    );
  }
}

// Sidebar.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

const styles = (theme) => ({
  itemText: {
    marginRight: 15,
  },
  list: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  active: {
    backgroundColor: theme.palette.primary[500],
    "&:hover": {
      backgroundColor: theme.palette.primary[400],
      color: "black",
    },
  },
  default: {
    "&:hover": {
      backgroundColor: theme.palette.primary[400],
      color: "black",
    },
  },
  link: {
    textDecoration: "none",
  },
});

export const sidebar = sidebarItems;

const mapStateToProps = (state) => ({
  leads: selectLeads(state),
  user: selectUser(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Sidebar));
