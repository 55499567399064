import { call, delay, put, takeLatest } from "redux-saga/effects";
import UserService from "../../../common/services/user.service";
import ApiService from "../../../common/services/api.service";
import { getCurrentUserSuccess, getCurrentUserError } from "../actions/";
import {
  GET_CURRENT_USER_REQUEST,
  RECEIVE_USER_REQUEST,
} from "../../../common/constants";
import { handleSuccess } from "../handlers";
import { countLPSDataRequest } from "../../search/actions/lps";

function* getUserWithAuth(action) {
  try {
    if (action.message === "error") {
      return console.log("errorFetchGoogleClient ", action.message);
    }
    UserService.setToken = action.currentUser.tokenId;
    const user = yield call(ApiService.post2, "/login");
    const { name } = user.data.user;
    if (user && user.default_lps_profile) {
      const config = user.default_lps_profile;

      yield delay(2000);
      // console.log("Lps process started");
      yield put(countLPSDataRequest({ config, city: "" }, action.router));
    }

    yield put(getCurrentUserSuccess(user.data.user));
    action.router.push("/dashboard");
    handleSuccess(name);
    action.cb();
  } catch (e) {
    yield put(getCurrentUserError(e));
  }
}

function* getCurrentUser(action) {
  try {
    const user = yield call(ApiService.get2, "/user");
    yield put(getCurrentUserSuccess(user.data.user));
  } catch (e) {
    yield put(getCurrentUserError(e));
  }
}

export default function* actionLogin() {
  yield takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser);
  yield takeLatest(RECEIVE_USER_REQUEST, getUserWithAuth);
}
