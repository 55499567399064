import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Drawer from "@material-ui/core/Drawer";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

class EditResultsMenu extends Component {

    state = {
        menuType: "object",
        showSearchBlocks: {
            object: true,
            entity: true,
        },
    };

    handleSelectType = (e, menuType) => {
        menuType &&
        this.setState({
            menuType,
        });
    };

    handleChange = (block, key) => ({ target: { checked } }) => {
        const { objectSwitches, entitySwitches } = this.state;
        if (block === "objectSwitches") {
            this.setState({
                objectSwitches: {
                    ...objectSwitches,
                    [key]: checked,
                },
            });
        }
        if (block === "entitySwitches") {
            this.setState({
                entitySwitches: {
                    ...entitySwitches,
                    [key]: checked,
                },
            });
        }
    };

    render() {
        const { classes, openMenu } = this.props;
        const { menuType, showSearchBlocks } = this.state;
        const { object, entity } = showSearchBlocks;
        const objectSwitches = [
            {
                title: "Object",
                value: object.object,
                onChange: this.handleChange("objectSwitches", "object"),
            }, {
                title: "Gesplitst",
                value: object.gesplitst,
                onChange: this.handleChange("objectSwitches", "gesplitst"),
            },
            {
                title: "Plot size",
                value: object.plotSize,
                onChange: this.handleChange("objectSwitches", "plotSize"),
            },
            {
                title: "Full plot",
                value: object.fullPlot,
                onChange: this.handleChange("objectSwitches", "fullPlot"),
            },
            {
                title: "Plot citycode",
                value: object.plotCityCode,
                onChange: this.handleChange("objectSwitches ", "plotCityCode"),
            },
            {
                title: "Plot number",
                value: object.plotNumber,
                onChange: this.handleChange("objectSwitches", "plotNumber"),
            },
            {
                title: "Kad Object ID number",
                value: object.kadasterId,
                onChange: this.handleChange("objectSwitches", "kadasterId"),
            },
            {
                title: "Eigendom",
                value: object.eigendom,
                onChange: this.handleChange("objectSwitches ", "eigendom"),
            },
            {
                title: "Units",
                value: object.units,
                onChange: this.handleChange("objectSwitches", "units"),
            },
            {
                title: "Object size",
                value: object.objectSize,
                onChange: this.handleChange("objectSwitches", "objectSize"),
            },
            {
                title: "Monument",
                value: object.monument,
                onChange: this.handleChange("objectSwitches", "monument"),
            },
            {
                title: "Value",
                value: object.value,
                onChange: this.handleChange("objectSwitches", "value"),
            },
            {
                title: "Development",
                value: object.development,
                onChange: this.handleChange("objectSwitches", "development"),
            },
            {
                title: "Linked",
                value: object.linked,
                onChange: this.handleChange("objectSwitches", "linked"),
            },
        ];
        const entitySwitches = [
            {
                title: "Owner",
                value: entity.owner,
                onChange: this.handleChange("entitySwitches", "owner"),
            },
            {
                title: "Age",
                value: entity.age,
                onChange: this.handleChange("entitySwitches", "age"),
            },
            {
                title: "Kad entity id number",
                value: entity.kadId,
                onChange: this.handleChange("entitySwitches", "kadId"),
            },
            {
                title: "Share",
                value: entity.share,
                onChange: this.handleChange("entitySwitches", "share"),
            },
            {
                title: "Kad objects",
                value: entity.zipcode,
                onChange: this.handleChange("entitySwitches", "c"),
            },
            {
                title: "Zipcode",
                value: entity.zipcode,
                onChange: this.handleChange("entitySwitches", "zipcode"),
            },
            {
                title: "Street name",
                value: entity.streetName,
                onChange: this.handleChange("entitySwitches", "streetName"),
            },
            {
                title: "House number + addition",
                value: entity.houseNumberAddition,
                onChange: this.handleChange("entitySwitches", "houseNumberAddition"),
            },
            {
                title: "City",
                value: entity.city,
                onChange: this.handleChange("entitySwitches", "city"),
            },
            {
                title: "Contact",
                value: entity.contact,
                onChange: this.handleChange("entitySwitches", "contact"),
            },
            {
                title: "Manager",
                value: entity.manager,
                onChange: this.handleChange("entitySwitches", "manager"),
            },
            {
                title: "Phone",
                value: entity.phoneNumber,
                onChange: this.handleChange("entitySwitches", "phoneNumber"),
            },
            {
                title: "Email",
                value: entity.email,
                onChange: this.handleChange("entitySwitches", "email"),
            },
            {
                title: "Last contact",
                value: entity.lastContact,
                onChange: this.handleChange("entitySwitches", "lastContact"),
            },
            {
                title: "Responsible",
                value: entity.responsible,
                onChange: this.handleChange("entitySwitches", "responsible"),
            },
        ];

        return (
            <div className={openMenu ? classes.rootOpen : classes.rootClose}>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={openMenu}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <ToggleButtonGroup
                        value={menuType}
                        exclusive
                        onChange={this.handleSelectType}
                        className={classes.toggleButtonGroup}
                    >
                        <ToggleButton className={classes.toggleButton} value="object">
                            object
                        </ToggleButton>
                        <ToggleButton className={classes.toggleButton} value="entity">
                            entity
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {menuType === "object" && (
                        <React.Fragment>
                            <Typography variant="subtitle1" className={classes.subtitle}>Object information</Typography>
                            {objectSwitches.map(({ title, value, onChange }) => (
                                <div key={title} className={classes.row}>
                                    <div className={classes.container}>
                                        <Typography variant="body2">{title}</Typography>
                                        <Switch className={classes.switch}
                                                defaultChecked={true}
                                                checked={value}
                                                onChange={onChange}
                                                color="secondary"/>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                    {menuType === "entity" && (
                        <React.Fragment>
                            <Typography variant="subtitle1" className={classes.subtitle}>Entity information</Typography>
                            {entitySwitches.map(({ title, value, onChange }) => (
                                <div key={title} className={classes.row}>
                                    <div className={classes.container}>
                                        <Typography variant="body2">{title}</Typography>
                                        <Switch className={classes.switch}
                                                defaultChecked={true}
                                                checked={value}
                                                onChange={onChange}
                                                color="secondary"/>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </Drawer>
            </div>
        );
    }
}

EditResultsMenu.propTypes = {};

const drawerRightWidth = 220;
const drawerRightHeight = "100%";

const styles = theme => ({
    drawer: {
        width: drawerRightWidth,
        height: drawerRightHeight,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerRightWidth,
        height: drawerRightHeight,
        marginTop: "64px",
    },
    toggleButtonGroup: {
        borderRadius: 0,
        marginBottom: 2,
    },
    toggleButton: {
        borderRadius: 0,
        width: "50%",
    },

    subtitle: {
        padding: 8,
        textAlign: "center",
        fontSize: "18px",
        color: theme.palette.primary[500],
    },
    row: {
        padding: 4,
    },
    color: {
        color: theme.palette.primary.grayFont,
    },
    switch: {
        display: "flex",
        margin: "-10px 0",
    },
    head: {
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary[400],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        borderRadius: 0,
        color: theme.palette.secondary["A400"],
        width: "100%",
        padding: 0,
        height: "30px",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: " 0 20px",
    },
    body: {
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

const mapStateToProps = state => ({
    ...state,
});
export default connect(mapStateToProps)(withStyles(styles)(EditResultsMenu));
