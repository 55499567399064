import { combineReducers } from "redux";
import objects from "./objects";
import dependentEntities from "./dependentEntities";
import owners from "./owners";
import notes from "./notes";
import maps from "./maps";
import drive from "./drive";

const leadgen = combineReducers({
    objects,
    dependentEntities,
    owners,
    notes,
    maps,
    drive,
});

export default leadgen;
