import { combineReducers } from "redux";
import city from "./city";
import company from "./company";
import lps from "./lps";
import name from "./name";
import object from "./object";
import person from "./person";
import settings from "./settings";
import street from "./street";
import phone from "./phone";

const search = combineReducers({
    city,
    company,
    lps,
    name,
    object,
    person,
    settings,
    street,
    phone,
});

export default search;
