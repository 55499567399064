import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Row from "../common/Row";

const Leads = ({ classes }) => (
    <div className={classes.root}>
        <Grid container>
            <Grid className={classes.head} item xs={12}>
                <Typography className={classes.title} variant="h6">
                    Leads
                </Typography>
                <Typography className={classes.title} variant="subtitle1">
                    Show leads for (everyone)(me)(user)
                </Typography>
            </Grid>
            <Divider className={classes.divider}/>
            <Row
                title="today"
                object="Object/portfolio"
                owner="Owner"
                task="(task)"
            />
            <Row
                title="tomorrow"
                object="Object/portfolio"
                owner="Owner"
                task="(task)"
            />
            <Row
                title="(date)"
                object="Object/portfolio"
                owner="Owner"
                task="(task)"
            />
            <Row
                title="(date)"
                object="Object/portfolio"
                owner="Owner"
                task="(task)"
            />
        </Grid>
    </div>
);

Leads.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    root: {
        display: "flex",
        backgroundColor: theme.palette.primary[400],
    },
    divider: {
        width: "100%",
        backgroundColor: theme.palette.primary.divider,
    },
    head: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#fff",
    },
    title: {
        display: "flex",
        alignItems: "center",
        height: 34,
    },
});

export default withStyles(styles)(Leads);
