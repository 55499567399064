import { combineReducers } from "redux";
import {
  GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST,
  GET_LEADGEN_OBJECTS_BY_OWNER_SUCCESS,
  GET_LEADGEN_OBJECT_DATA_SUCCESS,
  SET_LEADGEN_EMPTY_OBJECTS_SUCCESS,
  GET_LEADGEN_OBJECTS_BY_OWNER_ERROR,
  GET_LEADGEN_OBJECT_DATA_ERROR,
  DELETE_LEADGEN_OBJECT_ERROR,
  SET_LEADGEN_EMPTY_OBJECTS_ERROR,
  ERROR_EDIT_OBJECT,
  GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_SUCCESS,
  GET_LEADGEN_EDIT_OBJECT_DATA_SUCCESS,
  GET_LEADGEN_EDIT_OBJECT_DATA_ERROR,
} from "../../../common/constants";

const requestObjects = (state = false, action) => {
  switch (action.type) {
    case GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST:
      return true;
    case GET_LEADGEN_OBJECTS_BY_OWNER_SUCCESS:
    case GET_LEADGEN_OBJECTS_BY_OWNER_ERROR:
      return false;

    default:
      return state;
  }
};

const receiveObjects = (state = [], action) => {
  switch (action.type) {
    case GET_LEADGEN_OBJECTS_BY_OWNER_SUCCESS:
    case SET_LEADGEN_EMPTY_OBJECTS_SUCCESS:
    case GET_LEADGEN_OBJECT_DATA_SUCCESS:
      return action.objects;

    default:
      return state;
  }
};

const receiveObjectsInfo = (state = "", action) => {
  switch (action.type) {
    case GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_SUCCESS:
      return action.objectsInfo;

    default:
      return state;
  }
};

const editableObject = (state = {}, action) => {
  switch (action.type) {
    case GET_LEADGEN_EDIT_OBJECT_DATA_SUCCESS:
      return action.object;

    default:
      return state;
  }
};

const errorFetchObjects = (state = {}, action) => {
  switch (action.type) {
    case GET_LEADGEN_OBJECTS_BY_OWNER_ERROR:
    case SET_LEADGEN_EMPTY_OBJECTS_ERROR:
    case GET_LEADGEN_OBJECT_DATA_ERROR:
    case GET_LEADGEN_EDIT_OBJECT_DATA_ERROR:
    case DELETE_LEADGEN_OBJECT_ERROR:
    case ERROR_EDIT_OBJECT:
      return action.error;

    default:
      return state;
  }
};

const objects = combineReducers({
  requestObjects,
  receiveObjects,
  editableObject,
  receiveObjectsInfo,
  errorObjects: errorFetchObjects,
});

export default objects;
