import {
  SHOW_LEADGEN_ADDRESS,
  SHOW_LEADGEN_ADDRESS_ERROR
} from "../../../common/constants";

export const showAddress = address => {
  return {
    type: SHOW_LEADGEN_ADDRESS,
    address
  };
};

export const setAddressError = error => {
  return {
    type: SHOW_LEADGEN_ADDRESS_ERROR,
    error
  };
};
