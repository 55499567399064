import { combineReducers } from "redux";
import {
  GET_LEADGEN_OWNERS_SUCCESS,
  SET_EMPTY_LEADGEN_OWNERS_SUCCESS,
  GET_LEADGEN_OWNERS_ERROR,
  SET_EMPTY_LEADGEN_OWNERS_ERROR
} from "../../../common/constants";

const receiveOwners = (state = [], action) => {
  switch (action.type) {
    case GET_LEADGEN_OWNERS_SUCCESS:
    case SET_EMPTY_LEADGEN_OWNERS_SUCCESS:
      return action.owners;

    default:
      return state;
  }
};

const errorFetchOwners = (state = {}, action) => {
  switch (action.type) {
    case GET_LEADGEN_OWNERS_ERROR:
    case SET_EMPTY_LEADGEN_OWNERS_ERROR:
      return action.error;

    default:
      return state;
  }
};

const owners = combineReducers({
  receiveOwners,
  error: errorFetchOwners
});

export default owners;
