//ADMIN CONSTANTS

export const GET_ADMIN_USERS_REQUEST = "GET_ADMIN_USERS_REQUEST";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_ERROR = "GET_ADMIN_USERS_ERROR";

export const SET_TEMPORARILY_ASSIGNED_REQUEST =
  "SET_TEMPORARILY_ASSIGNED_REQUEST";
export const SET_TEMPORARILY_ASSIGNED_SUCCESS =
  "SET_TEMPORARILY_ASSIGNED_SUCCESS";
export const SET_TEMPORARILY_ASSIGNED_ERROR = "SET_TEMPORARILY_ASSIGNED_ERROR";

export const CREATE_ADMIN_USER_REQUEST = "CREATE_ADMIN_USER_REQUEST";
export const CREATE_ADMIN_USER_SUCCESS = "CREATE_ADMIN_USER_SUCCESS";
export const CREATE_ADMIN_USER_ERROR = "CREATE_ADMIN_USER_ERROR";

export const UPDATE_ADMIN_USER_REQUEST = "DELETE_ADMIN_USER_REQUEST";
export const UPDATE_ADMIN_USER_SUCCESS = "UPDATE_ADMIN_USER_SUCCESS";
export const UPDATE_ADMIN_USER_ERROR = "UPDATE_ADMIN_USER_ERROR";

export const DELETE_ADMIN_USER_REQUEST = " DELETE_ADMIN_USER_REQUEST";
export const DELETE_ADMIN_USER_SUCCESS = " DELETE_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_ERROR = "DELETE_ADMIN_USER_ERROR";

export const GET_ADMIN_DRIVE_REQUEST = "GET_ADMIN_DRIVE_REQUEST";
export const GET_ADMIN_DRIVE_SUCCESS = "GET_ADMIN_DRIVE_SUCCESS";
export const GET_ADMIN_DRIVE_ERROR = "GET_ADMIN_DRIVE_ERROR";

// LEADGEN CONSTANTS

export const GET_LEADGEN_NOTES_REQUEST = "GET_LEADGEN_NOTES_REQUEST";
export const GET_LEADGEN_NOTES_SUCCESS = "GET_LEADGEN_NOTES_SUCCESS";
export const GET_LEADGEN_NOTES_ERROR = "GET_LEADGEN_NOTES_ERROR";

export const PUT_LEADGEN_NOTES_REQUEST = "PUT_LEADGEN_NOTES_REQUEST";
export const PUT_LEADGEN_NOTES_SUCCESS = "PUT_LEADGEN_NOTES_SUCCESS";
export const PUT_LEADGEN_NOTES_ERROR = "PUT_LEADGEN_NOTES_ERROR";

export const REMOVE_LEADGEN_NOTE_REQUEST = "REMOVE_LEADGEN_NOTE_REQUEST";
export const REMOVE_LEADGEN_NOTE_SUCCESS = "REMOVE_NOTE_SUCCESS";
export const REMOVE_LEADGEN_NOTE_ERROR = "REMOVE_NOTE_ERROR";

export const GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST =
  "GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST";
export const GET_LEADGEN_OBJECTS_BY_OWNER_SUCCESS =
  "GET_LEADGEN_OBJECTS_BY_OWNER_SUCCESS";
export const GET_LEADGEN_OBJECTS_BY_OWNER_ERROR =
  "GET_LEADGEN_OBJECTS_BY_OWNER_ERROR";

export const GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_REQUEST =
  "GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_REQUEST";
export const GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_SUCCESS =
  "GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_SUCCESS";
export const GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_ERROR =
  "GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_ERROR";

export const GET_LEADGEN_PREV_OBJECTS_BY_OWNER_REQUEST =
  "GET_LEADGEN_PREV_OBJECTS_BY_OWNER_REQUEST";
export const GET_LEADGEN_NEXT_OBJECTS_BY_OWNER_REQUEST =
  "GET_LEADGEN_NEXT_OBJECTS_BY_OWNER_REQUEST";

export const GET_LEADGEN_OBJECT_DATA_REQUEST =
  "GET_LEADGEN_OBJECT_DATA_REQUEST";
export const GET_LEADGEN_OBJECT_DATA_SUCCESS =
  "GET_LEADGEN_OBJECT_DATA_SUCCESS";
export const GET_LEADGEN_OBJECT_DATA_ERROR = "GET_LEADGEN_OBJECT_DATA_ERROR";

export const GET_LEADGEN_EDIT_OBJECT_DATA_REQUEST =
  "GET_LEADGEN_EDIT_OBJECT_DATA_REQUEST";
export const GET_LEADGEN_EDIT_OBJECT_DATA_SUCCESS =
  "GET_LEADGEN_EDIT_OBJECT_DATA_SUCCESS";
export const GET_LEADGEN_EDIT_OBJECT_DATA_ERROR =
  "GET_LEADGEN_EDIT_OBJECT_DATA_ERROR";

export const GET_LEADGEN_OBJECT_REQUEST = "GET_LEADGEN_OBJECT_REQUEST";
export const GET_LEADGEN_OBJECT_SUCCESS = "GET_LEADGEN_OBJECT_SUCCESS";
export const GET_LEADGEN_OBJECT_ERROR = "GET_LEADGEN_OBJECT_ERROR";

export const DELETE_LEADGEN_OBJECT_REQUEST = "DELETE_LEADGEN_OBJECT_REQUEST";
export const DELETE_LEADGEN_OBJECT_SUCCESS = "DELETE_LEADGEN_OBJECT_SUCCESS";
export const DELETE_LEADGEN_OBJECT_ERROR = "DELETE_LEADGEN_OBJECT_ERROR";

export const SET_EMPTY_LEADGEN_OBJECTS_REQUEST =
  "SET_EMPTY_LEADGEN_OBJECTS_REQUEST";
export const SET_LEADGEN_EMPTY_OBJECTS_SUCCESS =
  "SET_LEADGEN_EMPTY_OBJECTS_SUCCESS";
export const SET_LEADGEN_EMPTY_OBJECTS_ERROR =
  "SET_LEADGEN_EMPTY_OBJECTS_ERROR";

export const PUT_LEADGEN_OBJECT_REQUEST = "PUT_LEADGEN_OBJECT_REQUEST";
export const PUT_LEADGEN_OBJECT_SUCCESS = "PUT_LEADGEN_OBJECT_SUCCESS";
export const PUT_LEADGEN_OBJECT_ERROR = "PUT_LEADGEN_OBJECT_ERROR";

export const SHOW_LEADGEN_ADDRESS = "SHOW_LEADGEN_ADDRESS";
export const SHOW_LEADGEN_ADDRESS_ERROR = "SHOW_LEADGEN_ADDRESS_ERROR";

export const GET_LEADGEN_OWNERS_REQUEST = "GET_LEADGEN_OWNERS_REQUEST";
export const GET_LEADGEN_OWNERS_SUCCESS = "GET_LEADGEN_OWNERS_SUCCESS";
export const GET_LEADGEN_OWNERS_ERROR = "GET_LEADGEN_OWNERS_ERROR";

export const SET_EMPTY_LEADGEN_OWNERS_REQUEST =
  "SET_EMPTY_LEADGEN_OWNERS_REQUEST";
export const SET_EMPTY_LEADGEN_OWNERS_SUCCESS =
  "SET_EMPTY_LEADGEN_OWNERS_SUCCESS";
export const SET_EMPTY_LEADGEN_OWNERS_ERROR = "SET_EMPTY_LEADGEN_OWNERS_ERROR";

export const GET_LEADGEN_DEPENDENT_ENTITIES_REQUEST =
  "GET_LEADGEN_DEPENDENT_ENTITIES_REQUEST";
export const GET_LEADGEN_DEPENDENT_ENTITIES_SUCCESS =
  "GET_LEADGEN_DEPENDENT_ENTITIES_SUCCESS";
export const GET_LEADGEN_DEPENDENT_ENTITIES_ERROR =
  "GET_LEADGEN_DEPENDENT_ENTITIES_ERROR";

export const GET_DEPENDENT_ENTITIES_REQUEST = "GET_DEPENDENT_ENTITIES_REQUEST";
export const GET_DEPENDENT_ENTITIES_SUCCESS = "GET_DEPENDENT_ENTITIES_SUCCESS";
export const GET_DEPENDENT_ENTITIES_ERROR = "GET_DEPENDENT_ENTITIES_ERROR";

export const SET_EMPTY_LEADGEN_DEPENDENT_ENTITIES_REQUEST =
  "SET_EMPTY_LEADGEN_DEPENDENT_ENTITIES_REQUEST";
export const SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_SUCCESS =
  "SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_SUCCESS";
export const SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_ERROR =
  "SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_ERROR";

// ENTITY CONSTANTS

export const CREATE_NEW_ENTITY_REQUEST = "CREATE_NEW_ENTITY_REQUEST";
export const CREATE_NEW_ENTITY_SUCCESS = "CREATE_NEW_ENTITY_SUCCESS";
export const CREATE_NEW_ENTITY_ERROR = "CREATE_NEW_ENTITY_ERROR";

export const GET_ENTITY_REQUEST = "GET_ENTITY_REQUEST";
export const GET_ENTITY_SUCCESS = "GET_ENTITY_SUCCESS";
export const GET_ENTITY_ERROR = "GET_ENTITY_ERROR";

export const UPDATE_ENTITY_REQUEST = "UPDATE_ENTITY_REQUEST";
export const UPDATE_ENTITY_SUCCESS = "UPDATE_ENTITY_SUCCESS";
export const UPDATE_ENTITY_ERROR = "UPDATE_ENTITY_ERROR";

export const DESTROY_ENTITY_REQUEST = "DESTROY_ENTITY_REQUEST";
export const DESTROY_ENTITY_SUCCESS = "DESTROY_ENTITY_SUCCESS";
export const DESTROY_ENTITY_ERROR = "DESTROY_ENTITY_ERROR";

export const SET_EMPTY_ENTITY_REQUEST = "SET_EMPTY_ENTITY_REQUEST";

// OBJECT CONSTANTS

export const CREATE_NEW_OBJECT_REQUEST = "CREATE_NEW_OBJECT_REQUEST";
export const CREATE_NEW_OBJECT_SUCCESS = "CREATE_NEW_OBJECT_SUCCESS";
export const CREATE_NEW_OBJECT_ERROR = "CREATE_NEW_OBJECT_ERROR";
export const ERROR_EDIT_OBJECT = "ERROR_EDIT_OBJECT";

// USERS CONSTANTS

export const RECEIVE_USERS_DATA_REQUEST = "RECEIVE_USERS_DATA_REQUEST";
export const RECEIVE_USERS_DATA_SUCCESS = "RECEIVE_USERS_DATA_SUCCESS";
export const RECEIVE_USERS_DATA_ERROR = "RECEIVE_USERS_DATA_ERROR";

export const RECEIVE_USER_REQUEST = "RECEIVE_USER_REQUEST";
export const RECEIVE_USER_SUCCESS = "RECEIVE_USER_SUCCESS";
export const ERROR_FETCH_USER = "ERROR_FETCH_USER";

export const REQUEST_USER = "REQUEST_USER";
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_USER_ERROR = "RECEIVE_USER_ERROR";

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";

// LABELS CONSTANTS

export const RECEIVE_LABELS_REQUEST = "RECEIVE_LABELS_REQUEST";
export const RECEIVE_LABELS_SUCCESS = "RECEIVE_LABELS_SUCCESS";
export const RECEIVE_LABELS_ERROR = "RECEIVE_LABELS_ERROR";

export const UPDATE_LABEL_REQUEST = "UPDATE_LABEL_REQUEST";
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS";
export const UPDATE_LABEL_ERROR = "UPDATE_LABEL_ERROR";

export const CREATE_LABEL_REQUEST = "CREATE_LABEL_REQUEST";
export const CREATE_LABEL_SUCCESS = "CREATE_LABEL_SUCCESS";
export const CREATE_LABEL_ERROR = "CREATE_LABEL_ERROR";

export const REMOVE_LABEL_REQUEST = "REMOVE_LABEL_REQUEST";
export const REMOVE_LABEL_SUCCESS = "REMOVE_LABEL_SUCCESS";
export const REMOVE_LABEL_ERROR = "REMOVE_LABEL_ERROR";

// TAGS CONSTANTS

export const RECEIVE_TAGS_REQUEST = "RECEIVE_TAGS_REQUEST";
export const RECEIVE_TAGS_SUCCESS = "RECEIVE_TAGS_SUCCESS";
export const RECEIVE_TAGS_ERROR = "RECEIVE_TAGS_ERROR";

export const UPDATE_TAG_REQUEST = "UPDATE_TAG_REQUEST";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_ERROR = "UPDATE_TAG_ERROR";

export const CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_ERROR = "CREATE_TAG_ERROR";

export const REMOVE_TAG_REQUEST = "REMOVE_TAG_REQUEST";
export const REMOVE_TAG_SUCCESS = "REMOVE_TAG_SUCCESS";
export const REMOVE_TAG_ERROR = "REMOVE_TAG_ERROR";

// LEADS CONSTANTS

export const RECEIVE_LEADS_DATA_REQUEST = "RECEIVE_LEADS_DATA_REQUEST";
export const RECEIVE_LEADS_DATA_SUCCESS = "RECEIVE_LEADS_DATA_SUCCESS";
export const RECEIVE_LEADS_DATA_ERROR = "RECEIVE_LEADS_DATA_ERROR";
export const CHECK_BEST_LEAD_REQUEST = "CHECK_BEST_LEAD_REQUEST";
export const CHECK_BEST_LEAD_SUCCESS = "CHECK_BEST_LEAD_SUCCESS";
export const CHECK_BEST_LEAD_ERROR = "CHECK_BEST_LEAD_ERROR";

export const CHECK_FREE_LEAD_REQUEST = "CHECK_FREE_LEAD_REQUEST";
export const CHECK_FREE_LEAD_SUCCESS = "CHECK_FREE_LEAD_SUCCESS";
export const CHECK_FREE_LEAD_ERROR = "CHECK_FREE_LEAD_ERROR";

export const RECEIVE_CHECK_FREE_LEAD = "RECEIVE_CHECK_FREE_LEAD";
export const ERROR_CHECK_FREE_LEAD = "ERROR_CHECK_FREE_LEAD";
export const SET_LEAD_ID = "SET_LEAD_ID";
export const REQUEST_LEADS = "REQUEST_LEADS";
export const GO_TO_NEXT_LEAD = "GO_TO_NEXT_LEAD";

// GOOGLE DRIVE CONSTANTS

export const RECEIVE_DRIVE_DOCS_REQUEST = "RECEIVE_DRIVE_DOCS_REQUEST";
export const RECEIVE_DRIVE_DOCS_SUCCESS = "RECEIVE_DRIVE_DOCS_SUCCESS";
export const RECEIVE_DRIVE_DOCS_ERROR = "RECEIVE_DRIVE_DOCS_ERROR";

export const ADD_NEW_FILES_REQUEST = "ADD_NEW_FILES_REQUEST";
export const ADD_NEW_FILES_SUCCESS = "ADD_NEW_FILES_SUCCESS";
export const ADD_NEW_FILES_ERROR = "ADD_NEW_FILES_ERROR";

export const SEND_NEW_FILES_REQUEST = "SEND_NEW_FILES_REQUEST";
export const SEND_NEW_FILES_SUCCESS = "SEND_NEW_FILES_SUCCESS";
export const SEND_NEW_FILES_ERROR = "SEND_NEW_FILES_ERROR";

// SEARCH CONSTANTS

export const SEARCH_RECEIVE_CITY_REQUEST = "SEARCH_RECEIVE_CITY_REQUEST";
export const SEARCH_RECEIVE_CITY_SUCCESS = "SEARCH_RECEIVE_CITY_SUCCESS";
export const SEARCH_RECEIVE_CITY_ERROR = "SEARCH_RECEIVE_CITY_ERROR";

export const SEARCH_RECEIVE_BY_NAME_REQUEST = "SEARCH_RECEIVE_BY_NAME_REQUEST";
export const SEARCH_RECEIVE_BY_NAME_SUCCESS = "SEARCH_RECEIVE_BY_NAME_SUCCESS";
export const SEARCH_RECEIVE_BY_NAME_ERROR = "SEARCH_RECEIVE_BY_NAME_ERROR";

export const SEARCH_RECEIVE_BY_PHONE_REQUEST =
  "SEARCH_RECEIVE_BY_PHONE_REQUEST";
export const SEARCH_RECEIVE_BY_PHONE_SUCCESS =
  "SEARCH_RECEIVE_BY_PHONE_SUCCESS";
export const SEARCH_RECEIVE_BY_PHONE_ERROR = "SEARCH_RECEIVE_BY_PHONE_ERROR";

export const SEARCH_RECEIVE_COMPANY_REQUEST = "SEARCH_RECEIVE_COMPANY_REQUEST";
export const SEARCH_RECEIVE_COMPANY_SUCCESS = "SEARCH_RECEIVE_COMPANY_SUCCESS";
export const SEARCH_RECEIVE_COMPANY_ERROR = "SEARCH_RECEIVE_COMPANY_ERROR";

export const SET_QUERY_COMPANY_REQUEST = "SET_QUERY_COMPANY_REQUEST";
export const SET_QUERY_COMPANY_SUCCESS = "SET_QUERY_COMPANY_SUCCESS";
export const SET_QUERY_COMPANY_ERROR = "SET_QUERY_COMPANY_ERROR";

export const SEARCH_RECEIVE_OBJECT_REQUEST = "SEARCH_RECEIVE_OBJECT_REQUEST";
export const SEARCH_RECEIVE_OBJECT_SUCCESS = "SEARCH_RECEIVE_OBJECT_SUCCESS";
export const SEARCH_RECEIVE_OBJECT_ERROR = "SEARCH_RECEIVE_OBJECT_ERROR";

export const SEARCH_SET_EMPTY_OBJECT_REQUEST =
  "SEARCH_SET_EMPTY_OBJECT_REQUEST";
export const SEARCH_SET_EMPTY_OBJECT_SUCCESS =
  "SEARCH_SET_EMPTY_OBJECT_SUCCESS";
export const SEARCH_SET_EMPTY_OBJECT_ERROR = "SEARCH_SET_EMPTY_OBJECT_ERROR";

export const SEARCH_RECEIVE_PERSON_REQUEST = "SEARCH_RECEIVE_PERSON_REQUEST";
export const SEARCH_RECEIVE_PERSON_SUCCESS = "SEARCH_RECEIVE_PERSON_SUCCESS";
export const SEARCH_RECEIVE_PERSON_ERROR = "SEARCH_RECEIVE_PERSON_ERROR";

export const SEARCH_SET_EMPTY_PERSON_REQUEST =
  "SEARCH_SET_EMPTY_PERSON_REQUEST";
export const SEARCH_SET_EMPTY_PERSON_SUCCESS =
  "SEARCH_SET_EMPTY_PERSON_SUCCESS";
export const SEARCH_SET_EMPTY_PERSON_ERROR = "SEARCH_SET_EMPTY_PERSON_ERROR";

export const SEARCH_RECEIVE_STREET_REQUEST = "SEARCH_RECEIVE_STREET_REQUEST";
export const SEARCH_RECEIVE_STREET_SUCCESS = "SEARCH_RECEIVE_STREET_SUCCESS";
export const SEARCH_RECEIVE_STREET_ERROR = "SEARCH_RECEIVE_STREET_ERROR";

export const SEARCH_RECEIVE_STREET_LIST_REQUEST =
  "SEARCH_RECEIVE_STREET_LIST_REQUEST";
export const SEARCH_RECEIVE_STREET_LIST_SUCCESS =
  "SEARCH_RECEIVE_STREET_LIST_SUCCESS";
export const SEARCH_RECEIVE_STREET_LIST_ERROR =
  "SEARCH_RECEIVE_STREET_LIST_ERROR";

export const SEARCH_SET_EMPTY_STREET_REQUEST =
  "SEARCH_SET_EMPTY_STREET_REQUEST";
export const SEARCH_SET_EMPTY_STREET_SUCCESS =
  "SEARCH_SET_EMPTY_STREET_SUCCESS";
export const SEARCH_SET_EMPTY_STREET_ERROR = "SEARCH_SET_EMPTY_STREET_ERROR";

export const SEARCH_RECEIVE_SETTINGS_REQUEST =
  "SEARCH_RECEIVE_SETTINGS_REQUEST";
export const SEARCH_RECEIVE_SETTINGS_SUCCESS =
  "SEARCH_RECEIVE_SETTINGS_SUCCESS";
export const SEARCH_RECEIVE_SETTINGS_ERROR = "SEARCH_RECEIVE_SETTINGS_ERROR";

export const SEARCH_EDIT_SETTINGS_REQUEST = "SEARCH_EDIT_SETTINGS_REQUEST";
export const SEARCH_EDIT_SETTINGS_SUCCESS = "SEARCH_EDIT_SETTINGS_SUCCESS";
export const SEARCH_EDIT_SETTINGS_ERROR = "SEARCH_EDIT_SETTINGS_ERROR";

export const SET_QUERY_CITY = "SET_QUERY_CITY";

// LPS CONSTANTS

export const RUN_PROCESSING_LPS_REQUEST = "RUN_PROCESSING_LPS_REQUEST";
export const FINISH_PROCESSING_LPS_SUCCESS = "FINISH_PROCESSING_LPS_SUCCESS";
export const FINISH_PROCESSING_LPS_ERROR = "FINISH_PROCESSING_LPS_ERROR";

export const RECEIVE_LPS_CONFIGS_REQUEST = "RECEIVE_LPS_CONFIGS_REQUEST";
export const RECEIVE_LPS_CONFIGS_SUCCESS = "RECEIVE_LPS_CONFIGS_SUCCESS";
export const RECEIVE_LPS_CONFIGS_ERROR = "RECEIVE_LPS_CONFIGS_ERROR";

export const EDIT_LPS_CONFIGS_REQUEST = "EDIT_LPS_CONFIGS_REQUEST";
export const EDIT_LPS_CONFIGS_SUCCESS = "EDIT_LPS_CONFIGS_SUCCESS";
export const EDIT_LPS_CONFIGS_ERROR = "EDIT_LPS_CONFIGS_ERROR";

export const DELETE_LPS_CONFIGS_REQUEST = "DELETE_LPS_CONFIGS_REQUEST ";
export const DELETE_LPS_CONFIGS_SUCCESS = "DELETE_LPS_CONFIGS_SUCCESS";
export const DELETE_LPS_CONFIGS_ERROR = "DELETE_LPS_CONFIGS_ERROR";

export const RUN_PROCESSING_LPS = "RUN_PROCESSING_LPS";
export const FINISH_PROCESSING_LPS = "FINISH_PROCESSING_LPS";
export const ERROR_PROCESSING_LPS = "ERROR_PROCESSING_LPS";

export const RECEIVE_LPS_LEAD = "RECEIVE_LPS_LEAD";
export const ERROR_FETCH_LPS_LEAD = "ERROR_FETCH_LPS_LEAD";

export const RECEIVE_SETTINGS = "RECEIVE_SETTINGS";
export const ERROR_SETTINGS = "ERROR_SETTINGS";

export const RECEIVE_LPSCONFIGS = "RECEIVE_LPSCONFIGS";
export const ERROR_LPSCONFIGS = "ERROR_LPSCONFIGS";

export const SET_LPS_ITEM_INDEX = "SET_LPS_ITEM_INDEX";
export const SHOW_THE_BEST = "SHOW_THE_BEST";

export const NEXT_LPS_ITEM = "NEXT_LPS_ITEM";
export const PREV_LPS_ITEM = "PREV_LPS_ITEM";

export const SHOW_SCHEDULED = "SHOW_SCHEDULED";
export const SHOW_ASSIGNED = "SHOW_ASSIGNED";
export const SET_CURRENT_USER_ID = "SET_CURRENT_USER_ID";
export const RECALCULATE_DEFAULT_PROFILE = "RECALCULATE_DEFAULT_PROFILE";
export const FIND_LEAD_FLAG = "FIND_LEAD_FLAG";
export const SKIP_UNASSIGNED_LEADS_IN_LPS_RATING =
  "SKIP_UNASSIGNED_LEADS_IN_LPS_RATING";

export const CHECK_LPS_USAGE_REQUEST = "CHECK_LPS_USAGE_REQUEST";
export const CHECK_LPS_USAGE_SUCCESS = "CHECK_LPS_USAGE_SUCCESS";
export const CHECK_LPS_USAGE_ERROR = "CHECK_LPS_USAGE_ERROR";

// PDF CONSTANTS

export const SAVE_PDF_REQUEST = "SAVE_PDF_REQUEST";
export const SAVE_PDF_SUCCESS = "SAVE_PDF_SUCCESS";
export const SAVE_PDF_ERROR = "SAVE_PDF_ERROR";

export const SEND_PDF_REQUEST = "SEND_PDF_REQUEST";
export const SEND_PDF_SUCCESS = "SEND_PDF_SUCCESS";
export const SEND_PDF_ERROR = "SEND_PDF_ERROR";

export const PING_SAVING_PDF_REQUEST = "PING_SAVING_PDF_REQUEST";
export const PING_SAVING_PDF_SUCCESS = "PING_SAVING_PDF_SUCCESS";
export const PING_SAVING_PDF_ERROR = "PING_SAVING_PDF_ERROR";

export const CANCEL_PDF_REQUEST = "CANCEL_PDF_REQUEST";
export const CANCEL_PDF_SUCCESS = "CANCEL_PDF_SUCCESS";
export const CANCEL_PDF_ERROR = "CANCEL_PDF_ERROR";

export const CHECK_PDF_USAGE_REQUEST = "CHECK_PDF_USAGE_REQUEST";
export const CHECK_PDF_USAGE_SUCCESS = "CHECK_PDF_USAGE_SUCCESS";
export const CHECK_PDF_USAGE_ERROR = "CHECK_PDF_USAGE_ERROR";

// export const SAVE_PDF_AND_NEW_ENTITY_REQUEST = "SAVE_PDF_AND_NEW_ENTITY_REQUEST";
export const CANCEL_PDF = "CANCEL_PDF";

// QUERY OBJECTS CONSTANTS

export const QUERY_OBJECTS_SAVE_CONFIG_REQUEST =
  "QUERY_OBJECTS_SAVE_CONFIG_REQUEST";
export const QUERY_OBJECTS_SAVE_CONFIG_SUCCESS =
  "QUERY_OBJECTS_SAVE_CONFIG_SUCCESS";
export const QUERY_OBJECTS_SAVE_CONFIG_ERROR =
  "QUERY_OBJECTS_SAVE_CONFIG_ERROR";

export const QUERY_OBJECTS_RECEIVE_CONFIGS_REQUEST =
  "QUERY_OBJECTS_RECEIVE_CONFIGS_REQUEST";
export const QUERY_OBJECTS_RECEIVE_CONFIGS_SUCCESS =
  "QUERY_OBJECTS_RECEIVE_CONFIGS_SUCCESS";
export const QUERY_OBJECTS_RECEIVE_CONFIGS_ERROR =
  "QUERY_OBJECTS_RECEIVE_CONFIGS_ERROR";

export const QUERY_OBJECTS_DELETE_CONFIG_REQUEST =
  "QUERY_OBJECTS_DELETE_CONFIG_REQUEST";
export const QUERY_OBJECTS_DELETE_CONFIG_SUCCESS =
  "QUERY_OBJECTS_DELETE_CONFIG_SUCCESS";
export const QUERY_OBJECTS_DELETE_CONFIG_ERROR =
  "QUERY_OBJECTS_DELETE_CONFIG_ERROR";

export const QUERY_OBJECTS_GET_RESULTS_REQUEST =
  "QUERY_OBJECTS_GET_RESULTS_REQUEST";
export const QUERY_OBJECTS_GET_RESULTS_SUCCESS =
  "QUERY_OBJECTS_GET_RESULTS_SUCCESS";
export const QUERY_OBJECTS_GET_RESULTS_ERROR =
  "QUERY_OBJECTS_GET_RESULTS_ERROR";

export const QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST =
  "QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST";
export const QUERY_OBJECTS_GET_PREV_RESULTS_LIST_SUCCESS =
  "QUERY_OBJECTS_GET_PREV_RESULTS_LIST_SUCCESS";
export const QUERY_OBJECTS_GET_PREV_RESULTS_LIST_ERROR =
  "QUERY_OBJECTS_GET_PREV_RESULTS_LIST_ERROR";

export const QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST =
  "QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST";
export const QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_SUCCESS =
  "QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_SUCCESS";
export const QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_ERROR =
  "QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_ERROR";
