import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Paper from "@material-ui/core/es/Paper/Paper";
import DialogWrapper from "../../../../../common/components/dialog/DialogWrapper";
import QueryCitySearch from "../../search";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import FieldEditor from "../../../../../common/components/editor/field/FieldEditor";
import { INPUT, INPUT_NUMBER } from "../../../../../common/constants/field-types";
import Container from "../../../../../common/components/editor/entity/Container";
import Clear from "@material-ui/icons/Clear";

class LocationFilter extends Component {
    state = {
        cityEditMode: true,
        postcodeEditMode: true,
        streetEditMode: true,
        openSearchByCity: false,
        openSearchByPostCode: false,
        openSearchByStreetName: false,
        type: "",
        postcode: "",
        street: "",
        city: {
            cityName: "",
            cityCode: "",
            section: "",
            plotNumber: "",
        },
        index: "",
    };

    clearIndex = () => {
        this.setState({
            index: "",
        });
    };

    toggleMode = type => {
        if (type === "city") {
            const { cityEditMode } = this.state;
            this.setState({ cityEditMode: !cityEditMode });
        }
        if (type === "postcode") {
            const { postcodeEditMode } = this.state;
            this.setState({ postcodeEditMode: !postcodeEditMode });
        }
        if (type === "street") {
            const { streetEditMode } = this.state;
            this.setState({ streetEditMode: !streetEditMode });
        }
    };

    handleOpenDialog = (type, index) => {
        const { locationFilter } = this.props;
        const { cities, postcodes, streets } = locationFilter;
        if (type === "city") {
            this.setState({
                openSearchByCity: true,
                type: "city",
            });
        }
        if (type === "postcode") {
            this.setState({
                openSearchByPostCode: true,
                type: "postcode",
            });
        }
        if (type === "street") {
            this.setState({
                openSearchByStreetName: true,
                type: "street",
            });
        }
        if (type === "cityEditMode") {
            const selectedCity = cities[index];
            this.setState({
                openSearchByCity: true,
                type: "city",
                city: selectedCity,
                index: index,
            });
        }
        if (type === "postcodeEditMode") {
            const selectedPostcode = postcodes[index];
            this.setState({
                openSearchByPostCode: true,
                type: "postcode",
                postcode: selectedPostcode,
                index: index,
            });
        }
        if (type === "streetEditMode") {
            const selectedStreet = streets[index];
            this.setState({
                openSearchByStreetName: true,
                type: "street",
                street: selectedStreet,
                index: index,
            });
        }
    };

    handleChange = (type, e) => {
        const { target } = e;
        const { city } = this.state;

        if (type === "postcode") {
            this.setState({
                postcode: target.value,
            });
        }
        if (type === "street") {
            this.setState({
                street: target.value,
            });
        }
        if (type === "city_code") {
            this.setState({
                city: {
                    ...city,
                    cityCode: target.value,
                },
            });
        }
        if (type === "section") {
            this.setState({
                city: {
                    ...city,
                    section: target.value,
                },
            });
        }
        if (type === "plot_number") {
            this.setState({
                city: {
                    ...city,
                    plotNumber: target.value,
                },
            });
        }
    };

    clearCity = () => {
        this.setState({
            city: {
                cityName: "",
                cityCode: "",
                section: "",
                plotNumber: "",
            },
        });
    };

    handleAddCity = cityName => {
        const { city } = this.state;
        this.setState({
            city: {
                ...city,
                cityName: cityName,
            },
        });
    };

    handleAddPostcode = () => {
        const { addFilter } = this.props;
        const { postcode, index } = this.state;
        addFilter("locationFilter", "postcode", postcode, index);
        this.clearIndex();
    };

    handleAddStreet = () => {
        const { addFilter } = this.props;
        const { street, index } = this.state;
        addFilter("locationFilter", "street", street, index);
        this.clearIndex();
    };

    closeDialog = (type) => {
        if (type === "closeCityDialog") {
            this.setState({
                openSearchByCity: false,
                city: "",
            });
        }
        if (type === "closePostCodeDialog") {
            this.setState({
                openSearchByPostCode: false,
                postcode: "",
            });
        }
        if (type === "closeStreetDialog") {
            this.setState({
                openSearchByStreetName: false,
                street: "",
            });
        }
    };

    render() {
        const {
            classes,
            clearFilterProperty,
            locationFilter,
            handleAddCities,
        } = this.props;

        const {
            cities,
            postcodes,
            streets,
        } = locationFilter;

        const {
            openSearchByCity,
            openSearchByPostCode,
            openSearchByStreetName,
            type,
            cityEditMode,
            postcodeEditMode,
            streetEditMode,
            city,
            postcode,
            street,
            index,
        } = this.state;

        const { cityName, cityCode, section, plotNumber } = city;

        return (
            <React.Fragment>
                <Paper>
                    <Typography className={classes.head} variant="subtitle1">
                        Location filter
                    </Typography>
                    <Divider className={classes.divider}/>
                    <div className={classes.body}>
                        <div className={classes.row}>
                            <div className={classes.container}>
                                <Typography variant="body2">Filter by city</Typography>
                                <Switch
                                    className={classes.switch}
                                    checked={cityEditMode}
                                    onChange={() => this.toggleMode("city")}
                                    color="secondary"/>
                            </div>
                            {cityEditMode && cities && cities.map((city, index) => {
                                return (
                                    <div className={classes.container} key={index}>
                                        <Typography className={classes.example} variant="body2">
                                            {city.cityName}
                                        </Typography>
                                        <div className={classes.buttonContainer}>
                                            <IconButton
                                                onClick={() => this.handleOpenDialog("cityEditMode", index)}
                                                className={classes.button}
                                            >
                                                <CreateIcon fontSize="small"/>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => clearFilterProperty("city", index)}
                                                aria-label="Delete"
                                                className={classes.button}
                                            >
                                                <DeleteIcon fontSize="small"/>
                                            </IconButton>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className={classes.buttonWrapper}>
                                <Button
                                    onClick={() => this.handleOpenDialog("city")}
                                    variant="outlined"
                                    size="small"
                                    disabled={!cityEditMode}
                                >
                                    + add City
                                </Button>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.container}>
                                <Typography variant="body2">Filter by postcode</Typography>
                                <Switch
                                    className={classes.switch}
                                    checked={postcodeEditMode}
                                    onChange={() => this.toggleMode("postcode")}
                                    color="secondary"/>
                            </div>
                            <div className={classes.container2}>
                                {postcodeEditMode && postcodes && postcodes.map((postcode, index) => {
                                    return (
                                        <div className={classes.subContainer} key={index}>
                                            <Typography className={classes.example} variant="body2">
                                                {postcode}
                                            </Typography>
                                            <div className={classes.buttonContainer}>
                                                <IconButton
                                                    onClick={() => this.handleOpenDialog("postcodeEditMode", index)}
                                                    className={classes.button}
                                                >
                                                    <CreateIcon fontSize="small"/>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => clearFilterProperty("postcode", index)}
                                                    aria-label="Delete"
                                                    className={classes.button}
                                                >
                                                    <DeleteIcon fontSize="small"/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={classes.buttonWrapper}>
                                <Button
                                    onClick={() => this.handleOpenDialog("postcode")}
                                    variant="outlined"
                                    size="small"
                                    disabled={!postcodeEditMode}
                                >
                                    + add Postcode
                                </Button>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.container}>
                                <Typography variant="body2">Filter by street</Typography>
                                <Switch
                                    className={classes.switch}
                                    checked={streetEditMode}
                                    onChange={() => this.toggleMode("street")}
                                    color="secondary"/>
                            </div>
                            <div className={classes.container2}>
                                {cityEditMode && streets && streets.map((street, index) => {
                                    return (
                                        <div className={classes.subContainer} key={index}>
                                            <Typography className={classes.example} variant="body2">
                                                {street}
                                            </Typography>
                                            <div className={classes.buttonContainer}>
                                                <IconButton
                                                    onClick={() => this.handleOpenDialog("streetEditMode", index)}
                                                    className={classes.button}
                                                >
                                                    <CreateIcon fontSize="small"/>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => clearFilterProperty("street", index)}
                                                    aria-label="Delete"
                                                    className={classes.button}
                                                >
                                                    <DeleteIcon fontSize="small"/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={classes.buttonWrapper}>
                                <Button
                                    onClick={() => this.handleOpenDialog("street")}
                                    variant="outlined"
                                    size="small"
                                    disabled={!streetEditMode}
                                >
                                    + add Street
                                </Button>
                            </div>
                        </div>
                    </div>
                </Paper>

                {type === "city" && (
                    <DialogWrapper
                        open={openSearchByCity}
                        onClose={() => this.closeDialog("closeCityDialog")}
                        handleClick={() => {
                            handleAddCities(city, index);
                            this.clearIndex();
                            this.closeDialog("closeCityDialog");
                        }}
                        title="Find city"
                        buttonText="Add address filters"
                    >
                        <div className={classes.searchWrapper}>
                            <QueryCitySearch
                                addCity={this.handleAddCity}
                            />
                            {cityName && <Grid
                                container
                                spacing={16}
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={classes.cityContainer}
                                    >
                                        {cityName}
                                        <Clear
                                            className={classes.cityRemoveButton}
                                            onClick={() => this.clearCity()}
                                            fontSize="small"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>}
                            <div className={classes.wrapper2}>
                                <div className={classes.table}>
                                    <Container
                                        leftColumn={[
                                            <FieldEditor
                                                type={INPUT}
                                                name="city_code"
                                                label="City code"
                                                value={cityCode || ""}
                                                onChange={e => this.handleChange("city_code", e)}
                                            />,
                                            <FieldEditor
                                                type={INPUT}
                                                name="section"
                                                label="Section"
                                                value={section || ""}
                                                onChange={e => this.handleChange("section", e)}
                                            />,
                                        ]}
                                        rightColumn={[
                                            <FieldEditor
                                                type={INPUT_NUMBER}
                                                name="plot_number"
                                                label="Plot number"
                                                onChange={e => this.handleChange("plot_number", e)}
                                                value={plotNumber || ""}
                                            />,
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogWrapper>
                )}
                {type === "postcode" && (
                    <DialogWrapper
                        open={openSearchByPostCode}
                        onClose={() => this.closeDialog("closePostCodeDialog")}
                        handleClick={() => {
                            this.handleAddPostcode();
                            this.closeDialog("closePostCodeDialog");
                        }}
                        title="Add postcode"
                        buttonText="Add"
                    >
                        <div className={classes.searchWrapper}>
                            <FieldEditor
                                type={INPUT}
                                name="postcode"
                                label="Postcode"
                                value={postcode || ""}
                                onChange={e => this.handleChange("postcode", e)}
                            />
                        </div>
                    </DialogWrapper>
                )}
                {type === "street" && (
                    <DialogWrapper
                        open={openSearchByStreetName}
                        onClose={() => this.closeDialog("closeStreetDialog")}
                        handleClick={() => {
                            this.handleAddStreet();
                            this.closeDialog("closeStreetDialog");
                        }}
                        title="Add street"
                        buttonText="Add"
                    >
                        <div className={classes.searchWrapper}>
                            <FieldEditor
                                type={INPUT}
                                name="street"
                                label="Street name"
                                value={street || ""}
                                onChange={e => this.handleChange("street", e)}
                            />
                        </div>
                    </DialogWrapper>
                )}
            </React.Fragment>
        );
    }
}

LocationFilter.propTypes = {};

const styles = theme => ({
    cityContainer: {
        display: "inline-flex",
        alignItems: "center",
        color: theme.palette.primary[500],
        padding: "0 4px",
        marginTop: 6,
    },
    cityRemoveButton: {
        marginLeft: 2,
        "&:hover": {
            color: theme.palette.secondary.error,
        },
    },
    panel: {
        marginTop: 15,
    },
    wrapper: {
        height: "700px",
    },
    wrapper2: {
        height: "130px",
    },
    searchWrapper: {
        minWidth: 550,
    },
    row: {
        padding: 8,
    },
    head: {
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary[400],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    example: {
        color: theme.palette.primary.grayFont,
        marginLeft: 8,
    },
    divider: {
        width: "100%",
        backgroundColor: theme.palette.primary.divider,
    },
    switch: {
        display: "flex",
        margin: "-10px 0",
    },
    button: {
        padding: 8,
        marginRight: 5,
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    container2: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
    },
    subContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    buttonContainer: {
        width: "30%",
    },
    body: {
        height: "260px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

export default connect(state => ({
    ...state,
}))(withStyles(styles)(LocationFilter));
