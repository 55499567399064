import { all } from "redux-saga/effects";
import actionSearchByCity from "./city";
import actionSearchByCompany from "./company";
import actionSearchLPS from "./lps";
import actionSearchByName from "./name";
import actionSearchByObject from "./object";
import actionSearchByPerson from "./person";
import actionSearchSettings from "./settings";
import actionSearchByStreet from "./street";
import actionSearchByPhone from "./phone";

export default function* actionSearch() {
    yield all([
        actionSearchByCity(),
        actionSearchByCompany(),
        actionSearchLPS(),
        actionSearchByName(),
        actionSearchByObject(),
        actionSearchByPerson(),
        actionSearchSettings(),
        actionSearchByStreet(),
        actionSearchByPhone(),
    ]);
}
