import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const NoData = ({ classes, text, button }) => {
    return (
        <div className={classes.root}>
            <Typography variant="h4">{text || "NO DATA"}</Typography>
            {button}
        </div>
    );
};

NoData.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string,
};

const styles = () => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 100,
    },
});

export default withStyles(styles)(NoData);
