import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import DisplayEntity from "../common/DisplayEntity";
import deepPurple from "@material-ui/core/colors/deepPurple";
import DialogWrapper from "../../../../common/components/dialog";
import { EntityEditor } from "../../../../common/components/editor/entity";
import { getPersonName } from "../../../../common/helpers/getter";
import { crossSign } from "../../../../common/constants/symbols";
import { selectSearch } from "../../../search/selectors";
import { updateEntityRequest, destroyEntityRequest } from "../../actions";
import {
  leadgenDependentEntitiesRequest,
  setTempAssignedRequest,
} from "../../../leadgen/actions/dependentEntities";
import {
  leadgenObjectsByOwnerRequest,
  getObjectsAmount,
} from "../../../leadgen/actions/objects";
import { validate, validateKadID } from "../../../../common/helpers/validator";
import { selectEntity } from "../../selectors";

class Entity extends Component {
  state = {
    anchorElEntity: null,
    openDialog: {
      editEntity: false,
      deleteEntity: false,
    },
    entity: {},
    action_types: [],
    deleteMode: false,
  };

  componentDidUpdate(prevProps) {
    const { dispatch, currentEntity } = this.props;
    const { entity, destroyEntity } = currentEntity;
    const action_types = ["temp_assigned"];

    const currState = {
      temporarily_assigned: false,
    };

    const nextState = {
      temporarily_assigned: true,
    };

    if (entity !== prevProps.currentEntity.entity) {
      this.setState({
        entity: {
          ...entity,
          addressesForDelete: [],
          phoneNumbersForDelete: [],
          emailsForDelete: [],
          socialsForDelete: [],
        },
      });

      if (prevProps.currentEntity.entity.id && !destroyEntity.entityIsDeleted) {
        dispatch(
          setTempAssignedRequest(
            prevProps.currentEntity.entity.id,
            currState.temporarily_assigned,
            action_types
          )
        );
      }
      !destroyEntity.entityIsDeleted &&
        dispatch(
          setTempAssignedRequest(
            entity.id,
            nextState.temporarily_assigned,
            action_types
          )
        );
    }
  }

  componentWillUnmount() {
    const { dispatch, currentEntity } = this.props;
    const { entity, destroyEntity } = currentEntity;
    const action_types = ["temp_assigned"];
    const currState = {
      temporarily_assigned: false,
    };
    !destroyEntity.entityIsDeleted &&
      dispatch(
        setTempAssignedRequest(
          entity.id,
          currState.temporarily_assigned,
          action_types
        )
      );
  }

  handleOpenMenu = (e) => {
    this.setState({ anchorElEntity: e.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorElEntity: null });
  };

  handleOpenDialog = (actionType) => () => {
    const { openDialog } = this.state;

    const newState = {
      openDialog: { ...openDialog, [actionType]: true },
      anchorElEntity: null,
    };
    this.setState(newState);
  };

  handleCloseDialog = (actionType, personType) => () => {
    const { openDialog, entity } = this.state;
    const newState = {
      openDialog: { ...openDialog, [actionType]: false },
      entity: entity,
    };

    if (personType) newState[personType] = null;

    this.setState(newState);
  };

  handleValidate = async (entityType, entity) => {
    const isValidated = validate(entityType, { ...entity });
    let isExistedKadID = false;
    const kad_id = entity.kadaster_personal_number;

    if (kad_id) {
      await validateKadID(
        "entity",
        { kad_id, id: entity.id },
        (data) => (isExistedKadID = data)
      );
    }

    return isValidated && !isExistedKadID;
  };

  handleSave = (actionType, personType) => async () => {
    const { dispatch, router } = this.props;
    const { openDialog, entity, action_types, deleteMode } = this.state;

    const newState = {
      openDialog: { ...openDialog, [actionType]: false, deleteMode: false },
    };

    if (personType) newState[personType] = null;

    switch (actionType) {
      case "deleteEntity":
        dispatch(destroyEntityRequest(entity.id, router));
        return this.setState(newState);

      case "editEntity":
        if (
          !(await this.handleValidate(entity.entity_type, {
            ...entity,
          }))
        ) {
          return;
        } else break;

      default:
        return;
    }

    this.setState(newState);
    dispatch(
      updateEntityRequest(
        deleteMode,
        action_types,
        entity.id,
        entity,
        router,
        () => dispatch(leadgenDependentEntitiesRequest(entity.id))
      )
    );
    dispatch(leadgenObjectsByOwnerRequest(entity.id));
    dispatch(getObjectsAmount(entity.id));
  };

  handleChangeEntity = (name, e, deleteMode, deletedItem) => {
    // console.log('name ', name);
    // console.log('deleteMode ', deleteMode);
    // console.log('deletedItem ', deletedItem);
    const { entity, action_types } = this.state;
    const {
      addressesForDelete,
      phoneNumbersForDelete,
      emailsForDelete,
      socialsForDelete,
    } = entity;
    const { target } = e;
    const default_type = "general_fields";

    if(!deleteMode) {
      switch (name) {
        case "phone_numbers":
        case "addresses":
        case "socials":
        case "emails":
          return this.setState({
            action_types: action_types.includes(name)
              ? action_types
              : [name, ...action_types],
            entity: {
              ...entity,
              [name]: target.value,
            },
          });
        default:
          this.setState({
            action_types: action_types.includes(default_type)
              ? action_types
              : [name, ...action_types],
            entity: {
              ...entity,
              [name]: target.value,
            },
          });
          break;
      }
    }

    if (name === "phone_numbers" && deleteMode && deletedItem[0].id) {
      return this.setState({
        deleteMode: deleteMode,
        action_types: action_types.includes(name)
          ? action_types
          : [name, ...action_types],

        entity: {
          ...entity,
          [name]: target.value,
          phoneNumbersForDelete: [deletedItem[0].id, ...phoneNumbersForDelete],
        },
      });
    }

    if (name === "addresses" && deleteMode && deletedItem[0].id) {
      return this.setState({
        deleteMode: deleteMode,
        action_types: action_types.includes(name)
          ? action_types
          : [name, ...action_types],
        entity: {
          ...entity,
          [name]: target.value,
          addressesForDelete: [deletedItem[0].id, ...addressesForDelete],
        },
      });
    }

    if (name === "socials" && deleteMode && deletedItem[0].id) {
      return this.setState({
        deleteMode: deleteMode,
        action_types: action_types.includes(name)
          ? action_types
          : [name, ...action_types],
        entity: {
          ...entity,
          [name]: target.value,
          socialsForDelete: [deletedItem[0].id, ...socialsForDelete],
        },
      });
    }

    if (name === "emails" && deleteMode && deletedItem[0].id) {
      return this.setState({
        deleteMode: deleteMode,
        action_types: action_types.includes(name)
          ? action_types
          : [name, ...action_types],
        entity: {
          ...entity,
          [name]: target.value,
          emailsForDelete: [deletedItem[0].id, ...emailsForDelete],
        },
      });
    }

    const link =
      "https://kadaster-on-line.kadaster.nl/selectie.asp?identificatie=NL.IMKAD.Persoon.";

    const kvkLink = "https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer=";

    if (name === "kadaster_personal_number") {
      this.setState({
        action_types: action_types,
        entity: {
          ...entity,
          [name]: target.value,
          kadaster_url: target.value ? link + target.value : null,
        },
      });
      return null;
    }

    if (name === "chamber_commerce_number") {
      return this.setState({
        action_types: action_types,
        entity: {
          ...entity,
          [name]: target.value,
          chamber_commerce_url: target.value ? kvkLink + target.value : null,
        },
      });
    }

    this.setState({
      action_types: action_types.includes(default_type)
        ? action_types
        : [default_type, ...action_types],
      entity: {
        ...entity,
        [name]: target.value,
      },
    });
  };

  render() {
    let entityTitle = "";
    let avatarEntity = "";
    const { entity, anchorElEntity, openDialog } = this.state;
    const { lps, classes, validationMode, deleteLinkedCompany } = this.props;
    const entityType = entity.entity_type || null;
    const entityIsDead = entity.is_dead || null;
    const { lpcData } = lps;
    const lpc = lpcData.lpc || [];
    let leadPercentage;
    if (lpc) {
      lpc.forEach((i) => {
        if (i.id === entity.id) {
          leadPercentage = i.percentage;
        }
      });
    }

    if (entity) {
      if (entity.company_name) {
        entityTitle =
          entity.company_name.length > 15
            ? entity.company_name.slice(0, 15) + "..."
            : entity.company_name;

        avatarEntity = leadPercentage || entity.company_name[0].toUpperCase();
      } else {
        entityTitle = getPersonName(entity, true, true);

        avatarEntity =
          leadPercentage ||
          `${(entity.last_name && entity.last_name[0].toUpperCase()) || ""}${
            (entity.initials && entity.initials[0].toUpperCase()) || ""
          }`;
      }
    }

    return (
      <div>
        <div
          className={classes.wrapperAvatar}
          onClick={(e) => this.handleOpenMenu(e)}
        >
          <Avatar className={avatarEntity ? classes.avatarName : classes.image}>
            {avatarEntity || <Person />}
          </Avatar>
          <ListItemText
            className={classes.itemText}
            primary={
              <Typography className={classes.text} variant="body2">
                {entityType &&
                  entityType[0].toUpperCase() + entityType.slice(1)}
              </Typography>
            }
            secondary={
              entityTitle ? (
                <span>
                  {entityTitle}
                  {entityIsDead && <span>({crossSign})</span>}
                </span>
              ) : (
                "n/a"
              )
            }
          />
        </div>
        <Menu
          id={anchorElEntity}
          anchorEl={anchorElEntity}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transitionDuration={0}
          disableAutoFocusItem
          className={classes.menu}
          open={Boolean(anchorElEntity)}
          onClose={this.handleCloseMenu}
          MenuListProps={{
            className: classes.menuList,
          }}
        >
          <DisplayEntity
            buttons={[
              { btnText: "edit", handler: this.handleOpenDialog("editEntity") },
              {
                btnText: "delete",
                handler: this.handleOpenDialog("deleteEntity"),
                styles: { color: "#d20000" },
              },
            ]}
            entity={entity || {}}
          />
        </Menu>
        <DialogWrapper
          open={openDialog.editEntity}
          onClose={this.handleCloseDialog("editEntity")}
          handleClick={this.handleSave("editEntity")}
          title="Edit Entity"
          buttonText="Save"
        >
          <EntityEditor
            validationMode={validationMode}
            entityType={entityType}
            editableEntity={entity}
            onChange={this.handleChangeEntity}
            deleteLinkedCompany={deleteLinkedCompany}
          />
        </DialogWrapper>
        <DialogWrapper
          open={openDialog.deleteEntity}
          onClose={this.handleCloseDialog("deleteEntity")}
          handleClick={this.handleSave("deleteEntity")}
          title="Delete Entity"
          buttonText="Delete"
        >
          <Typography className={classes.deleteBlock} variant="subtitle1">
            Are you sure you want to delete
            {entityTitle ? " " + entityTitle : " this entity"}?
          </Typography>
        </DialogWrapper>
      </div>
    );
  }
}

Entity.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  link: {
    textDecoration: "none",
  },
  wrapperAvatar: {
    display: "flex",
    padding: 5,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.14)",
      cursor: "pointer",
    },
  },
  text: {
    fontStyle: "italic",
  },
  menu: {
    top: 56,
  },
  menuList: {
    padding: "8px 0 0 0",
  },
  itemText: {
    marginRight: 0,
  },
  iconButton: {
    padding: 6,
  },
  image: {
    width: 45,
    height: 45,
    backgroundColor: theme.palette.primary[400],
  },
  avatarName: {
    width: 45,
    height: 45,
    backgroundColor: deepPurple[500],
    fontSize: "1rem",
  },
  deleteBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
});

const mapStateToProps = (state) => ({
  lps: selectSearch(state),
  currentEntity: selectEntity(state),
});
export default connect(mapStateToProps)(withStyles(styles)(React.memo(Entity)));
