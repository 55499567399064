import { combineReducers } from "redux";
import {
    CHECK_BEST_LEAD_REQUEST,
    CHECK_BEST_LEAD_SUCCESS,
    RECEIVE_DRIVE_DOCS_ERROR,
    RECEIVE_DRIVE_DOCS_REQUEST,
    RECEIVE_DRIVE_DOCS_SUCCESS,
    RECEIVE_LEADS_DATA_ERROR,
    RECEIVE_LEADS_DATA_REQUEST,
    RECEIVE_LEADS_DATA_SUCCESS,
} from "../../../common/constants";

const receiveLeads = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_LEADS_DATA_REQUEST:
        case RECEIVE_DRIVE_DOCS_REQUEST:
            return {
                isFetching: true,
            };
        case RECEIVE_LEADS_DATA_SUCCESS:
            return {
                isFetching: false,
                getLeads: action.leads,
            };

        default:
            return state;
    }
};

const receiveDriveFolderData = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_DRIVE_DOCS_SUCCESS:
            return {
                isFetching: false,
                folderData: action.folderData,
            };

        default:
            return state;
    }
};

const errorFetchLeads = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_LEADS_DATA_ERROR:
        case RECEIVE_DRIVE_DOCS_ERROR:
            return {
                isFetching: false,
                error: action.error,
            };

        default:
            return state;
    }
};

const checkBestLead = (state = {}, action) => {
    switch (action.type) {
        case CHECK_BEST_LEAD_REQUEST:
            return {
                isFetching: true,
            };
        case CHECK_BEST_LEAD_SUCCESS:
            return {
                isFetching: false,
                bestLead: action.bestLead,
            };

        default:
            return state;
    }
};

const leads = combineReducers({
    receive: receiveLeads,
    freeLead: checkBestLead,
    receiveDriveFolderData,
    error: errorFetchLeads,
});

export default leads;
