import { combineReducers } from "redux";
import createEntity from "./newEntity";
import createObject from "./newObject";

const newEntity = combineReducers({
  createEntity,
  createObject
});

export default newEntity;
