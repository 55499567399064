import AlertService from "../../../common/services/alert.service";
import ErrorHandler from "../../../common/services/error.handler";
import {
    CHECK_BEST_LEAD_REQUEST,
    CHECK_FREE_LEAD_REQUEST,
    RECEIVE_LEADS_DATA_REQUEST,
    RECEIVE_DRIVE_DOCS_REQUEST,
    CHECK_BEST_LEAD_SUCCESS,
    CHECK_FREE_LEAD_SUCCESS,
    RECEIVE_LEADS_DATA_SUCCESS,
    RECEIVE_DRIVE_DOCS_SUCCESS,
    CHECK_BEST_LEAD_ERROR,
    CHECK_FREE_LEAD_ERROR,
    RECEIVE_LEADS_DATA_ERROR,
    RECEIVE_DRIVE_DOCS_ERROR,
    REQUEST_LEADS,
    GO_TO_NEXT_LEAD,
} from "../../../common/constants";

export const getLeadsDataRequest = params => {
    return {
        type: RECEIVE_LEADS_DATA_REQUEST,
        params,
    };
};

export const receiveDriveDocsRequest = () => {
    return {
        type: RECEIVE_DRIVE_DOCS_REQUEST,
    };
};

export const receiveLeadsSuccess = leads => {
    AlertService.success("Received Leads");
    return {
        type: RECEIVE_LEADS_DATA_SUCCESS,
        leads: leads.data,
    };
};

export const receiveDocsSuccess = folderData => {
    return {
        type: RECEIVE_DRIVE_DOCS_SUCCESS,
        folderData: folderData.data,
    };
};

export const receiveLeadsError = error => {
    ErrorHandler.do(error);
    return {
        type: RECEIVE_LEADS_DATA_ERROR,
        error,
    };
};

export const receiveDocsError = error => {
    ErrorHandler.do(error);
    return {
        type: RECEIVE_DRIVE_DOCS_ERROR,
        error,
    };
};

export const checkBestLeadRequest = router => {
    return {
        type: CHECK_BEST_LEAD_REQUEST,
        router,
    };
};

export const goToNextLead = router => {
    return {
        type: GO_TO_NEXT_LEAD,
        router,
    };
};

export const checkBestLeadSuccess = (bestLead, leadIdIndex, router) => {
    return {
        type: CHECK_BEST_LEAD_SUCCESS,
        bestLead: bestLead.data,
        indexLPSItem: leadIdIndex,
        router,
    };
};

export const checkBestLeadError = error => {
    ErrorHandler.do(error);
    return {
        type: CHECK_BEST_LEAD_ERROR,
        error,
    };
};

export const checkFreeLeadRequest = () => {
    return {
        type: CHECK_FREE_LEAD_REQUEST,
    };
};

export const checkFreeLeadSuccess = freeLead => {
    return {
        type: CHECK_FREE_LEAD_SUCCESS,
        freeLead,
    };
};

export const checkFreeLeadError = error => {
    ErrorHandler.do(error);
    return {
        type: CHECK_FREE_LEAD_ERROR,
        error,
    };
};

//todo
export const requestLeads = () => ({
    type: REQUEST_LEADS,
});
