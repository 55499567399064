import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/es/Paper/Paper";
import FieldEditor from "../../../../../common/components/editor/field/FieldEditor";
import { INPUT_NUMBER, SELECT } from "../../../../../common/constants/field-types";

class ObjectFilter extends Component {

    get housingOptions() {
        return [ "housing", "office", "industrial", "others" ].map(i => ({ value: i, label: i }));
    }

    render() {
        const { classes, toggleMode, addFilter, objectFilter } = this.props;
        const {
            linkedEditMode,
            G0Switch,
            A1Switch,
            ownersAmountLessThen,
            ownersAmountMoreThen,
            unitsAmountLessThen,
            unitsAmountMoreThen,
            unitsSizeLessThen,
            unitsSizeMoreThen,
            objectIncludesFunction,
            objectExcludesFunction,
            priceLessThen,
            priceMoreThen,
        } = objectFilter;

        return (
            <React.Fragment>
                <Paper>
                    <Typography className={classes.head} variant="subtitle1">
                        Object filter
                    </Typography>
                    <Divider className={classes.divider}/>
                    <div className={classes.body}>
                        <div className={classes.row}>
                            <div className={classes.container}>
                                <Typography variant="body2">Linked to entity</Typography>
                                <Switch
                                    className={classes.switch}
                                    checked={linkedEditMode}
                                    onChange={() => toggleMode("objectFilter", "linkedEditMode")}
                                    color="secondary"/>
                            </div>
                            <div className={classes.container}>
                                <Typography variant="body2">G0 Object</Typography>
                                <Switch
                                    className={classes.switch}
                                    checked={G0Switch}
                                    onChange={() => toggleMode("objectFilter", "G0Switch")}
                                    color="secondary"/>
                            </div>
                            <div className={classes.container}>
                                <Typography variant="body2">A1 Object</Typography>
                                <Switch className={classes.switch}
                                        checked={A1Switch}
                                        onChange={() => toggleMode("objectFilter", "A1Switch")}
                                        color="secondary"/>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.block}>
                                 <span className={classes.inputText}>
                            <Typography
                                variant="subtitle1"
                                className={classes.condition}
                            >
                                Owners Amount:
                            </Typography>
                             </span>
                                <span className={classes.input}>
                                    <FieldEditor
                                        type={INPUT_NUMBER}
                                        name="from"
                                        label="from"
                                        onChange={e => addFilter("objectFilter", "ownersAmountMoreThen", e)}
                                        value={ownersAmountMoreThen}
                                    />
                                </span>
                                <span className={classes.input}>
                                <FieldEditor
                                    type={INPUT_NUMBER}
                                    name="to"
                                    label="to"
                                    onChange={e => addFilter("objectFilter", "ownersAmountLessThen", e)}
                                    value={ownersAmountLessThen}
                                />
                                </span>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.block}>
                                 <span className={classes.inputText}>
                            <Typography
                                variant="subtitle1"
                                className={classes.condition}
                            >
                                Units Amount:
                            </Typography>
                             </span>
                                <span className={classes.input}>
                                    <FieldEditor
                                        type={INPUT_NUMBER}
                                        name="from"
                                        label="from"
                                        onChange={e => addFilter("objectFilter", "unitsAmountMoreThen", e)}
                                        value={unitsAmountMoreThen}
                                    />
                                </span>
                                <span className={classes.input}>
                                <FieldEditor
                                    type={INPUT_NUMBER}
                                    name="to"
                                    label="to"
                                    onChange={e => addFilter("objectFilter", "unitsAmountLessThen", e)}
                                    value={unitsAmountLessThen}
                                />
                                </span>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.block}>
                                <span className={classes.inputText}>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.condition}
                                    >
                                        Units Size:
                                    </Typography>
                                </span>
                                <span className={classes.input}>
                                    <FieldEditor
                                        type={INPUT_NUMBER}
                                        name="from"
                                        label="from"
                                        onChange={e => addFilter("objectFilter", "unitsSizeMoreThen", e)}
                                        value={unitsSizeMoreThen}
                                    />
                                </span>
                                <span className={classes.input}>
                                <FieldEditor
                                    type={INPUT_NUMBER}
                                    name="to"
                                    label="to"
                                    onChange={e => addFilter("objectFilter", "unitsSizeLessThen", e)}
                                    value={unitsSizeLessThen}
                                />
                                </span>
                            </div>
                        </div>


                        <div className={classes.row}>
                            <div className={classes.block}>
                                <span className={classes.inputText}>
                                    <Typography

                                        variant="subtitle1"
                                        className={classes.condition}
                                    >
                                        Includes function:
                                    </Typography>
                                </span>
                                <span className={classes.select}>
                                    <FieldEditor
                                        type={SELECT}
                                        name="Function"
                                        label="function"
                                        onChange={e => addFilter("objectFilter", "objectIncludesFunction", e)}
                                        value={{
                                            current: objectIncludesFunction || "",
                                            options: this.housingOptions,
                                            none: "n/a",
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.block}>
                                <span className={classes.inputText}>
                                    <Typography

                                        variant="subtitle1"
                                        className={classes.condition}
                                    >
                                        Excludes function:
                                    </Typography>
                                </span>
                                <span className={classes.select}>
                                    <FieldEditor
                                        type={SELECT}
                                        name="Function"
                                        label="function"
                                        onChange={e => addFilter("objectFilter", "objectExcludesFunction", e)}
                                        value={{
                                            current: objectExcludesFunction || "",
                                            options: this.housingOptions,
                                            none: "n/a",
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.block}>
                                <span className={classes.inputText}>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.condition}
                                    >
                                Price:
                            </Typography>
                                </span>
                                <span className={classes.input}>
                                    <FieldEditor
                                        type={INPUT_NUMBER}
                                        name="from"
                                        label="from"
                                        onChange={e => addFilter("objectFilter", "priceMoreThen", e)}
                                        value={priceMoreThen}
                                    />
                                </span>
                                <span className={classes.input}>
                                <FieldEditor
                                    type={INPUT_NUMBER}
                                    name="to"
                                    label="to"
                                    onChange={e => addFilter("objectFilter", "priceLessThen", e)}
                                    value={priceLessThen}
                                />
                                </span>
                            </div>
                        </div>
                    </div>
                </Paper>
            </React.Fragment>
        );
    }
}

ObjectFilter.propTypes = {};

const styles = theme => ({
    block: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    input: {
        width: "35%",
    },
    select: {
        width: "74%",
    },
    inputText: {
        width: "22%",
    },
    row: {
        padding: 8,
    },
    head: {
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary[400],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    divider: {
        width: "100%",
        backgroundColor: theme.palette.primary.divider,
    },
    switch: {
        display: "flex",
        margin: "-10px 0",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px 5px 0",
    },
    body: {
        height: "260px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

export default withStyles(styles)(ObjectFilter);
