import axios from 'axios';
const { REACT_APP_X_API_KEY } = process.env;

class PostCodeApiService {
  static get({ postcode, number }, cbOnSuccess, cbOnError) {
    return axios
      .get(
        `https://api.postcodeapi.nu/v2/addresses/?${
          postcode ? 'postcode=' + postcode : ''
        }${postcode && number ? '&number=' + number : ''}`,
        {
          headers: {
            'X-Api-Key': REACT_APP_X_API_KEY,
          },
        },
      )
      .then(cbOnSuccess)
      .catch(cbOnError);
  }
}

export default PostCodeApiService;
