import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const DeleteUser = props => {
    const { classes, user } = props;

    return (
        <div className={classes.root}>
            <div className={classes.str}>
                <Typography variant="h5">{user.email}</Typography>
            </div>
            <div className={classes.str}>
                <Typography variant="h5">{user.name}</Typography>
            </div>
            <div className={classes.str}>
                <Typography variant="h6">Are you sure?</Typography>
            </div>
        </div>
    );
};

const styles = theme => ({
    root: {
        marginTop: 24,
    },
    str: {
        height: 35,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

DeleteUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteUser);
