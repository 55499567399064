import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const Block = ({ classes, children, title }) => {
    return (
        <div className={classes.root}>
            <div>
                {title && (
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                )}
                <div className={classes.body}>{children}</div>
            </div>
        </div>
    );
};

Block.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    title: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 10,
    },
    root: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    body: {
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

export default withStyles(styles)(Block);
