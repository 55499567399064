import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DialogWrapper from "../../../../common/components/dialog";
import { ObjectEditor } from "../../../../common/components/editor/entity";
import { leadgenUpdateObjectRequest } from "../../actions/objects";
import { validate } from "../../../../common/helpers/validator";
import { selectLeadgen } from "../../selectors";

class EditObject extends PureComponent {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onCloseDialog: PropTypes.func.isRequired,
    };

    state = {
        object: {},
        validationMode: false,
        action_types: [],
    };

    componentDidUpdate(prevProps) {
        if (prevProps.leadgen.editableObject !== this.props.leadgen.editableObject) {
            this.setState({ object: this.props.leadgen.editableObject[0] || {} });
        }
    }

    handleChange = (name, { target }) => {
        const default_type = 'general_fields';
        let value = target.value;
        const { object, action_types } = this.state;


        if (name === "price") value = Number(value);
        if (name === "kadaster_id") return null;

        if(name === 'price' || name === 'kadaster_id' || name === 'kadaster_url' || name ===  'restrictions' ) {
            this.setState({
                action_types: ( action_types.includes(default_type) ? action_types : [default_type, ...action_types]),
                object: { ...object, [name]: value }
            })
        }
        this.setState({
            action_types: ( action_types.includes(name) ? action_types : [name, ...action_types]),
            object: { ...object, [name]: value }
        });
    };

    handleSave = async () => {
        const { object, action_types } = this.state;
        const { dispatch, onCloseDialog, updateEntityData } = this.props;
        const isValidated = validate("object", { ...object });
        if (isValidated) {
            onCloseDialog();
            dispatch(leadgenUpdateObjectRequest(action_types, object));
        } else this.setState({ validationMode: true });
        updateEntityData();
    };

    render() {
        const { open, onCloseDialog } = this.props;
        const { object, validationMode } = this.state;

        return (
            <DialogWrapper
                open={open}
                onClose={onCloseDialog}
                handleClick={this.handleSave}
                title="Edit object"
                buttonText="Save"
            >
                <ObjectEditor
                    validationMode={validationMode}
                    editableObject={object}
                    onChange={this.handleChange}
                />
            </DialogWrapper>
        );
    }
}

const mapStateToProps = state => ({
    leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(EditObject);
