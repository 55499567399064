import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import { receiveByNameSuccess, receiveByNameError } from "../actions/name";
import {
    SEARCH_RECEIVE_BY_NAME_REQUEST,
} from "../../../common/constants";

function* searchReceiveByName(action) {
    const name = action.query.name.replace(/[+()]/gm, "");
    const { filters, otherName } = action.query;
    const dataForRequest = {
        filters: filters,
        name: name,
        otherName: otherName,
    };
    try {
        const resByName = yield call(ApiService.post2, "/search/name", dataForRequest);
        yield put(receiveByNameSuccess(resByName));
    } catch (error) {
        yield put(receiveByNameError(error));
    }
}

export default function* actionSearchByName() {
    yield takeLatest(SEARCH_RECEIVE_BY_NAME_REQUEST, searchReceiveByName);
}
