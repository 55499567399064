import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import MoreVert from "@material-ui/icons/MoreVert";
import Search from "@material-ui/icons/Search";
import { Item } from "./Item";
import { withStyles } from "@material-ui/core/styles";

const SelectEditor = ({
  classes,
  objects,
  entities,
  query,
  objectsLimit,
  entitiesLimit,
  openSearchBar,
  toggleFocus,
  getQuery,
  openDialog,
  showBlocks,
  showSubEntities,
  showSubObjects,
  handleQuery,
  isLeadgen,
  isFetchingObjects,
  isFetchingEntities,
}) => {
  let currentItem = {};
  const setAddress = (item) => {
    currentItem = item;
  };

  const handleKeyPress = () => {
    const { type, item } = currentItem;
    let query = "";

    if (type === "entity") {
      query = item.name;
    }
    if (type === "object") {
      const { street_name, number } = item.address;
      query = `${street_name} ${number}`;
    }

    return item && getQuery(query, item);
  };

  const handleChange = (e, { newValue, method }) => {
    if (method === "type") return handleQuery(newValue, {});
  };

  let currentLimit = false;
  const handleClickQuery = (query, limit, allowed) => {
    if (limit) {
      allowed && handleQuery(query, limit);
      currentLimit = !!limit;
    } else if (query.reason === "suggestion-selected") {
      !currentLimit && handleKeyPress();
      currentLimit = false;
    }
  };

  const getHeader = (type, subHeads) => (
    <div className={classes.tableColumns}>
      <Typography variant="body2" className={classes.firstColumn}>
        {type}
      </Typography>
      <div className={classes.wrapperColumns}>
        {subHeads.map((head) => (
          <Typography key={head} className={classes.col} variant="body2">
            {head}
          </Typography>
        ))}
      </div>
    </div>
  );

  const items = [];
  const limitedObjects = isFetchingObjects
    ? []
    : objects.slice(0, objectsLimit);

  isFetchingObjects &&
    items.push({
      type: "loadingObject",
      isShow: showBlocks.objects,
      header: (
        <div className={classes.tableColumns}>
          <Typography variant="body2" className={classes.firstColumn}>
            Objects
          </Typography>
        </div>
      ),
    });
  limitedObjects.forEach((item, index) =>
    items.push({
      isShow: showBlocks.objects,
      label: `${item.address.street_name} ${item.address.number || ""} ${
        item.address.number_add || ""
      }`.trim(),
      type: "object",
      item: item,
      header:
        index === 0
          ? getHeader("Objects", [
              "Size",
              "Function",
              "Units",
              "Value",
              "Owner",
            ])
          : null,
      footer:
        index === limitedObjects.length - 1 ? (
          <div className={classes.buttonWrapper}>
            <Typography className={classes.text} variant="button">
              See
            </Typography>
            <button
              className={classes.footerItemButton}
              disabled={objectsLimit >= 70}
            >
              <Typography
                color="inherit"
                variant="button"
                onClick={() =>
                  handleClickQuery(
                    query,
                    {
                      type: "objectsLimit",
                      num: 15,
                    },
                    objectsLimit < 70
                  )
                }
              >
                +15
              </Typography>
            </button>
            <button
              className={classes.footerItemButton}
              disabled={objectsLimit <= 25}
            >
              <Typography
                color="inherit"
                variant="button"
                onClick={() =>
                  handleClickQuery(
                    query,
                    {
                      type: "objectsLimit",
                      num: -15,
                    },
                    objectsLimit > 25
                  )
                }
              >
                -15
              </Typography>
            </button>
            <Typography className={classes.text} variant="button">
              current limit: {objectsLimit}
            </Typography>
          </div>
        ) : null,
    })
  );
  const limitedEntities = isFetchingEntities
    ? []
    : entities.slice(0, entitiesLimit);

  isFetchingEntities &&
    items.push({
      type: "loadingEntity",
      isShow: showBlocks.entities,
      header: (
        <div className={classes.tableColumns}>
          <Typography variant="body2" className={classes.firstColumn}>
            Entities
          </Typography>
        </div>
      ),
    });

  limitedEntities.forEach((item, index) =>
    items.push({
      isShow: showBlocks.entities,
      label: item.name,
      type: "entity",
      item: item,
      header:
        index === 0
          ? getHeader("Entities", [
              "Age",
              "Objects",
              "m2",
              "%housing",
              "%mixed",
            ])
          : null,
      footer:
        index === limitedEntities.length - 1 ? (
          <div className={classes.buttonWrapper}>
            <Typography className={classes.text} variant="button">
              See
            </Typography>
            <button
              className={classes.footerItemButton}
              disabled={entitiesLimit >= 20}
            >
              <Typography
                color="inherit"
                variant="button"
                onClick={() =>
                  handleClickQuery(
                    query,
                    {
                      type: "entitiesLimit",
                      num: 5,
                    },
                    entitiesLimit < 20
                  )
                }
              >
                +5
              </Typography>
            </button>
            <button
              className={classes.footerItemButton}
              disabled={entitiesLimit <= 5}
            >
              <Typography
                color="inherit"
                variant="button"
                onClick={() =>
                  handleClickQuery(
                    query,
                    {
                      type: "entitiesLimit",
                      num: -5,
                    },
                    entitiesLimit > 5
                  )
                }
              >
                -5
              </Typography>
            </button>
            <Typography className={classes.text} variant="button">
              current limit: {entitiesLimit}
            </Typography>
          </div>
        ) : null,
    })
  );

  return (
    <Autosuggest
      renderInputComponent={({ classes, ref, ...other }) => (
        <div className={classes.wrapper}>
          <Search className={classes.iconSearch} />
          <TextField
            className={classes.input}
            fullWidth
            onClick={() => toggleFocus(true)}
            inputRef={(node) => ref(node)}
            inputProps={{
              style: {
                paddingLeft: 40,
              },
            }}
            {...other}
          />
          <IconButton
            onClick={openDialog}
            aria-label="Edit"
            className={classes.iconButtonMore}
          >
            <MoreVert />
          </IconButton>
        </div>
      )}
      alwaysRenderSuggestions={openSearchBar}
      onSuggestionsFetchRequested={handleClickQuery}
      onSuggestionsClearRequested={() => {}}
      renderSuggestion={Item}
      getSuggestionValue={({ label, type, item }) => {
        setAddress({ type, item });
        return label || {};
      }}
      suggestions={items}
      inputProps={{
        classes,
        placeholder: "Search",
        value: query,
        onChange: handleChange,
        onFocus: () => toggleFocus(true),
        onBlur: () => toggleFocus(false),
      }}
      theme={{
        container: classes.container,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={({ containerProps, children }) => {
        return (
          <Paper
            className={
              isLeadgen
                ? classes.suggestionsContainerOpenOnLeadgen
                : classes.suggestionsContainerOpen
            }
            {...containerProps}
            square
          >
            {openSearchBar && (
              <div>
                {children && (
                  <React.Fragment>
                    <Typography className={classes.filter} variant="subtitle1">
                      Filter:{" "}
                      <span className={classes.filterItem}>
                        {showBlocks.objects &&
                          `objects: ${
                            showSubObjects.byKadasterID
                              ? "byKadasterID"
                              : "byAddress"
                          } /`}
                      </span>
                      <span className={classes.filterItem}>
                        {showBlocks.entities &&
                          `entities: ${showSubEntities.firstGroup} + ${showSubEntities.secondGroup}`}
                      </span>
                    </Typography>
                    <Divider
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                    />
                  </React.Fragment>
                )}
                {children && <div className={classes.children}>{children}</div>}
              </div>
            )}
          </Paper>
        );
      }}
    />
  );
};

const styles = (theme) => ({
  container: {
    position: "relative",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 4,
    marginBottom: 6,
  },
  text: {
    margin: "0 10px",
  },
  footerItemButton: {
    minWidth: 40,
    minHeight: 28,
    padding: 0,
    border: "none",
    borderRadius: 4,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
  filterItem: {
    marginLeft: 8,
    color: theme.palette.primary.grayFont,
  },
  blockWrapper: {
    padding: "8px 16px",
  },
  filter: {
    padding: "4px 16px 0",
    height: 26,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 6,
    marginRight: -6,
  },
  input: {
    marginLeft: -30,
  },
  iconSearch: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  iconButtonMore: {
    padding: 6,
    marginLeft: -30,
  },
  tableColumns: {
    color: theme.palette.primary.grayFont,
    height: 26,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 8,
  },
  children: {
    maxHeight: 484,
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  wrapperColumns: {
    width: "73%",
    display: "flex",
  },
  firstColumn: {
    color: theme.palette.primary.grayFont,
    textDecoration: "underline",
    width: "27%",
  },
  col: {
    width: "20%",
    textAlign: "center",
    color: theme.palette.primary.grayFont,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestionsContainerOpenOnLeadgen: {
    position: "absolute",
    zIndex: 99,
    marginTop: 22,
    width: 1000,
    right: 0,
  },
  div: {
    color: "black",
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
});

SelectEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectEditor);
