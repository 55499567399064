import Root from "./Root";
import DashboardRoutes from "./modules/dashboard/routes";
import NotFoundPage from "./containers/404";
import LoginRoutes from "./modules/login/routes";
import AdminRoutes from "./modules/admin/routes";
import QueryObjectsRoutes from "./modules/query-objects/routes";
import LeadgenRoutes from "./modules/leadgen/routes";
import AddNewEntityRoutes from "./modules/add-new-entity/routes";
import ImportPDF from "./modules/importPDF/routes";
import Leads from "./modules/leads/routes";
import LPSForm from "./modules/lps-form/routes";
// import Query from "./modules/query/routes";
import QueryObjects from "./modules/query-objects/routes";

const routes = [
    {
        path: "/",
        component: Root,
        childRoutes: [
            ...DashboardRoutes,
            ...LoginRoutes,
            ...AdminRoutes,
            ...QueryObjectsRoutes,
            ...LeadgenRoutes,
            ...AddNewEntityRoutes,
            ...ImportPDF,
            ...Leads,
            ...LPSForm,
            // ...Query,
            ...QueryObjects,
            { path: "404", component: NotFoundPage },
        ],
    },
    { path: "*", component: NotFoundPage },
];

export default routes;
