import { combineReducers } from "redux";
import {
  SEARCH_RECEIVE_PERSON_SUCCESS,
  SEARCH_SET_EMPTY_PERSON_SUCCESS,
  SEARCH_RECEIVE_PERSON_ERROR,
  SEARCH_SET_EMPTY_PERSON_ERROR
} from "../../../common/constants";

const receivePerson = (state = [], action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_PERSON_SUCCESS:
    case SEARCH_SET_EMPTY_PERSON_SUCCESS:
      return action.person;

    default:
      return state;
  }
};

const errorFetchPerson = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_PERSON_ERROR:
    case SEARCH_SET_EMPTY_PERSON_ERROR:
      return action.error;

    default:
      return state;
  }
};

const person = combineReducers({
  receivePerson,
  error: errorFetchPerson
});

export default person;
