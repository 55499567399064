import { combineReducers } from "redux";
import {
  CANCEL_PDF,
  SAVE_PDF_ERROR,
  SAVE_PDF_SUCCESS,
  SEND_PDF_ERROR,
  SEND_PDF_REQUEST,
  SEND_PDF_SUCCESS,
  CANCEL_PDF_SUCCESS,
  CANCEL_PDF_ERROR,
} from "../../../common/constants";

const initialState = {};

const parsePDF = (state = {}, action) => {
  switch (action.type) {
    case SEND_PDF_REQUEST:
      return {
        isFetching: true,
      };
    case SEND_PDF_SUCCESS:
      return {
        isFetching: false,
        pdfData: action.pdfData,
      };
    case CANCEL_PDF_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

const savePDF = (state = {}, action) => {
  switch (action.type) {
    case SAVE_PDF_SUCCESS:
      return {
        pdfData: action.pdfData,
      };
    case CANCEL_PDF:
      return initialState;

    default:
      return state;
  }
};

const errorSavePDF = (state = {}, action) => {
  switch (action.type) {
    case SEND_PDF_ERROR:
    case SAVE_PDF_ERROR:
    case CANCEL_PDF_ERROR:
      return {
        error: action.error,
      };

    default:
      return state;
  }
};

const importPDF = combineReducers({
  parsePDF,
  savePDF,
  errorSavePDF,
});

export default importPDF;
