import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { formatDate, formatTime, formatDateAndTimeTable } from "../../../../../common/formats/date";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import TableWrapper from "../../../../../common/components/table";

const UserLogs = props => {
    const { classes, user } = props;

    const columns = [
        {
            Header: "IP",
            accessor: "ip",
            headProps: { align: "inherit" },
            cellProps: {
                className: `${classes.tableCell}`,
                component: "th",
                scope: "row",
            },
            Cell: info => info || "n/a",
        },
        {
            Header: "OS",
            accessor: "os",
            headProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => info || "n/a",
        },
        {
            Header: "Date",
            accessor: "date",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => (info && formatDate(info)) || "n/a",
        },
        {
            Header: "Time",
            accessor: "date",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => (info && formatTime(info)) || "n/a",
        },
    ];

    const Head = () => (
        <div className={classes.tableHead}>
            <Typography variant="body2">Activity Logs</Typography>
        </div>
    );

    return (
        <div>
            <Paper className={classes.root}>
                <Card className={classes.card}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            {user.name && (
                                <Typography component="h5" variant="subtitle1">
                                    Name: {user.name}
                                </Typography>
                            )}
                            {user.first_login && (
                                <Typography variant="subtitle1" color="textSecondary">
                                    First login: {formatDate(user.first_login)}
                                </Typography>
                            )}
                            {(user.logs && user.logs.length && (
                                <Typography variant="subtitle1" color="textSecondary">
                                    Last login: {formatDateAndTimeTable(user.logs[user.logs.length - 1].date)}
                                </Typography>
                            )) ||
                            ""}
                            {(user.logs && user.logs.length && (
                                <Typography variant="subtitle1" color="textSecondary">
                                    IP: {user.logs[user.logs.length - 1].ip}
                                </Typography>
                            )) ||
                            ""}
                            {(user.logs && user.logs.length && (
                                <Typography variant="subtitle1" color="textSecondary">
                                    OS: {user.logs[user.logs.length - 1].os}
                                </Typography>
                            )) ||
                            ""}
                        </CardContent>
                    </div>
                    {user.img_url && (
                        <CardMedia className={classes.cover} image={user.img_url} title="User icon"/>
                    )}
                </Card>
            </Paper>
            <Paper className={classes.root}>
                <TableWrapper Head={Head} data={(user && user.logs) || []} columns={columns}/>
            </Paper>
        </div>
    );
};

const styles = theme => ({
    tableHead: {
        backgroundColor: theme.palette.primary[500],
        height: 34,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
    },
    tableCell: {
        padding: "0px 5px 0px 20px",
    },
    card: {
        display: "flex",
        justifyContent: "space-between",
    },
    details: {
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 0 auto",
        padding: 15,
        "&:last-child": {
            paddingBottom: 15,
        },
    },
    cover: {
        width: 128,
        height: 128,
    },
});

UserLogs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserLogs);
