import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import FieldEditor from "../../../../common/components/editor/field";
import {INPUT, INPUT_NUMBER} from "../../../constants/field-types";
import Container from "./Container";
import {CollapsePanel} from "../../../../common/components/panel/";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import PostcodeForm from "./PostcodeForm";
import PhoneInput from "./phone-input";

interface ObjectEditorProps {
    classes: any,
    onChange: (name: string, {target}: any) => void,
    editableObject: {
        city?: string,
        house_size?: number,
        kadaster_id?: string,
        kadaster_url?: string,
        object_id?: string,
        other_size?: number,
        plot?: {
            city_code?: string,
            index?: string,
            plot_number?: number,
            section?: string,
        },
        plot_index?: string,
        price?: number,
        purpose?: string,
        street?: string,
        restrictions?: any,
        address?: any,
        units?: {
            address?: {
                number?: string,
                postcode?: string,
                street_name?: string,
                number_add?: string,
            }
            built_year?: string,
            price?: number,
            purpose?: string,
            size?: number | string
            phone_url?: string,
            phone_number?: string,
            all_names?: string,
            name_or_initials?: string,
            last_name?: string,
        }[]
    },
    validationMode?: any
}

const ObjectEditor: React.FC<ObjectEditorProps> = ({
                                                       classes,
                                                       onChange,
                                                       editableObject,
                                                       validationMode,
                                                   }) => {
    const {
        kadaster_url,
        kadaster_id,
        city,
        price,
        restrictions,
        plot = {},
        address = {},
        units = [],
    } = editableObject;

    const unit = {
        address: {
            street_name: "",
            number: "",
            number_add: "",
            postcode: "",
        },
        size: "",
        purpose: "",
        built_year: "",
        phone_url: "",
        phone_number: "",
        all_names: "",
        name_or_initials: "",
        last_name: "",
    };
    //@ts-ignore
    const {city_code = "", section = "", plot_number = 0, index = ""} = plot;
    const {
        street_name = "",
        number = "",
        number_add = "",
        postcode = "",
    } = address;

    const handleChange = async (name, key, {target}, index) => {
        let value = null;
        if (name === "apiAddress") {
            const {city, street_name, number, number_add, postcode} = target.value;
            //@ts-ignore
            await onChange("city", {target: {value: city}});
            //@ts-ignore
            await onChange("address", {
                target: {value: {street_name, number, number_add, postcode}},
            });

            return null;
        }
        if (name === "apiUnits") {
            const {
                purpose,
                postcode,
                city,
                number,
                street_name,
                built_year,
                number_add,
            } = target.value;


            units[index] = {
                ...units[index],
                address: {
                    street_name,
                    number,
                    number_add,
                    postcode,
                },
                purpose,
                built_year,
            };
            //@ts-ignore
            value = units;
            //@ts-ignore
            await onChange("city", {target: {value: city}});
            //@ts-ignore
            await onChange(units, {target: {value: value}});

            return null;
        }
        if (name === "units") {
            units[index] = {...units[index], [key]: target.value};
            //@ts-ignore
            value = units;
            //@ts-ignore
            return onChange && onChange(name, {target: {value: value}});
        }
        if (name === "units_addresses") {
            units[index] = {...units[index], [key]: target.value};
            //@ts-ignore
            value = units;
            //@ts-ignore
            return onChange && onChange(name, {target: {value: value}});
        }
        if (name === "units_price") {
            units[index] = {...units[index], [key]: target.value};
            //@ts-ignore
            value = units;
            //@ts-ignore
            return onChange && onChange(name, {target: {value: value}});
        }
        if (name === "plot") {
            //@ts-ignore
            value = {
                ...plot,
                [key]:
                    key === "plot_number"
                        ? Number(target.value)
                        : target.value.toUpperCase(),
            };
            //@ts-ignore
            return onChange && onChange(name, {target: {value: value}});
        }
        if (name === "address") {
            value = {...address, [key]: target.value};
            //@ts-ignore
            return onChange && onChange(name, {target: {value: value}});
        }
    };

    const handleDelete = (name, index) => {
        let value = null;
        if (name === "units") {
            units.splice(index, 1);
            //@ts-ignore
            value = units;
        }
        //@ts-ignore
        onChange && onChange(name, {target: {value: value}});
    };

    const handleAdd = name => {
        let value = null;
        if (name === "units") {
            if (JSON.stringify(units[0]) === JSON.stringify(unit)) return null;
            units.unshift(unit);
            //@ts-ignore
            value = units;
        }
        //@ts-ignore
        onChange && onChange(name, {target: {value: value}});
    };

    return (
        <div>
            <CollapsePanel header="General Fields">
                <div className={classes.table}>
                    <Container
                        leftColumn={[
                            <FieldEditor
                                type={INPUT_NUMBER}
                                name="kadaster_id"
                                label="Kadaster ID"
                                //@ts-ignore
                                onChange={e => onChange("kadaster_id", e)}
                                value={kadaster_id || ""}
                                required
                                error={validationMode && !Boolean(kadaster_id)}
                            />,
                            <FieldEditor
                                type={INPUT}
                                name="kadaster_url"
                                label="Kadaster URL"
                                //@ts-ignore
                                onChange={e => onChange("kadaster_url", e)}
                                value={kadaster_url || ""}
                            />,
                        ]}
                        rightColumn={[
                            <FieldEditor
                                type={INPUT_NUMBER}
                                name="price"
                                label="Price"
                                //@ts-ignore
                                onChange={e => onChange("price", e)}
                                value={price || ""}
                                disabled={units.length > 0}
                            />,
                            <FieldEditor
                                type={INPUT}
                                name="restrictions"
                                label="Restrictions"
                                //@ts-ignore
                                onChange={e => onChange("restrictions", e)}
                                value={restrictions || ""}
                            />,
                        ]}
                    />
                </div>
            </CollapsePanel>

            <CollapsePanel header="Plot *" open={false}>
                <div className={classes.wrapper}>
                    <div className={classes.table}>
                        <Container
                            leftColumn={[
                                <FieldEditor
                                    type={INPUT}
                                    name="city_code"
                                    label="City code"
                                    //@ts-ignore
                                    onChange={e => handleChange("plot", "city_code", e)}
                                    value={city_code || ""}
                                    required
                                    error={validationMode && !Boolean(city_code)}
                                />,
                                <FieldEditor
                                    type={INPUT}
                                    name="section"
                                    label="Section"
                                    //@ts-ignore
                                    onChange={e => handleChange("plot", "section", e)}
                                    value={section || ""}
                                    required
                                    error={validationMode && !Boolean(section)}
                                />,
                            ]}
                            rightColumn={[
                                <FieldEditor
                                    type={INPUT_NUMBER}
                                    name="plot_number"
                                    label="Plot number"
                                    //@ts-ignore
                                    onChange={e => handleChange("plot", "plot_number", e)}
                                    value={plot_number || ""}
                                    required
                                    error={validationMode && !Boolean(plot_number)}
                                />,
                                <FieldEditor
                                    type={INPUT}
                                    name="index"
                                    label="Index"
                                    //@ts-ignore
                                    onChange={e => handleChange("plot", "index", e)}
                                    value={index || ""}
                                    required
                                    error={validationMode && !Boolean(index)}
                                />,
                            ]}
                        />
                    </div>
                </div>
            </CollapsePanel>

            <CollapsePanel header="Address *" open={false}>
                <div className={classes.wrapper}>
                    <PostcodeForm
                        //@ts-ignore
                        onSelect={e => handleChange("apiAddress", "address", e)}
                    />
                    <div className={classes.table}>
                        <Container
                            leftColumn={[
                                <FieldEditor
                                    type={INPUT}
                                    name="city"
                                    label="City"
                                    //@ts-ignore
                                    onChange={e => onChange("city", e)}
                                    value={city || ""}
                                    required
                                    error={validationMode && !Boolean(city)}
                                />,
                                <FieldEditor
                                    type={INPUT}
                                    name="street_name"
                                    label="Street name"
                                    //@ts-ignore
                                    onChange={e => handleChange("address", "street_name", e)}
                                    value={street_name || ""}
                                />,
                                <FieldEditor
                                    type={INPUT_NUMBER}
                                    name="number"
                                    label="Number"
                                    //@ts-ignore
                                    onChange={e => handleChange("address", "number", e)}
                                    value={number || ""}
                                />,
                            ]}
                            rightColumn={[
                                <FieldEditor
                                    type={INPUT}
                                    name="number_add"
                                    label="Number add"
                                    //@ts-ignore
                                    onChange={e => handleChange("address", "number_add", e)}
                                    value={number_add || ""}
                                />,
                                <FieldEditor
                                    type={INPUT}
                                    name="postcode"
                                    label="Postcode"
                                    //@ts-ignore
                                    onChange={e => handleChange("address", "postcode", e)}
                                    value={postcode || ""}
                                />,
                            ]}
                        />
                    </div>
                </div>
            </CollapsePanel>

            <CollapsePanel header="Units" open={false}>
                <div className={classes.wrapper}>
                    {units.map(
                        (
                            {
                                address,
                                size,
                                purpose,
                                built_year,
                                phone_url,
                                phone_number,
                                all_names,
                                name_or_initials,
                                last_name,
                                price
                            },
                            index,
                        ) => (
                            <CollapsePanel
                                key={index}
                                header={`${index + 1} Unit`}
                                open={false}
                            >
                                <div className={classes.fieldBlock}>
                                    <div className={classes.buttons}>
                                        <IconButton
                                            onClick={() => handleDelete("units", index)}
                                            aria-label="Delete"
                                            className={classes.button}
                                        >
                                            <DeleteIcon fontSize="small"/>
                                        </IconButton>
                                    </div>
                                    <PostcodeForm
                                        //@ts-ignore
                                        onSelect={e => handleChange("apiUnits", "units", e, index)}
                                    />
                                    <CollapsePanel header="Address" open={false}>
                                        <div className={classes.wrapper}>
                                            <div className={classes.table}>
                                                <Container
                                                    leftColumn={[
                                                        <FieldEditor
                                                            type={INPUT}
                                                            name="street_name"
                                                            label="Street name"
                                                            //@ts-ignore
                                                            onChange={e =>
                                                                handleChange(
                                                                    "units_addresses",
                                                                    "address",
                                                                    {
                                                                        target: {
                                                                            value: {
                                                                                ...address,
                                                                                street_name: e.target.value,
                                                                            },
                                                                        },
                                                                    },
                                                                    index,
                                                                )
                                                            }
                                                            //@ts-ignore
                                                            value={address.street_name || ""}
                                                        />,
                                                        <FieldEditor
                                                            type={INPUT_NUMBER}
                                                            name="number"
                                                            label="Number"
                                                            //@ts-ignore
                                                            onChange={e =>
                                                                handleChange(
                                                                    "units_addresses",
                                                                    "address",
                                                                    {
                                                                        target: {
                                                                            value: {
                                                                                ...address,
                                                                                number: e.target.value,
                                                                            },
                                                                        },
                                                                    },
                                                                    index,
                                                                )
                                                            }
                                                            //@ts-ignore
                                                            value={address.number || ""}
                                                        />,
                                                    ]}
                                                    rightColumn={[
                                                        <FieldEditor
                                                            type={INPUT}
                                                            name="number_add"
                                                            label="Number add"
                                                            //@ts-ignore
                                                            onChange={e =>
                                                                handleChange(
                                                                    "units_addresses",
                                                                    "address",
                                                                    {
                                                                        target: {
                                                                            value: {
                                                                                ...address,
                                                                                number_add: e.target.value,
                                                                            },
                                                                        },
                                                                    },
                                                                    index,
                                                                )
                                                            }
                                                            //@ts-ignore
                                                            value={address.number_add || ""}
                                                        />,
                                                        <FieldEditor
                                                            type={INPUT}
                                                            name="postcode"
                                                            label="Postcode"
                                                            //@ts-ignore
                                                            onChange={e =>
                                                                handleChange(
                                                                    "units_addresses",
                                                                    "address",
                                                                    {
                                                                        target: {
                                                                            value: {
                                                                                ...address,
                                                                                postcode: e.target.value,
                                                                            },
                                                                        },
                                                                    },
                                                                    index,
                                                                )
                                                            }
                                                            //@ts-ignore
                                                            value={address.postcode || ""}
                                                        />,
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </CollapsePanel>
                                    <div className={classes.table}>
                                        <Container
                                            leftColumn={[
                                                <FieldEditor
                                                    type={INPUT_NUMBER}
                                                    name="size"
                                                    label="Size"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange(
                                                            "units",
                                                            "size",
                                                            {target: {value: Number(e.target.value)}},
                                                            index,
                                                        )
                                                    }
                                                    value={size || 0}
                                                />,
                                                <FieldEditor
                                                    type={INPUT}
                                                    name="built_year"
                                                    label="Built year"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange("units", "built_year", e, index)
                                                    }
                                                    value={built_year || ""}
                                                />,
                                                <FieldEditor
                                                    type={INPUT}
                                                    name="phone_url"
                                                    label="Phone URL"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange("units", "phone_url", e, index)
                                                    }
                                                    value={phone_url || ""}
                                                />,

                                                <FieldEditor>
                                                    <div className={classes.phoneInput}>
                                                        {/*@ts-ignore*/}
                                                        <PhoneInput
                                                            placeholder="Enter phone number"
                                                            value={phone_number || ""}
                                                            onChange={value => {
                                                                handleChange(
                                                                    "units",
                                                                    "phone_number",
                                                                    {target: {value}},
                                                                    index,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </FieldEditor>,

                                                <FieldEditor
                                                    type={INPUT_NUMBER}
                                                    name="price"
                                                    label="Price"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange("units_price", "price", e, index)
                                                    }
                                                    value={price || ""}
                                                />,
                                            ]}
                                            rightColumn={[
                                                <FieldEditor
                                                    type={INPUT}
                                                    name="purpose"
                                                    label="Purpose"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange("units", "purpose", e, index)
                                                    }
                                                    value={purpose || ""}
                                                />,
                                                <FieldEditor
                                                    type={INPUT}
                                                    name="all_names"
                                                    label="All names"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange("units", "all_names", e, index)
                                                    }
                                                    value={all_names || ""}
                                                />,
                                                <FieldEditor
                                                    type={INPUT}
                                                    name="name_or_initials"
                                                    label="Name or Initials"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange("units", "name_or_initials", e, index)
                                                    }
                                                    value={name_or_initials || ""}
                                                />,
                                                <FieldEditor
                                                    type={INPUT}
                                                    name="last_name"
                                                    label="Last name"
                                                    //@ts-ignore
                                                    onChange={e =>
                                                        handleChange("units", "last_name", e, index)
                                                    }
                                                    value={last_name || ""}
                                                />,
                                            ]}
                                        />
                                    </div>
                                </div>
                            </CollapsePanel>
                        ),
                    )}
                    <Button
                        className={classes.buttonAdd}
                        onClick={() => handleAdd("units")}
                    >
                        add new unit
                    </Button>
                </div>
            </CollapsePanel>
        </div>
    );
};

ObjectEditor.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    wrapper: {
        width: "100%",
    },
    fieldBlock: {
        marginBottom: 15,
    },
    buttonAdd: {
        display: "flex",
        width: "100%",
    },
    button: {
        padding: 8,
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    table: {
        display: "table",
        width: "100%",
    },
    phoneInput: {
        display: "flex",
        alignItems: "center",
        height: 54,
        fontFamily: "Roboto",
        paddingLeft: 12,
        border: "1px solid #c4c4c4",
        borderRadius: 4,
        "&:hover": {
            borderColor: theme.palette.primary[500],
            borderWidth: 2,
            height: 52,
            paddingLeft: 11,
        },
    },
});

export default withStyles(styles)(ObjectEditor);
