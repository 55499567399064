import { combineReducers } from "redux";
import {
  REQUEST_USER,
  RECEIVE_USER_SUCCESS,
  RECEIVE_USER_ERROR, GET_CURRENT_USER_SUCCESS,
} from "../../../common/constants";

const requestUser = (state = false, action) => {
  switch (action.type) {
    case REQUEST_USER:
      return {
        isFetching: true
      };
    case RECEIVE_USER_SUCCESS:
      return {
        isFetching: false
      };

    default:
      return state;
  }
};

const receiveUser = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_SUCCESS:
      return action.user;

    default:
      return state;
  }
};

const errorFetchUser = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_USER_ERROR:
      return {
        isFetching: false,
        error: action.error
      };

    default:
      return state;
  }
};

const login = combineReducers({
  request: requestUser,
  receive: receiveUser,
  error: errorFetchUser
});

export default login;
