import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import GoogleLogin from "react-google-login";
// import { GoogleLogin } from '@react-oauth/google';
import UserService from "../../common/services/user.service";
import googleButton from "../../common/img/btn_google.png";

class Login extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  handleSubmit = (message, res) => {
    const { onSubmit } = this.props;
    onSubmit(message, res);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h3" className={classes.title}>
              Welcome
            </Typography>
            <GoogleLogin
              clientId={UserService.googleClient.id}
              onSuccess={(res) => this.handleSubmit("success", res)}
              onFailure={(err) => this.handleSubmit("error", err)}
              className={classes.button}
              // onSuccess={(res) => credentialResponse => {
              //   console.log('credentialResponse' , credentialResponse)}}
              // onError={() => {console.log('Login Failed');}}
            >
              <img src={googleButton} alt="google-button" />
            </GoogleLogin>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    "min-height": "100vh",
    "justify-content": "center",
    "align-items": "center",
  },
  button: {
    cursor: "pointer",
    width: "100%",
    background: "#fff",
    border: "1px solid transparent",
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
  card: {
    minWidth: 275,
  },
  title: {
    marginBottom: 16,
    textAlign: "center",
  },
});

export default withStyles(styles)(Login);
