import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const Row = ({ classes, title, object, owner, task }) => (
    <Grid item xs={12}>
        <Grid container>
            <Grid item xs={6}>
                <Grid container>
                    <Grid className={classes.title} item xs={4}>
                        <Typography variant="body2">{title}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={8}>
                        <Typography className={classes.textCenter} variant="body2">
                            {object}
                        </Typography>
                        <Typography className={classes.textCenter} variant="body2">
                            {owner}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.column} item xs={6}>
                <Typography className={classes.textCenter} variant="body2">
                    {task}
                </Typography>
            </Grid>
        </Grid>
    </Grid>
);

Row.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = () => ({
    iconCall: {
        color: "#1fd44c",
    },
    iconCallEnd: {
        color: "#fd382f",
    },
    title: {
        display: "flex",
        justifyContent: "flex-end",
        fontStyle: "italic",
        paddingRight: 6,
        paddingTop: 9,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        backgroundColor: "#fff",
        marginBottom: 16,
    },
    textCenter: {
        display: "flex",
        justifyContent: "center",
    },
    subheading: {
        height: 24,
        display: "flex",
        justifyContent: "center",
    },
});

export default withStyles(styles)(Row);
