import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "../../../../common/components/editor/entity/Container";
import Item from "../common/Item";
import Block from "../common/Block";
import { CHECK_BOX } from "../../../../common/constants/field-types";
import FieldEditor from "../../../../common/components/editor/field";
import { getPersonName } from '../../../../common/helpers/getter';

const Main = ({
  classes,
  fullList,
  objectsForUnlink = [],
  objectsForSaveAndLink = [],
  linkedObjects = [],
  objectsForLink = [],
  objectsForCheckbox = [],
  selectObjsWithUnitsToSave = [],
  nonResidentOwnersInDB = [],
  nonResidentOwnersNotInDB = [],
  residentOwnersInDB = [],
  residentOwnersNotInDB = [],
  linkedOwners = [],
}) => {
  const getObjectName = (municipality, plot = {}) =>
    `${municipality || ""} ${plot.section || ""} ${plot.plot_number || ""} ${
      plot.index || ""
    }`.trim();

  const getLocation = (city, address = {}, unitAddress = {}) => {
    return `${city || ""} ${unitAddress.postcode || address.postcode || ""} ${
      unitAddress.street_name || address.street_name || ""
    } ${unitAddress.number || address.number || ""} ${
      unitAddress.number_add || address.number_add || ""
    }`.trim();
  };

  const getUnitName = (unit_address) => {
    return `${unit_address.postcode || ""} ${unit_address.street_name || ""} ${
      unit_address.number || ""
    } ${unit_address.number_add || ""}`.trim();
  };

  const viewObjFromDB = ({
    object_name,
    kadaster_id,
    kadaster_url,
    city,
    plot,
    address,
    id,
    location,
    municipality,
    units,
  }) => (
    <Item
      title={
        <a
          href={`/leadgen/object/${id}`}
          title={`to Leadgen`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          {object_name || getObjectName(municipality, plot)}
        </a>
      }
      text={
        kadaster_id ? (
          <a
            href={kadaster_url || "/"}
            title={"to Kadaster"}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {kadaster_id}
          </a>
        ) : (
          "n/a"
        )
      }
      subText={
        location ||
        getLocation(
          city,
          address || {},
          units && units[0] ? units[0].units_addresses : {}
        ) ||
        ""
      }
    />
  );

  const viewObjsWithUnits = (object) => {
    const {
      object_name,
      kadaster_id,
      kadaster_url,
      city,
      plot,
      address,
      id,
      location,
      municipality,
      units,
    } = object;
    return (
      <Item
        title={
          <a
            href={`/leadgen/object/${id}`}
            title={`to Leadgen`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {object_name || getObjectName(municipality, plot)}
          </a>
        }
        text={
          kadaster_id ? (
            <a
              href={kadaster_url || "/"}
              title={"to Kadaster"}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {kadaster_id}
            </a>
          ) : (
            "n/a"
          )
        }
        subText={
          location ||
          getLocation(
            city,
            address || {},
            units && units[0] ? units[0].units_addresses : {}
          ) ||
          ""
        }
        checkBox={
          <FieldEditor
            type={CHECK_BOX}
            onChange={(e) => selectObjsWithUnitsToSave(e, object, null)}
            value={object.isSelected}
          />
        }
        unitsList={
          units.length &&
          units.map((unit) => {
            unit.unitName = getUnitName(unit.units_addresses);
            return unit;
          })
        }
        selectObjsWithUnitsToSave={selectObjsWithUnitsToSave}
      />
    );
  };

  const viewObjectOwners = (entity) => {
    return entity && entity.id ? (
      <Item
        title={
          <a
            href={`/leadgen/entity/${entity.id}`}
            title={`to Leadgen`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {entity.entity_type === 'person' ? getPersonName(entity) : entity.company_name}
          </a>
        }
      />
    ) : (
      <Item
        title={entity.entity_type === 'person' ? getPersonName(entity) : entity.company_name}
      />
    );
  };

  const linkObjsColumns = [];
  objectsForSaveAndLink.length &&
    linkObjsColumns.push(
      ...[
        <Item title="" text={<span className={classes.itemNEW}>NEW</span>} />,
        ...objectsForSaveAndLink.map(
          ({
            object_name,
            kadaster_id,
            kadaster_url,
            plot,
            location,
            city,
          }) => (
            <Item
              title={
                plot
                  ? `${object_name || ""} (${plot.section || ""} ${
                      plot.plot_number || ""
                    } ${plot.index || ""})`.trim()
                  : object_name || "n/a"
              }
              text={
                kadaster_id || plot ? (
                  <a
                    href={kadaster_url || "/"}
                    title={"to Kadaster"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    {kadaster_id || "n/a"}
                  </a>
                ) : (
                  "n/a"
                )
              }
              subText={location || city || ""}
            />
          )
        ),
      ]
    );
  objectsForLink.length &&
    linkObjsColumns.push(
      ...[
        <Item title="" text={<span className={classes.itemDB}>IN DB</span>} />,
        ...objectsForLink.map(viewObjFromDB),
      ]
    );

  const unlinkObjsColumns = objectsForUnlink.map(viewObjFromDB);
  const linkedObjsColumns = linkedObjects.map(viewObjFromDB);
  const checkObjsColumns = objectsForCheckbox.map(viewObjsWithUnits);
  const residentOwnersInDBColumns = residentOwnersInDB.map(viewObjectOwners);
  const residentOwnersNotInDBColumns =
    residentOwnersNotInDB.map(viewObjectOwners);
  const nonResidentOwnersNotInDBColumns =
    nonResidentOwnersNotInDB.map(viewObjectOwners);
  const nonResidentOwnersInDBColumns =
    nonResidentOwnersInDB.map(viewObjectOwners);
  const linkedOwnersColumns = linkedOwners.map(viewObjectOwners);

  return (
    <div className={classes.root}>
      {linkObjsColumns.length ? (
        <Block title="LINK">
          <Container leftColumn={linkObjsColumns} />
        </Block>
      ) : null}
      {unlinkObjsColumns.length && fullList ? (
        <Block title="UNLINK">
          <Container leftColumn={unlinkObjsColumns} />
        </Block>
      ) : null}
      {checkObjsColumns.length ? (
        <Block title="OBJECTS WITH UNITS FOR UPDATE">
          <Container leftColumn={checkObjsColumns} />
        </Block>
      ) : null}
      {linkedObjsColumns.length ? (
        <Block title="LINKED">
          <Container leftColumn={linkedObjsColumns} />
        </Block>
      ) : null}
      {linkedOwnersColumns.length ? (
        <Block title="LINKED OWNERS">
          <Container leftColumn={linkedOwnersColumns} />
        </Block>
      ) : null}
      {residentOwnersInDBColumns.length ? (
        <Block title="NL RESIDENTS IN DB">
          <Container leftColumn={residentOwnersInDBColumns} />
        </Block>
      ) : null}
      {residentOwnersNotInDBColumns.length ? (
        <Block title="NL RESIDENTS NOT IN DB">
          <Container leftColumn={residentOwnersNotInDBColumns} />
        </Block>
      ) : null}
      {nonResidentOwnersNotInDBColumns.length ? (
        <Block title="NON-NL RESIDENTS NOT IN DB">
          <Container leftColumn={nonResidentOwnersNotInDBColumns} />
        </Block>
      ) : null}
      {nonResidentOwnersInDBColumns.length ? (
        <Block title="NON-NL RESIDENTS IN DB">
          <Container leftColumn={nonResidentOwnersInDBColumns} />
        </Block>
      ) : null}
    </div>
  );
};

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: 15,
  },
  itemDB: {
    color: theme.palette.secondary.lightGreen600,
  },
  itemNEW: {
    color: theme.palette.secondary.yellow800,
  },
  title: {
    width: "50%",
    marginBottom: 10,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.light,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
});

export default withStyles(styles)(Main);
