import { call, put, select, takeLatest } from "redux-saga/effects";
import { ADD_NEW_FILES_REQUEST, SEND_NEW_FILES_REQUEST } from "../../../common/constants";
import ApiService from "../../../common/services/api.service";
import { sendFilesSuccess, addFilesSuccess, sendFilesError, saveFilesError } from "../actions/drive";

function* SendFilesData(action) {
    try {
        const driveFilesData = yield call(
            ApiService.post2,
            "/upload/files",
            action.data,
        );
        yield put(sendFilesSuccess(driveFilesData, action.router));
    } catch (error) {
        sendFilesError(error);
    }

};

function* SaveFiles(action) {
    const state = yield select();
    const { setLeadId } = state.entity;
    const { objects } = state.leadgen;
    const { receiveObjects } = objects;
    let driveFilesData;
    try {
        if (setLeadId) {
            driveFilesData = yield call(
                ApiService.post2,
                "/upload/save_files",
                {
                    data: action.data,
                    leadID: setLeadId,
                },
            );
        } else {
            driveFilesData = yield call(
                ApiService.post2,
                "/upload/save_files",
                {
                    data: action.data,
                    objectId: receiveObjects[0].object_id,
                },
            );
        }
        yield put(addFilesSuccess(driveFilesData, action.router));
    } catch (error) {
        saveFilesError(error);
    }
}

export default function* actionGoogleDrive() {
    yield takeLatest(SEND_NEW_FILES_REQUEST, SendFilesData);
    yield takeLatest(ADD_NEW_FILES_REQUEST, SaveFiles);
}