import React, { ReactElement } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  INPUT_NUMBER,
  INPUT_MULTILINE,
  INPUT,
  SELECT,
  COMPONENT,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE_TIME,
  DATE,
  TIME,
  SWITCH,
} from "../../../constants/field-types";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { formatDateAndTime, formatDate } from "../../../formats/date";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

interface FieldEditorProps {
  type?: string;
  name?: string;
  label?: string;
  labelPlacement?: any;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  classes: {
    formControl: string;
    formLabel: string;
    input: string;
    checkbox: string;
    helper: string;
    creatableSelect: string;
  };
  value?:
    | any
    | {
        current: string;
        options: { value: string; label: string }[];
      };
  helperText?: string;
  component?: ReactElement;
  children?: React.ReactNode;
}

const FieldEditor: React.FC<FieldEditorProps> = ({
  type,
  name,
  label,
  labelPlacement,
  required = false,
  disabled = false,
  error = false,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  classes,
  value,
  helperText,
  component = <div />,
  children,
}) => {
  switch (type) {
    case INPUT_NUMBER:
    case INPUT_MULTILINE:
    case INPUT:
      return (
        <FormControl className={classes.formControl}>
          <TextField
            label={label}
            disabled={disabled}
            id={name}
            type={type === INPUT_NUMBER ? "number" : "text"}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value || ""}
            multiline={type === INPUT_MULTILINE}
            name={name}
            required={required}
            error={error}
            variant="outlined"
            className={classes.input}
          />
        </FormControl>
      );
    case RADIO_BUTTON:
      return (
        <FormControlLabel
          control={
            <Radio
              checked={value}
              disabled={disabled}
              onChange={onChange}
              value={String(value)}
              name={name}
              color="primary"
            />
          }
          label={label}
        />
      );
    case SWITCH:
      return (
        <FormControlLabel
          control={
            <Switch
              checked={value}
              disabled={disabled}
              onChange={onChange}
              value={String(value)}
              name={name}
              color="primary"
            />
          }
          label={label}
        />
      );
    case CHECK_BOX:
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              disabled={disabled}
              color="primary"
              onChange={onChange}
              value={String(value)}
              name={name}
              className={classes.checkbox}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
        />
      );
    case COMPONENT:
      return (
        <FormControl className={classes.formControl}>{component}</FormControl>
      );
    case SELECT:
      return (
        <FormControl className={classes.formControl}>
          <TextField
            id="outlined-select"
            disabled={disabled}
            select
            label={label}
            className={classes.input}
            value={value.current}
            onChange={onChange}
            error={error}
            SelectProps={{
              MenuProps: {
                className: classes.input,
              },
            }}
            helperText={helperText || ""}
            variant="outlined"
          >
            {value.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}

            {value.none && (
              // @ts-ignore
              <MenuItem value={null}>
                <em>{value.none}</em>
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      );
    case DATE_TIME:
      return (
        <FormControl className={classes.formControl}>
          <TextField
            label={label}
            disabled={disabled}
            type="datetime-local"
            onChange={onChange}
            value={(value && formatDateAndTime(value)) || ""}
            name={name}
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
          />
        </FormControl>
      );
    case DATE:
      return (
        <FormControl className={classes.formControl}>
          <TextField
            label={label}
            disabled={disabled}
            type="date"
            onChange={onChange}
            value={(value && formatDate(value)) || ""}
            name={name}
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant="outlined"
          />
        </FormControl>
      );
    case TIME:
      return (
        <FormControl className={classes.formControl}>
          <TextField
            label={label}
            disabled={disabled}
            type="time"
            onChange={onChange}
            value={value || ""}
            name={name}
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
          />
        </FormControl>
      );
    default:
      return (
        <FormControl className={classes.formControl}>
          {children || ""}
        </FormControl>
      );
  }
};

const styles = (theme) => ({
  formControl: {
    width: "100%",
    marginTop: 10,
  },
  formLabel: {
    fontSize: 14,
  },
  input: {
    fontSize: 14,
  },
  checkbox: {
    padding: '5px',
  },
  helper: {
    color: "red",
  },
  creatableSelect: {
    marginBottom: 2,
    borderBottom: " 1px solid grey",
    width: "100%",
    "&:hover": {
      borderBottom: "1.5px solid black",
    },
    "&:focus": {
      borderBottom: "1.5px solid blue",
    },
  },
});

export default withStyles(styles)(FieldEditor);
