import {
  GET_ADMIN_USERS_REQUEST,
  CREATE_ADMIN_USER_REQUEST,
  UPDATE_ADMIN_USER_REQUEST,
  DELETE_ADMIN_USER_REQUEST,
  GET_ADMIN_DRIVE_REQUEST,
  GET_ADMIN_USERS_SUCCESS,
  GET_ADMIN_DRIVE_SUCCESS,
  GET_ADMIN_USERS_ERROR,
  CREATE_ADMIN_USER_ERROR,
  UPDATE_ADMIN_USER_ERROR,
  DELETE_ADMIN_USER_ERROR,
  GET_ADMIN_DRIVE_ERROR
} from "../../../common/constants";

export const adminUsersRequest = () => {
  return {
    type: GET_ADMIN_USERS_REQUEST
  };
};

export const adminCreateUserRequest = user => {
  return {
    type: CREATE_ADMIN_USER_REQUEST,
    user
  };
};

export const adminUpdateUserRequest = (id, user) => {
  return {
    type: UPDATE_ADMIN_USER_REQUEST,
    id,
    user
  };
};

export const adminDeleteUserRequest = id => {
  return {
    type: DELETE_ADMIN_USER_REQUEST,
    id
  };
};

export const adminConnectToDriveRequest = () => {
  return {
    type: GET_ADMIN_DRIVE_REQUEST
  };
};

export const adminGetUsersSuccess = users => {
  return {
    type: GET_ADMIN_USERS_SUCCESS,
    users: users.data
  };
};

export const adminConnectToDriveSuccess = driveAuthUrl => {
  return {
    type: GET_ADMIN_DRIVE_SUCCESS,
    driveAuthUrl: driveAuthUrl.data
  };
};

export const adminGetUsersError = error => {
  return {
    type: GET_ADMIN_USERS_ERROR,
    error
  };
};

export const adminCreateUsersError = error => {
  return {
    type: CREATE_ADMIN_USER_ERROR,
    error
  };
};

export const adminUpdateUsersError = error => {
  return {
    type: UPDATE_ADMIN_USER_ERROR,
    error
  };
};

export const adminDeleteUsersError = error => {
  return {
    type: DELETE_ADMIN_USER_ERROR,
    error
  };
};

export const adminConnectToDriveError = error => {
  return {
    type: GET_ADMIN_DRIVE_ERROR,
    error
  };
};
