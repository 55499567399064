import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { formatDateAndTime } from "../../../../common/formats/date";
import TableWrapper from "../../../../common/components/table";
import { selectAdmin } from "../../selectors";

const UserTable = props => {
    const {
        classes,
        admin,
        onAddUser,
        onEditUser,
        onDeleteUser,
        onConnectToDrive,
        onShowUserLogs,
    } = props;
    const { users } = admin;

    const columns = [
        {
            Header: "Users",
            accessor: "email",
            headProps: { align: "inherit" },
            cellProps: {
                className: `${classes.tableCell}`,
                component: "th",
                scope: "row",
            },
            Cell: info => info || "n/a",
        },
        {
            Header: "Name",
            accessor: "name",
            headProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => info || "n/a",
        },
        {
            Header: "Surname",
            accessor: "surname",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => info || "n/a",
        },
        {
            Header: "Role",
            accessor: "role",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => info || "n/a",
        },
        {
            Header: "Last activity",
            accessor: "logs",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info =>
                (info.length && formatDateAndTime(info[info.length - 1].date)) || "n/a",
        },
        {
            Header: "Details activity",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => (
                <Button
                    onClick={() => onShowUserLogs(info)}
                    variant="outlined"
                    size="small"
                >
                    show details
                </Button>
            ),
        },
        {
            Header: "Whitelisted",
            accessor: "ip_restriction",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => (info ? "yes" : "no"),
        },
        {
            Header: "",
            headProps: { className: `${classes.tableCell}`, align: "right" },
            cellProps: {
                className: `${classes.tableCell}`,
                align: "right",
            },
            Cell: info => (
                <React.Fragment>
                    <IconButton
                        onClick={() => onEditUser(info)}
                        aria-label="Edit"
                        className={classes.button}
                    >
                        <EditIcon fontSize="small"/>
                    </IconButton>
                    <IconButton
                        onClick={() => onDeleteUser(info)}
                        aria-label="Delete"
                        className={classes.button}
                    >
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </React.Fragment>
            ),
        },
    ];

    const Head = () => (
        <div className={classes.tableHead}>
            <Typography variant="h6">Admin panel Randvast</Typography>
            <Button onClick={onConnectToDrive} className={classes.buttonHead}>
                + Connect to drive
            </Button>
            <Button onClick={onAddUser} className={classes.buttonHead}>
                + Add user
            </Button>
        </div>
    );

    return (
        <Paper className={classes.root}>
            {users && <TableWrapper Head={Head} data={users} columns={columns}/>}
        </Paper>
    );
};

const styles = theme => ({
    root: {
        width: "100%",
        height: "835px",
        marginTop: 18,
        overflowX: "auto",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    tableHead: {
        backgroundColor: theme.palette.primary[500],
        height: 56,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        paddingLeft: 100,
    },
    tableCell: {
        height: 56,
        padding: "4px 10px 2px 24px",
    },
    button: {
        marginBottom: 2,
        padding: 8,
    },
    buttonHead: {
        height: "inherit",
    },
});

UserTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    admin: selectAdmin(state),
});

export default connect(mapStateToProps)(withStyles(styles)(UserTable));
