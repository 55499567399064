import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FieldEditor from "../../../../common/components/editor/field";
import { DATE, INPUT, SELECT } from "../../../constants/field-types";
import Container from "./Container";
import SearchCompany from "../../../../modules/search/common/SearchCompany";
import { CollapsePanel } from "../../../../common/components/panel/";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import PostcodeForm from "./PostcodeForm";
import PhoneInput from "./phone-input";
import PlaceInput from "./place-input";
import { formatInitials } from "../../../formats";
import { selectEntity } from "../../../../modules/leadgen-head-menu/selectors";

interface EntityEditorProps {
  classes: {
    wrapper: string;
    button: string;
    fieldBlock: string;
    buttonAdd: string;
    buttonSearchKVK: string;
    buttons: string;
    table: string;
    phoneInput: string;
  };
  onChange: any;
  editableEntity?: {
    prefix?: string | null;
    company_name?: string;
    chamber_commerce_url?: string;
    chamber_commerce_number?: string;
    last_name?: string;
    first_name?: string;
    initials?: string;
    all_names?: string;
    nick_name?: string;
    birth_date?: Date | string;
    birth_year?: string;
    birth_place?: string;
    blacklisted?: boolean | string;
    is_dead?: boolean | string;
    gender?: boolean | string;
    kadaster_personal_number?: string;
    kadaster_objects_amount?: string;
    kadaster_url?: string;
    url?: string;
    company_id?: string;
    drive_folder_id?: string;
    addresses?: any;
    phone_numbers?: any;
    emails?: any;
    socials?: any;
  };
  entityType?: string;
  deleteLinkedCompany: () => void;
  validationMode: {
    company: boolean;
    person: boolean;
  };
}

const EntityEditor: React.FC<EntityEditorProps> = ({
  classes,
  onChange,
  editableEntity = {},
  entityType,
  deleteLinkedCompany,
  validationMode = {},
}) => {
  let {
    prefix,
    company_name,
    chamber_commerce_url,
    chamber_commerce_number,
    last_name,
    first_name,
    initials,
    all_names,
    nick_name,
    birth_date,
    birth_year,
    birth_place,
    blacklisted,
    is_dead,
    gender,
    kadaster_personal_number,
    kadaster_objects_amount,
    kadaster_url,
    url,
    company_id,
    drive_folder_id,
    addresses = [],
    phone_numbers = [],
    emails = [],
    socials = [],
  } = editableEntity;

  const addressFields = {
    type: "",
    street_name: "",
    city_name: "",
    number: "",
    number_add: "",
    postcode: "",
  };
  const phoneFields = {
    type: "",
    number: "",
  };
  const emailFields = {
    type: "",
    email: "",
  };
  const socialFields = {
    type: "",
    url: "",
  };

  const handleChange = (
    name,
    key,
    { target, keyCode, which, ...args },
    index
  ) => {
    let value = null;

    switch (name) {
      case "apiAddress":
        const { city, street_name, number, number_add, postcode } =
          target.value;

        addresses[index] = {
          ...addresses[index],
          city_name: city,
          street_name,
          number,
          number_add,
          postcode,
        };

        return onChange("addresses", { target: { value: addresses } });
      case "addresses":
        addresses[index] = {
          ...addresses[index],
          [key]: target.value.replace(/[^A-Za-z0-9]/g, "").toUpperCase(),
        };
        value = addresses;
        break;
      case "phone_numbers":
        phone_numbers[index] = { ...phone_numbers[index], [key]: target.value };
        value = phone_numbers;
        break;
      case "emails":
        emails[index] = { ...emails[index], [key]: target.value };
        value = emails;
        break;
      case "socials":
        socials[index] = { ...socials[index], [key]: target.value };
        value = socials;
        break;

      default:
        return onChange && onChange(name, { target: { value: value } });
    }
    onChange && onChange(name, { target: { value: value } });
  };

  const handleDelete = (name, index) => {
    let value = null;
    let deletedItem = [];
    let deleteMode = true;

    switch (name) {
      case "addresses":
        deletedItem = addresses.splice(index, 1);
        value = addresses;
        break;
      case "phone_numbers":
        deletedItem = phone_numbers.splice(index, 1);
        value = phone_numbers;
        break;
      case "emails":
        deletedItem = emails.splice(index, 1);
        value = emails;
        break;
      case "socials":
        deletedItem = socials.splice(index, 1);
        value = socials;
        break;

      default:
        return (
          onChange &&
          onChange(name, { target: { value: value } }, deleteMode, deletedItem)
        );
    }

    onChange &&
      onChange(name, { target: { value: value } }, deleteMode, deletedItem);
  };

  const handleAdd = (name) => {
    let value = null;
    if (name === "addresses") {
      if (JSON.stringify(addresses[0]) === JSON.stringify(addressFields))
        return;
      addresses.unshift(addressFields);
      value = addresses;
    }
    if (name === "phone_numbers") {
      if (JSON.stringify(phone_numbers[0]) === JSON.stringify(phoneFields))
        return;
      phone_numbers.unshift(phoneFields);
      value = phone_numbers;
    }
    if (name === "emails") {
      if (JSON.stringify(emails[0]) === JSON.stringify(emailFields)) return;
      emails.unshift(emailFields);
      value = emails;
    }
    if (name === "socials") {
      if (JSON.stringify(socials[0]) === JSON.stringify(socialFields)) return;
      socials.unshift(socialFields);
      value = socials;
    }
    onChange && onChange(name, { target: { value: value } });
  };

  const getSearchUrl = (type, person) => {
    const entity = person.company_name
      ? `${person.company_name}`
      : `${person.last_name || ""}+${person.first_name || ""}`.trim();

    switch (type) {
      case "facebook":
        return `https://www.${type}.com/search/str/${entity}/keywords_users`;
      case "linkedin":
        return `https://www.${type}.com/search/results/all/?keywords=${entity}`;
      case "twitter":
        return `https://${type}.com/search?f=users&vertical=default&q=${entity}`;
      case "kvk":
        return `https://www.${type}.nl/zoeken/?source=all&q=${entity}`;
      default:
        return "#";
    }
  };

  const selectCompany = (companyId) => {
    onChange("company_id", { target: { value: companyId } });
  };
  return (
    <div>
      <CollapsePanel header="General Fields" open={true}>
        <div>
          <div className={classes.table}>
            <Container
              leftColumn={[
                entityType === "company" && (
                  <FieldEditor
                    type={INPUT}
                    required
                    name="company_name"
                    label="Company Name"
                    // @ts-ignore
                    onChange={(e) => onChange("company_name", e)}
                    value={company_name || ""}
                    error={validationMode.company && !Boolean(company_name)}
                  />
                ),
                entityType === "company" && (
                  <div>
                    {company_name && (
                      <Button
                        className={classes.buttonSearchKVK}
                        href={getSearchUrl("kvk", {
                          company_name,
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="outlined"
                      >
                        {" "}
                        Search in kvk
                      </Button>
                    )}
                  </div>
                ),
                entityType === "company" && (
                  <FieldEditor
                    type={INPUT}
                    name="chamber_commerce_number"
                    label="KvK Number"
                    // @ts-ignore
                    onChange={(e) => onChange("chamber_commerce_number", e)}
                    value={chamber_commerce_number || ""}
                  />
                ),
                entityType === "company" && (
                  <FieldEditor
                    type={INPUT}
                    name="chamber_commerce_url"
                    label="KvK Link"
                    // @ts-ignore
                    onChange={(e) => onChange("chamber_commerce_url", e)}
                    value={chamber_commerce_url || ""}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={INPUT}
                    name="first_name"
                    label="First Name"
                    // @ts-ignore
                    onChange={(e) => onChange("first_name", e)}
                    value={first_name || ""}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={INPUT}
                    name="prefix"
                    label="Prefix"
                    // @ts-ignore
                    onChange={(e) => onChange("prefix", e)}
                    value={prefix || ""}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={INPUT}
                    required
                    name="last_name"
                    label="Last Name"
                    // @ts-ignore
                    onChange={(e) => onChange("last_name", e)}
                    value={last_name || ""}
                    error={validationMode.person && !Boolean(last_name)}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={INPUT}
                    name="initials"
                    label="Initials"
                    // @ts-ignore
                    onChange={({ target }) =>
                      onChange("initials", {
                        target: { value: formatInitials(target.value) },
                      })
                    }
                    onFocus={() =>
                      onChange("initials", {
                        target: { value: formatInitials(initials) },
                      })
                    }
                    onBlur={() =>
                      onChange("initials", {
                        target: { value: formatInitials(initials, true) },
                      })
                    }
                    value={initials || ""}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={INPUT}
                    name="all_names"
                    label="All Names"
                    // @ts-ignore
                    onChange={(e) => onChange("all_names", e)}
                    value={all_names || ""}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={INPUT}
                    name="nick_name"
                    label="Nick Name"
                    // @ts-ignore
                    onChange={(e) => onChange("nick_name", e)}
                    value={nick_name || ""}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={SELECT}
                    name="gender"
                    label="Gender"
                    // @ts-ignore
                    onChange={(e) => onChange("gender", e)}
                    value={
                      {
                        current: gender || "",
                        none: "M/V",
                        options: [
                          { value: "M", label: "Mr." },
                          { value: "V", label: "Ms." },
                        ],
                      } || {}
                    }
                  />
                ),
                <FieldEditor
                  type={SELECT}
                  name="blacklisted"
                  label="Blacklisted"
                  // @ts-ignore
                  onChange={(e) => onChange("blacklisted", e)}
                  value={
                    {
                      current:
                        blacklisted === undefined || blacklisted === null
                          ? ""
                          : blacklisted,
                      none: "n/a",
                      options: [
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ],
                    } || {}
                  }
                />,
              ]}
              rightColumn={[
                entityType === "person" && (
                  <FieldEditor
                    type={DATE}
                    name="birth_date"
                    label="D.o.B."
                    // @ts-ignore
                    onChange={(e) => onChange("birth_date", e)}
                    value={birth_date || ""}
                  />
                ),
                entityType === "person" && (
                  <FieldEditor
                    type={INPUT}
                    name="birth_year"
                    label="Y.o.B."
                    // @ts-ignore
                    onChange={(e) => onChange("birth_year", e)}
                    value={birth_year || ""}
                  />
                ),
                entityType === "person" && (
                  <PlaceInput
                    onSelect={(value) =>
                      onChange("birth_place", { target: { value } })
                    }
                    limit={5}
                    value={birth_place || ""}
                  />
                ),
                <FieldEditor
                  type={INPUT}
                  name="kadaster_personal_number"
                  label="K.P.N."
                  // @ts-ignore
                  onChange={(e) => {
                    onChange("kadaster_personal_number", e);
                  }}
                  value={kadaster_personal_number || ""}
                />,
                <FieldEditor
                  type={INPUT}
                  name="kadaster_objects_amount"
                  label="Kad. Rechthebbende"
                  // @ts-ignore
                  onChange={(e) => onChange("kadaster_objects_amount", e)}
                  value={kadaster_objects_amount || ""}
                />,
                <FieldEditor
                  type={INPUT}
                  name="kadaster_url"
                  label="Kad. Link"
                  // @ts-ignore
                  onChange={(e) => onChange("kadaster_url", e)}
                  value={kadaster_url || ""}
                />,
                <FieldEditor
                  type={INPUT}
                  name="url"
                  label="Link"
                  // @ts-ignore
                  onChange={(e) => onChange("url", e)}
                  value={url || ""}
                  // error={
                  // validationMode.person || validationMode.company
                  // ? !url
                  //   ? false
                  //   : !validateURL(url)
                  // : false
                  // }
                />,
                entityType === "person" && (
                  <FieldEditor
                    type={SELECT}
                    name="is_dead"
                    label="Alive"
                    // @ts-ignore
                    onChange={(e) => onChange("is_dead", e)}
                    value={
                      {
                        current:
                          is_dead === undefined || is_dead === null
                            ? ""
                            : is_dead,
                        none: "n/a",
                        options: [
                          { value: false, label: "Yes" },
                          { value: true, label: "No" },
                        ],
                      } || {}
                    }
                  />
                ),
              ]}
            />
          </div>
          <SearchCompany
            deleteLinkedCompany={deleteLinkedCompany}
            company_id={company_id}
            selectCompany={selectCompany}
          />
        </div>
      </CollapsePanel>
      <CollapsePanel header="Addresses" open={false}>
        <div className={classes.wrapper}>
          {addresses.map(
            (
              { street_name, city_name, number, number_add, postcode, type },
              index
            ) => (
              <div key={index} className={classes.fieldBlock}>
                <div className={classes.buttons}>
                  <IconButton
                    onClick={() => handleDelete("addresses", index)}
                    aria-label="Delete"
                    className={classes.button}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
                <PostcodeForm
                  // @ts-ignore
                  onSelect={(e) =>
                    handleChange("apiAddress", "address", e, index)
                  }
                />
                <div className={classes.table}>
                  <Container
                    leftColumn={[
                      <FieldEditor
                        type={INPUT}
                        name="type"
                        label="Type"
                        // @ts-ignore
                        onChange={(e) =>
                          handleChange("addresses", "type", e, index)
                        }
                        value={type || ""}
                      />,
                      <FieldEditor
                        type={INPUT}
                        name="street_name"
                        label="Street"
                        // @ts-ignore
                        onChange={(e) =>
                          handleChange("addresses", "street_name", e, index)
                        }
                        value={street_name || ""}
                      />,
                      <FieldEditor
                        type={INPUT}
                        name="postcode"
                        label="Postcode"
                        // @ts-ignore
                        onChange={(e) =>
                          handleChange("addresses", "postcode", e, index)
                        }
                        value={postcode || ""}
                      />,
                    ]}
                    rightColumn={[
                      <FieldEditor
                        type={INPUT}
                        name="city_name"
                        label="City"
                        // @ts-ignore
                        onChange={(e) =>
                          handleChange("addresses", "city_name", e, index)
                        }
                        value={city_name || ""}
                      />,
                      <FieldEditor
                        type={INPUT}
                        name="number"
                        label="Number"
                        // @ts-ignore
                        onChange={(e) =>
                          handleChange("addresses", "number", e, index)
                        }
                        value={number || ""}
                      />,
                      <FieldEditor
                        type={INPUT}
                        name="number_add"
                        label="Number Add"
                        // @ts-ignore
                        onChange={(e) =>
                          handleChange("addresses", "number_add", e, index)
                        }
                        value={number_add || ""}
                      />,
                    ]}
                  />
                </div>
              </div>
            )
          )}
          <Button
            className={classes.buttonAdd}
            onClick={() => handleAdd("addresses")}
          >
            add new address
          </Button>
        </div>
      </CollapsePanel>
      <CollapsePanel header="Phone Numbers" open={false}>
        <div className={classes.wrapper}>
          {phone_numbers.map(({ number, type }, index) => (
            <div key={index} className={classes.fieldBlock}>
              <div className={classes.buttons}>
                <IconButton
                  onClick={() => handleDelete("phone_numbers", index)}
                  aria-label="Delete"
                  className={classes.button}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
              <div className={classes.table}>
                <Container
                  leftColumn={[
                    <FieldEditor
                      type={INPUT}
                      name="type"
                      label="Type"
                      // @ts-ignore
                      onChange={(e) =>
                        handleChange("phone_numbers", "type", e, index)
                      }
                      value={type || ""}
                    />,
                  ]}
                  rightColumn={[
                    <FieldEditor>
                      <div className={classes.phoneInput}>
                        <PhoneInput
                          country="NL"
                          placeholder="Enter phone number"
                          value={number || ""}
                          onChange={(value = "") => {
                            handleChange(
                              "phone_numbers",
                              "number",
                              // @ts-ignore
                              { target: { value } },
                              index
                            );
                          }}
                        />
                      </div>
                    </FieldEditor>,
                  ]}
                />
              </div>
            </div>
          ))}
          <Button
            className={classes.buttonAdd}
            onClick={() => handleAdd("phone_numbers")}
          >
            add new phone
          </Button>
        </div>
      </CollapsePanel>
      <CollapsePanel header="Emails" open={false}>
        <div className={classes.wrapper}>
          {emails.map(({ email, type }, index) => (
            <div key={index} className={classes.fieldBlock}>
              <div className={classes.buttons}>
                <IconButton
                  onClick={() => handleDelete("emails", index)}
                  aria-label="Delete"
                  className={classes.button}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
              <div className={classes.table}>
                <Container
                  leftColumn={[
                    <FieldEditor
                      type={INPUT}
                      name="type"
                      label="Type"
                      // @ts-ignore
                      onChange={(e) => handleChange("emails", "type", e, index)}
                      value={type || ""}
                    />,
                  ]}
                  rightColumn={[
                    <FieldEditor
                      type={INPUT}
                      name="email"
                      label="Email"
                      // @ts-ignore
                      onChange={(e) =>
                        handleChange("emails", "email", e, index)
                      }
                      value={email || ""}
                    />,
                  ]}
                />
              </div>
            </div>
          ))}
          <Button
            className={classes.buttonAdd}
            onClick={() => handleAdd("emails")}
          >
            add new email
          </Button>
        </div>
      </CollapsePanel>
      <CollapsePanel header="Social Media" open={false}>
        <div className={classes.wrapper}>
          {socials.map(({ url, type }, index) => (
            <div key={index} className={classes.fieldBlock}>
              <div className={classes.buttons}>
                {type && (last_name || first_name || company_name) && (
                  <Button
                    href={getSearchUrl(type, {
                      last_name,
                      first_name,
                      company_name,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                  >
                    Search in {type}
                  </Button>
                )}
                <IconButton
                  onClick={() => handleDelete("socials", index)}
                  aria-label="Delete"
                  className={classes.button}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
              <div className={classes.table}>
                <Container
                  leftColumn={[
                    <FieldEditor
                      type={SELECT}
                      name="type"
                      label="Type"
                      // @ts-ignore
                      onChange={(e) =>
                        handleChange("socials", "type", e, index)
                      }
                      value={
                        {
                          current: type || "",
                          options: [
                            { value: "facebook", label: "Facebook" },
                            { value: "twitter", label: "Twitter" },
                            { value: "linkedin", label: "LinkedIn" },
                          ],
                        } || {}
                      }
                    />,
                  ]}
                  rightColumn={[
                    <FieldEditor
                      type={INPUT}
                      name="url"
                      label="Link"
                      // @ts-ignore
                      onChange={(e) => handleChange("socials", "url", e, index)}
                      value={url || ""}
                    />,
                  ]}
                />
              </div>
            </div>
          ))}
          <Button
            className={classes.buttonAdd}
            onClick={() => handleAdd("socials")}
          >
            add new social net.
          </Button>
        </div>
      </CollapsePanel>
      <CollapsePanel header="Google Drive" open={false}>
        <div className={classes.wrapper}>
          <FieldEditor
            type={INPUT}
            name="drive_folder_id"
            label="Google drive folder link"
            // @ts-ignore
            onChange={(e) => onChange("drive_folder_id", e)}
            value={
              `https://drive.google.com/drive/folders/${drive_folder_id}` || ""
            }
          />
        </div>
      </CollapsePanel>
    </div>
  );
};

// EntityEditor.propTypes = {
//     classes: PropTypes.object.isRequired,
//     entityType: PropTypes.string,
// };

const styles = (theme) => ({
  wrapper: {
    width: "100%",
  },
  fieldBlock: {
    marginBottom: 15,
  },
  buttonAdd: {
    display: "flex",
    width: "100%",
  },
  buttonSearchKVK: {
    display: "flex",
    width: "100%",
    marginTop: 10,
  },
  button: {
    padding: 8,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  table: {
    display: "table",
    width: "100%",
  },
  phoneInput: {
    display: "flex",
    alignItems: "center",
    height: 54,
    fontFamily: "Roboto",
    paddingLeft: 12,
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    "&:hover": {
      borderColor: theme.palette.primary[500],
      borderWidth: 2,
      height: 52,
      paddingLeft: 11,
    },
  },
});

const mapStateToProps = (state) => ({
  currentEntity: selectEntity(state),
});
export default connect(mapStateToProps)(withStyles(styles)(EntityEditor));
