import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CollapsePanel } from "../../../../common/components/panel";
import { Button, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const WhitelistEditor = ({ ips, classes, onChange }) => {
  const ipFields = {
    ip: "",
    ip_type: "",
    is_allowed: true,
  };

  const handleChange = (index, key, { target }) => {
    const ipsForUpdate = [];
    let { value, checked } = target;

    value = key === "is_allowed" ? checked : value;
    ips[index] = { ...ips[index], [key]: value };
    if (ips[index].id) ipsForUpdate.push(ips[index]);
    onChange && onChange({ target: { value: ips }, ipsForUpdate });
  };

  const handleDelete = (index) => {
    const ipsForDelete = [];
    const deletedItem = ips.splice(index, 1);
    /**removes only one ip per operation**/
    ipsForDelete.push(deletedItem[0]);
    onChange && onChange({ target: { value: ips }, ipsForDelete });
  };
  const handleAdd = () => {
    if (ips.length && ips[0].ip === "") return;

    ips.unshift(ipFields);
    onChange && onChange({ target: { value: ips } });
  };

  const handleEdit = (index) => {
    const item = ips[index];

    ips.splice(index, 1);
    ips.length && ips[0].ip === "" && ips.splice(0, 1);
    ips.unshift(item);
    onChange && onChange({ target: { value: ips } });
  };

  return (
    <div>
      <CollapsePanel open={false} header="Whitelisted IP's">
        <div className={classes.root}>
          {ips.map(({ ip, ip_type, is_allowed }, index) => {
            if (index === 0) {
              return (
                <React.Fragment key={index}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label="IP"
                      id="id_ip"
                      onChange={(e) => handleChange(index, "ip", e)}
                      value={ip || ""}
                      name="ip_whitelist"
                      variant="outlined"
                      required
                      className={classes.input}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label="Type"
                      id="id_ip_type"
                      onChange={(e) => handleChange(index, "ip_type", e)}
                      value={ip_type || ""}
                      name="ip_whitelist"
                      variant="outlined"
                      className={classes.input}
                    />
                  </FormControl>
                  <div className={classes.switch}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={is_allowed}
                          onChange={(e) => handleChange(index, "is_allowed", e)}
                          value={String(is_allowed)}
                          name="is_allowed"
                          color="primary"
                        />
                      }
                      label="Allow"
                    />
                  </div>
                </React.Fragment>
              );
            }
            return (
              <div key={index}>
                <div className={classes.row}>
                  <Typography variant="h6">
                    {ip} {ip_type}
                  </Typography>
                  <div>
                    <IconButton
                      onClick={() => handleEdit(index)}
                      aria-label="Edit"
                      className={classes.button}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(index)}
                      aria-label="Delete"
                      className={classes.button}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
                <div className={classes.switch}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={is_allowed}
                        onChange={(e) => handleChange(index, "is_allowed", e)}
                        value={String(is_allowed)}
                        name="is_allowed"
                        color="primary"
                      />
                    }
                    label="Allow"
                  />
                </div>
              </div>
            );
          })}
          <Button className={classes.buttonAdd} onClick={handleAdd}>
            add new ip
          </Button>
        </div>
      </CollapsePanel>
    </div>
  );
};

WhitelistEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    height: 20,
    alignItems: "center",
  },
  switch: {
    display: "flex",
  },
  formControl: {
    width: "100%",
    marginBottom: 4,
    marginTop: 4,
  },
  formLabel: {
    fontSize: 14,
  },
  input: {
    fontSize: 14,
  },
  buttonAdd: {
    display: "flex",
    width: "100%",
  },
  button: {
    marginBottom: 2,
    padding: 8,
  },
});

export default withStyles(styles)(WhitelistEditor);
