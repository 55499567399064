import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  editLPSConfigsRequest,
  deleteLPSConfigsRequest,
} from "../search/actions/lps";
import { withStyles } from "@material-ui/core/styles";
import {
  INPUT,
  INPUT_NUMBER,
  SELECT,
} from "../../common/constants/field-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/AddRounded";
import Save from "@material-ui/icons/SaveRounded";
import Delete from "@material-ui/icons/DeleteRounded";
import FieldEditor from "../../common/components/editor/field";
import SearchCity from "../search/filter-menu/SearchCity";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { selectSearch } from "../search/selectors";
import { selectUser } from "../login/selectors";
import { validateLPSConfig } from "../../common/helpers/validator";

class LPSForm extends PureComponent {
  state = this.emptyState;

  get objectConditionOptions() {
    return ["bigger than", "equal", "less than"].map((i) => ({
      value: i,
      label: i,
    }));
  }

  get objectVariableOptions() {
    return [
      "units amount",
      "non housing units",
      "built year",
      "owners amount",
      "WOZ-value",
    ].map((i) => ({ value: i, label: i }));
  }

  get emptyState() {
    return {
      id: null,
      name: "",
      lps_config_cities: [],
      lps_config_entity_KOA_rules: {
        lps_config_entity_KOA_points: "",
      },
      lpsc_object_condition_rules: [
        {
          variable: "units amount",
          condition: "",
          condition_value: "",
          condition_points: "",
        },
      ],
      action_types: [],
      cityForDelete: [],
      validationMode: false,
      objectRulesForDelete: [],
      // base_points: 0,
      // config_type: "",
      // lps_config_entity_rules: {},
      // lpsc_entity_objects_rules: [],
      // lpsc_entity_age_rules: [],
      // lpsc_object_label_rules: [],
      // labelForDelete: [],
      // entityAgeForDelete: [],
      // entityObjForDelete: [],
    };
  }

  initialState = (lpsConfig) => {
    this.setState({ ...lpsConfig });
  };

  removeLPSConfig = () => {
    const { dispatch, user } = this.props;
    const { userData } = user;
    const lpsConfig = this.state;
    const id = lpsConfig && lpsConfig.id ? lpsConfig.id : null;
    if (userData && userData.role !== "User") {
      dispatch(deleteLPSConfigsRequest(id));
    }

    this.clearForm();
  };

  saveLPSConfigs = () => {
    const { dispatch, router, user } = this.props;
    const { userData } = user;
    const {
      action_types,
      objectRulesForDelete,
      cityForDelete,
      // labelForDelete,
      // entityAgeForDelete,
      // entityObjForDelete,
    } = this.state;
    const lpsConfig = this.state;
    const id = lpsConfig && lpsConfig.id ? lpsConfig.id : null;
    const isValidated = validateLPSConfig(lpsConfig);
    if (isValidated === false) {
      return this.setState({
        validationMode: true,
      });
    }
    if (userData && userData.role !== "User") {
      /** probably later we need to add props.user_id here to check who created this config**/
      isValidated &&
        dispatch(
          editLPSConfigsRequest(
            {
              id,
              action_types,
              // labelForDelete,
              // entityAgeForDelete,
              // entityObjForDelete,
              objectRulesForDelete,
              cityForDelete,
              ...lpsConfig,
            },
            router
          )
        );
    }

    this.clearForm();
  };

  clearForm = () => {
    this.setState(this.emptyState);
  };

  addNewLPSConfig = () => {
    const {
      lps_config_cities,
      lps_config_entity_KOA_rules,
      lpsc_object_condition_rules,
      // lps_config_entity_rules,
      // lpsc_entity_objects_rules,
      // lpsc_entity_age_rules,
      // lpsc_object_label_rules,
      action_types,
    } = this.state;

    // let objRules;
    // for (let key in lpsc_object_condition_rules) {
    //   objRules = {
    //     ...objRules,
    //     [key]: {
    //       condition_name: lpsc_object_condition_rules[key].condition_name,
    //       condition_data: lpsc_object_condition_rules[key].condition_data,
    //       condition_value: lpsc_object_condition_rules[key].condition_value,
    //     },
    //   };
    // }

    // for (let key in lps_config_entity_rules) {
    //   if (key === "id" || key === "lps_config_id")
    //     delete lps_config_entity_rules[key];
    // }

    this.setState({
      name: "",
      id: null,
      lps_config_cities: lps_config_cities.map((city) => {
        return { city_name: city.city_name };
      }),
      lps_config_entity_KOA_rules,
      lpsc_object_condition_rules,
      action_types,
      objectRulesForDelete: [],
      cityForDelete: [],
      // lpsc_object_condition_rules: objRules,
      // lps_config_entity_rules: { ...lps_config_entity_rules },
      // lpsc_entity_objects_rules: lpsc_entity_objects_rules.map((rule) => {
      //   for (let key in rule) {
      //     if (key === "id" || key === "lps_config_id") delete rule[key];
      //   }
      //   return { ...rule };
      // }),
      // lpsc_entity_age_rules: lpsc_entity_age_rules.map((rule) => {
      //   for (let key in rule) {
      //     if (key === "id" || key === "lps_config_id") delete rule[key];
      //   }
      //   return { ...rule };
      // }),
      // lpsc_object_label_rules: {
      //   labelRules: lpsc_object_label_rules.labelRules.map((rule) => {
      //     for (let key in rule) {
      //       if (key === "id" || key === "lps_config_id") delete rule[key];
      //     }
      //     return { ...rule };
      //   }),
      // },
      // labelForDelete: [],
      // entityAgeForDelete: [],
      // entityObjForDelete: [],
    });
  };

  handleChangeObjectConditionRules = (
    blockName,
    fieldName,
    { target },
    index
  ) => {
    /** block name is not used here yet **/
    const { lpsc_object_condition_rules, action_types } = this.state;
    const currentType = "lpsc_object_condition_rules";
    const editableBlock = lpsc_object_condition_rules;
    editableBlock[index] = {
      ...editableBlock[index],
      [fieldName]: target.value,
    };

    return this.setState({
      [blockName]: [...editableBlock],
      action_types: action_types.includes(currentType)
        ? action_types
        : [currentType, ...action_types],
    });
  };

  handleChange = (fieldName, { target }) => {
    const { action_types } = this.state;
    let value = target.value;
    const currentType = "general_fields";
    if (fieldName === "name") value = value.toUpperCase();
    this.setState({
      [fieldName]: value,
      action_types: action_types.includes(currentType)
        ? action_types
        : [currentType, ...action_types],
    });
  };

  handleChangeEntityKOA = (blockName, fieldName, { target }) => {
    const { action_types, lps_config_entity_KOA_rules } = this.state;
    const currentType = "lps_config_entity_KOA_rules";
    let editableBlock = lps_config_entity_KOA_rules;
    editableBlock = {
      ...editableBlock,
      [fieldName]: target.value,
    };

    return this.setState({
      [blockName]: editableBlock,
      action_types: action_types.includes(currentType)
        ? action_types
        : [currentType, ...action_types],
    });
  };

  selectCity = (city) => {
    const { lps_config_cities, action_types } = this.state;
    const currentType = "cities";
    this.setState({
      lps_config_cities: [...lps_config_cities, { city_name: city }],
      action_types: action_types.includes(currentType)
        ? action_types
        : [currentType, ...action_types],
    });
  };

  removeCity = (index) => {
    const { lps_config_cities, cityForDelete, action_types } = this.state;
    const currentType = "cities";
    const deletedItem = lps_config_cities.splice(index, 1);
    if (Object.keys(deletedItem[0]).length > 0 && deletedItem[0].id) {
      return this.setState({
        cities: [...lps_config_cities],
        cityForDelete: [...cityForDelete, deletedItem[0]],
        action_types: action_types.includes(currentType)
          ? action_types
          : [currentType, ...action_types],
      });
    } else {
      this.setState({
        cities: [...lps_config_cities],
      });
    }
  };

  handleDelete = (type, blockName, index) => {
    // const { labelForDelete, entityAgeForDelete, action_types } = this.state;
    const { objectRulesForDelete, action_types } = this.state;
    const additableType = this.state[type] || {};
    const additableTypeBlock = additableType[blockName] || [];
    let deletedItem;
    const currentType = type;
    if (blockName === "lpsc_object_condition_rules") {
      deletedItem = additableType.splice(index, 1);
      if (Object.keys(deletedItem[0]).length > 0 && deletedItem[0].id) {
        return this.setState({
          [type]: [...additableType],
          objectRulesForDelete: [...objectRulesForDelete, deletedItem[0]],
          action_types: action_types.includes(currentType)
            ? action_types
            : [currentType, ...action_types],
        });
      } else {
        return this.setState({
          [type]: [...additableType],
        });
      }
    }
    // if (blockName === "labelRules") {
    //   deletedItem = additableTypeBlock.splice(index, 1);
    //   if (Object.keys(deletedItem[0]).length > 0 && deletedItem[0].id) {
    //     return this.setState({
    //       [type]: { ...additableType, [blockName]: [...additableTypeBlock] },
    //       labelForDelete: [...labelForDelete, deletedItem[0]],
    //       action_types: action_types.includes(currentType)
    //         ? action_types
    //         : [currentType, ...action_types],
    //     });
    //   } else {
    //     return this.setState({
    //       [type]: { ...additableType, [blockName]: [...additableTypeBlock] },
    //     });
    //   }
    // }
    // if (blockName === "lpsc_entity_age_rules") {
    //   deletedItem = additableType.splice(index, 1);
    //   if (Object.keys(deletedItem[0]).length > 0 && deletedItem[0].id) {
    //     return this.setState({
    //       [type]: [...additableType],
    //       entityAgeForDelete: [...entityAgeForDelete, deletedItem[0]],
    //       action_types: action_types.includes(currentType)
    //         ? action_types
    //         : [currentType, ...action_types],
    //     });
    //   } else {
    //     return this.setState({
    //       [type]: [...additableType],
    //     });
    //   }
    // }

    // if (blockName === "lpsc_entity_objects_rules") {
    //   deletedItem = additableType.splice(index, 1);
    //   if (Object.keys(deletedItem[0]).length > 0 && deletedItem[0].id) {
    //     return this.setState({
    //       [type]: [...additableType],
    //       entityObjForDelete: [...entityAgeForDelete, deletedItem[0]],
    //       action_types: action_types.includes(currentType)
    //         ? action_types
    //         : [currentType, ...action_types],
    //     });
    //   } else {
    //     return this.setState({
    //       [type]: [...additableType],
    //     });
    //   }
    // }

    this.setState({
      [type]: { ...additableType, [blockName]: [...additableTypeBlock] },
    });
  };

  handleAdd = (type, blockName) => {
    const additableType = this.state[type] || {};
    let additableTypeBlock;
    if (blockName === "lpsc_object_condition_rules") {
      additableTypeBlock = additableType[blockName] || {};
      return this.setState({
        [blockName]: [...additableType, additableTypeBlock],
      });
    }
    // if (
    //   blockName === "lpsc_entity_objects_rules" ||
    //   blockName === "lpsc_entity_age_rules"
    // ) {
    //   const additableTypeBlock = additableType[blockName] || {};
    //
    //   return this.setState({
    //     [blockName]: [...additableType, additableTypeBlock],
    //   });
    // }

    // additableTypeBlock = additableType[blockName] || [];
    //
    // this.setState({
    //   [type]: { ...additableType, [blockName]: [...additableTypeBlock, {}] },
    // });
  };

  render() {
    const {
      name,
      lps_config_cities,
      lpsc_object_condition_rules,
      lps_config_entity_KOA_rules,
      // lps_config_entity_rules,
      // lpsc_entity_objects_rules,
      // lpsc_entity_age_rules,
      // config_type,
      // lpsc_object_label_rules,
      validationMode,
    } = this.state;

    // const {
    //   hasUnits = {},
    //   isNotHousing = {},
    //   hasUnitSizeMoreThan = {},
    //   hasUnitSizeLessThan = {},
    //   hasUnitSizeBetween = {},
    //   builtYear = {},
    //   hasOwners = {},
    // } = lpsc_object_condition_rules;

    // const { labelRules = [] } = lpsc_object_label_rules;
    const { classes, search } = this.props;
    const { lpsConfig } = search;
    const configs = lpsConfig ? lpsConfig || [] : [];

    return (
      <Grid container>
        <Grid item xs={12} className={classes.item}>
          <Paper className={classes.content}>
            <div className={classes.header}>
              <Grid item xs={4} className={classes.headGridItem}>
                <div>
                  <IconButton
                    title="Clear form"
                    onClick={this.clearForm}
                    className={classes.iconButtonLeft}
                  >
                    <Clear />
                  </IconButton>
                  <IconButton
                    title="Clone LPS Config By Current"
                    onClick={this.addNewLPSConfig}
                    className={classes.iconButtonRight}
                  >
                    <Add />
                  </IconButton>
                  <IconButton
                    title="Save LPS Configs"
                    onClick={this.saveLPSConfigs}
                    className={classes.iconButtonRight}
                  >
                    <Save />
                  </IconButton>
                  <IconButton
                    title="Delete LPS Config"
                    onClick={this.removeLPSConfig}
                    className={classes.iconButtonRight}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.headGridItem}>
                <div className={classes.headerText}>
                  <Typography variant="h6">Lead Priority System</Typography>
                  <Typography variant="caption">configuration form</Typography>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.headGridLastItem}>
                <PopupState variant="popover" popupId="popup-label-filter-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button>
                        <span {...bindTrigger(popupState)}>select lps</span>
                      </Button>
                      {configs && Boolean(configs.length) && (
                        <Menu {...bindMenu(popupState)}>
                          {configs.map((config) => (
                            <MenuItem
                              key={config.id}
                              onClick={() => {
                                popupState.close();
                                this.initialState(config);
                              }}
                            >
                              {config.name}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </React.Fragment>
                  )}
                </PopupState>
              </Grid>
            </div>
            <div className={classes.body}>
              <div className={classes.headRow}>
                <div className={classes.headRowInput}>
                  <FieldEditor
                    type={INPUT}
                    name="name"
                    label="NAME"
                    onChange={(e) => this.handleChange("name", e)}
                    value={name}
                    error={validationMode && !Boolean(name)}
                  />
                </div>
                <div className={classes.headRowInput}>
                  <div className={classes.searchCity}>
                    <SearchCity
                      focus={false}
                      selectCity={this.selectCity}
                      error={
                        validationMode && !Boolean(lps_config_cities.length)
                      }
                    />
                  </div>
                  {!!lps_config_cities.length && (
                    <div className={classes.citiesContainer}>
                      {lps_config_cities.map((city, index) => (
                        <div key={index} className={classes.cityContainer}>
                          <Typography
                            className={classes.cityText}
                            variant="subtitle1"
                          >
                            {city.city_name}
                          </Typography>
                          <Clear
                            className={classes.cityRemoveButton}
                            onClick={() => this.removeCity(index)}
                            fontSize="small"
                          />
                          {lps_config_cities.length - 1 !== index && (
                            <span className={classes.citySeparator}>,</span>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.sideBlock}>
                <div className={classes.sideBlockHead}>
                  <Typography variant="subtitle1">
                    Object points condition
                  </Typography>
                  <Button
                    disabled={lpsc_object_condition_rules.length >= 5}
                    onClick={() =>
                      this.handleAdd(
                        "lpsc_object_condition_rules",
                        "lpsc_object_condition_rules"
                      )
                    }
                    className={classes.sideBlockHeadButtonAdd}
                  >
                    <Add />
                  </Button>
                </div>
                <div className={classes.sideBlockForm}>
                  {lpsc_object_condition_rules.length > 0 &&
                    lpsc_object_condition_rules.map(
                      (
                        {
                          variable,
                          condition,
                          condition_value,
                          condition_points,
                        },
                        index
                      ) => (
                        <div key={index} className={classes.formRow}>
                          <FieldEditor
                            type={SELECT}
                            name="variable"
                            label="Variable"
                            onChange={(e) =>
                              this.handleChangeObjectConditionRules(
                                "object_points_condition_rules",
                                "variable",
                                e,
                                index
                              )
                            }
                            value={{
                              current: variable || "",
                              options: this.objectVariableOptions,
                            }}
                          />
                          <FieldEditor
                            type={SELECT}
                            name={"condition"}
                            label={"Condition"}
                            onChange={(e) =>
                              this.handleChangeObjectConditionRules(
                                "object_points_condition_rules",
                                "condition",
                                e,
                                index
                              )
                            }
                            value={{
                              current: condition || "",
                              options: this.objectConditionOptions,
                            }}
                          />
                          <FieldEditor
                            type={INPUT_NUMBER}
                            name={"value"}
                            label={"Value"}
                            onChange={(e) =>
                              this.handleChangeObjectConditionRules(
                                "object_points_condition_rules",
                                "condition_value",
                                e,
                                index
                              )
                            }
                            value={condition_value}
                          />
                          <FieldEditor
                            type={INPUT_NUMBER}
                            name={"points"}
                            label={"Points"}
                            onChange={(e) =>
                              this.handleChangeObjectConditionRules(
                                "object_points_condition_rules",
                                "condition_points",
                                e,
                                index
                              )
                            }
                            value={condition_points}
                          />
                          <IconButton
                            onClick={() =>
                              this.handleDelete(
                                "lpsc_object_condition_rules",
                                "lpsc_object_condition_rules",
                                index
                              )
                            }
                            aria-label="Delete"
                            className={classes.buttonDelete}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className={classes.sideBlock}>
                <div className={classes.sideBlockHead}>
                  <Typography variant="subtitle1">
                    Entity kadaster objects amount points
                  </Typography>
                </div>
                <div className={classes.sideBlockForm}>
                  <div className={classes.formRow}>
                    <FieldEditor
                      type={INPUT_NUMBER}
                      name={"lps_config_entity_KOA_points"}
                      label={"Points"}
                      onChange={(e) =>
                        this.handleChangeEntityKOA(
                          "lps_config_entity_KOA_rules",
                          "lps_config_entity_KOA_points",
                          e
                        )
                      }
                      value={
                        lps_config_entity_KOA_rules &&
                        lps_config_entity_KOA_rules.lps_config_entity_KOA_points
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export const styles = (theme) => ({
  item: {
    width: "100%",
    display: "flex",
    marginTop: 18,
    flexDirection: "column",
  },
  headGridLastItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  citiesContainer: {
    color: theme.palette.primary[500],
    padding: "0 4px",
    marginTop: 6,
  },
  cityContainer: {
    display: "inline-flex",
    alignItems: "center",
  },
  cityText: {
    color: theme.palette.primary[500],
  },
  cityRemoveButton: {
    marginLeft: 2,
    "&:hover": {
      color: theme.palette.secondary.error,
    },
  },
  citySeparator: {
    marginRight: 4,
  },
  iconButtonLeft: {
    marginLeft: 4,
  },
  iconButtonRight: {
    marginRight: 4,
  },
  sideBlockHead: {
    backgroundColor: theme.palette.primary[400],
    height: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "4px 4px 0 0",
  },
  sideBlockHeadButtonAdd: {
    height: "inherit",
    minWidth: 42,
    borderRadius: 0,
    padding: 8,
  },
  sideBlockForm: {
    padding: "12px 20px 20px",
  },
  searchCity: {
    margin: "5px -10px 4px 0",
  },
  sideBlock: {
    margin: 24,
    borderRadius: 4,
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  },
  formRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 0",
  },
  condition: {
    margin: "12px 10px 0 10px",
    whiteSpace: "nowrap",
  },
  buttonDelete: {
    padding: 8,
    margin: "8px 0 0 8px",
  },
  conditionIfDisabled: {
    margin: "12px 10px 0 10px",
    color: "rgba(0, 0, 0, 0.38)",
  },
  headerText: {
    textAlign: "center",
  },
  headRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
  },
  headRowInput: {
    marginTop: 6,
    width: "30%",
    minWidth: 140,
  },
  content: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 2}px)`,
  },
  body: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 3}px)`,
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  header: {
    height: 56,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary[500],
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
});

const mapStateToProps = (state) => ({
  search: selectSearch(state),
  user: selectUser(state),
  user_id: state.login.receive.id,
});

export default connect(mapStateToProps)(withStyles(styles)(LPSForm));

// {
//   /*<Typography*/
// }
// {
//   /*  variant="subtitle1"*/
// }
// {
//   /*  className={*/
// }
// {
//   /*    condition === "from" && first_number*/
// }
// {
//   /*      ? classes.condition*/
// }
// {
//   /*      : classes.conditionIfDisabled*/
// }
// {
//   /*  }*/
// }
// {
//   /*>*/
// }
// {
//   /*  to*/
// }
// {
//   /*</Typography>*/
// }
// {
//   /*<div className={classes.headRowInput}>*/
// }
// {
//   /*  <FieldEditor*/
// }
// {
//   /*    type={SELECT}*/
// }
// {
//   /*    name="configType"*/
// }
// {
//   /*    label="CONFIGTYPE"*/
// }
// {
//   /*    onChange={(e) => this.handleChange("config_type", e)}*/
// }
// {
//   /*    value={*/
// }
// {
//   /*      {*/
// }
// {
//   /*        current: config_type || "",*/
// }
// {
//   /*        options: [*/
// }
// {
//   /*          { value: "public", label: "PUBLIC" },*/
// }
// {
//   /*          { value: "private", label: "PRIVATE" },*/
// }
// {
//   /*        ],*/
// }
// {
//   /*      } || {}*/
// }
// {
//   /*    }*/
// }
// {
//   /*    error={validationMode && !Boolean(config_type)}*/
// }
// {
//   /*  />*/
// }
// {
//   /*</div>*/
// }
// {
//   /*<div className={classes.sideBlock}>*/
// }
// {
//   /*  <div className={classes.sideBlockHead}>*/
// }
// {
//   /*    <Typography variant="subtitle1">*/
// }
// {
//   /*      Object Points Conditions*/
// }
// {
//   /*    </Typography>*/
// }
// {
//   /*  </div>*/
// }
// {
//   /*  <div className={classes.sideBlockForm}>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <Typography*/
// }
// {
//   /*        variant="subtitle1"*/
// }
// {
//   /*        className={classes.condition}*/
// }
// {
//   /*      >*/
// }
// {
//   /*        Units Amount:*/
// }
// {
//   /*      </Typography>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={SELECT}*/
// }
// {
//   /*        name="condition"*/
// }
// {
//   /*        label="Condition"*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject("hasUnits", "condition_name", e)*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={{*/
// }
// {
//   /*          current: hasUnits.condition_name || "",*/
// }
// {
//   /*          options: this.entityOptions,*/
// }
// {
//   /*        }}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"amount"}*/
// }
// {
//   /*        label={"Number"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject("hasUnits", "condition_data", e)*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasUnits.condition_data}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"value"}*/
// }
// {
//   /*        label={"Points"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasUnits",*/
// }
// {
//   /*            "condition_value",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasUnits.condition_value}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <Typography*/
// }
// {
//   /*        variant="subtitle1"*/
// }
// {
//   /*        className={classes.condition}*/
// }
// {
//   /*      >*/
// }
// {
//   /*        Non Housing Units:*/
// }
// {
//   /*      </Typography>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={SELECT}*/
// }
// {
//   /*        name="condition"*/
// }
// {
//   /*        label="Condition"*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "isNotHousing",*/
// }
// {
//   /*            "condition_name",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={{*/
// }
// {
//   /*          current: isNotHousing.condition_name || "",*/
// }
// {
//   /*          options: this.entityOptions,*/
// }
// {
//   /*        }}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"size"}*/
// }
// {
//   /*        label={"Size m2"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "isNotHousing",*/
// }
// {
//   /*            "condition_data",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={isNotHousing.condition_data}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"value"}*/
// }
// {
//   /*        label={"Points"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "isNotHousing",*/
// }
// {
//   /*            "condition_value",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={isNotHousing.condition_value}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <Typography*/
// }
// {
//   /*        variant="subtitle1"*/
// }
// {
//   /*        className={classes.condition}*/
// }
// {
//   /*      >*/
// }
// {
//   /*        Size:*/
// }
// {
//   /*      </Typography>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"size"}*/
// }
// {
//   /*        label={"More m2"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasUnitSizeMoreThan",*/
// }
// {
//   /*            "condition_data",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasUnitSizeMoreThan.condition_data}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"value"}*/
// }
// {
//   /*        label={"Points"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasUnitSizeMoreThan",*/
// }
// {
//   /*            "condition_value",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasUnitSizeMoreThan.condition_value}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"value"}*/
// }
// {
//   /*        label={"Between"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasUnitSizeBetween",*/
// }
// {
//   /*            "condition_value",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasUnitSizeBetween.condition_value}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"size"}*/
// }
// {
//   /*        label={"Less m2"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasUnitSizeLessThan",*/
// }
// {
//   /*            "condition_data",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasUnitSizeLessThan.condition_data}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"value"}*/
// }
// {
//   /*        label={"Points"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasUnitSizeLessThan",*/
// }
// {
//   /*            "condition_value",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasUnitSizeLessThan.condition_value}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <Typography*/
// }
// {
//   /*        variant="subtitle1"*/
// }
// {
//   /*        className={classes.condition}*/
// }
// {
//   /*      >*/
// }
// {
//   /*        Built Year:*/
// }
// {
//   /*      </Typography>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={SELECT}*/
// }
// {
//   /*        name="condition"*/
// }
// {
//   /*        label="Condition"*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "builtYear",*/
// }
// {
//   /*            "condition_name",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={{*/
// }
// {
//   /*          current: builtYear.condition_name || "",*/
// }
// {
//   /*          options: this.entityOptions,*/
// }
// {
//   /*        }}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"year"}*/
// }
// {
//   /*        label={"Year"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "builtYear",*/
// }
// {
//   /*            "condition_data",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={builtYear.condition_data}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"value"}*/
// }
// {
//   /*        label={"Points"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "builtYear",*/
// }
// {
//   /*            "condition_value",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={builtYear.condition_value}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <Typography*/
// }
// {
//   /*        variant="subtitle1"*/
// }
// {
//   /*        className={classes.condition}*/
// }
// {
//   /*      >*/
// }
// {
//   /*        Owners Amount:*/
// }
// {
//   /*      </Typography>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={SELECT}*/
// }
// {
//   /*        name="condition"*/
// }
// {
//   /*        label="Condition"*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasOwners",*/
// }
// {
//   /*            "condition_name",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={{*/
// }
// {
//   /*          current: hasOwners.condition_name || "",*/
// }
// {
//   /*          options: this.entityOptions,*/
// }
// {
//   /*        }}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"amount"}*/
// }
// {
//   /*        label={"Number"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasOwners",*/
// }
// {
//   /*            "condition_data",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasOwners.condition_data}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"value"}*/
// }
// {
//   /*        label={"%"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeObject(*/
// }
// {
//   /*            "hasOwners",*/
// }
// {
//   /*            "condition_value",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={hasOwners.condition_value}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*  </div>*/
// }
// {
//   /*</div>*/
// }
// {
//   /*<div className={classes.sideBlock}>*/
// }
// {
//   /*  <div className={classes.sideBlockHead}>*/
// }
// {
//   /*    <Typography variant="subtitle1">*/
// }
// {
//   /*      Object Units Energy Label Rules*/
// }
// {
//   /*    </Typography>*/
// }
// {
//   /*    <Button*/
// }
// {
//   /*      onClick={() =>*/
// }
// {
//   /*        this.handleAdd("lpsc_object_label_rules", "labelRules")*/
// }
// {
//   /*      }*/
// }
// {
//   /*      className={classes.sideBlockHeadButtonAdd}*/
// }
// {
//   /*    >*/
// }
// {
//   /*      <Add />*/
// }
// {
//   /*    </Button>*/
// }
// {
//   /*  </div>*/
// }
// {
//   /*  <div className={classes.sideBlockForm}>*/
// }
// {
//   /*    {labelRules.map(({ label, value }, index) => (*/
// }
// {
//   /*      <div key={index} className={classes.formRow}>*/
// }
// {
//   /*        <Typography*/
// }
// {
//   /*          variant="subtitle1"*/
// }
// {
//   /*          className={classes.condition}*/
// }
// {
//   /*        >*/
// }
// {
//   /*          Energy label*/
// }
// {
//   /*        </Typography>*/
// }
// {
//   /*        <FieldEditor*/
// }
// {
//   /*          type={SELECT}*/
// }
// {
//   /*          name="label"*/
// }
// {
//   /*          label="Label"*/
// }
// {
//   /*          onChange={(e) =>*/
// }
// {
//   /*            this.handleChangeObject(*/
// }
// {
//   /*              "labelRules",*/
// }
// {
//   /*              "label",*/
// }
// {
//   /*              e,*/
// }
// {
//   /*              index*/
// }
// {
//   /*            )*/
// }
// {
//   /*          }*/
// }
// {
//   /*          value={{*/
// }
// {
//   /*            current: label || "",*/
// }
// {
//   /*            options: this.labelOptions,*/
// }
// {
//   /*          }}*/
// }
// {
//   /*        />*/
// }
// {
//   /*        <FieldEditor*/
// }
// {
//   /*          type={INPUT_NUMBER}*/
// }
// {
//   /*          name={"value"}*/
// }
// {
//   /*          label={"Points"}*/
// }
// {
//   /*          onChange={(e) =>*/
// }
// {
//   /*            this.handleChangeObject(*/
// }
// {
//   /*              "labelRules",*/
// }
// {
//   /*              "value",*/
// }
// {
//   /*              e,*/
// }
// {
//   /*              index*/
// }
// {
//   /*            )*/
// }
// {
//   /*          }*/
// }
// {
//   /*          value={value}*/
// }
// {
//   /*        />*/
// }
// {
//   /*        <IconButton*/
// }
// {
//   /*          onClick={() =>*/
// }
// {
//   /*            this.handleDelete(*/
// }
// {
//   /*              "lpsc_object_label_rules",*/
// }
// {
//   /*              "labelRules",*/
// }
// {
//   /*              index*/
// }
// {
//   /*            )*/
// }
// {
//   /*          }*/
// }
// {
//   /*          aria-label="Delete"*/
// }
// {
//   /*          className={classes.buttonDelete}*/
// }
// {
//   /*        >*/
// }
// {
//   /*          <DeleteIcon fontSize="small" />*/
// }
// {
//   /*        </IconButton>*/
// }
// {
//   /*      </div>*/
// }
// {
//   /*    ))}*/
// }
// {
//   /*  </div>*/
// }
// {
//   /*</div>*/
// }
// {
//   /*<div className={classes.sideBlock}>*/
// }
// {
//   /*  <div className={classes.sideBlockHead}>*/
// }
// {
//   /*    <Typography variant="subtitle1">*/
// }
// {
//   /*      Entity Multiplier Rules*/
// }
// {
//   /*    </Typography>*/
// }
// {
//   /*    <Button*/
// }
// {
//   /*      onClick={() =>*/
// }
// {
//   /*        this.handleAdd(*/
// }
// {
//   /*          "lpsc_entity_objects_rules",*/
// }
// {
//   /*          "lpsc_entity_objects_rules"*/
// }
// {
//   /*        )*/
// }
// {
//   /*      }*/
// }
// {
//   /*      className={classes.sideBlockHeadButtonAdd}*/
// }
// {
//   /*    >*/
// }
// {
//   /*      <Add />*/
// }
// {
//   /*    </Button>*/
// }
// {
//   /*  </div>*/
// }
// {
//   /*  <div className={classes.sideBlockForm}>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"byA1"}*/
// }
// {
//   /*        label={"1 object A1 (in %)"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeEntity(*/
// }
// {
//   /*            "lps_config_entity_rules",*/
// }
// {
//   /*            "byA1",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={lps_config_entity_rules.byA1}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"byG0"}*/
// }
// {
//   /*        label={"1 object G0 (in %)"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeEntity(*/
// }
// {
//   /*            "lps_config_entity_rules",*/
// }
// {
//   /*            "byG0",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={lps_config_entity_rules.byG0}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"increasing"}*/
// }
// {
//   /*        label={"Increasing (in %)"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeEntity(*/
// }
// {
//   /*            "lps_config_entity_rules",*/
// }
// {
//   /*            "increasing",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={lps_config_entity_rules.increasing}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"decreasing"}*/
// }
// {
//   /*        label={"Decreasing (in %)"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeEntity(*/
// }
// {
//   /*            "lps_config_entity_rules",*/
// }
// {
//   /*            "decreasing",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={lps_config_entity_rules.decreasing}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"by_address"}*/
// }
// {
//   /*        label={"By Address (Except Selected Cities) (in %)"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeEntity(*/
// }
// {
//   /*            "lps_config_entity_rules",*/
// }
// {
//   /*            "by_address",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={lps_config_entity_rules.by_address}*/
// }
// {
//   /*      />*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"is_not_person"}*/
// }
// {
//   /*        label={"For NNP (in %)"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeEntity(*/
// }
// {
//   /*            "lps_config_entity_rules",*/
// }
// {
//   /*            "is_not_person",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={lps_config_entity_rules.is_not_person}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    <div className={classes.formRow}>*/
// }
// {
//   /*      <FieldEditor*/
// }
// {
//   /*        type={INPUT_NUMBER}*/
// }
// {
//   /*        name={"kad_objs_points"}*/
// }
// {
//   /*        label={"Kadaster objects amount points"}*/
// }
// {
//   /*        onChange={(e) =>*/
// }
// {
//   /*          this.handleChangeEntity(*/
// }
// {
//   /*            "lps_config_entity_rules",*/
// }
// {
//   /*            "kad_objs_points",*/
// }
// {
//   /*            e*/
// }
// {
//   /*          )*/
// }
// {
//   /*        }*/
// }
// {
//   /*        value={lps_config_entity_rules.kad_objs_points}*/
// }
// {
//   /*      />*/
// }
// {
//   /*    </div>*/
// }
// {
//   /*    {lpsc_entity_objects_rules.map(*/
// }
// {
//   /*      (*/
// }
// {
//   /*        { condition, first_number, second_number, value },*/
// }
// {
//   /*        index*/
// }
// {
//   /*      ) => (*/
// }
// {
//   /*        <div key={index} className={classes.formRow}>*/
// }
// {
//   /*          <Typography*/
// }
// {
//   /*            variant="subtitle1"*/
// }
// {
//   /*            className={classes.condition}*/
// }
// {
//   /*          >*/
// }
// {
//   /*            Objs:*/
// }
// {
//   /*          </Typography>*/
// }
// {
//   /*          <FieldEditor*/
// }
// {
//   /*            type={SELECT}*/
// }
// {
//   /*            name="condition"*/
// }
// {
//   /*            label="Condition"*/
// }
// {
//   /*            onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_objects_rules",*/
// }
// {
//   /*                "condition",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={{*/
// }
// {
//   /*              current: condition || "",*/
// }
// {
//   /*              options: this.entityOptions,*/
// }
// {
//   /*            }}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <FieldEditor*/
// }
// {
//   /*            type={INPUT_NUMBER}*/
// }
// {
//   /*            name={"firstNumber"}*/
// }
// {
//   /*            label={"Number"}*/
// }
// {
//   /*            onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_objects_rules",*/
// }
// {
//   /*                "first_number",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={first_number}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <Typography*/
// }
// {
//   /*            variant="subtitle1"*/
// }
// {
//   /*            className={*/
// }
// {
//   /*              condition === "from" && first_number*/
// }
// {
//   /*                ? classes.condition*/
// }
// {
//   /*                : classes.conditionIfDisabled*/
// }
// {
//   /*            }*/
// }
// {
//   /*          >*/
// }
// {
//   /*            to*/
// }
// {
//   /*          </Typography>*/
// }
// {
//   /*          <FieldEditor*/
// }
// {
//   /*            type={INPUT_NUMBER}*/
// }
// {
//   /*            name={"secondNumber"}*/
// }
// {
//   /*            label={"Number"}*/
// }
// {
//   /*            disabled={condition !== "from" || !first_number}*/
// }
// {
//   /*            onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_objects_rules",*/
// }
// {
//   /*                "second_number",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={second_number}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <FieldEditor*/
// }
// {
//   /*            type={INPUT_NUMBER}*/
// }
// {
//   /*            name={"value"}*/
// }
// {
//   /*            label={"%"}*/
// }
// {
//   /*            onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_objects_rules",*/
// }
// {
//   /*                "value",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={value}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <IconButton*/
// }
// {
//   /*            onClick={() =>*/
// }
// {
//   /*              this.handleDelete(*/
// }
// {
//   /*                "lpsc_entity_objects_rules",*/
// }
// {
//   /*                "lpsc_entity_objects_rules",*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            aria-label="Delete"*/
// }
// {
//   /*            className={classes.buttonDelete}*/
// }
// {
//   /*          >*/
// }
// {
//   /*            <DeleteIcon fontSize="small" />*/
// }
// {
//   /*          </IconButton>*/
// }
// {
//   /*        </div>*/
// }
// {
//   /*      )*/
// }
// {
//   /*    )}*/
// }
// {
//   /*  </div>*/
// }
// {
//   /*</div>*/
// }
// {
//   /*<div className={classes.sideBlock}>*/
// }
// {
//   /*  <div className={classes.sideBlockHead}>*/
// }
// {
//   /*    <Typography variant="subtitle1">*/
// }
// {
//   /*      Age Multiplier Rules*/
// }
// {
//   /*    </Typography>*/
// }
// {
//   /*    <Button*/
// }
// {
//   /*      onClick={() =>*/
// }
// {
//   /*        this.handleAdd(*/
// }
// {
//   /*          "lpsc_entity_age_rules",*/
// }
// {
//   /*          "lpsc_entity_age_rules"*/
// }
// {
//   /*        )*/
// }
// {
//   /*      }*/
// }
// {
//   /*      className={classes.sideBlockHeadButtonAdd}*/
// }
// {
//   /*    >*/
// }
// {
//   /*      <Add />*/
// }
// {
//   /*    </Button>*/
// }
// {
//   /*  </div>*/
// }
// {
//   /* <div className={classes.sideBlockForm}>*/
// }
// {
//   /*   {lpsc_entity_age_rules.length > 0 &&*/
// }
// {
//   /*     lpsc_entity_age_rules.map(*/
// }
// {
//   /*       (*/
// }
// {
//   /*         { condition, first_number, second_number, value },*/
// }
// {
//   /*         index*/
// }
// {
//   /*       ) => (*/
// }
// {
//   /*         <div key={index} className={classes.formRow}>*/
// }
// {
//   /*           <Typography*/
// }
// {
//   /*             variant="subtitle1"*/
// }
// {
//   /*             className={classes.condition}*/
// }
// {
//   /*           >*/
// }
// {
//   /*             Age:*/
// }
// {
//   /*           </Typography>*/
// }
// {
//   /*           <FieldEditor*/
// }
// {
//   /*             type={SELECT}*/
// }
// {
//   /*             name="condition"*/
// }
// {
//   /*             label="Condition"*/
// }
// {
//   /*             onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_age_rules",*/
// }
// {
//   /*                "condition",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={{*/
// }
// {
//   /*              current: condition || "",*/
// }
// {
//   /*              options: this.entityOptions,*/
// }
// {
//   /*            }}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <FieldEditor*/
// }
// {
//   /*            type={INPUT_NUMBER}*/
// }
// {
//   /*            name={"firstNumber"}*/
// }
// {
//   /*            label={"Number"}*/
// }
// {
//   /*            onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_age_rules",*/
// }
// {
//   /*                "first_number",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={first_number}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <Typography*/
// }
// {
//   /*            variant="subtitle1"*/
// }
// {
//   /*            className={*/
// }
// {
//   /*              condition === "from" && first_number*/
// }
// {
//   /*                ? classes.condition*/
// }
// {
//   /*                : classes.conditionIfDisabled*/
// }
// {
//   /*            }*/
// }
// {
//   /*          >*/
// }
// {
//   /*            to*/
// }
// {
//   /*          </Typography>*/
// }
// {
//   /*          <FieldEditor*/
// }
// {
//   /*            type={INPUT_NUMBER}*/
// }
// {
//   /*            name={"secondNum"}*/
// }
// {
//   /*            label={"Number"}*/
// }
// {
//   /*            disabled={condition !== "from" || !first_number}*/
// }
// {
//   /*            onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_age_rules",*/
// }
// {
//   /*                "second_number",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={second_number}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <FieldEditor*/
// }
// {
//   /*            type={INPUT_NUMBER}*/
// }
// {
//   /*            name={"value"}*/
// }
// {
//   /*            label={"%"}*/
// }
// {
//   /*            onChange={(e) =>*/
// }
// {
//   /*              this.handleChangeEntity(*/
// }
// {
//   /*                "lpsc_entity_age_rules",*/
// }
// {
//   /*                "value",*/
// }
// {
//   /*                e,*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            value={value}*/
// }
// {
//   /*          />*/
// }
// {
//   /*          <IconButton*/
// }
// {
//   /*            onClick={() =>*/
// }
// {
//   /*              this.handleDelete(*/
// }
// {
//   /*                "lpsc_entity_age_rules",*/
// }
// {
//   /*                "lpsc_entity_age_rules",*/
// }
// {
//   /*                index*/
// }
// {
//   /*              )*/
// }
// {
//   /*            }*/
// }
// {
//   /*            aria-label="Delete"*/
// }
// {
//   /*            className={classes.buttonDelete}*/
// }
// {
//   /*          >*/
// }
// {
//   /*            <DeleteIcon fontSize="small" />*/
// }
// {
//   /*          </IconButton>*/
// }
// {
//   /*        </div>*/
// }
// {
//   /*      )*/
// }
// {
//   /*    )}*/
// }
// {
//   /*</div>*/
// }

// get entityOptions() {
//   return ["equal", "from", "to"].map((i) => ({ value: i, label: i }));
// }

// get labelOptions() {
//   return [
//     "A++++",
//     "A+++",
//     "A++",
//     "A+",
//     "A",
//     "B",
//     "C",
//     "D",
//     "E",
//     "F",
//     "G",
//   ].map((i) => ({
//     value: i,
//     label: i,
//   }));
// }

// handleChangeObject = (blockName, fieldName, { target }, index) => {
//   const {
//     lpsc_object_condition_rules,
//     // lpsc_object_label_rules,
//     action_types,
//   } = this.state;
//   let currentType;
// if (blockName === "labelRules") {
//   const editableBlock = [...lpsc_object_label_rules[blockName]];
//   editableBlock[index] = {
//     ...editableBlock[index],
//     [fieldName]: target.value,
//   };
//   currentType = "lpsc_object_label_rules";
//   return this.setState({
//     lpsc_object_label_rules: {
//       ...lpsc_object_label_rules,
//       [blockName]: editableBlock,
//     },
//     action_types: action_types.includes(currentType)
//       ? action_types
//       : [currentType, ...action_types],
//   });
// }
//
// currentType = "lpsc_object_condition_rules";
// return this.setState({
//   action_types: action_types.includes(currentType)
//     ? action_types
//     : [currentType, ...action_types],
//   lpsc_object_condition_rules: {
//     ...lpsc_object_condition_rules,
//     [blockName]: {
//       ...lpsc_object_condition_rules[blockName],
//       [fieldName]: target.value,
//     },
//   },
// });
// };

// handleChangeEntity = (blockName, fieldName, { target }, index) => {
//   const { action_types } = this.state;
//   const currentType = blockName;
//   if (
//     blockName === "lpsc_entity_objects_rules" ||
//     blockName === "lpsc_entity_age_rules"
//   ) {
//     const editableBlock = [...this.state[blockName]];
//     editableBlock[index] = {
//       ...editableBlock[index],
//       [fieldName]: target.value,
//     };
//
//     return this.setState({
//       [blockName]: [...editableBlock],
//       action_types: action_types.includes(currentType)
//         ? action_types
//         : [currentType, ...action_types],
//     });
//   }
//   this.setState({
//     [blockName]: { ...this.state[blockName], [fieldName]: target.value },
//     action_types: action_types.includes(currentType)
//       ? action_types
//       : [currentType, ...action_types],
//   });
// };
