import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { Grid, Paper } from "@material-ui/core";
import Objects from "./components/objects";
import Owners from "./components/owners";
import Notes from "./components/notes";
import { leadgenNotesRequest } from "./actions/notes";
import {
  getObjectsAmount,
  leadgenObjectDataRequest,
  leadgenObjectsByOwnerRequest,
} from "./actions/objects";
import { leadgenDependentEntitiesRequest } from "./actions/dependentEntities";
import NoData from "./components/no-data";
import GoogleTools from "./components/google-tools";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { nextLPSItem, prevLPSItem } from "../search/actions/lps";
import { setLeadID } from "../leadgen-head-menu/actions";
import { selectSearch } from "../search/selectors";
import { selectLeads } from "../leads/selectors";
import { selectEntity } from "../leadgen-head-menu/selectors";
import { selectLeadgen } from "./selectors";
// import { receiveDriveDocsRequest } from "../leads/actions";

class Leadgen extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {};

  componentDidMount() {
    this.updateEntityData();
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    const { model, id } = router.params;
    const { selectedObjectID, ownerID } = this.state;

    if (this.props !== prevProps) {
      if (model === "object" && Boolean(id) && id !== selectedObjectID) {
        return this.updateEntityData();
      }
      if (model === "entity" && Boolean(id) && id !== ownerID) {
        return this.updateEntityData();
      }
    }
  }

  initialStateByOwner = (id) => {
    this.setState({
      selectedObjectID: "",
      selectedOwnerID: id,
      ownerID: id,
    });
  };

  initialStateByObject = (id) => {
    this.setState({
      selectedObjectID: id,
      selectedOwnerID: "",
      ownerID: "",
    });
  };

  initialObjectsData = (currentItem) => {
    let { dispatch, router } = this.props;
    const id = currentItem.owner;
    let flag;

    if (id && !currentItem.object_id) {
      flag = "entity";
      this.initialStateByOwner(id);
      dispatch(leadgenNotesRequest({ entity_id: id }));
      dispatch(leadgenObjectsByOwnerRequest(id));
      dispatch(getObjectsAmount(id, flag));
      dispatch(leadgenDependentEntitiesRequest(id));
      // dispatch(receiveDriveDocsRequest());
    } else {
      flag = "object";
      this.initialStateByObject(currentItem.object_id);
      dispatch(leadgenNotesRequest({ entity_id: currentItem.object_id }));
      dispatch(leadgenObjectDataRequest(currentItem.object_id, router));
      dispatch(getObjectsAmount(id, flag));
    }
  };

  showMoreInfo = (object_id) => this.setState({ selectedObjectID: object_id });

  updateEntityData = () => {
    let { router, dispatch } = this.props;
    const { model, id } = router.params;
    if (id && model === "entity") {
      dispatch(setLeadID(id));
      this.initialObjectsData({ owner: id });
    }

    if (id && model === "object") {
      dispatch(setLeadID(0));
      this.initialObjectsData({ object_id: id });
    }
  };

  handleSelectOwner = (id) => {
    this.setState({ selectedOwnerID: id });
    const { dispatch } = this.props;
    dispatch(leadgenNotesRequest({ entity_id: id }));
  };

  handleSwitchHeadOwner = (id) => {
    const { router, dispatch } = this.props;
    id && dispatch(setLeadID(id));
    id && router.push(`/leadgen/entity/${id}`);
    this.initialObjectsData({ owner: id });
  };

  getSelectedOwnerObjectIDs = () => {
    const { selectedOwnerID, ownerID } = this.state;
    if (selectedOwnerID === ownerID) return null;
    const { leadgen } = this.props;
    const { owners } = leadgen;
    const selectedOwner = owners.find((i) => i.id === selectedOwnerID);
    return selectedOwner && selectedOwner.objects.objectIDs;
  };

  render() {
    const { classes, leadgen, currentEntity, router, dispatch, search } =
      this.props;
    const { owners, headMenuManagers, headMenuMContacts, objectIDs } = leadgen;
    const { selectedObjectID, selectedOwnerID, ownerID } = this.state;
    const { countOfLeads, indexOfCurrentLeadID } = search;
    const contacts = headMenuMContacts;
    const managers = headMenuManagers;
    const filteredOwners =
      selectedObjectID && owners
        ? owners.filter((i) => i.objects.objectIDs.includes(selectedObjectID))
        : owners;

    let allOwners = filteredOwners.concat(contacts, managers) || [];
    allOwners = _.uniqBy(allOwners, "id");

    const selectedOwnerObjectIDs = this.getSelectedOwnerObjectIDs();

    return (
      <Grid container>
        <Grid item xs={12} className={classes.item}>
          <Grid
            container
            spacing={16}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={6} className={classes.wrapper}>
              {countOfLeads > 0 && indexOfCurrentLeadID >= 0 && (
                <IconButton
                  title="Prev"
                  disabled={indexOfCurrentLeadID === 0}
                  onClick={() => dispatch(prevLPSItem(router))}
                >
                  <ArrowBackIosRoundedIcon fontSize="default" color="primary" />
                </IconButton>
              )}
              <div className={classes.leftColumnWrapper}>
                <Paper>
                  <Objects
                    updateEntityData={this.updateEntityData}
                    objectIDsToHighlight={selectedOwnerObjectIDs}
                    showMoreInfo={this.showMoreInfo}
                    ownerID={ownerID}
                    router={router}
                  />
                </Paper>
                <Paper className={classes.paperWrapper}>
                  {(allOwners.length && (
                    <Owners
                      objectIDs={objectIDs}
                      owners={allOwners}
                      ownerID={
                        selectedOwnerID ||
                        (currentEntity && currentEntity.setLeadId)
                      }
                      onSelectOwner={this.handleSelectOwner}
                      onSwitchHeadOwner={this.handleSwitchHeadOwner}
                    />
                  )) || <NoData text="No Stakeholders" />}
                </Paper>
                <Paper className={classes.paperWrapper}>
                  <Notes />
                </Paper>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.wrapper}>
              <Paper className={classes.tools}>
                <GoogleTools />
              </Paper>
              {countOfLeads > 0 && indexOfCurrentLeadID >= 0 && (
                <IconButton
                  title="Next"
                  disabled={indexOfCurrentLeadID === countOfLeads - 1}
                  onClick={() => dispatch(nextLPSItem(router))}
                >
                  <ArrowForwardIosRoundedIcon
                    fontSize="default"
                    color="primary"
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export const styles = {
  leftColumnWrapper: {
    height: "calc(100vh - 112px)",
    overflow: "hidden",
    overflowY: "auto",
    marginLeft: "5px",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  item: {
    width: "100%",
    display: "flex",
    marginTop: 18,
    flexDirection: "column",
  },
  paperWrapper: {
    marginTop: 16,
  },
  tools: {
    height: "calc(100vh - 112px)",
    width: "100%",
    marginRight: "5px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

const mapStateToProps = (state) => ({
  search: selectSearch(state),
  leads: selectLeads(state),
  currentEntity: selectEntity(state),
  leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Leadgen));
