import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  leadgenObjectsByOwnerSuccess,
  leadgenObjectDataSuccess,
  leadgenDeleteObjectSuccess,
  leadgenEmptyObjectsSuccess,
  leadgenObjectsAmountSuccess,
  leadgenObjectsAmountError,
  leadgenObjectsByOwnerError,
  leadgenObjectDataError,
  leadgenDeleteObjectError,
  leadgenEmptyObjectsError,
  leadgenUpdateObjectError,
  leadgenEditObjectDataSuccess,
  leadgenEditObjectDataError,
} from "../actions/objects";
import {
  GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_REQUEST,
  GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST,
  GET_LEADGEN_OBJECT_DATA_REQUEST,
  PUT_LEADGEN_OBJECT_REQUEST,
  DELETE_LEADGEN_OBJECT_REQUEST,
  SET_EMPTY_LEADGEN_OBJECTS_REQUEST,
  GET_LEADGEN_PREV_OBJECTS_BY_OWNER_REQUEST,
  GET_LEADGEN_NEXT_OBJECTS_BY_OWNER_REQUEST,
  GET_LEADGEN_EDIT_OBJECT_DATA_REQUEST,
} from "../../../common/constants";

const LIMIT = 100;

function* getNextObjects(action) {
  try {
    const objects = yield call(ApiService.post2, "/object/by_owner", {
      id: action.id,
      objId: action.lastItem.object_id,
      limit: LIMIT,
      direction: 1,
    });
    yield put(leadgenObjectsByOwnerSuccess(objects));
  } catch (error) {
    yield put(leadgenObjectsByOwnerError(error));
  }
}

function* getPrevObjects(action) {
  try {
    const objects = yield call(ApiService.post2, "/object/by_owner", {
      id: action.id,
      objId: action.firstItem.object_id,
      limit: LIMIT,
      direction: -1,
    });
    yield put(leadgenObjectsByOwnerSuccess(objects));
  } catch (error) {
    yield put(leadgenObjectsByOwnerError(error));
  }
}

function* getLeadgenObjectsByOwner(action) {
  try {
    const objects = yield call(ApiService.post2, "/object/by_owner", {
      id: action.id,
      index: 0,
      limit: LIMIT,
    });
    yield put(leadgenObjectsByOwnerSuccess(objects));
  } catch (error) {
    yield put(leadgenObjectsByOwnerError(error));
  }
}

function* getObjectsAmount(action) {
  try {
    const objectsInfo = yield call(
      ApiService.post2,
      "/object/by_owner/amount",
      { id: action.id, flag: action.flag }
    );
    yield put(leadgenObjectsAmountSuccess(objectsInfo));
  } catch (error) {
    yield put(leadgenObjectsAmountError(error));
  }
}

function* getLeadgenObjectData(action) {
  try {
    const objects = yield call(ApiService.get2, "/object/data/" + action.id);
    yield put(leadgenObjectDataSuccess(objects));
  } catch (error) {
    yield put(leadgenObjectDataError(error));
  }
}

function* getLeadgenEditObjectData(action) {
  try {
    const object = yield call(ApiService.get2, "/object/data/" + action.id);
    yield put(leadgenEditObjectDataSuccess(object));
  } catch (error) {
    yield put(leadgenEditObjectDataError(error));
  }
}

function* deleteLeadgenObject(action) {
  try {
    yield call(ApiService.delete2, "/object/" + action.id);
    yield put(leadgenDeleteObjectSuccess(action.cb));
  } catch (error) {
    yield put(leadgenDeleteObjectError(error));
  }
}

function* setLeadgenEmptyObjects() {
  try {
    const objects = yield call(ApiService.post2, "/object/data/", "", []);
    yield put(leadgenEmptyObjectsSuccess(objects));
  } catch (error) {
    yield put(leadgenEmptyObjectsError(error));
  }
}

function* leadgenUpdateObject(action) {
  // console.log('action_types ', action.action_types);
  try {
    yield call(ApiService.put2, `/object/${action.id}`, {
      types: action.action_types,
      data: action.data,
    });
    put(getLeadgenObjectData());
  } catch (error) {
    yield put(leadgenUpdateObjectError(error));
  }
}

export default function* actionLeadgenObjects() {
  yield takeLatest(
    GET_LEADGEN_OBJECTS_BY_OWNER_REQUEST,
    getLeadgenObjectsByOwner
  );
  yield takeLatest(
    GET_LEADGEN_OBJECTS_AMOUNT_BY_OWNER_REQUEST,
    getObjectsAmount
  );
  yield takeLatest(GET_LEADGEN_NEXT_OBJECTS_BY_OWNER_REQUEST, getNextObjects);
  yield takeLatest(GET_LEADGEN_PREV_OBJECTS_BY_OWNER_REQUEST, getPrevObjects);
  yield takeLatest(GET_LEADGEN_OBJECT_DATA_REQUEST, getLeadgenObjectData);
  yield takeLatest(
    GET_LEADGEN_EDIT_OBJECT_DATA_REQUEST,
    getLeadgenEditObjectData
  );
  yield takeLatest(DELETE_LEADGEN_OBJECT_REQUEST, deleteLeadgenObject);
  yield takeLatest(SET_EMPTY_LEADGEN_OBJECTS_REQUEST, setLeadgenEmptyObjects);
  yield takeLatest(PUT_LEADGEN_OBJECT_REQUEST, leadgenUpdateObject);
}
