import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Clear from "@material-ui/icons/Clear";
import { selectSearch } from "../../../search/selectors";
import { selectEntity } from "../../../leadgen-head-menu/selectors";
import { selectLeadgen } from "../../selectors";
import { receivePersonRequest } from "../../../search/actions/person";
import { getAge, getPersonTitle } from "../../../../common/helpers/getter";

let selectedEntity;

class SearchEntity extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        query: "",
        limit: 15,
        entitiesList: [],
    };

    componentDidMount() {
        const { propsEntitiesList } = this.props;
        this.setState({
            entitiesList: [ ...propsEntitiesList ],
        });
    }

    onChange = ({ target }) => {
        this.setState({
            fieldType: target.value,
        });
    };

    runQuery = query => {
        const { dispatch, router } = this.props;
        const splitedName = query.split(" ");
        const { limit } = this.state;
        dispatch(
            receivePersonRequest(
                {
                    splitedName,
                    limit,
                },
                router,
            ),
        );
    };

    debounce = _.debounce(args => this.runQuery(args), 300);

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounce(value);
        this.runQuery(value);
    };

    handleChange = (e, { newValue, method }) => {
        if (method === "type")
            this.setState({
                query: newValue,
            });

        if (method === "click" || method === "enter")
            this.handleKeyPress({ key: method });
    };

    setEntity = c => {
        selectedEntity = c;
        const { entitiesList } = this.state;
        const { onChange } = this.props;
        const duplicatedEntity = entitiesList.find(entity => entity.id === selectedEntity.id);
        if (!duplicatedEntity) {
            this.setState({
                entitiesList: [ ...entitiesList, selectedEntity ],
            });

            onChange([ ...entitiesList, selectedEntity ]);
        }
    };

    handleKeyPress = ({ key }) => {
        if (key === "enter" || key === "click") {
            this.setState({
                query: "",
            });
        }
    };

    removeEntity = (id, index) => {
        const { entitiesList } = this.state;
        const { onChange } = this.props;
        entitiesList.splice(index, 1);
        this.setState({
            entitiesList: [ ...entitiesList ],
        });

        onChange([ ...entitiesList ]);
    };

    render() {
        const { query, limit, entitiesList } = this.state;
        const { classes, search, focus } = this.props;
        const { personData } = search;
        const showEntitiesList = (entitiesList && entitiesList.map((entity, index) => {
            const { first_name, last_name, company_name, name } = entity;
            let ownerNameArr;
            let ownerName;
            if (name) {
                ownerNameArr = name.split(" ");
                ownerNameArr.shift();
                ownerName = ownerNameArr.join(" ");
            }
            return (
                <Typography className={classes.entityContainer} key={index}>
                    {ownerName ? ownerName : `${first_name ? first_name : ""} ${company_name ? company_name : last_name}`}
                    <Clear
                        className={classes.itemRemoveButton}
                        onClick={() => this.removeEntity(entity.id, index)}
                        fontSize="small"
                    />
                </Typography>
            );
        })) || "";

        return (
            <div className={classes.root}>
                <Autosuggest
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => {
                    }}
                    suggestions={personData ? personData.slice(0, limit) : []}
                    getSuggestionValue={person => {
                        this.setEntity(person);
                        return (person.last_name && person.first_name) || person.company_name;
                    }}
                    renderInputComponent={({ classes, ref, ...other }) => {
                        return (
                            <div className={classes.companyEditor}>
                                <div className={classes.selectEntity}>
                                    <TextField
                                        variant="outlined"
                                        label="Attach files to Entity / Owner"
                                        InputProps={{
                                            inputRef: node => {
                                                node && focus && node.focus();
                                                ref(node);
                                            },
                                            classes: {
                                                input: classes.input,
                                            },
                                        }}
                                        {...other}
                                    />
                                    {showEntitiesList}
                                </div>
                            </div>
                        );
                    }}
                    renderSuggestion={({
                                           company_name,
                                           last_name,
                                           first_name,
                                           nick_name,
                                           initials,
                                           gender,
                                           birth_year,
                                           birth_date,
                                       },
                                       { query, isHighlighted }) => {
                        const matches = match(last_name || company_name || first_name || initials, query);
                        const parts = parse(last_name || company_name || first_name || initials, matches);

                        return (
                            <MenuItem
                                className={classes.item}
                                selected={isHighlighted}
                                component="div"
                            >
                                <Typography variant="body2">
                                    {`${gender ? getPersonTitle(gender) : ""} ${first_name ||
                                    nick_name ||
                                    ""}`.trim() + " "}
                                    {parts.map((part, index) => {
                                        return part.highlight ? (
                                            <span key={String(index)} style={{ fontWeight: 500 }}>
                                                {part.text}
                                            </span>
                                        ) : (
                                            <strong key={String(index)} style={{ fontWeight: 400 }}>
                                                {part.text}
                                            </strong>
                                        );
                                    })}
                                    {" " +
                                    `${getAge({
                                        birth_date,
                                        birth_year,
                                    }) || ""}yo. `.trim()}
                                </Typography>
                            </MenuItem>
                        );
                    }}
                    inputProps={{
                        classes,
                        value: query,
                        onChange: this.handleChange,
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={({ containerProps, children }) => (
                        <Paper {...containerProps} square>
                            {children && <div className={classes.children}>{children}</div>}
                        </Paper>
                    )}
                />
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        width: "49%",
    },
    item: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    container: {
        position: "relative",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 2,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: "block",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    children: {
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    companyEditor: {
        display: "flex",
    },
    entityContainer: {
        display: "inline-flex",
        alignItems: "center",
        color: theme.palette.primary[500],
        padding: "0 4px",
        marginTop: 6,
    },
    selectEntity: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
    },
    itemRemoveButton: {
        marginLeft: 2,
        "&:hover": {
            color: theme.palette.secondary.error,
        },
    },
});

const mapStateToProps = state => ({
    search: selectSearch(state),
    currentEntity: selectEntity(state),
    leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(withStyles(styles)(SearchEntity));
