import { call, put, takeLatest, delay } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import UserService from "../../../common/services/user.service";
import AlertService from "../../../common/services/alert.service";
import {
  checkLpsUsageError,
  CheckLpsUsageRequest,
  checkLpsUsageSuccess,
  deleteLPSConfigsError,
  deleteLPSConfigsSuccess,
  editLPSConfigsError,
  editLPSConfigsSuccess,
  finishCountLPSDataError,
  finishCountLPSDataSuccess,
  receiveLPSConfigsError,
  receiveLPSConfigsSuccess,
} from "../actions/lps";
import {
  DELETE_LPS_CONFIGS_REQUEST,
  EDIT_LPS_CONFIGS_REQUEST,
  RECEIVE_LPS_CONFIGS_REQUEST,
  RUN_PROCESSING_LPS_REQUEST,
  CHECK_LPS_USAGE_REQUEST,
} from "../../../common/constants";

function* checkLpsUsage(action) {
  try {
    let result;
    while (!result) {
      result = yield call(ApiService.post2, "/lps/checklps");
      if (action.needAlertFlag) {
        AlertService.info(result.data.message);
        console.log(result.data.message);
      }
      if (result.data.canUse) {
        if (result.data.lpc) {
          AlertService.success(result.data.message);
          UserService.setLPC = JSON.stringify(result.data.lpc);
          yield put(finishCountLPSDataSuccess(result.data.lpc));
        }
        break;
      } else {
        if (result.data.timeout) {
          yield delay(result.data.timeout);
          yield put(CheckLpsUsageRequest());
        }
      }
      yield put(checkLpsUsageSuccess(result.data.message));
    }
  } catch (error) {
    yield put(checkLpsUsageError(error));
  }
}

function* receiveLPSResult(action) {
  try {
    const result = yield call(ApiService.post2, "/lps/rating", action.query);
    if (result.data.success) {
      console.log("result.data.message ", result.data.message);
      AlertService.info(result.data.message);
      yield put(CheckLpsUsageRequest());
    } else {
      AlertService.error(result.data.message);
    }
  } catch (error) {
    yield put(finishCountLPSDataError(error));
  }
}

function* getLPSConfigs() {
  try {
    const lpsConfigs = yield call(ApiService.get2, "/lps/configs");
    yield put(receiveLPSConfigsSuccess(lpsConfigs));
  } catch (error) {
    yield put(receiveLPSConfigsError(error));
  }
}

function* editLPSConfigs(action) {
  try {
    const result = yield call(ApiService.post2, "/lps/configs", action.data);
    if (result) {
      yield put(editLPSConfigsSuccess(result.data.updatedConfig));
      yield getLPSConfigs();
    } else {
      AlertService.error("SOMETHING WENT WRONG");
    }
  } catch (error) {
    yield put(editLPSConfigsError(error));
  }
}

function* deleteLPSConfigs(action) {
  try {
    yield call(ApiService.delete2, `/lps/configs/${action.id}`, action.id);
    yield put(deleteLPSConfigsSuccess());
    yield getLPSConfigs();
  } catch (error) {
    yield put(deleteLPSConfigsError(error));
  }
}

export default function* actionSearchLPS() {
  yield takeLatest(RUN_PROCESSING_LPS_REQUEST, receiveLPSResult);
  yield takeLatest(RECEIVE_LPS_CONFIGS_REQUEST, getLPSConfigs);
  yield takeLatest(EDIT_LPS_CONFIGS_REQUEST, editLPSConfigs);
  yield takeLatest(DELETE_LPS_CONFIGS_REQUEST, deleteLPSConfigs);
  yield takeLatest(CHECK_LPS_USAGE_REQUEST, checkLpsUsage);
}
