import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
    receiveCompanyRequest,
    // setCurrentCompanyRequest,
} from "../actions/company";
import _ from "lodash";
import { SELECT } from "../../../common/constants/field-types";
import FieldEditor from "../../../common/components/editor/field";
import Clear from "@material-ui/icons/Clear";
import { selectSearch } from "../selectors";
import { selectEntity } from "../../leadgen-head-menu/selectors";

let company;

class SearchCompany extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        query: "",
        companyName: "",
        fieldType: "company_name",
        limit: 5,
        companyId: "",
    };

    componentDidUpdate(prevProps) {
        // const { search, currentEntity, company_id, dispatch } = this.props;
        const { search, currentEntity, company_id } = this.props;
        const { selectedCompanyName } = search;
        const { linkedCompanyId } = currentEntity;
        if (company_id && !selectedCompanyName) {
            // dispatch(setCurrentCompanyRequest(company_id));
        }

        if (selectedCompanyName !== prevProps.search.selectedCompanyName) {
            this.setState({
                companyName: selectedCompanyName,
                companyId: linkedCompanyId || company_id,
            });
        }
    }

    onChange = ({ target }) => {
        this.setState({
            fieldType: target.value,
        });
    };

    runQuery = query => {
        const { dispatch, router } = this.props;
        const { fieldType, limit } = this.state;
        dispatch(
            receiveCompanyRequest(
                {
                    query,
                    fieldType,
                    limit,
                },
                router,
            ),
        );
    };

    debounce = _.debounce(args => this.runQuery(args), 3000);

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounce(value);
        this.runQuery(value);
    };

    handleChange = (e, { newValue, method }) => {
        if (method === "type")
            this.setState({
                query: newValue,
            });

        if (method === "click" || method === "enter")
            this.handleKeyPress({ key: method });
    };

    setCompany = c => {
        company = c;
    };

    handleKeyPress = ({ key }) => {
        if (key === "enter" || key === "click") {
            this.props.selectCompany(company.id);
            this.setState({
                companyName: company.company_name,
                query: "",
                companyId: company.id,
            });
        }
    };

    removeCompany = () => {
        const { companyId } = this.state;
        const { deleteLinkedCompany } = this.props;
        if (deleteLinkedCompany) deleteLinkedCompany(companyId);
        this.setState({ companyName: "", companyId: "" });
    };

    render() {
        const { query, limit, fieldType, companyId, companyName } = this.state;
        const { classes, search, focus } = this.props;
        const { companyData } = search;
        const removeCompanyBtn = companyName ? (
            <Clear
                className={classes.companyRemoveButton}
                onClick={() => this.removeCompany()}
                fontSize="small"
            />
        ) : (
            ""
        );

        const shownCompanyName =
            companyName || companyId ? (
                <Typography className={classes.companyContainer}>
                    {companyName}
                    {removeCompanyBtn}
                </Typography>
            ) : (
                []
            );
        return (
            <div className={classes.root}>
                <Autosuggest
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => {
                    }}
                    suggestions={companyData ? companyData.slice(0, limit) : []}
                    getSuggestionValue={company => {
                        this.setCompany(company);
                        return company.company_name;
                    }}
                    renderInputComponent={({ classes, ref, ...other }) => {
                        return (
                            <div className={classes.companyEditor}>
                                <div className={classes.selectCompanyType}>
                                    <FieldEditor
                                        type={SELECT}
                                        name="fieldType"
                                        label="Select search type"
                                        onChange={this.onChange}
                                        value={
                                            {
                                                current: fieldType || "",
                                                options: [
                                                    { value: "company_name", label: "by company name" },
                                                    {
                                                        value: "kadaster_personal_number",
                                                        label: "by kadaster ID",
                                                    },
                                                    {
                                                        value: "chamber_commerce_number",
                                                        label: "by KvK number",
                                                    },
                                                ],
                                            } || {}
                                        }
                                    />
                                </div>

                                <div className={classes.selectCompany}>
                                    <TextField
                                        variant="outlined"
                                        label="Company"
                                        InputProps={{
                                            inputRef: node => {
                                                node && focus && node.focus();
                                                ref(node);
                                            },
                                            classes: {
                                                input: classes.input,
                                            },
                                        }}
                                        {...other}
                                    />
                                    {shownCompanyName}
                                </div>
                            </div>
                        );
                    }}
                    renderSuggestion={({ company_name }, { query, isHighlighted }) => {
                        const matches = match(company_name, query);
                        const parts = parse(company_name, matches);

                        return (
                            <MenuItem
                                className={classes.item}
                                selected={isHighlighted}
                                component="div"
                            >
                                <Typography variant="body2">
                                    {parts.map((part, index) => {
                                        return part.highlight ? (
                                            <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                      </span>
                                        ) : (
                                            <strong key={String(index)} style={{ fontWeight: 400 }}>
                                                {part.text}
                                            </strong>
                                        );
                                    })}
                                </Typography>
                            </MenuItem>
                        );
                    }}
                    inputProps={{
                        classes,
                        value: query,
                        onChange: this.handleChange,
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={({ containerProps, children }) => (
                        <Paper {...containerProps} square>
                            {children && <div className={classes.children}>{children}</div>}
                        </Paper>
                    )}
                />
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    item: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    container: {
        position: "relative",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 2,
        left: "21%",
        right: 0,
    },
    suggestion: {
        display: "block",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    children: {
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    companyEditor: {
        display: "flex",
    },
    selectCompanyType: {
        width: "20%",
    },
    companyContainer: {
        display: "inline-flex",
        alignItems: "center",
        color: theme.palette.primary[500],
        padding: "0 4px",
        marginTop: 6,
    },
    selectCompany: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginLeft: "2%",
        marginTop: 10,
    },
    companyRemoveButton: {
        marginLeft: 2,
        "&:hover": {
            color: theme.palette.secondary.error,
        },
    },
});

const mapStateToProps = state => ({
    search: selectSearch(state),
    currentEntity: selectEntity(state),
});

export default connect(mapStateToProps)(withStyles(styles)(SearchCompany));
