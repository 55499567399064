import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { selectLeads } from "../../../leads/selectors";
import { selectEntity } from "../../../leadgen-head-menu/selectors";
import { selectLeadgen } from "../../selectors";
import { addFilesRequest, sendFilesRequest } from "../../actions/drive";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/es/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import Dropzone from "react-dropzone";
import DriveDialog from "./DriveDialog";
import Button from "@material-ui/core/es/Button/Button";
import Link from "@material-ui/core/es/Link/Link";
// import { receiveDriveDocsRequest } from "../../../leads/actions";

class Drive extends Component {

    state = {
        value: 0,
        openDialog: false,
        entitiesList: [],
        objectsList: [],
        files: [],
    };

    componentDidMount() {
        const { leadgen } = this.props;
        const { owners } = leadgen;

        this.setState({
            entitiesList: owners,
        });
    }

    componentDidUpdate(prevProps) {
        const { leadgen } = this.props;
        const { importDriveFiles } = leadgen;

        if (prevProps.leadgen.importDriveFiles !== importDriveFiles) {
            this.setState({
                files: [ ...importDriveFiles ],
            });
        }
    }

    handleSave = () => {
        const { dispatch } = this.props;
        const { files, entitiesList, objectsList } = this.state;

        dispatch(addFilesRequest({ files, entitiesList, objectsList }));
        this.setState({
            openDialog: false,
        });
    };

    handleClose = () => {
        this.setState({
            openDialog: false,
        });
    };

    onChange = (type, updatedIndex, { target: { value } }) => {
        if (type === "filename") {
            this.setState({
                files: this.state.files.map((file, index) => {
                    return updatedIndex === index
                        ? {
                            ...file,
                            name: value,
                        }
                        : file;
                }),
            });
        }
    };

    entitiesListChange = list => {
        this.setState({
            entitiesList: list,
        });
    };

    objectsListChange = list => {
        this.setState({
            objectsList: list,
        });
    };

    onDrop = acceptedFiles => {
        const { dispatch, router } = this.props;
        const formData = new FormData();

        this.setState({
            files: acceptedFiles,
            openDialog: true,
        });

        acceptedFiles.forEach(file => {
            formData.append("files", file, file.name);
        });

        dispatch(sendFilesRequest(formData, router));
    };

    handleChange = (e, value) => {
        e.preventDefault();
        // const { dispatch } = this.props;
        this.setState({ value });
        if (value === 0) {
            // dispatch(receiveDriveDocsRequest());
        }
    };

    render() {
        const { value, openDialog, entitiesList, objectsList, files } = this.state;
        const { classes, leads } = this.props;
        const { driveFolderList, driveFolderId } = leads;

        return (
            <React.Fragment>
                <div className={classes.tabs}>
                    <Tab
                        onClick={e => this.handleChange(e, 0)}
                        label="Browse files"
                        className={value === 0 ? classes.selectedTab : classes.tab}
                        fullWidth
                    />
                    <Tab
                        onClick={e => this.handleChange(e, 1)}
                        label="Add files"
                        className={value === 1 ? classes.selectedTab : classes.tab}
                        fullWidth
                    />
                </div>
                {value === 0 && driveFolderList && (
                    <div className={classes.container}>
                        <Button className={classes.button}>
                            <Link
                                href={`https://drive.google.com/drive/folders/${driveFolderId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Drive
                            </Link>
                        </Button>
                        {!driveFolderList.length ?
                            (<div className={classes.driveHolder}>
                                <Typography variant="h6">NO FILES YET...</Typography>
                            </div>) :
                            (<div className={classes.driveHolder}>
                                <Typography variant="h6">FILES:</Typography>
                            </div>)}
                        <List className={classes.list}>
                            {driveFolderList.map(({ id, name, createdTime, properties }, index) => (
                                <ListItem key={index} className={classes.listItem}>
                                    <a
                                        href={`https://drive.google.com/file/d/${id}/view`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.link}
                                    >
                                        <ListItemText className={classes.listItemText}
                                                      primary={`${name} `}
                                                      secondary={`${new Date(createdTime).toDateString()} user: ${properties ? properties.userUploaded : ""}`}
                                        />
                                    </a>
                                </ListItem>),
                            )}
                        </List>
                    </div>

                )}
                {value === 1 && (
                    <div className={classes.container}>
                        {openDialog ?
                            (<DriveDialog
                                onChange={this.onChange}
                                files={files}
                                entitiesList={entitiesList}
                                objectsList={objectsList}
                                entitiesListChange={this.entitiesListChange}
                                objectsListChange={this.objectsListChange}
                                onClose={this.handleClose}
                                onSave={this.handleSave}
                            />) :
                            (<Dropzone
                                onDrop={this.onDrop} multiple={true}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div {...getRootProps()} className={classes.dragWrapper}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <Typography
                                                className={`${classes.wrapperText} ${classes.dragWrapperIsActive}`}
                                                variant="subtitle1"
                                            >
                                                Drop files here...
                                            </Typography>
                                        ) : (
                                            <Typography
                                                className={classes.wrapperText}
                                                variant="subtitle1"
                                            >
                                                Try dropping some files here, or click to select files
                                                to upload.
                                            </Typography>
                                        )}
                                    </div>
                                )}
                            </Dropzone>)
                        }
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export const styles = theme => ({
    container: {
        height: "calc(100% - 93px)",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    tabs: {
        display: "flex",
    },
    tab: {
        borderBottom: `3px solid ${theme.palette.primary[500]}`,
        color: theme.palette.primary[500],
    },
    selectedTab: {
        borderBottom: `3px solid ${theme.palette.secondary.lightGreen600}`,
        color: theme.palette.primary[500],
        opacity: 1,
    },
    list: {
        paddingTop: "10px",
        borderRadius: "0 0 4px 4px",
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        "&:hover": {
            backgroundColor: theme.palette.primary[400],
            color: "black",
        },
    },
    listItemText: {
        marginRight: 0,
    },
    link: {
        textDecoration: "none",
        color: "inherit",
    },
    button: {
        borderRadius: 0,
        color: theme.palette.secondary["A400"],
        width: "100%",
        padding: "5px",
    },
    driveHolder: {
        marginLeft: "15px",
        marginTop: "10px",
    },
    dragWrapper: {
        display: "flex",
        outline: "none",
        cursor: "pointer",
        height: "calc(100% - 15px)",
    },
    dragWrapperIsActive: {
        backgroundColor: "#f5f5f5",
    },
    wrapperText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: 16,
        border: "2px dashed #999",
        borderRadius: 4,
    },
});

const mapStateToProps = state => ({
    leads: selectLeads(state),
    entity: selectEntity(state),
    leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Drive));

