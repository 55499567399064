import {
  GET_LEADGEN_NOTES_REQUEST,
  PUT_LEADGEN_NOTES_REQUEST,
  REMOVE_LEADGEN_NOTE_REQUEST,
  GET_LEADGEN_NOTES_SUCCESS,
  PUT_LEADGEN_NOTES_SUCCESS,
  REMOVE_LEADGEN_NOTE_SUCCESS,
  GET_LEADGEN_NOTES_ERROR,
  PUT_LEADGEN_NOTES_ERROR,
  REMOVE_LEADGEN_NOTE_ERROR
} from "../../../common/constants";

export const leadgenNotesRequest = query => {
  return {
    type: GET_LEADGEN_NOTES_REQUEST,
    query
  };
};

export const leadgenPutNotesRequest = (id, data) => {
  return {
    type: PUT_LEADGEN_NOTES_REQUEST,
    id,
    data,
  };
};

export const removeNoteRequest = (id) => {
  return {
    type: REMOVE_LEADGEN_NOTE_REQUEST,
    id
  };
};

export const leadgenGetNotesSuccess = notes => {
  return {
    type: GET_LEADGEN_NOTES_SUCCESS,
    notes: notes.data
  };
};

export const leadgenPutNotesSuccess = notes => {
  return {
    type: PUT_LEADGEN_NOTES_SUCCESS,
    notes: notes.data
  };
};

// todo rewrite returned after remove note data
export const removeNoteSuccess = note => {
  return {
    type: REMOVE_LEADGEN_NOTE_SUCCESS,
    notes: note.data
  };
};

export const leadgenGetNotesError = (query, error) => {
  return {
    type: GET_LEADGEN_NOTES_ERROR,
    query,
    error
  };
};

export const leadgenPutNotesError = (query, error) => {
  return {
    type: PUT_LEADGEN_NOTES_ERROR,
    query,
    error
  };
};

export const leadgenRemoveNoteError = error => {
  return {
    type: REMOVE_LEADGEN_NOTE_ERROR,
    error
  };
};
