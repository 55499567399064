import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
  leadgenDependentEntitiesSuccess,
  leadgenEmptyDependentEntitiesSuccess,
  setTempAssignedSuccess,
  leadgenDependentEntitiesError,
  leadgenEmptyDependentEntitiesError,
  setTempAssignedError
} from "../actions/dependentEntities";
import {
  GET_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
  SET_EMPTY_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
  SET_TEMPORARILY_ASSIGNED_REQUEST
} from "../../../common/constants";

function* setTemporarilyAssign(action) {
  try {
    const entity = yield call(ApiService.put2, `/entity/${action.id}`, {
      info: {
        tempAssigned: action.temporarily_assigned,
      },
      types: action.action_types
    });
    yield put(setTempAssignedSuccess(entity));
  } catch (error) {
    yield put(setTempAssignedError(error));
  }
}

function* getLeadgenDependentEntities(action) {
  try {
    const dependentEntities = yield call(
      ApiService.post2,
      "/entity/dependent_entities",
      action.id
    );
    yield put(leadgenDependentEntitiesSuccess(dependentEntities));
  } catch (error) {
    yield put(leadgenDependentEntitiesError(error));
  }
}

function* setLeadgenEmptyDependentEntities() {
  try {
    const dependentEntities = yield call(
      ApiService.post2,
      "/entity/dependent_entities",
      "",
      []
    );
    yield put(leadgenEmptyDependentEntitiesSuccess(dependentEntities));
  } catch (error) {
    yield put(leadgenEmptyDependentEntitiesError(error));
  }
}

export default function* actionDependentEntities() {
  yield takeLatest(
    GET_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
    getLeadgenDependentEntities
  );
  yield takeLatest(
    SET_EMPTY_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
    setLeadgenEmptyDependentEntities
  );
  yield takeLatest(SET_TEMPORARILY_ASSIGNED_REQUEST, setTemporarilyAssign);
}
