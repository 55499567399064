import ErrorHandler from "../../../common/services/error.handler";
import {
  GET_LEADGEN_OWNERS_REQUEST,
  SET_EMPTY_LEADGEN_OWNERS_REQUEST,
  GET_LEADGEN_OWNERS_SUCCESS,
  SET_EMPTY_LEADGEN_OWNERS_SUCCESS,
  GET_LEADGEN_OWNERS_ERROR,
  SET_EMPTY_LEADGEN_OWNERS_ERROR
} from "../../../common/constants";

export const fetchLeadgenOwnersRequest = (ids, id) => {
  return {
    type: GET_LEADGEN_OWNERS_REQUEST,
    ids: { ids, id }
  };
};

export const leadgenEmptyOwnersRequest = (id, data) => {
  return {
    type: SET_EMPTY_LEADGEN_OWNERS_REQUEST,
    id,
    data
  };
};

export const fetchLeadgenOwnersSuccess = owners => {
  return {
    type: GET_LEADGEN_OWNERS_SUCCESS,
    owners: owners.data
  };
};

export const leadgenEmptyOwnersSuccess = owners => {
  return {
    type: SET_EMPTY_LEADGEN_OWNERS_SUCCESS,
    owners: owners.data
  };
};

export const fetchLeadgenOwnersError = (query, error) => {
  ErrorHandler.do(error);
  return {
    type: GET_LEADGEN_OWNERS_ERROR,
    query,
    error
  };
};

export const leadgenEmptyOwnersError = (query, error) => {
  ErrorHandler.do(error);
  return {
    type: SET_EMPTY_LEADGEN_OWNERS_ERROR,
    query,
    error
  };
};
