import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const Footer = ({ classes, onSave = () => {}, onCancel = () => {}, show }) => {
  return (
    <div className={classes.root}>
      {!show && <Button onClick={onSave}>Save and create new</Button>}
      {show && <Button onClick={onSave}>Save</Button>}
      <Button onClick={onCancel}>Cancel</Button>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: -24,
  },
});

export default withStyles(styles)(Footer);