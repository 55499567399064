import React, { Component } from "react";
import { Provider } from "react-redux";
import storeLauncher from "./storeLauncher";
import App from "./containers/App";
import Alert from "react-s-alert";
import AlertDialog from "./common/components/alert";
import IdleTimer from "react-idle-timer";
import UserService from "./common/services/user.service";
// import PingService from "./common/services/ping.service";

const store = storeLauncher({}); // initialization of store
class Root extends Component {
  onIdle = () => {
    console.log("Logout Time ", Date());
    UserService.logout();
    this.props.router.push("/login");
  };
  render() {
    const minutes = 1000 * 60 * 60; // 60 minutes to logout;
    return (
      <Provider store={store}>
        <div>
          <IdleTimer
            ref={ref => {
              this.timer = ref;
            }}
            element={document}
            onActive={() => {}}
            onIdle={this.onIdle}
            onAction={() => {}}
            debounce={500}
            timeout={minutes}
          />
          <App {...this.props} />
          <Alert contentTemplate={AlertDialog} />
          {/*<PingService router={this.props.router} />*/}
        </div>
      </Provider>
    );
  }
}

export default Root;
