import { combineReducers } from "redux";
import {
  SEARCH_RECEIVE_COMPANY_SUCCESS,
  SET_QUERY_COMPANY_SUCCESS,
  SEARCH_RECEIVE_COMPANY_ERROR,
  SET_QUERY_COMPANY_ERROR
} from "../../../common/constants";

const receiveCompany = (state = [], action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_COMPANY_SUCCESS:
      return action.company;

    default:
      return state;
  }
};

const errorFetchCompany = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_COMPANY_ERROR:
    case SET_QUERY_COMPANY_ERROR:
      return action.error;

    default:
      return state;
  }
};

const setQueryCompany = (state = {}, action) => {
  switch (action.type) {
    case SET_QUERY_COMPANY_SUCCESS:
      return action.queryCompany;
    case "CLEAR_QUERY_COMPANY_DATA":
      return {};

    default:
      return state;
  }
};

const company = combineReducers({
  receiveCompany,
  error: errorFetchCompany,
  setCompany: setQueryCompany
});

export default company;
