import React, { Component } from 'react';
import UserService from '../../services/user.service';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

class UserDropdown extends Component {
  static propTypes = {};

  state = {
    anchorEl: null,
  };

  handleOpenMenu = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseByItem = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    UserService.logout();
  };

  render() {
    const { anchorEl } = this.state;
    const { user, classes } = this.props;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.userWrapper}>
        <IconButton
          className={classes.iconButton}
          aria-owns={open ? 'menu-appbar' : null}
          onClick={this.handleOpenMenu}
          color="inherit"
        >
          <Avatar alt="user avatar" src={user.img_url} className={classes.image} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleCloseByItem}>Menu</MenuItem>
          <Link to={'/'} className={classes.link}>
            <MenuItem onClick={this.logout}>Logout</MenuItem>
          </Link>
        </Menu>
      </div>
    );
  }
}

const styles = {
  link: {
    textDecoration: 'none',
  },
  userWrapper: {
    display: 'flex',
    width: '100%',
    marginLeft: 8,
  },
  iconButton: {
    padding: 6,
  },
  image: {
    width: 45,
    height: 45,
  },
};

export default withStyles(styles)(UserDropdown);
