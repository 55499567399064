import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ResultTable from "./ResultTable";

const Results = ({ classes }) => (
    <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h6">
                    Results
                </Typography>
            </Grid>
            <ResultTable title="Results today" calls={5} callsEnd={10} deals={1}/>
            <ResultTable
                title="Results this week"
                calls={7}
                callsEnd={15}
                deals={3}
            />
        </Grid>
    </div>
);

Results.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    root: {
        display: "flex",
        padding: 10,
    },
    title: {
        height: 34,
        display: "flex",
        justifyContent: "center",
    },
});

export default withStyles(styles)(Results);
