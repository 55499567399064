import React, {Component} from "react";
import PropTypes from "prop-types";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {receiveUserRequest, getCurrentUserRequest} from "../modules/login/actions/index";
import UserService from "../common/services/user.service";
import {Login} from "../modules";
import Template from "./Template";
// import moment from "moment";
import {selectSearch} from "../modules/search/selectors";
import { selectUser } from "../modules/login/selectors"
import {receiveSettingsRequest} from "../modules/search/actions/settings";
import {receiveLPSConfigsRequest} from "../modules/search/actions/lps";
import {receiveUsersDataRequest} from "../modules/leadgen-head-menu/actions";
// import {countLPSDataRequest} from "../modules/search/actions/lps";

// type AppProps = {}

// declare module '@material-ui/core/styles/createMuiTheme' {
//     palette: {
//         primary: {
//             grayFont: string
//         }
//     }
// }

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        // fontFamily: 'monospace',
        // fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'"
    },
    palette: {
        background: {
            paper: "#fff",
        },
        primary: {
            //@ts-ignore
            grayFont: "#777",
            default: "#bdbdbd",
            // 100: '#bbdefb',
            // 200: '#90caf9',
            divider: "#555",

            // 400: '#f5f5f5',
            // 500: '#bdbdbd',

            400: "#ddebf8",
            500: "#607d9f",
            600: "#cdd1d4",
            700: "#4e5058",

            // 600: '#1e88e5',
            // 700: '#1976d2',
            // 800: '#1565c0',
            // 900: '#0d47a1',
            // A100: '#82b1ff',
            // A200: '#448aff',
            // A400: '#2979ff',
            // A700: '#2962ff',
        },
        secondary: {
            // 50: '#e3f2fd',
            // 100: '#bbdefb',
            // 200: '#90caf9',
            // divider: '#555',
            // 400: '#ddebf8',
            // 500: '#607d9f',
            // 600: '#1e88e5',
            // 700: '#1976d2',
            //@ts-ignore
            yellow800: "#f9a825",
            // 900: '#0d47a1',
            // A100: '#82b1ff',
            // A200: '#448aff',
            lightGreen600: "#7cb342",
            call: "#8bc34a",
            A400: "#8bc34a",
            callEnd: "#fd382f",
            error: "#fd382f",
        },
    },
    overrides: {
        MuiCollapse: {
            entered: {
                overflow: "visible",
            },
        },
        MuiListItemText: {
            root: {
                marginRight: "75px",
            },
        },
    },
});

class App extends Component {
    static propTypes = {
        json: PropTypes.object,
        isFetching: PropTypes.bool,
        dispatch: PropTypes.func,
        classes: PropTypes.object,
    };

    componentWillMount() {
        //@ts-ignore
        const { location, router, dispatch } = this.props;

        if (!UserService.token && location.pathname !== "/login") {
            return router.push("/login");
        }

        if (
            localStorage.getItem("token") &&
            (location.pathname === "/login" || location.pathname === "/")
        ) {
            return router.push("/dashboard");
        }

        if (localStorage.getItem("token")) {
            console.log('get user from server')
            dispatch(getCurrentUserRequest())
        }
    }


    // startInterval = () => {
        //@ts-ignore
        // const { router, dispatch, user } = this.props;
        // const { userData } = user;
        // if (userData.length) {
            // if (localStorage.getItem("user")) {
            //     //@ts-ignore
            //     const user = JSON.parse(UserService.user); //todo change
            //     const {config} = userData;
            //     const minutes = 60;
            //     setInterval(() => {
            //         //@ts-ignore
            //         const {lps} = this.props;
            //         const {lastRecalculationDate, isCalculate} = lps;
            //         const diff =
            //             lastRecalculationDate &&
            //             moment().diff(lastRecalculationDate, "minutes");
            //
            //         if (
            //             userData &&
            //             lastRecalculationDate &&
            //             config &&
            //             isCalculate === true &&
            //             diff >= minutes
            //         ) {
            //             console.log("Recalculation started ", Date());
            //             dispatch(countLPSDataRequest({config, city: ""}, router));
            //         }
            //     }, 500000);
            // }
        // }
    // }

    // componentDidMount() {
    //     this.startInterval();
    // }

    // componentWillUnmount() {
    //     //@ts-ignore
    //     clearInterval(this.startInterval());
    // }

    handlerOnSubmit = (message, user) => {
        //@ts-ignore
        const {dispatch, router} = this.props;
        dispatch(receiveUserRequest(message, user, router, () => {
            dispatch(receiveSettingsRequest());
            dispatch(receiveLPSConfigsRequest());
            dispatch(receiveUsersDataRequest());
        }));
    };

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                {UserService.token ? (
                    //@ts-ignore
                    <Template {...this.props} />
                ) : (
                    <Login onSubmit={this.handlerOnSubmit}/>
                )}
            </MuiThemeProvider>
        );
    }
}

const styles = {
    root: {
        display: "flex",
        width: "100%",
        margin: "auto",
    },
};

const mapStateToProps = state => ({
    user: selectUser(state),
    lps: selectSearch(state),
    ...state.login.data,
});
//@ts-ignore
export default connect(mapStateToProps)(withStyles(styles)(App));
