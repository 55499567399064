import { combineReducers } from "redux";
import {
  GET_ADMIN_USERS_SUCCESS,
  GET_ADMIN_DRIVE_SUCCESS,
  GET_ADMIN_USERS_ERROR,
  CREATE_ADMIN_USER_ERROR,
  UPDATE_ADMIN_USER_ERROR,
  DELETE_ADMIN_USER_ERROR,
  GET_ADMIN_DRIVE_ERROR
} from "../../../common/constants";

const receiveUsers = (state = [], action) => {
  switch (action.type) {
    case GET_ADMIN_USERS_SUCCESS:
      return action.users;
    default:
      return state;
  }
};
const receiveDriveAuthUrl = (state = "", action) => {
  switch (action.type) {
    case GET_ADMIN_DRIVE_SUCCESS:
      return action.driveAuthUrl;
    default:
      return state;
  }
};

const usersError = (state = {}, action) => {
  switch (action.type) {
    case GET_ADMIN_USERS_ERROR:
    case CREATE_ADMIN_USER_ERROR:
    case UPDATE_ADMIN_USER_ERROR:
    case DELETE_ADMIN_USER_ERROR:
    case GET_ADMIN_DRIVE_ERROR:
      return action.error;

    default:
      return state;
  }
};

const admin = combineReducers({
  receive: receiveUsers,
  receiveDriveAuthUrl,
  error: usersError
});

export default admin;
