import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const ViewTemplate = ({children, classes}) => (
    <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
);

ViewTemplate.propTypes = {
    children: PropTypes.element,
    classes: PropTypes.object.isRequired,
};

const styles = () => ({
    root: {
        display: 'flex',
        width: '100%',
        marginTop: 46,
    },
});

export default withStyles(styles)(ViewTemplate);
