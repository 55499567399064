import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Clear from "@material-ui/icons/Clear";
import { selectSearch } from "../../../search/selectors";
import { selectEntity } from "../../../leadgen-head-menu/selectors";
import { selectLeadgen } from "../../selectors";
import Button from "@material-ui/core/Button";
import { receiveStreetListRequest } from "../../../search/actions/street";
import { parseQueryStreet } from "../../../../common/helpers/parser";

let selectedObject;

class SearchObjects extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        query: "",
        limit: 10,
        objectsList: [],
        objectsSuggestions: [],
    };

    componentDidMount() {
        const { propsObjectsList } = this.props;

        this.setState({
            objectsList: [ ...propsObjectsList ],
        });
    }

    runQuery = query => {
        const { dispatch, search, currentEntity } = this.props;
        const { receiveStreetList } = search;
        const { setLeadId } = currentEntity;
        this.setState({
            objectsSuggestions: receiveStreetList,
        });

        dispatch(receiveStreetListRequest({ ...parseQueryStreet(query), setLeadId }));
    };

    debounce = _.debounce(args => this.runQuery(args), 300);

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounce(value);
        this.runQuery(value);
    };

    handleChange = (e, { newValue, method }) => {
        if (method === "type")
            this.setState({
                query: newValue,
            });

        if (method === "click" || method === "enter")
            this.handleKeyPress({ key: method });
    };

    setObject = c => {
        selectedObject = c;
        const { objectsList } = this.state;
        const { onChange } = this.props;
        const duplicatedObject = objectsList.find(object => object.object_id === selectedObject.object_id);
        if (!duplicatedObject) {
            this.setState({
                objectsList: [ ...objectsList, selectedObject ],
            });
            onChange([ ...objectsList, selectedObject ]);
        }

    };

    handleKeyPress = ({ key }) => {
        if (key === "enter" || key === "click") {
            this.setState({
                query: "",
            });
        }
    };

    removeObject = (id, index) => {
        const { objectsList } = this.state;
        const { onChange } = this.props;
        objectsList.splice(index, 1);
        this.setState({
            objectsList: [ ...objectsList ],
        });

        onChange([ ...objectsList ]);
    };

    removeAllObjectList = () => {
        const { onChange } = this.props;
        this.setState({
            objectsList: [],
        });

        onChange([]);
    };

    render() {
        const { query, limit, objectsList, objectsSuggestions } = this.state;
        const { classes, focus } = this.props;
        const showObjectsList =
            (objectsList && objectsList.map((object, index) => {
                const { address } = object;
                return (
                    <Typography className={classes.objectContainer} key={index}>
                        {address}
                        <Clear
                            className={classes.itemRemoveButton}
                            onClick={() => this.removeObject(object.object_id, index)}
                            fontSize="small"
                        />
                    </Typography>
                );
            })) || "";

        return (
            <div className={classes.root}>
                <Autosuggest
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => {
                    }}
                    suggestions={objectsSuggestions ? objectsSuggestions.slice(0, limit) : []}
                    getSuggestionValue={objectsSuggestion => {
                        this.setObject(objectsSuggestion);
                        return (objectsSuggestion.address);
                    }}
                    renderInputComponent={({ classes, ref, ...other }) => {
                        return (
                            <div className={classes.objectsSuggestions}>
                                <TextField
                                    variant="outlined"
                                    label="Attach files to Object"
                                    InputProps={{
                                        inputRef: node => {
                                            node && focus && node.focus();
                                            ref(node);
                                        },
                                        classes: {
                                            input: classes.input,
                                        },
                                    }}
                                    {...other}
                                />
                            </div>
                        );
                    }}
                    renderSuggestion={({
                                           address,
                                       },
                                       { query, isHighlighted }) => {
                        const matches = match(address, query);
                        const parts = parse(address, matches);

                        return (
                            <MenuItem
                                className={classes.item}
                                selected={isHighlighted}
                                component="div"
                            >
                                <Typography variant="body2">
                                    {parts.map((part, index) => {
                                        return part.highlight ? (
                                            <span key={String(index)} style={{ fontWeight: 500 }}>
                                                {part.text}
                                            </span>
                                        ) : (
                                            <strong key={String(index)} style={{ fontWeight: 400 }}>
                                                {part.text}
                                            </strong>
                                        );
                                    })}
                                </Typography>
                            </MenuItem>
                        );
                    }}
                    inputProps={{
                        classes,
                        value: query,
                        onChange: this.handleChange,
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={({ containerProps, children }) => (
                        <Paper {...containerProps} square>
                            {children && <div className={classes.children}>{children}</div>}
                        </Paper>
                    )}
                />
                <div className={classes.selectObject}>
                    {showObjectsList}
                </div>
                {Object.keys(showObjectsList).length ? (
                    <Button className={classes.cancelButton} onClick={this.removeAllObjectList}>Cancel
                        all
                        objects</Button>) : ""
                }
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        width: "49%",
    },
    item: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    container: {
        position: "relative",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 2,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: "block",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    children: {
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    objectContainer: {
        display: "inline-flex",
        alignItems: "center",
        color: theme.palette.primary[500],
        padding: "0 4px",
        marginTop: 6,
    },
    objectsSuggestions: {
        display: "grid",
        marginTop: 10,
    },
    selectObject: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
    itemRemoveButton: {
        marginLeft: 2,
        "&:hover": {
            color: theme.palette.secondary.error,
        },
    },
    cancelButton: {
        margin: "5px auto",
        float: "right",
    },
});

const mapStateToProps = state => ({
    search: selectSearch(state),
    currentEntity: selectEntity(state),
    leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(withStyles(styles)(SearchObjects));
