export const INPUT = 'text';
export const INPUT_NUMBER = 'number';
export const INPUT_MULTILINE = 'multiline';
export const INPUT_EMAIL = 'email';
export const SELECT = 'select';
export const MULTI_SELECT = 'multi_select';
export const COMPONENT = 'component';
export const DATE_TIME = 'date_time';
export const DATE = 'date';
export const TIME = 'time';
export const RADIO_BUTTON = 'radio_button';
export const CHECK_BOX = 'check_box';
export const SWITCH = 'switch';
