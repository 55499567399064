import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";

const ResponsiblePerson = ({
                               classes,
                               handleOpen,
                               handleClose,
                               handleChange,
                               anchorEl,
                               users,
                               responsiblePerson = {},
                           }) => {
    const open = Boolean(anchorEl);
    const responsiblePersonIsEmpty = !!Object.keys(responsiblePerson).length;

    return (
        <div>
            <div className={classes.wrapperAvatar} onClick={handleOpen}>
                <Avatar
                    alt="responsible person avatar"
                    src={responsiblePerson.img_url}
                    className={classes.image}
                >
                    <Person/>
                </Avatar>
                <ListItemText
                    className={classes.itemText}
                    primary={
                        <Typography className={classes.text} variant="body2">
                            Responsible
                        </Typography>
                    }
                    secondary={
                        responsiblePersonIsEmpty
                            ? `${responsiblePerson.name || ""} ${responsiblePerson.surname ||
                            ""}`.trim()
                            : "n/a"
                    }
                />
            </div>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transitionDuration={0}
                disableAutoFocusItem
                className={classes.menu}
                open={open}
                onClose={handleClose}
            >
                {users && (
                    <div>
                        {users.map(({ id, email, name, surname, img_url }) => (
                            <MenuItem
                                key={id}
                                className={classes.menuItem}
                                onClick={() =>
                                    handleChange("responsible_person", {
                                        id,
                                        email,
                                        name,
                                        surname,
                                        img_url,
                                    })
                                }
                            >
                                <Avatar
                                    alt="user email"
                                    src={img_url}
                                    className={classes.image}
                                >
                                    <Person/>
                                </Avatar>
                                <ListItemText
                                    className={classes.itemText}
                                    primary={`${name || ""} ${surname || ""}`}


                                />
                            </MenuItem>
                        ))}
                        <MenuItem
                            className={classes.menuLastItem}
                            onClick={() => handleChange("responsible_person", {})}
                        >
                            set empty / unlink
                        </MenuItem>
                    </div>
                )}
            </Menu>
        </div>
    );
};

ResponsiblePerson.propTypes = {};

const styles = theme => ({
    link: {
        textDecoration: "none",
    },
    menuItem: {
        height: 35,
    },
    wrapperAvatar: {
        display: "flex",
        padding: 5,
        borderRadius: 4,
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.14)",
            cursor: "pointer",
        },
    },
    text: {
        fontStyle: "italic",
    },
    menu: {
        top: 56,
    },
    menuLastItem: {
        display: "flex",
        justifyContent: "center",
    },
    itemText: {
        marginRight: 0,
    },
    iconButton: {
        padding: 6,
    },
    image: {
        width: 45,
        height: 45,
        backgroundColor: theme.palette.primary[400],
    },
});

export default withStyles(styles)(React.memo(ResponsiblePerson));
