import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';

const Spinner = ({classes}) => {
    return (
        <div className={classes.root}>
            <CircularProgress color="secondary"/>
        </div>
    );
};

Spinner.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = () => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
    },
});

export default withStyles(styles)(Spinner);
