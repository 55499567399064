import { combineReducers } from "redux";
import login from "../modules/login/reducers";
import admin from "../modules/admin/reducers";
import search from "../modules/search/reducers";
import leadgen from "../modules/leadgen/reducers";
import entity from "../modules/leadgen-head-menu/reducers";
import newEntity from "../modules/add-new-entity/reducers";
import importPDF from "../modules/importPDF/reducers";
import leads from "../modules/leads/reducers";
import queryObjects from "../modules/query-objects/reducers";

const rootReducer = combineReducers({
    leadgen,
    newEntity,
    entity,
    login,
    admin,
    search,
    importPDF,
    leads,
    queryObjects,
});

export default rootReducer;