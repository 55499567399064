import React from "react";
import {connect} from "react-redux";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import {receiveCityRequest} from "../../../../../modules/search/actions/city";
import FieldEditor from "../../field/";
import _ from "lodash";
import {selectSearch} from "../../../../../modules/search/selectors";

interface PlaceProps {
    classes: {
        children: string,
        container: string,
        item: string,
        suggestionsContainerOpen: string,
        suggestionsList: string,
        suggestion: string,
    },
    search: any,
    limit: number,
    value: any,
    onSelect: (newValue?: string, city?: string) => void,
    dispatch: any,
    router: any
}

const Place: React.FC<PlaceProps> = ({
                                         classes,
                                         search,
                                         limit,
                                         value,
                                         onSelect,
                                         dispatch,
                                     }) => {
    const runQuery = query => {
        dispatch(
            receiveCityRequest(
                {
                    query,
                }
            ),
        );
    };
    const {receiveCity} = search;
    const debounce = _.debounce(args => runQuery(args), 300);

    let city;
    const handleChange = (e, {newValue, method}) => {
        if (method === "type") onSelect(newValue);
        if (method === "click" || method === "enter") onSelect(city);
    };

    return (
        <div>
            <Autosuggest
                onSuggestionsFetchRequested={({value}) => {
                    debounce(value);
                    runQuery(value);
                }}
                onSuggestionsClearRequested={() => {
                }}
                suggestions={receiveCity ? receiveCity.slice(0, limit) : []}
                getSuggestionValue={o => {
                    city = o.city;
                    return city;
                }}
                renderInputComponent={({classes, ref, ...other}) => {
                    return (
                        <FieldEditor>
                            <TextField
                                fullWidth
                                InputProps={{
                                    inputRef: node => {
                                        ref(node);
                                    },
                                    classes: {
                                        input: classes.input,
                                    },
                                }}
                                label={"P.o.B"}
                                variant="outlined"
                                {...other}
                            />
                        </FieldEditor>
                    );
                }}
                renderSuggestion={({city}, {query, isHighlighted}) => {
                    const matches = match(city, query);
                    const parts = parse(city, matches);

                    return (
                        <MenuItem
                            className={classes.item}
                            selected={isHighlighted}
                            component="div"
                        >
                            <Typography variant="body2">
                                {parts.map((part, index) => {
                                    return part.highlight ? (
                                        <span key={String(index)} style={{fontWeight: 500}}>
                      {part.text}
                    </span>
                                    ) : (
                                        <strong key={String(index)} style={{fontWeight: 400}}>
                                            {part.text}
                                        </strong>
                                    );
                                })}
                            </Typography>
                        </MenuItem>
                    );
                }}
                inputProps={{
                    classes,
                    value,
                    onChange: handleChange,
                }}
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={({containerProps, children}) => (
                    <Paper {...containerProps} square>
                        {children && <div className={classes.children}>{children}</div>}
                    </Paper>
                )}
            />
        </div>
    );
};

const styles = theme => ({
    item: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    container: {
        position: "relative",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 2,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: "block",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    children: {
        maxHeight: 200,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

const mapStateToProps = state => ({
    search: selectSearch(state),
});
//@ts-ignore
export default connect(mapStateToProps)(withStyles(styles)(Place));
