import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DialogWrapper from "../../../../common/components/dialog/DialogWrapper";
import LabelForm from "../../../leads/components/LabelForm";
import { selectEntity } from "../../selectors";

class Tag extends Component {
  state = {
    openType: "tag",
    stateOpen: false,
    deleteMode: false,
    selectedTag: {},
  };

  get tagOptions() {
    const { entity } = this.props;
    const { entityTags } = entity;
    return entityTags.map((tag) => {
      return {
        value: tag.EntityTag.id,
        label: tag.name,
      };
    });
  }

  handleOpenTagDialog = (openType) => {
    /**close tags list menu after changing**/
    this.props.handleClose();
    this.setState({
      stateOpen: true,
      openType: openType,
      tag: { name: "" },
      deleteMode: true,
    });
  };

  handleCloseTagDialog = () => {
    /**close tags list menu without changing**/
    // this.props.handleClose();
    this.setState({
      stateOpen: false,
    });
  };

  handleChangeTags = ({ target }) => {
    const { entity } = this.props;
    const { entityTags } = entity;
    const { tag, deleteMode } = this.state;
    let selectedTagForDelete = {};
    if (deleteMode === true) {
      selectedTagForDelete = entityTags.find(
        (tag) => tag.EntityTag.id === target.value
      );
    }
    this.setState({
      tag: { ...tag, name: target.value },
      selectedTag: {
        id: selectedTagForDelete.EntityTag.id,
        name: selectedTagForDelete.name,
      },
    });
  };

  handleDelete = () => {
    const { selectedTag } = this.state;
    const { handleChange } = this.props;

    if (selectedTag) {
      const { id } = selectedTag;
      handleChange("tags", id, "removeTags", "singleTag");
      this.setState({ tag: null, selectedTag: null, stateOpen: false });
    }
  };

  render() {
    const {
      classes,
      handleOpen,
      handleClose,
      handleChange,
      anchorEl,
      tags,
      stateTags,
    } = this.props;
    const { deleteMode, stateOpen, tag } = this.state;
    const open = Boolean(anchorEl);
    const tagIsEmpty = !!Object.keys(stateTags).length;

    return (
      <div>
        <div className={classes.wrapperAvatar} onClick={handleOpen}>
          <ListItemText
            className={classes.itemText}
            primary={
              <Typography className={classes.text} variant="body2">
                Tags
              </Typography>
            }
            secondary={
              <span>
                {tagIsEmpty
                  ? stateTags
                      .map((tag) => {
                        return tag.name;
                      })
                      .toString()
                  : "n/a"}
              </span>
            }
          />
        </div>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transitionDuration={0}
          disableAutoFocusItem
          className={classes.menu}
          open={open}
          onClose={handleClose}
        >
          {tags && (
            <div>
              {tags.map(({ id, name }) => (
                <MenuItem
                  key={id}
                  className={classes.menuItem}
                  onClick={() =>
                    handleChange("tags", {
                      id,
                      name,
                    })
                  }
                >
                  <ListItemText className={classes.itemText} primary={name} />
                </MenuItem>
              ))}
              <MenuItem
                className={classes.menuLastItem}
                onClick={() => {
                  this.handleOpenTagDialog("tag", "deleteTag");
                }}
              >
                remove tag
              </MenuItem>
              <MenuItem
                className={classes.menuLastItem}
                onClick={() => handleChange("tags", [], "removeTags")}
              >
                set empty
              </MenuItem>
            </div>
          )}
        </Menu>
        <DialogWrapper
          open={stateOpen}
          onClose={this.handleCloseTagDialog}
          handleClick={this.handleDelete}
          title="Select tag to delete"
          buttonText="Remove tag"
        >
          <LabelForm
            tag={tag}
            deleteMode={deleteMode}
            onChange={this.handleChangeTags}
            tagOptions={this.tagOptions}
          />
        </DialogWrapper>
      </div>
    );
  }
}

Tag.propTypes = {};

const styles = (theme) => ({
  link: {
    textDecoration: "none",
  },
  menuItem: {
    height: 35,
  },
  wrapperAvatar: {
    display: "flex",
    height: 45,
    padding: 5,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.14)",
      cursor: "pointer",
    },
  },
  text: {
    fontStyle: "italic",
  },
  menu: {
    top: 56,
  },
  menuLastItem: {
    display: "flex",
    justifyContent: "center",
  },
  itemText: {
    marginRight: 0,
  },
  iconButton: {
    padding: 6,
  },
  image: {
    width: 45,
    height: 45,
    backgroundColor: theme.palette.primary[400],
  },
});

const mapStateToProps = (state) => ({
  entity: selectEntity(state),
});

export default connect(mapStateToProps)(withStyles(styles)(React.memo(Tag)));
