import ErrorHandler from "../../../common/services/error.handler";
import AlertService from "../../../common/services/alert.service";
import {
  CANCEL_PDF,
  CANCEL_PDF_REQUEST,
  CANCEL_PDF_SUCCESS,
  CANCEL_PDF_ERROR,
  SAVE_PDF_ERROR,
  SAVE_PDF_REQUEST,
  SAVE_PDF_SUCCESS,
  SEND_PDF_ERROR,
  SEND_PDF_REQUEST,
  SEND_PDF_SUCCESS,
  CHECK_PDF_USAGE_REQUEST,
  CHECK_PDF_USAGE_SUCCESS,
  CHECK_PDF_USAGE_ERROR,
  // SAVE_PDF_AND_NEW_ENTITY_REQUEST,
} from "../../../common/constants";

export const sendPDFRequest = (data, router) => {
  return {
    type: SEND_PDF_REQUEST,
    data,
    router,
  };
};

export const savePDFRequest = (data, router) => {
  return {
    type: SAVE_PDF_REQUEST,
    data,
    router,
  };
};

export const checkPDFUsageRequest = (pdfId, router) => {
  return {
    type: CHECK_PDF_USAGE_REQUEST,
    pdfId,
    router,
  };
};

export const checkPDFUsageSuccess = (message) => {
  return {
    type: CHECK_PDF_USAGE_SUCCESS,
    message,
  };
};

export const checkPDFUsageError = (error) => {
  return {
    type: CHECK_PDF_USAGE_ERROR,
    error,
  };
};
// todo remove

// export const savePDFAndEntityRequest = (data, router) => {
//     return {
//         type: SAVE_PDF_AND_NEW_ENTITY_REQUEST,
//         data,
//         router,
//     };
// };

export const unlinkPDFRequest = (pdfData) => {
  return {
    type: CANCEL_PDF_REQUEST,
    pdfData,
  };
};

export const sendPDFSuccess = (pdfData, router) => {
  return {
    type: SEND_PDF_SUCCESS,
    pdfData,
    router,
  };
};

export const savePDFSuccess = (pdfData) => {
  pdfData.data && AlertService.info(pdfData.data.message);
  return {
    type: SAVE_PDF_SUCCESS,
    pdfData,
  };
};

export const unlinkPDFSuccess = (pdfData) => {
  return {
    type: CANCEL_PDF_SUCCESS,
    pdfData,
  };
};

export const sendPDFError = (error, router) => {
  ErrorHandler.do(error, router);
  return {
    type: SEND_PDF_ERROR,
    error,
  };
};

export const savePDFError = (error, router) => {
  ErrorHandler.do(error, router);
  return {
    type: SAVE_PDF_ERROR,
    error,
  };
};

export const unlinkPDFError = (error, router) => {
  ErrorHandler.do(error, router);
  return {
    type: CANCEL_PDF_ERROR,
    error,
  };
};

export const setEmptyFileData = (pdfData) => {
  return {
    type: CANCEL_PDF_SUCCESS,
    pdfData: pdfData,
  };
};

export const setEmptySaveData = (pdfData) => {
  return {
    type: CANCEL_PDF,
    pdfData: pdfData,
  };
};

export const setEmptyPingData = (pdfData) => {
  return {
    type: CANCEL_PDF,
    pdfData: pdfData,
  };
};
