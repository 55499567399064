export const clickQualifier = (single, double, ms) => {
  let clicks = 0;
  let timeout;

  return e => {
    e.persist();
    clicks++;

    if (clicks === 1) {
      timeout = setTimeout(() => {
        single(e);
        clicks = 0;
      }, ms || 250);
    } else {
      clearTimeout(timeout);
      double(e);
      clicks = 0;
    }
  };
};
