import ErrorHandler from "../../../common/services/error.handler";
import {
    SEARCH_RECEIVE_CITY_REQUEST,
    SEARCH_RECEIVE_CITY_SUCCESS,
    SEARCH_RECEIVE_CITY_ERROR,
    SET_QUERY_CITY,
} from "../../../common/constants";

export const receiveCityRequest = query => {
    return {
        type: SEARCH_RECEIVE_CITY_REQUEST,
        query,
    };
};

export const receiveCitySuccess = city => {
    return {
        type: SEARCH_RECEIVE_CITY_SUCCESS,
        city: city.data,
    };
};

export const receiveCityError = (query, error) => {
    ErrorHandler.do(error);
    return {
        type: SEARCH_RECEIVE_CITY_ERROR,
        query,
        error,
    };
};

export const setCurrentCity = queryCity => {
    return {
        type: SET_QUERY_CITY,
        queryCity,
    };
};
