import { formatPhoneNumber } from 'react-phone-number-input';
export const customFormatPhoneNumber = (
  format = [],
  str = '',
  subStrToSkip = '',
) => {
  const lToSkip = subStrToSkip.length;
  const prefix = str.slice(0, lToSkip);
  const rawBody = str.slice(lToSkip);
  let v = rawBody;

  const subStrArr = format.map((n, i) => {
    const subStr = i === format.length - 1 ? v.slice(0) : v.slice(0, n);
    v = v.slice(n);
    return subStr;
  });

  const body = subStrArr.join(' ').trim();

  return prefix === subStrToSkip
    ? `${prefix} (0)${body}`
    : formatPhoneNumber(str);
};
