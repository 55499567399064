import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FieldEditor from "../../../common/components/editor/field";
import { INPUT, SELECT } from "../../../common/constants/field-types";

const LabelForm = ({ tag, label, deleteMode, onChange, labelOptions, tagOptions, classes }) => {
    if (tag) {
        const { name } = tag || {};

        if (deleteMode === true && tagOptions) {
            return (
                <div className={classes.root}>
                    <FieldEditor
                        type={SELECT}
                        name="deleteTag"
                        label="Select tag"
                        onChange={onChange}
                        value={{
                            current: name || "",
                            options: tagOptions,
                        }}
                    />
                </div>
            );
        } else if (deleteMode === false) {
            return (
                <div className={classes.root}>
                    <FieldEditor
                        type={INPUT}
                        name="name"
                        label="Name"
                        onChange={onChange}
                        value={name}
                    />
                </div>
            );
        }

    } else {
        const { name } = label || {};

        if (deleteMode === true && labelOptions) {
            return (
                <div className={classes.root}>
                    <FieldEditor
                        type={SELECT}
                        name="deleteLabel"
                        label="Select label"
                        onChange={onChange}
                        value={{
                            current: name || "",
                            options: labelOptions,
                        }}
                    />
                </div>
            );
        } else if (deleteMode === false) {
            return (
                <div className={classes.root}>
                    <FieldEditor
                        type={INPUT}
                        name="name"
                        label="Name"
                        onChange={onChange}
                        value={name}
                    />
                </div>
            );
        }
    }
};

LabelForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export const styles = theme => ({
    root: {
        marginTop: 15,
    },
});

export default withStyles(styles)(React.memo(LabelForm));
