import AlertService from "../../../common/services/alert.service";
import ErrorHandler from "../../../common/services/error.handler";
import {
    ADD_NEW_FILES_REQUEST,
    SEND_NEW_FILES_REQUEST,
    ADD_NEW_FILES_SUCCESS,
    SEND_NEW_FILES_SUCCESS,
    ADD_NEW_FILES_ERROR,
    SEND_NEW_FILES_ERROR,
} from "../../../common/constants";

export const sendFilesRequest = data => {
    return {
        type: SEND_NEW_FILES_REQUEST,
        data,
    };
};

export const addFilesRequest = data => {
    return {
        type: ADD_NEW_FILES_REQUEST,
        data,
    };
};

export const sendFilesSuccess = (driveFilesData, router) => {
    AlertService.success("File was successfully read");
    return {
        type: SEND_NEW_FILES_SUCCESS,
        files: driveFilesData.data,
        router,
    };
};

export const addFilesSuccess = (driveFilesData, router) => {
    AlertService.success("File was successfully added");
    return {
        type: ADD_NEW_FILES_SUCCESS,
        files: driveFilesData.data,
        router,
    };
};

export const sendFilesError = error => {
    ErrorHandler.do(error);
    return {
        type: SEND_NEW_FILES_ERROR,
        error,
    };
};

export const saveFilesError = error => {
    ErrorHandler.do(error);
    return {
        type: ADD_NEW_FILES_ERROR,
        error,
    };
};
