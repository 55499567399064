import AlertService from '../../../common/services/alert.service';
import ErrorHandler from '../../../common/services/error.handler';
import {
  SEARCH_RECEIVE_SETTINGS_REQUEST,
  SEARCH_EDIT_SETTINGS_REQUEST,
  SEARCH_RECEIVE_SETTINGS_SUCCESS,
  SEARCH_EDIT_SETTINGS_SUCCESS,
  SEARCH_RECEIVE_SETTINGS_ERROR,
  SEARCH_EDIT_SETTINGS_ERROR,
} from '../../../common/constants';

export const receiveSettingsRequest = () => {
  return {
    type: SEARCH_RECEIVE_SETTINGS_REQUEST,
  };
};

export const editSettingsRequest = data => {
  return {
    type: SEARCH_EDIT_SETTINGS_REQUEST,
    data,
  };
};

export const receiveSettingsSuccess = settings => {
  return {
    type: SEARCH_RECEIVE_SETTINGS_SUCCESS,
    settings: settings.data,
  };
};

export const editSettingsSuccess = settings => {
  AlertService.success('SETTINGS UPDATED');
  return {
    type: SEARCH_EDIT_SETTINGS_SUCCESS,
    settings: settings.data,
  };
};

export const receiveSettingsError = error => {
  ErrorHandler.do(error);
  return {
    type: SEARCH_RECEIVE_SETTINGS_ERROR,
    error,
  };
};

export const editSettingsError = error => {
  ErrorHandler.do(error);
  return {
    type: SEARCH_EDIT_SETTINGS_ERROR,
    error,
  };
};
