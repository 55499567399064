import ErrorHandler from "../../../common/services/error.handler";
import {
  SEARCH_RECEIVE_COMPANY_REQUEST,
  SET_QUERY_COMPANY_REQUEST,
  SEARCH_RECEIVE_COMPANY_SUCCESS,
  SET_QUERY_COMPANY_SUCCESS,
  SEARCH_RECEIVE_COMPANY_ERROR
} from "../../../common/constants";

export const receiveCompanyRequest = (query, data) => {
  return {
    type: SEARCH_RECEIVE_COMPANY_REQUEST,
    query,
    data
  };
};

export const receiveCompanySuccess = company => {
  return {
    type: SEARCH_RECEIVE_COMPANY_SUCCESS,
    company: company.data
  };
};

export const receiveCompanyError = (query, error) => {
  ErrorHandler.do(error);
  return {
    type: SEARCH_RECEIVE_COMPANY_ERROR,
    query,
    error
  };
};

export const setCurrentCompanyRequest = id => {
  return {
    type: SET_QUERY_COMPANY_REQUEST,
    id
  };
};

export const clearCurrentCompanyRequest = () => {
  return {
    type: "CLEAR_QUERY_COMPANY_DATA"
  };
};

export const setCurrentCompanySuccess = queryCompany => {
  return {
    type: SET_QUERY_COMPANY_SUCCESS,
    queryCompany: queryCompany.data
  };
};
