import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
    createNewEntitySuccess,
    createNewObjectSuccess,
    createNewEntityError,
    createNewObjectError,
} from "../actions";
import { CREATE_NEW_ENTITY_REQUEST, CREATE_NEW_OBJECT_REQUEST } from "../../../common/constants";

function* createNewEntity(action) {
    try {
        const newEntity = yield call(ApiService.post2, "/entity", action.data);
        yield put(createNewEntitySuccess(newEntity));
    } catch (error) {
        yield put(createNewEntityError(error));
    }
}

function* createNewObject(action) {
    try {
        const newObject = yield call(ApiService.post2, "/object", action.data);
        yield put(createNewObjectSuccess(newObject));
    } catch (error) {
        yield put(createNewObjectError(error));
    }
}

export default function* actionNewEntity() {
    yield takeLatest(CREATE_NEW_ENTITY_REQUEST, createNewEntity);
    yield takeLatest(CREATE_NEW_OBJECT_REQUEST, createNewObject);
}
