import { combineReducers } from "redux";
import {
  SEARCH_RECEIVE_OBJECT_SUCCESS,
  SEARCH_SET_EMPTY_OBJECT_SUCCESS,
  SEARCH_RECEIVE_OBJECT_ERROR,
  SEARCH_SET_EMPTY_OBJECT_ERROR
} from "../../../common/constants";

const receiveObject = (state = [], action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_OBJECT_SUCCESS:
    case SEARCH_SET_EMPTY_OBJECT_SUCCESS:
      return action.object;

    default:
      return state;
  }
};

const errorFetchObject = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_RECEIVE_OBJECT_ERROR:
    case SEARCH_SET_EMPTY_OBJECT_ERROR:
      return action.error;

    default:
      return state;
  }
};

const object = combineReducers({
  receiveObject,
  error: errorFetchObject
});

export default object;
