import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { SELECT, DATE } from "../../../../common/constants/field-types";
import { formatImportDate } from "../../../../common/formats/date";
import FieldEditor from "../../../../common/components/editor/field";
import Container from "../../../../common/components/editor/entity/Container";
import Item from "../common/Item";
import Block from "../common/Block";
import { crossSign, euroSign } from "../../../../common/constants/symbols";
import { formatPrice } from "../../../../common/formats/price";

const Head = ({
  classes,
  fullList,
  lastImportDate,
  isDead,
  entity,
  object,
  statistic,
  type,
  onChange = () => {},
}) => {
  let objectColumns = [];
  let entityColumns = [];
  let typeColumnsPerson = [];
  let typeColumnsCompany = [];
  let typeColumnsObject = [];

  if (object) {
    objectColumns = object && [
      <Item
        title="OBJECT NAME"
        text={
          object.name ? (
            <span title={object.name || "not found"}>{object.name}</span>
          ) : (
            "n/a"
          )
        }
      />,
      <Item
        title="KPN"
        text={
          object.kadaster_id ? (
            <a
              href={object.kadaster_url || "/"}
              title={"Kadaster link"}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {object.kadaster_id}
            </a>
          ) : (
            "n/a"
          )
        }
      />,
      object && object.id ? (
        <Item
          title=""
          text={<span className={classes.entityLink}>OBJECT EXISTS</span>}
        />
      ) : (
        <Item
          title=""
          text={
            <span className={classes.warningText}>OBJECT DOES NOT EXIST</span>
          }
        />
      ),
    ];
    typeColumnsObject = [
      <FieldEditor
        type={DATE}
        name="lastImportDate"
        label="Import Date"
        entity
        onChange={(e) => onChange("lastImportDate", e)}
        value={lastImportDate}
      />,
    ];
  }

  if (entity) {
    entityColumns = [
      <Item
        title="NAME"
        text={
          entity.entity_name ? (
            <span title={entity.entity_db_name || "not found"}>
              {entity.entity_name}
              {entity.is_dead ? <span>({crossSign})</span> : ""}
            </span>
          ) : (
            "n/a"
          )
        }
      />,
      <Item
        title="KPN"
        text={
          entity.kadaster_personal_number ? (
            <a
              href={entity.kadaster_url || "/"}
              title={"Kadaster link"}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {entity.kadaster_personal_number}
            </a>
          ) : (
            "n/a"
          )
        }
      />,
    ];
    entity.chamber_commerce_number &&
      entityColumns.push(
        <Item
          title="KVK"
          text={
            entity.chamber_commerce_number ? (
              <a
                href={entity.chamber_commerce_url || "/"}
                title={"KVK link"}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                {entity.chamber_commerce_number}
              </a>
            ) : (
              "n/a"
            )
          }
        />
      );
    (entity.id &&
      entityColumns.push(
        <Item
          title={
            <a
              href={`/leadgen/entity/${entity.id}`}
              title={`leadgen entity-${entity.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.entityLink}
            >
              ENTITY EXISTS
            </a>
          }
          text=""
        />
      )) ||
      entityColumns.push(
        <Item
          title={
            <span className={classes.warningText}>ENTITY DOES NOT EXIST</span>
          }
          text=""
        />
      );
    entity.last_import_date &&
      entityColumns.push(
        <Item
          title="LAST IMPORT DATE"
          text={formatImportDate(entity.last_import_date)}
        />
      );
    typeColumnsPerson = [
      <Item title="TYPE" text={type} />,
      <FieldEditor
        type={SELECT}
        name="fullList"
        label="Select"
        onChange={(e) => onChange("fullList", e)}
        value={{
          current: fullList === undefined ? "" : fullList,
          options: [
            { value: true, label: "FULL LIST" },
            { value: false, label: "NOT FULL LIST" },
          ],
        }}
      />,
      <FieldEditor
        type={DATE}
        name="lastImportDate"
        label="Import Date"
        entity
        onChange={(e) => onChange("lastImportDate", e)}
        value={lastImportDate}
      />,
      <FieldEditor
        type={SELECT}
        name="is_dead"
        label="Alive"
        onChange={(e) => onChange("isDead", e)}
        value={{
          current: entity.is_dead || isDead,
          options: [
            { value: false, label: "Yes" },
            { value: true, label: "No" },
            { value: "", label: "n/a" },
          ],
        }}
      />,
    ];
    typeColumnsCompany = [
      <Item title="TYPE" text={type} />,
      <FieldEditor
        type={SELECT}
        name="fullList"
        label="Select"
        onChange={(e) => onChange("fullList", e)}
        value={{
          current: fullList === undefined ? "" : fullList,
          options: [
            { value: true, label: "FULL LIST" },
            { value: false, label: "NOT FULL LIST" },
          ],
        }}
      />,
      <FieldEditor
        type={DATE}
        name="lastImportDate"
        label="Import Date"
        entity
        onChange={(e) => onChange("lastImportDate", e)}
        value={lastImportDate}
      />,
    ];
  }

  const statisticColumns = [
    <Item title="PDF OBJECTS AMOUNT" text={statistic.pdfObjectsAmount} />,
    <Item
      title="EXTRACTED OBJECTS AMOUNT"
      text={statistic.extractedObjectsAmount}
    />,
    <Item title="FOUND OBJECTS IN DB" text={statistic.objectsInDB} />,
    <Item title="NOT FOUND OBJECTS IN DB" text={statistic.notFoundInDB} />,
    <Item title="NOT LINKED" text={statistic.notLinkedToEntityObjects} />,
  ];

  const statisticColumnsObject = [
    <Item title="UNITS AMOUNT" text={statistic.unitsAmount || "n/a"} />,
    <Item title="OWNERS AMOUNT" text={statistic.ownersAmount || "n/a"} />,
    <Item title="KADASTER SIZE" text={statistic.kadSize || "n/a"} />,
    <Item title="PURCHASE YEAR" text={statistic.purchaseYear || "n/a"} />,
    <Item
      title="PURCHASE PRICE"
      text={
        statistic.purchasePrice ? (
          <span>
            {euroSign}
            <span>{formatPrice(statistic.purchasePrice)}</span>
          </span>
        ) : (
          <span>n/a</span>
        )
      }
    />,
    <Item title="OBJECT COORDINATES" text={statistic.coordinates || "n/a"} />,
  ];

  statistic.entityObjects !== undefined &&
    statisticColumns.push(
      <Item title="ENTITY OBJECTS AMOUNT" text={statistic.entityObjects} />
    );
  statistic.linkedObjects !== undefined &&
    statisticColumns.push(
      <Item title="FOUND OBJECTS IN ENTITY" text={statistic.linkedObjects} />
    );
  statistic.objectsForUnlink !== undefined &&
    statisticColumns.push(
      <Item
        title="NOT FOUND OBJECTS IN ENTITY"
        text={statistic.objectsForUnlink}
      />
    );

  return (
    <div className={classes.root}>
      <Block>
        <Container
          leftColumn={
            object && Object.keys(object).length ? objectColumns : entityColumns
          }
        />
      </Block>
      {entity && entity.entity_type === "person" && (
        <Block>
          <Container leftColumn={typeColumnsPerson} />
        </Block>
      )}
      {entity && entity.entity_type === "company" && (
        <Block>
          <Container leftColumn={typeColumnsCompany} />
        </Block>
      )}
      {object && (
        <Block>
          <Container leftColumn={typeColumnsObject} />
        </Block>
      )}
      <Block>
        {entity && <Container leftColumn={statisticColumns} />}
        {object && <Container leftColumn={statisticColumnsObject} />}
      </Block>
    </div>
  );
};

Head.propTypes = {
  classes: PropTypes.object.isRequired,
  entity: PropTypes.object,
  object: PropTypes.object,
  statistic: PropTypes.object.isRequired,
  type: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  title: {
    width: "50%",
    marginBottom: 10,
  },
  warningText: {
    color: theme.palette.secondary.yellow800,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.light,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  entityLink: {
    textDecoration: "none",
    color: theme.palette.secondary.lightGreen600,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
});

export default withStyles(styles)(Head);
