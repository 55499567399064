import React from "react";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Config from "../../config/app.json";
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import {sidebar} from "../sidebar/Sidebar";
import {Search, LeadgenHeadMenu} from "../../../modules";
import { selectEntity } from "../../../modules/leadgen-head-menu/selectors/index";

interface HeadProps {
    classes: {
        appBar: string,
        appBarShift: string,
        hide: string,
        menuButton: string,
        titleClose: string,
        titleCloseLeadgen: string,
        titleOpen: string,
        titleOpenLeadgen: string,
        toolbar: string,
        toolbarBlacklisted: string,
    },
    router: any,
    location: any,
    toggleDrawer: () => void,
    toggleSideMenu: () => void,
    openBar: boolean,
    openMenu: boolean,
    currentEntity: {
        blacklisted?: boolean,
        currentLabels?: any,
        currentTags?: any,
        destroyEntity?: any,
        entity?: any,
        linkedCompanyId?: string,
        preparedLabels?: any,
        setLeadId?: any,
        userOptions?: any,
    }
}


const Head: React.FC<HeadProps> = ({
                                       classes,
                                       location,
                                       router,
                                       toggleDrawer,
                                       toggleSideMenu,
                                       openBar,
                                       openMenu,
                                       currentEntity = {},
                                   }) => {
    const {blacklisted} = currentEntity;
    const currentLocation = sidebar.find(({to}) =>
        location.pathname.includes(to),
    );
    const {model, id} = router.params;
    const isLeadgen = currentLocation
        ? currentLocation.to.includes("leadgen") && id && model === "entity"
        : false;

    return (
        <AppBar
            className={classNames(classes.appBar, {
                [classes.appBarShift]: openBar,
            })}
            position="fixed"
        >
            <Toolbar
                className={
                    isLeadgen && blacklisted
                        ? classes.toolbarBlacklisted
                        : classes.toolbar
                }
            >
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={toggleDrawer}
                    className={classNames(classes.menuButton, openBar && classes.hide)}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    variant="h6"
                    color="inherit"
                    className={
                        openBar
                            ? isLeadgen
                            ? classes.titleOpenLeadgen
                            : classes.titleOpen
                            : isLeadgen
                            ? classes.titleCloseLeadgen
                            : classes.titleClose
                    }
                    noWrap
                >
                    {!isLeadgen
                        ? `${Config.app.name} ${(currentLocation &&
                            currentLocation.title) ||
                        ""}`
                        : ""}
                </Typography>
                {isLeadgen ? <LeadgenHeadMenu router={{...router}}/> : ""}
                <Search
                    openMenu={openMenu}
                    toggleMenu={toggleSideMenu}
                    isLeadgen={isLeadgen}
                    router={router}
                />
            </Toolbar>
        </AppBar>
    );
};

// Head.propTypes = {
//     classes: PropTypes.object.isRequired,
//     isVisibleSidebar: PropTypes.bool,
// };

const drawerWidth = 200;
const styles = theme => ({
    toolbar: {
        paddingLeft: 0,
        paddingRight: 8,
    },
    toolbarBlacklisted: {
        backgroundColor: theme.palette.primary[700],
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: "none",
    },
    titleOpen: {
        paddingLeft: 16,
        flex: 1,
    },
    titleOpenLeadgen: {
        paddingLeft: 8,
    },
    titleClose: {
        paddingLeft: 0,
        flex: 1,
    },
    titleCloseLeadgen: {
        paddingLeft: 0,
    },
});

const mapStateToProps = state => ({
    currentEntity: selectEntity(state),
});
//@ts-ignore
export default connect(mapStateToProps)(withStyles(styles)(Head));
