import ErrorHandler from "../../../common/services/error.handler";
import {
  GET_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
  SET_EMPTY_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
  SET_TEMPORARILY_ASSIGNED_REQUEST,
  GET_LEADGEN_DEPENDENT_ENTITIES_SUCCESS,
  SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_SUCCESS,
  SET_TEMPORARILY_ASSIGNED_SUCCESS,
  GET_LEADGEN_DEPENDENT_ENTITIES_ERROR,
  SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_ERROR,
  SET_TEMPORARILY_ASSIGNED_ERROR
} from "../../../common/constants";

export const leadgenDependentEntitiesRequest = id => {
  return {
    type: GET_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
    id: { id }
  };
};

export const setTempAssignedRequest = (id, temporarily_assigned, action_types) => {
  return {
    type: SET_TEMPORARILY_ASSIGNED_REQUEST,
    id,
    temporarily_assigned,
    action_types
  };
};

export const leadgenEmptyDependentEntitiesRequest = id => {
  return {
    type: SET_EMPTY_LEADGEN_DEPENDENT_ENTITIES_REQUEST,
    id
  };
};

export const leadgenDependentEntitiesSuccess = dependentEntities => {
  return {
    type: GET_LEADGEN_DEPENDENT_ENTITIES_SUCCESS,
    dependentEntities: dependentEntities.data
  };
};

export const setTempAssignedSuccess = entity => {
  return {
    type: SET_TEMPORARILY_ASSIGNED_SUCCESS,
    entity: entity.data
  };
};

export const leadgenEmptyDependentEntitiesSuccess = dependentEntities => {
  return {
    type: SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_SUCCESS,
    dependentEntities: dependentEntities.data
  };
};

export const leadgenDependentEntitiesError = (query, error) => {
  ErrorHandler.do(error);
  return {
    type: GET_LEADGEN_DEPENDENT_ENTITIES_ERROR,
    query,
    error
  };
};

export const setTempAssignedError = (query, error) => {
  ErrorHandler.do(error);
  return {
    type: SET_TEMPORARILY_ASSIGNED_ERROR,
    query,
    error
  };
};

export const leadgenEmptyDependentEntitiesError = (id, error) => {
  ErrorHandler.do(error);
  return {
    type: SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_ERROR,
    id,
    error
  };
};
