import ApiService from "../services/api.service";
import AlertService from "../services/alert.service";

export const validate = (type, data) => {
  const { city, kadaster_id, plot, last_name, company_name } = data || {};
  // const { city, kadaster_id, plot, last_name, company_name, url } = data || {};
  const { index, section, plot_number, city_code } = plot || {};
  // const validUrl = !url ? true : validateURL(url);

  switch (type) {
    case "object":
      return [city, kadaster_id, index, section, plot_number, city_code].every(
        (i) => Boolean(i)
      );
    case "company":
      return Boolean(company_name);
    // && validUrl;
    case "person":
      return Boolean(last_name);
    // && validUrl;

    default:
      return false;
  }
};

export const validateKadID = (type, query, cbOnSuccess) => {
  return type
    ? ApiService.post2(
        `/${type}/kad_id`,
        query,
        ({ data }) => {
          data &&
            AlertService.warning(
              `Kadaster ID (${query.kad_id || ""}) already exists`
            );
          cbOnSuccess(data);
        },
        (error) => AlertService.showError(error)
      )
    : null;
};

// export const validateURL = url => /^(ftp|http|https):\/\//.test(url);

export const validateLPSConfig = (data) => {
  const { name, lps_config_cities, lpsc_object_condition_rules } = data;
  if (!name || !lps_config_cities.length || !lpsc_object_condition_rules.length)
    return false;

  const variablesArray = lpsc_object_condition_rules.map(
    (rule) => rule.variable
  );
  if (hasDuplicates(variablesArray)) {
    AlertService.warning(
      "You have duplicated fields in object condition rules"
    );
    return false;
  }
  if (
    lpsc_object_condition_rules.some((rule) => rule.variable === "units amount")
  ) {
    return true;
  } else {
    AlertService.warning("Units amount is a required field");
    return false;
  }
  // return true;
  // return [name, lps_config_cities.length, lpsc_object_condition_rules.length].every((i) => isValid(i));
};

function hasDuplicates(variablesArray) {
  return new Set(variablesArray).size !== variablesArray.length;
}
