import ErrorHandler from "../../../common/services/error.handler";
import {
    SEARCH_RECEIVE_OBJECT_REQUEST,
    SEARCH_SET_EMPTY_OBJECT_REQUEST,
    SEARCH_RECEIVE_OBJECT_SUCCESS,
    SEARCH_SET_EMPTY_OBJECT_SUCCESS,
    SEARCH_RECEIVE_OBJECT_ERROR,
    SEARCH_SET_EMPTY_OBJECT_ERROR,
} from "../../../common/constants";

export const receiveObjectRequest = query => {
    return {
        type: SEARCH_RECEIVE_OBJECT_REQUEST,
        query,
    };
};

export const setEmptyObjectRequest = () => {
    return {
        type: SEARCH_SET_EMPTY_OBJECT_REQUEST,
    };
};

export const receiveObjectSuccess = object => {
    return {
        type: SEARCH_RECEIVE_OBJECT_SUCCESS,
        object: object.data,
    };
};

export const setEmptyObjectSuccess = object => {
    return {
        type: SEARCH_SET_EMPTY_OBJECT_SUCCESS,
        object: object.data,
    };
};

export const receiveObjectError = (query, error) => {
    ErrorHandler.do(error);
    return {
        type: SEARCH_RECEIVE_OBJECT_ERROR,
        query,
        error,
    };
};

export const setEmptyObjectError = error => {
    ErrorHandler.do(error);
    return {
        type: SEARCH_SET_EMPTY_OBJECT_ERROR,
        error,
    };
};
