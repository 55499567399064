import { combineReducers } from "redux";
import {
  SHOW_LEADGEN_ADDRESS,
  SHOW_LEADGEN_ADDRESS_ERROR,
  SET_LEAD_ID
} from "../../../common/constants";

const initialState = ""

const showAddress = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LEADGEN_ADDRESS:
      return action.address;
    case SET_LEAD_ID:
      return initialState;
    default:
      return state;
  }
};

const errorShowAddress = (state = {}, action) => {
  switch (action.type) {
    case SHOW_LEADGEN_ADDRESS_ERROR:
      return action.error;

    default:
      return state;
  }
};

const maps = combineReducers({
  showAddress,
  error: errorShowAddress
});

export default maps;
