import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CHECK_BOX } from "../../../../common/constants/field-types";
import FieldEditor from "../../../../common/components/editor/field";

const Item = ({
  classes,
  title,
  text,
  subText,
  checkBox,
  unitsList,
  selectObjsWithUnitsToSave,
}) => {

  const selectUnits = (e, unit) => {
    for (let i = 0; i < unitsList.length; i++) {
      if (unit.temp_id === unitsList[i].temp_id) {
        selectObjsWithUnitsToSave(e, null, unit);
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="body2" color="textSecondary">
            {title}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1">{text}</Typography>
        </div>
        <div className={classes.objCheckbox}>{checkBox}</div>
      </div>
      {subText && (
        <div className={classes.container}>
          <div className={classes.subText}>
            <Typography variant="caption">{subText}</Typography>
          </div>
        </div>
      )}

      {unitsList && (
        <div className={classes.unitContainer}>
          {unitsList.map((unit, index) => (
            <div className={classes.subText} key={index}>
              <Typography>Unit: </Typography>
              <div className={classes.checkbox}>
                <FieldEditor
                  type={CHECK_BOX}
                  label={
                    <Typography variant="caption">{unit.unitName}</Typography>
                  }
                  labelPlacement="start"
                  onChange={(e) => selectUnits(e, unit)}
                  value={unit.isSelected}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

Item.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  unitContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingLeft: 20,
    paddingRight: 20,
  },
  checkbox: {
    display: "flex",
    justifyContent: "space-between",
  },
  objCheckbox: {
    marginLeft: "10px",
  },
  title: {
    marginRight: 15,
  },
  subText: {
    marginBottom: 4,
  },
});

export default withStyles(styles)(Item);
