import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Results from "./components/results";
import Rss from "./components/rss-news";
import Leads from "./components/leads";
import Deals from "./components/deals";
import Activity from "./components/recent-activity";

class Dashboard extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {};

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} className={classes.item}>
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <Paper className={classes.content}>
                                <Results/>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.content}>
                                <Rss/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.item}>
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <Paper className={classes.content}>
                                <Leads/>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.content}>
                                <Deals/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.item}>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Paper className={classes.content}>
                                <Activity/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export const styles = {
    item: {
        width: "100%",
        display: "flex",
        marginTop: 16,
        flexDirection: "column",
    },
    content: {
        height: "100%",
    },
};

export default connect(state => ({
    ...state,
}))(withStyles(styles)(Dashboard));
