import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

interface DialogWrapperProps {
    onClose: () => void,
    open: boolean,
    title: string,
    classes: {
        button: string,
        title: string,
        isBlacklisted: string
    },
    buttonText: string,
    handleClick: () => void,
    isBlacklisted?: boolean
}

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const DialogWrapper: React.FC<DialogWrapperProps> = ({onClose, open, title, children, classes, buttonText, handleClick, isBlacklisted}) => {
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                {title && (
                    <DialogTitle
                        className={isBlacklisted ? classes.isBlacklisted : classes.title}
                        id="alert-dialog-slide-title"
                    >
                        {title}
                    </DialogTitle>
                )}
                <DialogContent>{children}</DialogContent>
                {buttonText && (
                    <Button onClick={handleClick} className={classes.button}>
                        {buttonText}
                    </Button>
                )}
            </Dialog>
        </div>
    );
};

const styles = theme => ({
    button: {
        height: 50,
    },
    title: {
        backgroundColor: theme.palette.primary[500],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    isBlacklisted: {
        backgroundColor: theme.palette.primary[700],
    },
});

export default withStyles(styles)(DialogWrapper);
