import { createSelector } from "reselect";

const leadsState = state => {
    if (state && state.leads) {
        return state.leads;
    }
    return null;
};

const leadsSelector = createSelector(
    [ leadsState ],
    state => state, //return leads
);
const leadsReceiveLeadsSelector = createSelector(
    [ leadsSelector ],
    state => state.receive,
);
const leadsReceiveFolderSelector = createSelector(
    [ leadsSelector ],
    state => state.receiveDriveFolderData,
);
const leadsReceiveFreeLeadSelector = createSelector(
    [ leadsSelector ],
    state => state.freeLead,
);
const leadsGetLeadsSelector = createSelector(
    [ leadsReceiveLeadsSelector ],
    state => state.getLeads,
);
const leadsGetLeadsDataSelector = createSelector(
    [ leadsReceiveLeadsSelector ],
    state => state.getLeads,
);

export const selectLeads = createSelector(
    [
        leadsSelector,
        leadsReceiveLeadsSelector,
        leadsReceiveFolderSelector,
        leadsReceiveFreeLeadSelector,
        leadsGetLeadsSelector,
        leadsGetLeadsDataSelector,
    ],
    (leads, receive, receiveDriveFolderData, freeLead, getLeads) => {
        const folderData = receiveDriveFolderData.folderData ? receiveDriveFolderData.folderData : {};
        const driveFolderId = folderData && folderData.id ? folderData.id : "";
        const driveFolderList = folderData && folderData.files ? folderData.files : [];
        const currentLeads = getLeads ? getLeads : [];
        const isFetchingLeads = receive && receive.isFetching;
        const freeLeadId = freeLead.leadID;

        return {
            leads,
            driveFolderId,
            driveFolderList,
            currentLeads,
            isFetchingLeads,
            freeLeadId,
        };
    },
);
