import { combineReducers } from "redux";
import {
  GET_LEADGEN_DEPENDENT_ENTITIES_SUCCESS,
  SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_SUCCESS,
  GET_LEADGEN_DEPENDENT_ENTITIES_ERROR,
  SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_ERROR
} from "../../../common/constants";

const receiveDependentEntities = (state = {}, action) => {
  switch (action.type) {
    case GET_LEADGEN_DEPENDENT_ENTITIES_SUCCESS:
    case SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_SUCCESS:
      return action.dependentEntities;

    default:
      return state;
  }
};

const errorDependentEntities = (state = {}, action) => {
  switch (action.type) {
    case GET_LEADGEN_DEPENDENT_ENTITIES_ERROR:
    case SET_LEADGEN_EMPTY_DEPENDENT_ENTITIES_ERROR:
      return action.error;

    default:
      return state;
  }
};

const dependentEntities = combineReducers({
  receiveDependentEntities,
  error: errorDependentEntities
});

export default dependentEntities;
