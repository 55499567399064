import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { formatDateAndTime } from "../../../common/formats/date";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBackRounded";
import ArrowForward from "@material-ui/icons/ArrowForwardRounded";
import { SELECT, CHECK_BOX } from "../../../common/constants/field-types";
import FieldEditor from "../../../common/components/editor/field";
import { checkBestLeadRequest, goToNextLead } from "../../leads/actions";
import {
  setLPSItem,
  prevLPSItem,
  nextLPSItem,
  showAssigned,
  showScheduled,
  skipUnassignedLeadsFlag,
  calcDefaultProfile,
  setSelectedUsers,
  findLeadFlag,
} from '../actions/lps';
import { selectSearch } from "../selectors";
import { selectEntity } from "../../leadgen-head-menu/selectors";
import {
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

class LPSMenu extends React.Component {
  state = {
    assigned: false,
    scheduled: false,
    isCalculated: false,
    findNextLead: false,
    findPrevLead: false,
    findLead: false,
    isSkipped: false,
    users: [],
  };

  constructor(props) {
    super(props);

    const { lpcData, indexOfCurrentLeadID } = props.lps;
    const { setLeadId } = props.entity;
    const IDs = lpcData && lpcData.lpc ? lpcData.lpc.map((i) => i.id) : [];
    const index = IDs ? IDs.indexOf(setLeadId) : -1;

    if (indexOfCurrentLeadID !== index) {
      props.dispatch(setLPSItem(index));
    }
  }

  handleSelectUsers = ({ target }) => {
    const { users } = this.state;
    const { dispatch } = this.props;
    let newState = [];
    const index = users.indexOf(target.value);

    if (index !== -1) {
      const usersNew = [...users];
      usersNew.splice(index, 1);
      this.setState({ users: usersNew });
    } else {
      newState = [target.value, ...users];
      this.setState({ users: newState });
    }
    dispatch(setSelectedUsers(newState));
  };

  handleRemoveUser = () => {
    this.setState({
      users: [],
      isSkipped: false,
    });
  };

  handleChangeScheduled = () => {
    const { scheduled } = this.state;
    const { dispatch } = this.props;
    dispatch(showScheduled(!scheduled));
    this.setState({
      scheduled: !scheduled,
    });
  };

  handleChangeAssigned = () => {
    const { assigned } = this.state;
    const { dispatch, assign, users } = this.props;
    dispatch(showAssigned(!assigned));
    this.setState({
      assigned: !assigned,
    });
    if (!assign && users !== []) {
      this.handleRemoveUser();
    }
  };

  handleSkipUnassignedLeads = () => {
    const { isSkipped } = this.state;
    const { dispatch } = this.props;
    dispatch(skipUnassignedLeadsFlag(!isSkipped));
    this.setState({
      isSkipped: !isSkipped,
    });
  };

  handleChangeIsCalculated = () => {
    const { isCalculated } = this.state;

    const { dispatch, assign, users } = this.props;
    dispatch(calcDefaultProfile(!isCalculated));
    this.setState({
      isCalculated: !isCalculated,
    });
    if (!assign && users !== []) {
      this.handleRemoveUser();
    }
  };

  handleChangeFindLead = () => {
    const { dispatch } = this.props;
    const { findLead } = this.state;
    dispatch(findLeadFlag(!findLead));
    this.setState({
      findLead: !findLead,
    });
  };

  render() {
    const {
      onSelectConfig,
      onSelectCity,
      configName,
      cityName,
      dispatch,
      classes,
      router,
      entity,
      ALL,
      lps,
      processing,
    } = this.props;

    const { userOptions, setLeadId } = entity;

    const {
      lpsConfigs,
      lpsOptions,
      lpcData,
      configBasepoints,
      countOfLeads,
      indexOfCurrentLeadID,
    } = lps;
    const { assigned, isCalculated, findLead } = this.state;
    const freeLeadData =
      lpcData && lpcData.lpc ? lpcData.lpc.find((i) => i.id === setLeadId) : {};
    const leadMultiplier =
      freeLeadData && freeLeadData.multiplier
        ? freeLeadData.multiplier.toFixed(2)
        : "n/a";
    const leadObjectPoints =
      freeLeadData && freeLeadData.object_points
        ? freeLeadData.object_points
        : "n/a";
    const fullLeadPoints =
      freeLeadData && freeLeadData.full_points
        ? freeLeadData.full_points.toFixed(2)
        : "n/a";

    const currentConfig = lpsConfigs.find((i) => i.name === configName);
    const { lps_config_cities } = currentConfig ? currentConfig : [];
    const cityOptions = lps_config_cities
      ? lps_config_cities.map((i) => ({
          value: i.city_name,
          label: i.city_name,
        }))
      : [];
    const userList = assigned && (
      <div className={classes.fieldBlock2}>
        <FormControl>
          <FormLabel>Show leads for</FormLabel>
          <FormGroup>
            {userOptions.length > 0 &&
              userOptions.map(({ value, label }, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onChange={this.handleSelectUsers}
                        value={String(value)}
                        color="primary"
                      />
                    }
                    label={label}
                  />
                );
              })}
            {
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.handleSkipUnassignedLeads}
                    color="primary"
                  />
                }
                label={"Skip unassigned leads"}
              />
            }
          </FormGroup>
        </FormControl>
      </div>
    );

    return (
      <div className={classes.root}>
        <div className={classes.fieldBlock}>
          <FieldEditor
            type={SELECT}
            name="config"
            label="Select Config"
            onChange={onSelectConfig}
            value={{
              current: configName || "",
              options: lpsOptions,
            }}
          />
          {configName && (
            <FieldEditor
              type={SELECT}
              name="city"
              label="Select City"
              onChange={onSelectCity}
              value={{
                current: cityName,
                options: [...cityOptions, { value: ALL, label: ALL }],
              }}
            />
          )}
          <div className={classes.fieldBlock3}>
            <Typography variant="h6">DEFAULT LPS PROFILE</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCalculated}
                  color="primary"
                  onChange={this.handleChangeIsCalculated}
                />
              }
              label="Recalculate"
            />
          </div>
          {countOfLeads && indexOfCurrentLeadID >= 0 && (
            <div className={classes.fieldBlock3}>
              <Typography variant="h6">FIND FREE LEAD IN BACKGROUND</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={findLead}
                    color="primary"
                    onChange={this.handleChangeFindLead}
                  />
                }
                label="Find lead"
              />
              <Button
                className={classes.button}
                onClick={() => dispatch(goToNextLead(router))}
              >
                <span className={classes.buttonText}>show lead</span>
              </Button>
            </div>
          )}
        </div>
        {Object.keys(lpcData).length && (
          <div>
            <div className={classes.lpcBlock}>
              <Typography variant="h6">{lpcData.configName}</Typography>
              <Typography variant="h6">{lpcData.cityName || ALL}</Typography>
              <Typography variant="subtitle1">
                {formatDateAndTime(lpcData.date)}
              </Typography>
            </div>
            <div className={classes.lpcBlock}>
              <Typography variant="h6">
                BASEPOINTS: {configBasepoints}
              </Typography>
              <Typography variant="subtitle1">
                Total amount: {fullLeadPoints}
              </Typography>
              <Typography variant="subtitle1">
                Multilier: {leadMultiplier}
              </Typography>
              <Typography variant="subtitle1">
                Object points: {leadObjectPoints}
              </Typography>
            </div>
            <div className={classes.checkBoxes}>
              <FieldEditor
                type={CHECK_BOX}
                label="Show assigned"
                onChange={this.handleChangeAssigned}
              />
              {userList}
              <FieldEditor
                type={CHECK_BOX}
                label="Show scheduled"
                onChange={this.handleChangeScheduled}
              />
            </div>
            <Button
              disabled={!countOfLeads || processing}
              className={classes.button}
              onClick={() => dispatch(checkBestLeadRequest(router))}
            >
              <span className={classes.buttonText}>show the best</span>
            </Button>
            {countOfLeads && indexOfCurrentLeadID >= 0 && (
              <div className={classes.buttonGroup}>
                <IconButton
                  disabled={indexOfCurrentLeadID === 0}
                  title="Prev"
                  onClick={() => dispatch(prevLPSItem(router))}
                >
                  <ArrowBack />
                </IconButton>
                <div className={classes.currentLead}>
                  <Typography>LEAD</Typography>
                  <Typography variant="caption">
                    {`${indexOfCurrentLeadID + 1}/${countOfLeads}`}
                  </Typography>
                </div>
                <IconButton
                  disabled={indexOfCurrentLeadID === countOfLeads - 1}
                  title="Next"
                  onClick={() => dispatch(nextLPSItem(router))}
                >
                  <ArrowForward />
                </IconButton>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 1.45}px)`,
  },
  fieldBlock: {
    margin: "0 10px",
  },
  fieldBlock2: {
    margin: "0 10px",
    height: "190px",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  fieldBlock3: {
    margin: "20px 0",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 10px",
  },
  button: {
    borderRadius: 0,
    color: theme.palette.secondary["A400"],
    width: "100%",
    padding: 0,
    height: "30px",
  },
  buttonText: {
    margin: "0 4px",
  },
  lpcBlock: {
    margin: "10px 10px",
  },
  currentLead: {
    textAlign: "center",
  },
  checkBoxes: {
    display: "flex",
    flexDirection: "column",
    margin: "0 10px",
    maxHeight: "220px",
  },
});

const mapStateToProps = (state) => ({
  entity: selectEntity(state),
  lps: selectSearch(state),
});

export default connect(mapStateToProps)(withStyles(styles)(LPSMenu));
