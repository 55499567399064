import React, { Component } from "react";
import { connect } from "react-redux";
// import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ObjectFilter from "./object-filter";
import LocationFilter from "./location-filter";
import PropertyFilter from "./property-filter";

class FilterMenu extends Component {
    static propTypes = {};

    get fields() {
        return [
            {
                label: "Build year",
                defaultValue: "",
            },
            {
                label: "City",
                defaultValue: "",
            },
            {
                label: "Streetname",
                defaultValue: "",
            },
            {
                label: "Postcode",
                defaultValue: "",
            },
        ];
    }

    render() {
        const {
            onBlocksChange,
            toggleMode,
            clearFilterProperty,
            handleAddCities,
            objectFilter,
            locationFilter,
        } = this.props;

        return (
            <Grid container spacing={16}>
                <Grid item xs={4}>
                    <ObjectFilter
                        objectFilter={objectFilter}
                        addFilter={onBlocksChange}
                        toggleMode={toggleMode}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LocationFilter
                        handleAddCities={handleAddCities}
                        addFilter={onBlocksChange}
                        clearFilterProperty={clearFilterProperty}
                        locationFilter={locationFilter}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PropertyFilter/>
                </Grid>
            </Grid>
        );
    }
}

// const styles = theme => ({
//     root: {
//         margin: "auto",
//     },
//     content: {
//         height: "370px",
//         overflow: "hidden",
//     },
//     textField: {
//         marginLeft: theme.spacing.unit,
//         marginRight: theme.spacing.unit,
//     },
// });

// export default connect(state => ({
//     ...state,
// }))(withStyles(styles)(FilterMenu));

export default connect(state => ({
    ...state,
}))(FilterMenu);
