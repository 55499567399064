import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CallRounded from "@material-ui/icons/CallRounded";
import CallEndRounded from "@material-ui/icons/CallEndRounded";

const ResultTable = ({ classes, title, calls, callsEnd, deals }) => (
    <Grid item xs={6}>
        <Typography className={classes.subheading} variant="subtitle1">
            {title}
        </Typography>
        <Grid container>
            <Grid item xs={4}>
                <Grid container>
                    <Grid className={classes.column} item xs={9}>
                        <Typography variant="body2">Calls</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={3}>
                        <CallRounded className={classes.iconCall}/>
                        <CallEndRounded className={classes.iconCallEnd}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.column} item xs={8}>
                <Typography className={classes.textCenter} variant="body2">
                    {calls}
                </Typography>
                <Typography className={classes.textCenter} variant="body2">
                    {callsEnd}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">Deals added</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography className={classes.textCenter} variant="body2">
                    {deals}
                </Typography>
            </Grid>
        </Grid>
    </Grid>
);

ResultTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    iconCall: {
        color: theme.palette.secondary.call,
    },
    iconCallEnd: {
        color: theme.palette.secondary.callEnd,
    },
    title: {
        height: 34,
        display: "flex",
        justifyContent: "center",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
    textCenter: {
        display: "flex",
        justifyContent: "center",
    },
    subheading: {
        height: 24,
        display: "flex",
        justifyContent: "center",
    },
});

export default withStyles(styles)(ResultTable);
