import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FieldEditor from "../../../../../common/components/editor/field";
import {
  INPUT,
  SELECT,
  SWITCH,
  COMPONENT,
} from "../../../../../common/constants/field-types";
import WhitelistEditor from "../WhitelistEditor";

const UserDialog = (props) => {
  const { classes, onChange, user, currentOptions } = props;

  const {
    email = "",
    name = "",
    surname = "",
    role = "",
    default_lps_profile_id = "",
    ip_restriction = true,
    user_ip_whitelist = [],
  } = user;

  const fields = [
    {
      label: "Email",
      name: "email",
      value: email,
      type: INPUT,
      required: true,
    },
    {
      label: "Name",
      name: "name",
      value: name,
      type: INPUT,
      required: true,
    },
    {
      label: "Surname",
      name: "surname",
      value: surname,
      type: INPUT,
      required: true,
    },
    {
      label: "Default LPS profile",
      name: "default_lps_profile",
      value: {
        current: default_lps_profile_id || "",
        options: currentOptions,
        none: "n/a",
      },
      type: SELECT,
    },
    {
      label: "Role",
      name: "role",
      helperText: "select user role",
      value: {
        options: [
          {
            label: "admin",
            value: "Administrator",
          },
          {
            label: "user",
            value: "User",
          },
        ],
        current: role,
      },
      type: SELECT,
    },
    {
      label: "IP restriction",
      name: "ip_restriction",
      value: ip_restriction,
      type: SWITCH,
    },
    {
      label: "Whitelisted IP's",
      name: "user_ip_whitelist",
      type: COMPONENT,
      component: (
        <WhitelistEditor
          ips={user_ip_whitelist}
          onChange={(ip) => onChange("user_ip_whitelist", ip)}
        />
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {fields.map((field) => {
            return (
              <FieldEditor
                key={field.name}
                {...field}
                onChange={(e) => onChange(field.name, e)}
              />
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    margin: "auto",
    width: 400,
  },
  form: {
    width: "100%",
  },
});

UserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserDialog);
