const { REACT_APP_CLIENT_ID } = process.env;

class UserService {
  static set setToken(token) {
    localStorage.setItem("token", token);
  }
  static set setUser({ user }) {
    localStorage.setItem("user", user);
  }
  static set setLPC(lpc) {
    localStorage.setItem("lpc_data", lpc);
  }
  static get LPC() {
    return localStorage.getItem("lpc_data");
  }
  static get token() {
    return localStorage.getItem("token");
  }
  static get user() {
    return localStorage.getItem("user");
  }
  static get googleClient() {
    return {
      id: REACT_APP_CLIENT_ID
    };
  }
  static logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
}

export default UserService;
