import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
    receiveByStreetSuccess,
    receiveStreetListSuccess,
    receiveByStreetError,
    receiveStreetListError,
} from "../actions/street";
import { SEARCH_RECEIVE_STREET_LIST_REQUEST, SEARCH_RECEIVE_STREET_REQUEST } from "../../../common/constants";

function* searchReceiveStreet(action) {
    const number = action.query.number.replace(/[+()]/gm, "");
    const { city, filters, limit, street_name } = action.query;
    const requestQuery = {
        city,
        street_name,
        limit: limit,
        filters: filters,
        number: number,
    };
    try {
        const street = yield call(ApiService.post2, "/search/street", requestQuery);
        yield put(receiveByStreetSuccess(street));
    } catch (error) {
        yield put(receiveByStreetError(error));
    }
}

function* searchReceiveStreetList(action) {
    const number = action.query.number.replace(/[+()]/gm, "");
    const { street_name, setLeadId } = action.query;
    const dataForRequest = {
        street_name,
        number: number,
        setLeadId: setLeadId,
    };

    try {
        const street = yield call(ApiService.post2, "/search/street-list", dataForRequest);
        yield put(receiveStreetListSuccess(street));
    } catch (error) {
        yield put(receiveStreetListError(error));
    }
}

export default function* actionSearchByStreet() {
    yield takeLatest(SEARCH_RECEIVE_STREET_REQUEST, searchReceiveStreet);
    yield takeLatest(SEARCH_RECEIVE_STREET_LIST_REQUEST, searchReceiveStreetList);
}
