import { combineReducers } from "redux";
import {
    QUERY_OBJECTS_RECEIVE_CONFIGS_SUCCESS,
    QUERY_OBJECTS_GET_RESULTS_SUCCESS,
    QUERY_OBJECTS_RECEIVE_CONFIGS_ERROR,
    QUERY_OBJECTS_SAVE_CONFIG_ERROR,
    QUERY_OBJECTS_DELETE_CONFIG_ERROR,
    QUERY_OBJECTS_GET_RESULTS_ERROR,
    QUERY_OBJECTS_GET_RESULTS_REQUEST,
    QUERY_OBJECTS_GET_PREV_RESULTS_LIST_SUCCESS,
    QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_SUCCESS,
    QUERY_OBJECTS_GET_PREV_RESULTS_LIST_ERROR,
    QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_ERROR,
    QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST,
    QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST,
} from "../../../common/constants";

const receiveConfigs = (state = [], action) => {
    switch (action.type) {
        case QUERY_OBJECTS_RECEIVE_CONFIGS_SUCCESS:
            return action.queryObjectsConfigs;

        default:
            return state;
    }
};

const requestQueryObjectsResults = (state = false, action) => {
    switch (action.type) {
        case  QUERY_OBJECTS_GET_RESULTS_REQUEST:
        case  QUERY_OBJECTS_GET_PREV_RESULTS_LIST_REQUEST:
        case  QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_REQUEST:
            return true;
        case QUERY_OBJECTS_GET_RESULTS_SUCCESS:
        case QUERY_OBJECTS_GET_PREV_RESULTS_LIST_SUCCESS:
        case QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_SUCCESS:
        case QUERY_OBJECTS_GET_PREV_RESULTS_LIST_ERROR:
        case QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_ERROR:
        case QUERY_OBJECTS_GET_RESULTS_ERROR:
            return false;

        default:
            return state;
    }
};

const receiveQueryObjectsResults = (state = [], action) => {
    switch (action.type) {
        case QUERY_OBJECTS_GET_RESULTS_SUCCESS:
        case QUERY_OBJECTS_GET_PREV_RESULTS_LIST_SUCCESS:
        case QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_SUCCESS:
            return action.result;

        default:
            return state;
    }
};

const errorFetchConfigs = (state = {}, action) => {
    switch (action.type) {
        case QUERY_OBJECTS_RECEIVE_CONFIGS_ERROR:
        case QUERY_OBJECTS_SAVE_CONFIG_ERROR:
        case QUERY_OBJECTS_DELETE_CONFIG_ERROR:
        case QUERY_OBJECTS_GET_RESULTS_ERROR:
        case QUERY_OBJECTS_GET_PREV_RESULTS_LIST_ERROR:
        case QUERY_OBJECTS_GET_NEXT_RESULTS_LIST_ERROR:
            return action.error;

        default:
            return state;
    }
};

const queryObjects = combineReducers({
    receiveConfigs,
    requestResults: requestQueryObjectsResults,
    receiveResults: receiveQueryObjectsResults,
    error: errorFetchConfigs,
});

export default queryObjects;
