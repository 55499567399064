import { combineReducers } from "redux";
import {
    SEARCH_RECEIVE_BY_PHONE_REQUEST,
    SEARCH_RECEIVE_BY_PHONE_SUCCESS,
    SEARCH_RECEIVE_BY_PHONE_ERROR,

} from "../../../common/constants";

const requestByPhone = (state = false, action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_BY_PHONE_REQUEST:
            return true;
        case SEARCH_RECEIVE_BY_PHONE_SUCCESS:
        case SEARCH_RECEIVE_BY_PHONE_ERROR:

            return false;
        default:
            return state;
    }
};

const receiveResByPhone = (state = [], action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_BY_PHONE_SUCCESS:
            return action.resByPhone;
        default:
            return state;
    }
};

const errorFetchByPhone = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_RECEIVE_BY_PHONE_ERROR:
            return action.error;

        default:
            return state;
    }
};

const phone = combineReducers({
    requestPhone: requestByPhone,
    receiveResByPhone,
    error: errorFetchByPhone,
});

export default phone;
