import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { receivePersonRequest } from "../../../search/actions/person";
import { getPersonTitle, getAge } from "../../../../common/helpers/getter";
import _ from "lodash";
import { selectSearch } from "../../../search/selectors";

let person;

class Search extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        query: "",
        limit: 10,
        byKadasterID: false,
    };

    runQuery = name => {
        const { limit, byKadasterID } = this.state;
        const { dispatch, router } = this.props;
        const splitedName = name.split(" ");
        dispatch(
            receivePersonRequest(
                { splitedName, limit, filters: { byKadasterID } },
                router,
            ),
        );
    };

    debounce = _.debounce(args => this.runQuery(args), 300);

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounce(value);
    };

    handleChange = (e, { newValue, method }) => {
        if (method === "type")
            this.setState({
                query: newValue,
            });

        if (method === "click" || method === "enter")
            this.handleKeyPress({ key: method });
    };

    setPerson = p => {
        person = p;
    };

    handleKeyPress = ({ key }) => {
        const { addPerson } = this.props;
        // const { addPerson, dispatch } = this.props;
        if (key === "enter" || key === "click") {
            addPerson(person);
            // dispatch(setEmptyPersonRequest());
            person = {};
            this.setState({
                query: "",
            });
        }
    };

    handleChangeLimit = num => {
        const { limit } = this.state;
        this.setState({ limit: limit + num });
    };

    toggleFetchByKadId = () => {
        const { byKadasterID } = this.state;
        this.setState({ byKadasterID: !byKadasterID });
    };

    render() {
        const { classes, search } = this.props;
        const { personData } = search;
        const { query, limit, byKadasterID } = this.state;

        return (
            <div className={query ? classes.rootFull : classes.rootEmpty}>
                <div className={classes.buttonWrapper}>
                    <Typography className={classes.text} variant="button">
                        See
                    </Typography>
                    <button
                        id="item"
                        className={classes.footerItemButton}
                        onClick={() => this.handleChangeLimit(5)}
                        disabled={limit >= 50}
                    >
                        <Typography color="inherit" variant="button">
                            +5
                        </Typography>
                    </button>
                    <button
                        id="item"
                        className={classes.footerItemButton}
                        onClick={() => this.handleChangeLimit(-5)}
                        disabled={limit <= 5}
                    >
                        <Typography color="inherit" variant="button">
                            -5
                        </Typography>
                    </button>
                    <Typography className={classes.text} variant="button">
                        current limit: {limit}
                    </Typography>
                    <div className={classes.buttonWrapper}>
                        <Typography variant="body2">find by KadID</Typography>
                        <Switch
                            className={classes.switch}
                            checked={byKadasterID}
                            onChange={this.toggleFetchByKadId}
                            color="primary"
                        />
                    </div>
                </div>
                <Autosuggest
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => {
                    }}
                    suggestions={personData || []}
                    getSuggestionValue={p => {
                        this.setPerson(p);

                        return p.last_name || p.company_name;
                    }}
                    renderInputComponent={({ classes, ref, ...other }) => {
                        return (
                            <TextField
                                fullWidth
                                InputProps={{
                                    inputRef: node => {
                                        ref(node);
                                    },
                                    classes: {
                                        input: classes.input,
                                    },
                                }}
                                {...other}
                            />
                        );
                    }}
                    renderSuggestion={(
                        {
                            company_name,
                            last_name,
                            first_name,
                            nick_name,
                            initials,
                            gender,
                            birth_date,
                            birth_year,
                            kadaster_personal_number,
                            objects,
                            kadaster_objects_amount,
                        },
                        { query, isHighlighted },
                    ) => {
                        const matches = match(last_name || company_name || initials, query);
                        const parts = parse(last_name || company_name || initials, matches);
                        let objsCount = "";
                        if (kadaster_objects_amount || objects) {
                            objsCount =
                                objects && Number(objects.length)
                                    ? Number(kadaster_objects_amount) > Number(objects.length)
                                    ? Number(kadaster_objects_amount)
                                    : Number(objects.length)
                                    : Number(kadaster_objects_amount);
                        }
                        return (
                            <MenuItem
                                className={classes.item}
                                selected={isHighlighted}
                                component="div"
                            >
                                <Typography variant="body2" title={kadaster_personal_number}>
                                    {`${gender ? getPersonTitle(gender) : ""} ${first_name ||
                                    nick_name ||
                                    ""}`.trim() + " "}
                                    {parts.map((part, index) => {
                                        return part.highlight ? (
                                            <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                      </span>
                                        ) : (
                                            <strong key={String(index)} style={{ fontWeight: 400 }}>
                                                {part.text}
                                            </strong>
                                        );
                                    })}
                                    {" " +
                                    `${getAge({
                                        birth_date,
                                        birth_year,
                                    }) || ""}yo, `.trim()}
                                    {` ${objsCount}kad `}
                                </Typography>
                            </MenuItem>
                        );
                    }}
                    inputProps={{
                        classes,
                        placeholder: "Search",
                        value: query,
                        onChange: this.handleChange,
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={({ containerProps, children }) => (
                        <Paper {...containerProps} square>
                            {children && <div className={classes.children}>{children}</div>}
                        </Paper>
                    )}
                />
            </div>
        );
    }
}

const styles = theme => ({
    rootFull: {
        height: 260,
        marginTop: 10,
        flexGrow: 1,
    },
    rootEmpty: {
        height: 60,
        marginTop: 10,
        flexGrow: 1,
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        margin: "0 10px",
    },
    footerItemButton: {
        minWidth: 40,
        minHeight: 28,
        padding: 0,
        border: "none",
        borderRadius: 4,
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#e0e0e0",
        },
        "&:active": {
            outline: "none",
        },
        "&:focus": {
            outline: "none",
        },
    },
    item: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    container: {
        position: "relative",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 2,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: "block",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    children: {
        maxHeight: 200,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

const mapStateToProps = state => ({
    search: selectSearch(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Search));
