import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import {
    createLabelError,
    createLabelSuccess,
    deleteLabelError,
    deleteLabelSuccess,
    destroyEntityError,
    destroyEntitySuccess,
    getEntityError,
    getEntitySuccess,
    receiveLabelsError,
    receiveLabelsSuccess,
    receiveTagsSuccess,
    updateTagSuccess,
    createTagSuccess,
    deleteTagSuccess,
    receiveTagsError,
    updateTagError,
    deleteTagError,
    createTagError,
    receiveUsersDataError,
    receiveUsersDataSuccess,
    updateEntityError,
    updateEntitySuccess,
    updateLabelError,
    updateLabelSuccess,
} from "../actions/";
import {
    CREATE_LABEL_REQUEST, CREATE_TAG_REQUEST,
    DESTROY_ENTITY_REQUEST,
    GET_ENTITY_REQUEST,
    RECEIVE_LABELS_REQUEST, RECEIVE_TAGS_REQUEST,
    RECEIVE_USERS_DATA_REQUEST,
    REMOVE_LABEL_REQUEST, REMOVE_TAG_REQUEST,
    UPDATE_ENTITY_REQUEST,
    UPDATE_LABEL_REQUEST, UPDATE_TAG_REQUEST,
} from "../../../common/constants";
import AlertService from "../../../common/services/alert.service";

function* getUsersData() {
    try {
        // const users = yield call(ApiService.get2, "/user/data"); // prev version
        const users = yield call(ApiService.get2, "/users");
        yield put(receiveUsersDataSuccess(users));
    } catch (error) {
        yield put(receiveUsersDataError(error));
    }
}

// function* getUsersData(action) {
//     const { flag } = action;
//
//     if (flag) {
//         console.log("go skip");
//
//         try {
//             const users = yield call(ApiService.get2, "/user/data", { flag });
//             yield put(receiveUsersDataSuccess(users));
//         } catch (error) {
//             yield put(receiveUsersDataError(error));
//         }
//     } else {
//         try {
//             const users = yield call(ApiService.get2, "/user/data");
//             yield put(receiveUsersDataSuccess(users));
//         } catch (error) {
//             yield put(receiveUsersDataError(error));
//         }
//     }
//
// }

function* getLabels() {
    try {
        const labels = yield call(ApiService.get2, "/label");
        yield put(receiveLabelsSuccess(labels));
    } catch (error) {
        yield put(receiveLabelsError(error));
    }
}

function* updateLabel(action) {
    try {
        const updatedLabel = yield call(
            ApiService.put2,
            `/label/${action.id}`,
            action.data,
        );
        yield put(updateLabelSuccess(updatedLabel));
    } catch (error) {
        yield put(updateLabelError(error));
    }
}

function* createLabel(action) {
    try {
        const createdLabel = yield call(ApiService.post2, "/label", action.data);
        yield put(createLabelSuccess(createdLabel));
    } catch (error) {
        yield put(createLabelError(error));
    }
}

function* deleteLabel(action) {
    try {
        const deletedLabel = yield call(ApiService.delete2, `/label/${action.id}`);
        yield put(deleteLabelSuccess(deletedLabel));
    } catch (error) {
        yield put(deleteLabelError(error));
    }
}

function* getTags() {
    try {
        const tags = yield call(ApiService.get2, "/tag");
        yield put(receiveTagsSuccess(tags));
    } catch (error) {
        yield put(receiveTagsError(error));
    }
}

function* updateTag(action) {
    try {
        const updatedTag = yield call(
            ApiService.put2,
            `/tag/${action.id}`,
            action.data,
        );
        yield put(updateTagSuccess(updatedTag));
    } catch (error) {
        yield put(updateTagError(error));
    }
}

function* createTag(action) {
    try {
        const createdTag = yield call(ApiService.post2, "/tag", action.data);
        yield put(createTagSuccess(createdTag));
    } catch (error) {
        yield put(createTagError(error));
    }
}

function* deleteTag(action) {
    try {
        const deletedTag = yield call(ApiService.delete2, `/tag/${action.id}`);
        yield put(deleteTagSuccess(deletedTag));
    } catch (error) {
        yield put(deleteTagError(error));
    }
}

function* getEntity(action) {
    try {
        const entity = yield call(ApiService.get2, `/entity/${action.id}`);
        if (entity && entity.data && entity.data.company_id && action.cb) {
            action.cb(entity.data.company_id);
        } else if (entity && entity.data && action.cb && !entity.data.company_id) {
            action.cb();
        }

        yield put(getEntitySuccess(entity));

    } catch (error) {
        AlertService.error("Access is denied or entity does't exist");
        yield put(getEntityError(error));
    }
}

function* updateEntity(action) {
    try {
        const entity = yield call(
            ApiService.put2,
            `/entity/${action.id}`, {deleteMode: action.deleteMode, types: action.action_types, info: action.data}
        );
        yield put(updateEntitySuccess(entity, action.cb));
    } catch (error) {
        yield put(updateEntityError(error));
    }
}

function* deleteEntity(action) {
    try {
        const entity = yield call(
            ApiService.delete2,
            `/entity/${action.id}`,
            action.data,
        );
        yield put(destroyEntitySuccess(entity));
    } catch (error) {
        yield put(destroyEntityError(error));
    }
}

export default function* actionLeadgenHeadMenu() {
    yield takeLatest(RECEIVE_USERS_DATA_REQUEST, getUsersData);
    yield takeLatest(RECEIVE_LABELS_REQUEST, getLabels);
    yield takeLatest(UPDATE_LABEL_REQUEST, updateLabel);
    yield takeLatest(CREATE_LABEL_REQUEST, createLabel);
    yield takeLatest(REMOVE_LABEL_REQUEST, deleteLabel);
    yield takeLatest(RECEIVE_TAGS_REQUEST, getTags);
    yield takeLatest(UPDATE_TAG_REQUEST, updateTag);
    yield takeLatest(CREATE_TAG_REQUEST, createTag);
    yield takeLatest(REMOVE_TAG_REQUEST, deleteTag);
    yield takeLatest(GET_ENTITY_REQUEST, getEntity);
    yield takeLatest(UPDATE_ENTITY_REQUEST, updateEntity);
    yield takeLatest(DESTROY_ENTITY_REQUEST, deleteEntity);
}
