import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Schedule from "../../../common/components/schedule";
import { getDateFromNow, getPersonTitle } from "../../../common/helpers/getter";
import { euroSign } from "../../../common/constants/symbols";
import { formatPrice } from "../../../common/formats/price";
import { updateEntityRequest } from "../../leadgen-head-menu/actions";
import EmptyIcon from "../../../common/img/empty-person.png";

const Lead = ({ onOpen, entity, objects, classes, dispatch, cb }) => {
    const handleQuickSetDate = days => {
        const { id } = entity || {};
        const newDate = new Date();
        const action_types = ['save date'];
        newDate.setDate(newDate.getDate() + days);
        id && dispatch(updateEntityRequest(false, action_types, id, { schedule: newDate }, null, cb));

        return null;
    };

    const handleSaveLead = ({ type, schedule }, responsible_person_id) => {
        const { id } = entity || {};
        const action_types = [type];
        switch (type) {
            case "save date":
                return (
                    id && dispatch(updateEntityRequest(false, action_types, id, { schedule }, null, cb))
                );
            case "choose responsibility":
                return (
                    action_types,
                    responsible_person_id &&
                    id &&
                    dispatch(
                        updateEntityRequest(false, action_types,  id, { responsible_person_id }, null, cb),
                    )
                );
            case "remove all":
                return (
                    id &&
                    dispatch(
                        updateEntityRequest(
                            true,
                            action_types,
                            id,
                            {
                                responsible_person_id: null,
                                schedule: null,
                            },
                            null,
                            cb,
                        ),
                    )
                );
            case "remove date":
                return (
                    id &&
                    dispatch(updateEntityRequest(true, action_types, id, { schedule: null }, null, cb))
                );

            default:
                return null;
        }
    };
    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <div className={classes.cardTitle}>
                    <Typography variant="h5" className={classes.cardTitleText}>
                        {entity.schedule
                            ? getDateFromNow(entity.schedule).label
                            : "no schedule"}
                    </Typography>
                    <Schedule
                        handleSave={handleSaveLead}
                        handleQuickSave={handleQuickSetDate}
                        owner={{
                            schedule: entity.schedule,
                            responsiblePerson: entity.responsible_person,
                        }}
                    />
                    <CardMedia
                        className={classes.cover}
                        image={
                            entity.responsible_person.img_url
                                ? entity.responsible_person.img_url
                                : EmptyIcon
                        }
                        title="responsible icon"
                    />
                </div>
                <a
                    href={`/leadgen/entity/${entity.id}`}
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className={classes.cardName}>
                        <Typography variant="h6">
                            {`${entity.gender ? getPersonTitle(entity.gender) : ""} ${
                                entity.name
                            } ${entity.age ? `(${entity.age})` : ""}`.trim()}
                        </Typography>
                        {entity.city && (
                            <Typography variant="subtitle1">{entity.city}</Typography>
                        )}
                    </div>
                </a>
                <div className={classes.cardObjects}>
                    <div className={classes.cardObjectsText}>
                        {objects.common_size && (
                            <Typography variant="subtitle1">
                                {objects.common_size + "m2"}
                            </Typography>
                        )}
                        {objects.object_count && (
                            <Typography variant="subtitle1">
                                {objects.object_count + " objects"}
                            </Typography>
                        )}
                        {objects.units_count && (
                            <Typography variant="subtitle1">
                                {objects.units_count + " units"}
                            </Typography>
                        )}
                        {objects.common_price && (
                            <Typography variant="subtitle1">
                <span>
                  {euroSign}
                    <span>{formatPrice(objects.common_price)}</span>
                </span>
                            </Typography>
                        )}
                    </div>
                    <Button
                        onClick={() => onOpen(entity.notes)}
                        className={classes.cardObjectsButton}
                    >
                        show notes
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

Lead.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string,
};

export const styles = theme => ({
    card: {
        height: "fit-content",
    },
    cardTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.palette.primary[400],
    },
    cardTitleText: {
        width: "calc(100% - 60px)",
        minHeight: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardName: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 10,
        minHeight: 50,
    },
    cardObjects: {
        display: "flex",
        justifyContent: "space-between",
    },
    cardObjectsText: {
        margin: "0 6px 6px 20px",
    },
    cardObjectsButton: {
        width: "50%",
        borderRadius: "inherit",
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    cover: {
        width: 60,
        height: 60,
    },
    link: {
        textDecoration: "none",
    },
});

export default connect(null)(withStyles(styles)(React.memo(Lead)));
