import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { selectSearch } from "../../../search/selectors";
import { receiveCityRequest } from "../../../search/actions/city";

let city;

class QueryCitySearch extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        query: "",
        limit: 10,
    };

    runQuery = query => {
        const { dispatch } = this.props;
        dispatch(
            receiveCityRequest(
                {
                    query,
                },
            ),
        );
    };

    debounce = _.debounce(args => this.runQuery(args), 300);

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounce(value);
        this.runQuery(value);
    };

    handleChange = (e, { newValue, method }) => {
        if (method === "type")
            this.setState({
                query: newValue,
            });

        if (method === "click" || method === "enter")
            this.handleKeyPress({ key: method });
    };

    setCity = c => {
        city = c;
    };

    handleKeyPress = ({ key }) => {
        const { addCity } = this.props;
        if (key === "enter" || key === "click") {
            addCity(city);
            this.setState({
                query: "",
            });
        }
    };

    handleChangeLimit = num => {
        const { limit } = this.state;
        this.setState({ limit: limit + num });
    };

    render() {
        const { classes, search } = this.props;
        const { receiveCity } = search;
        const { query, limit } = this.state;

        return (
            <div className={query ? classes.rootFull : classes.rootEmpty}>
                <div className={classes.buttonWrapper}>
                    <Typography className={classes.text} variant="button">
                        See
                    </Typography>
                    <button
                        id="item"
                        className={classes.footerItemButton}
                        onClick={() => this.handleChangeLimit(5)}
                        disabled={limit >= 20}
                    >
                        <Typography color="inherit" variant="button">
                            +5
                        </Typography>
                    </button>
                    <button
                        id="item"
                        className={classes.footerItemButton}
                        onClick={() => this.handleChangeLimit(-5)}
                        disabled={limit <= 5}
                    >
                        <Typography color="inherit" variant="button">
                            -5
                        </Typography>
                    </button>
                    <Typography className={classes.text} variant="button">
                        current limit: {limit}
                    </Typography>
                </div>

                <Autosuggest
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => {
                    }}
                    suggestions={receiveCity ? receiveCity.slice(0, limit) : []}
                    getSuggestionValue={({ city }) => {
                        this.setCity(city);
                        return city;
                    }}
                    renderInputComponent={({ classes, ref, ...other }) => {
                        return (
                            <TextField
                                fullWidth
                                InputProps={{
                                    inputRef: node => {
                                        ref(node);
                                    },
                                    classes: {
                                        input: classes.input,
                                    },
                                }}
                                {...other}
                            />
                        );
                    }}
                    renderSuggestion={({ city }, { query, isHighlighted }) => {
                        const matches = match(city, query);
                        const parts = parse(city, matches);

                        return (
                            <MenuItem
                                className={classes.item}
                                selected={isHighlighted}
                                component="div"
                            >
                                <Typography variant="body2" title={city}>
                                    {parts.map((part, index) => {
                                        return part.highlight ? (
                                            <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                      </span>
                                        ) : (
                                            <strong key={String(index)} style={{ fontWeight: 400 }}>
                                                {part.text}
                                            </strong>
                                        );
                                    })}
                                </Typography>
                            </MenuItem>
                        );
                    }}
                    inputProps={{
                        classes,
                        placeholder: "Search",
                        value: query,
                        onChange: this.handleChange,
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={({ containerProps, children }) => (
                        <Paper {...containerProps} square>
                            {children && <div className={classes.children}>{children}</div>}
                        </Paper>
                    )}
                />
            </div>
        );
    }
}

const styles = theme => ({
    rootFull: {
        height: 260,
        marginTop: 10,
        marginBottom: 10,
        flexGrow: 1,
    },
    rootEmpty: {
        height: 60,
        marginTop: 10,
        marginBottom: 10,
        flexGrow: 1,
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        margin: "0 10px",
    },
    footerItemButton: {
        minWidth: 40,
        minHeight: 28,
        padding: 0,
        border: "none",
        borderRadius: 4,
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#e0e0e0",
        },
        "&:active": {
            outline: "none",
        },
        "&:focus": {
            outline: "none",
        },
    },
    item: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    container: {
        position: "relative",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 2,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: "block",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    children: {
        maxHeight: 200,
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

const mapStateToProps = state => ({
    search: selectSearch(state),
});

export default connect(mapStateToProps)(withStyles(styles)(QueryCitySearch));
