import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Head from "../common/components/head";
import ViewTemplate from "./ViewTemplate";
import Sidebar from "../common/components/sidebar";
import UserDropDown from "../common/components/user-drop-down";
import { selectUser } from "../modules/login/selectors"

type TemplateProps = {
    classes: {
        root: string,
        drawer: string,
        drawerPaper: string,
        drawerHeader: string,
        contentLeft: string,
        contentShiftLeft: string,
        contentRight: string,
        contentShiftRight: string,
    },
    children: any,
    location: any,
    router: any,
user: any
}

class Template extends Component<TemplateProps> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        openSideBar: false,
        openSideMenu: false,
    };

    handleToggleDrawer = () => {
        const {openSideBar} = this.state;
        this.setState({
            openSideBar: !openSideBar,
        });
    };

    handleToggleSideMenu = () => {
        const {openSideMenu} = this.state;
        this.setState({openSideMenu: !openSideMenu});
    };

    render() {
        const {classes, children, location, router, user} = this.props;
        const {userData} = user;
        const {openSideBar, openSideMenu} = this.state;

        return (
            <div className={classes.root}>
                <Head
                    router={router}
                    location={location}
                    toggleDrawer={this.handleToggleDrawer}
                    toggleSideMenu={this.handleToggleSideMenu}
                    openBar={openSideBar}
                    openMenu={openSideMenu}
                />
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={openSideBar}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        {/*@ts-ignore*/}
                        <UserDropDown user={userData}/>
                        <div>
                            <Typography variant="body2">{userData.name}</Typography>
                            <Typography variant="body2">{userData.role}</Typography>
                        </div>
                        <IconButton onClick={this.handleToggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </div>
                    <Divider/>
                    <Sidebar path={location.pathname} router={router}/>
                </Drawer>
                <main
                    className={classNames(classes.contentLeft, classes.contentRight, {
                        [classes.contentShiftLeft]: openSideBar,
                        [classes.contentShiftRight]: openSideMenu,
                    })}
                >
                    {/*@ts-ignore*/}
                    <ViewTemplate>{children}</ViewTemplate>
                </main>
            </div>
        );
    }
}

const drawerLeftWidth = 200;
const drawerRightWidth = 220;

const styles = theme => ({
    "@global": {
        ".react-calendar__tile": {
            "&:enabled:hover": {
                backgroundColor: theme.palette.primary[400],
            },
            "&:enabled:focus": {
                backgroundColor: theme.palette.primary[500],
            },
        },
        ".react-calendar__tile--active": {
            background: theme.palette.primary[500],
            color: "white",
            "&:enabled:hover": {
                background: theme.palette.primary[400],
            },
            "&:enabled:focus": {
                background: theme.palette.primary[500],
            },
        },
        ".react-calendar__navigation button": {
            fontFamily: "Roboto",
            "&:enabled:hover": {
                backgroundColor: theme.palette.primary[400],
            },
            "&:enabled:focus": {
                backgroundColor: theme.palette.primary[400],
            },
        },
        ".react-calendar__month-view__weekdays__weekday abbr": {
            textDecoration: "none",
        },
        ".react-calendar__tile--hasActive": {
            backgroundColor: theme.palette.primary[500],
        },
        ".react-calendar__month-view__weekdays": {
            fontWeight: "inherit",
        },
    },
    root: {
        display: "flex",
    },
    drawer: {
        width: drawerLeftWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerLeftWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    contentLeft: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerLeftWidth,
    },
    contentShiftLeft: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    contentRight: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
    },
    contentShiftRight: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerRightWidth,
    },
});


const mapStateToProps = state => ({
    user: selectUser(state),
});

//@ts-ignore
export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(Template));