import { put, takeLatest, call } from "redux-saga/effects";
import ApiService from "../../../common/services/api.service";
import { receiveByPhoneSuccess, receiveByPhoneError } from "../actions/phone";
import {
    SEARCH_RECEIVE_BY_PHONE_REQUEST,
} from "../../../common/constants";

function* searchReceiveByPhone(action) {
    const query = action.data.query.replace(/[+()]/gm, "");
    const { limit } = action.data;
    const dataForRequest = {
        limit,
        query,

    };
    try {
        const resByPhone = yield call(ApiService.post2, "/search/phone", dataForRequest);
        yield put(receiveByPhoneSuccess(resByPhone));
    } catch (error) {
        yield put(receiveByPhoneError(error));
    }
}

export default function* actionSearchByPhone() {
    yield takeLatest(SEARCH_RECEIVE_BY_PHONE_REQUEST, searchReceiveByPhone);
}
