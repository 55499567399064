import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";

class PropertyFilter extends Component {

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper>
                    <Typography className={classes.head} variant="subtitle1">
                        Property filter
                    </Typography>
                    <Divider className={classes.divider}/>
                    <div className={classes.body}>
                        <div className={classes.row}>
                            <div className={classes.container}>
                                <Typography variant="body2">Eigengrond</Typography>
                                <Switch className={classes.switch} defaultChecked={false} color="secondary"/>
                            </div>
                            <div className={classes.container}>
                                <Typography variant="body2">Erfpacht</Typography>
                                <Switch className={classes.switch} defaultChecked={false} color="secondary"/>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.container}>
                                <Typography variant="body2">Owner age</Typography>
                                <Typography variant="body2">(Number)</Typography>
                            </div>
                            <div className={classes.container}>
                                <Typography variant="body2">Shared ownership</Typography>
                                <Switch className={classes.switch} defaultChecked={false} color="secondary"/>
                                <Typography variant="body2">(Min owners)</Typography>
                            </div>
                        </div>
                    </div>
                </Paper>

            </React.Fragment>
        );
    }
}

PropertyFilter.propTypes = {};

const styles = theme => ({
    row: {
        padding: 8,
    },
    head: {
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary[400],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    divider: {
        width: "100%",
        backgroundColor: theme.palette.primary.divider,
    },
    switch: {
        display: "flex",
        margin: "-10px 0",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 20,
    },
    body: {
        height: "260px",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
        },
    },
});

export default withStyles(styles)(PropertyFilter);
