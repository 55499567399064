import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RowActivity from "./RowActivity";
import CallRounded from "@material-ui/icons/CallRounded";
import CallEndRounded from "@material-ui/icons/CallEndRounded";

const Activity = ({ classes }) => (
    <div className={classes.root}>
        <Grid container>
            <Grid className={classes.head} item xs={12}>
                <Typography className={classes.title} variant="h6">
                    Recent Activity
                </Typography>
                <div>
                    <FormControlLabel
                        control={<Switch defaultChecked color="secondary"/>}
                        labelPlacement="start"
                        label="Deals"
                    />
                    <FormControlLabel
                        label="Leads"
                        labelPlacement="start"
                        control={<Switch defaultChecked color="secondary"/>}
                    />
                </div>
                <Typography className={classes.title} variant="subtitle1">
                    Show leads for (everyone)(me)(user)
                </Typography>
            </Grid>
            <Divider className={classes.divider}/>
            <RowActivity
                date="today 11:28"
                object="Object/portfolio"
                task="(task)"
                phoneNumber="06-4385320"
                owner="Owner/contact"
                notes="(notes)"
                imgUrl={<CallRounded className={classes.iconCall}/>}
            />
            <RowActivity
                date="today 11:28"
                object="Object/portfolio"
                task="(task)"
                phoneNumber="06-4385320"
                owner="Owner/contact"
                notes="(notes)"
                imgUrl={<CallEndRounded className={classes.iconCallEnd}/>}
            />
            <RowActivity
                date="today 11:28"
                object="Object/portfolio"
                task="(task)"
                phoneNumber="06-4385320"
                owner="Owner/contact"
                notes="(notes)"
                imgUrl={<CallEndRounded className={classes.iconCallEnd}/>}
            />
            <RowActivity
                date="today 11:28"
                object="Object/portfolio"
                task="(task)"
                phoneNumber="06-4385320"
                owner="Owner/contact"
                notes="(notes)"
                imgUrl={<CallRounded className={classes.iconCall}/>}
            />
        </Grid>
    </div>
);

Activity.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    root: {
        display: "flex",
        backgroundColor: theme.palette.primary[400],
    },
    iconCall: {
        color: theme.palette.secondary.call,
    },
    iconCallEnd: {
        color: theme.palette.secondary.callEnd,
    },
    divider: {
        width: "100%",
        backgroundColor: theme.palette.primary.divider,
    },
    head: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#fff",
    },
    title: {
        display: "flex",
        alignItems: "center",
        height: 34,
    },
});

export default withStyles(styles)(Activity);
