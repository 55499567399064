import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  getObjectsAmount,
  leadgenDeleteObjectRequest,
  leadgenNextObjectsByOwnerRequest,
  leadgenObjectsByOwnerRequest,
  leadgenPrevObjectsByOwnerRequest,
} from "../../actions/objects";
import { fetchLeadgenOwnersRequest } from "../../actions/owners";
import { showAddress } from "../../actions/maps";
import {
  leadgenEditObjectDataRequest,
  leadgenUpdateObjectRequest,
} from "../../actions/objects";
import { updateEntityRequest } from "../../../leadgen-head-menu/actions";
import {
  Grid,
  Button,
  Divider,
  Typography,
  IconButton,
  Icon,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import ArrowBack from "@material-ui/icons/ArrowBackRounded";
import ArrowForward from "@material-ui/icons/ArrowForwardRounded";
import LinkIcon from "@material-ui/icons/Link";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Info from "@material-ui/icons/InfoOutlined";
import FavIconFull from "@material-ui/icons/Grade";
import FavIconEmpty from "@material-ui/icons/GradeOutlined";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { formatPrice } from "../../../../common/formats/price";
import { formatImportDate } from "../../../../common/formats/date";
import { euroSign } from "../../../../common/constants/symbols";
import kadIcon from "../../../../common/img/kadaster.jpeg";
import kadastralekaartIcon from "../../../../common/img/kadastralekaart.png";
import Spinner from "../../../../common/components/spinner";
import TooltipWrapper from "../../../../common/components/tooltip";
import Search from "../search";
import DialogWrapper from "../../../../common/components/dialog";
import EditObject from "../editObject";
import NoData from "../no-data";
import Head from "./Head";
import TotalHead from "./TotalHead";
import { CollapsePanel } from "../../../../common/components/panel";
import { selectSearch } from "../../../search/selectors";
import { selectEntity } from "../../../leadgen-head-menu/selectors";
import { selectLeadgen } from "../../selectors";

class Objects extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  state = {
    objectsAmount: 0,
    objects: [],
    newObjects: [],
    newObjectsForSave: [],
    objectsForUnlink: [],
    mainHead: {},
    editMode: false,
    openEditObjectMenu: false,
    addObjectsDialog: false,
    deleteObjectDialog: false,
    isBlacklisted: false,
    isConfirmed: false,
    limit: 0,
  };

  componentDidUpdate(prevProps) {
    const { objects, objectsAmount } = this.props.leadgen;
    const { blacklisted, entity } = this.props.currentEntity;
    const { id } = entity;
    const { dispatch } = this.props;

    if (objects !== prevProps.leadgen.objects) {
      const objIDs = objects.map(({ object_id }) => object_id);
      objIDs.length && dispatch(fetchLeadgenOwnersRequest(objIDs, id));
      this.setState({
        objects: [...objects],
        packedObjects: this.sortAndPackObjectsByCity(objects),
        mainHead: this.getObjectListHeadInfo(objects),
        isBlacklisted: blacklisted,
        isConfirmed: false,
        editMode: false,
      });
    }
    if (objectsAmount !== prevProps.leadgen.objectsAmount) {
      this.setState({
        objectsAmount: objectsAmount,
      });
    }
  }

  handleClose = () => {
    this.setState({
      isBlacklisted: false,
      isConfirmed: true,
    });
  };

  sortAndPackObjectsByCity = (objects) => {
    const { search } = this.props;
    const { getSettings } = search;
    const defaultCities = (getSettings && getSettings.openPanelsByCities) || [];
    if (!(objects && objects.length)) return null;

    const currentCities = [...new Set(objects.map((i) => i.city))].sort();

    const commonCities = currentCities
      .filter((i) => defaultCities.includes(i))
      .sort();

    let cities = [...new Set([...commonCities, ...currentCities])];

    const kad_sorter = (a, b) => {
      if (a.kadaster_id > b.kadaster_id) return 1;
      if (b.kadaster_id > a.kadaster_id) return -1;
      return 0;
    };

    return cities.map((i) => {
      let objectList = objects.filter((obj) => obj.city === i);

      const are_favorite = objectList
        .filter((obj) => obj.is_favorite)
        .sort(kad_sorter);
      const have_street = objectList
        .filter((obj) => obj.street && !obj.is_favorite)
        .sort(kad_sorter);
      const others = objectList
        .filter((obj) => !obj.street && !obj.is_favorite)
        .sort(kad_sorter);

      objectList = [...are_favorite, ...have_street, ...others];

      return {
        city: i || "NO CITY",
        head: this.getObjectListHeadInfo(objectList),
        preparedObjectList: this.getPreparedObjectList(objectList),
      };
    });
  };

  handleResetObjects = () => {
    const { leadgen } = this.props;
    const { objects } = leadgen;
    this.setState({
      objects: [...objects],
      objectsForUnlink: [],
      packedObjects: this.sortAndPackObjectsByCity(objects),
      mainHead: this.getObjectListHeadInfo(objects),
      editMode: false,
    });
  };

  getObjectListHeadInfo = (objects) => {
    let houseSize = 0;
    let otherSize = 0;
    let totalPrice = 0;

    objects &&
      objects.forEach(({ house_size, other_size, price }) => {
        houseSize += house_size || 0;
        otherSize += other_size || 0;
        totalPrice += Number(price) || 0;
      });

    let totalSize = houseSize + otherSize;
    return { houseSize, otherSize, totalPrice, totalSize };
  };

  openDialog = (type, id) => {
    if (type === "deleteObjectDialog") {
      return this.setState({ [type]: true, deleteObjectID: id });
    }
    this.setState({ [type]: true });
  };

  closeDialog = (type) => {
    this.setState({ [type]: false, newObjects: [] });
  };

  getPreparedObjectList = (objects) => {
    const { classes } = this.props;
    return objects.map(
      ({
        city = "",
        street,
        plot_index,
        plot,
        owner_count,
        price,
        purpose,
        units,
        house_size,
        other_size,
        object_id,
        kadaster_id,
        kadaster_url,
        is_favorite,
      }) => {
        const address = (units && units.length && units[0].address) || {};
        const object_street = `${address.street_name || ""} ${
          address.number || ""
        } ${
          units && units.length === 1 ? address.number_add || "" : ""
        }`.trim();
        const object_name = !!Object.keys(address).length ? (
          <span>
            {object_street && (
              <span className={classes.spacer}>{object_street}</span>
            )}
            {plot_index && <span className={classes.spacer}>{plot_index}</span>}
          </span>
        ) : (
          <span>
            {street && <span className={classes.spacer}>{street}</span>}
            {plot && (
              <span className={classes.spacer}>
                {`${plot.section || ""} ${plot.plot_number || ""} ${
                  plot.index || ""
                }`.trim()}
              </span>
            )}
          </span>
        );
        return {
          object_id,
          object_location: {
            city,
            address:
              street !== "mixed"
                ? street || ""
                : !!units.length
                ? `${address.street_name || ""} ${address.number || ""}`.trim()
                : "",
          },
          object_name,
          kadaster_id: kadaster_id || "",
          kadaster_url: kadaster_url || "",
          city_code: plot.city_code,
          plot_number: plot.plot_number,
          section: plot.section,
          is_favorite,
          owner_count,
          purpose,
          size:
            house_size || other_size ? house_size + other_size + " m2" : "n/a",
          price: price ? (
            <span>
              {euroSign}
              <span>{formatPrice(price)}</span>
            </span>
          ) : (
            "n/a"
          ),
          preparedUnitList: units
            ? units.map(({ address, size, price, purpose }) => ({
                street: `${address.street_name} ${address.number || ""} ${
                  address.number_add || ""
                }`.trim(),
                size: size ? size + " m2" : "",
                purpose,
                price: price ? (
                  <span>
                    {euroSign}
                    <span>{formatPrice(price)}</span>
                  </span>
                ) : (
                  "n/a"
                ),
              }))
            : [],
        };
      }
    );
  };

  getObjIDsForIgnore = () => {
    const { newObjects, objects } = this.state;
    const allObjects = [
      ...this.props.leadgen.objects,
      ...newObjects,
      ...objects,
    ].map(({ object_id }) => object_id);
    return [...new Set(allObjects)];
  };

  handleAddObject = (object) => {
    const { newObjects, objects } = this.state;
    /**check if we have duplicate objects in object list**/
    const allObjects = [...objects, ...newObjects];
    const allObjectsIds = allObjects.map((object) => object.object_id);
    if (allObjectsIds.includes(object.object_id)) {
      return this.setState({
        newObjects: newObjects,
      });
    } else {
      this.setState({
        newObjects: [...newObjects, object],
      });
    }
  };

  handleAddObjects = () => {
    let { newObjects, objects } = this.state;
    newObjects = newObjects.map((i) => ({
      ...i,
      owner_count: i.owner_count + 1,
    }));
    const allObjects = [...objects, ...newObjects];
    this.setState({
      objects: allObjects,
      newObjectsForSave: newObjects,
      newObjects: [],
      packedObjects: this.sortAndPackObjectsByCity(allObjects),
      mainHead: this.getObjectListHeadInfo(allObjects),
      addObjectsDialog: false,
    });
  };

  handleUnlinkObject = (city, id) => {
    const { packedObjects, objects, objectsForUnlink } = this.state;

    const newObjects = objects.filter((i) => i.object_id !== id);
    const newPackedObjects = [];

    for (let i = 0; i < packedObjects.length; i++) {
      const packedObject = packedObjects[i];
      if (packedObject.city === city) {
        const newPreparedObjectList = packedObject.preparedObjectList.filter(
          (i) => i.object_id !== id
        );
        const objIDs = newPreparedObjectList.map((i) => i.object_id);
        const objectList = objects.filter((i) => objIDs.includes(i.object_id));
        if (!newPreparedObjectList.length) continue;
        newPackedObjects.push({
          city,
          head: this.getObjectListHeadInfo(objectList),
          preparedObjectList: newPreparedObjectList,
        });
      } else newPackedObjects.push(packedObject);
    }

    this.setState({
      objects: newObjects,
      packedObjects: newPackedObjects,
      mainHead: this.getObjectListHeadInfo(newObjects),
      objectsForUnlink: [id, ...objectsForUnlink],
    });
  };

  handleUnlinkPartObjectsByCity = (city) => {
    const { packedObjects, objects } = this.state;
    const newPackedObjects = packedObjects.filter((i) => i.city !== city);
    const unlinkedObjectListByCity = packedObjects.filter(
      (i) => i.city === city
    )[0].preparedObjectList;
    const unlinkedObjIDs = unlinkedObjectListByCity.map((i) => i.object_id);
    const newObjects = objects.filter(
      (i) => !unlinkedObjIDs.includes(i.object_id)
    );

    this.setState({
      objects: newObjects,
      packedObjects: newPackedObjects,
      mainHead: this.getObjectListHeadInfo(newObjects),
    });
  };

  handleUnlinkAllObjects = () => {
    this.setState({ objects: [], packedObjects: [], mainHead: {} });
  };

  handleSave = () => {
    const { dispatch, currentEntity, router } = this.props;
    const { setLeadId, ...data } = currentEntity;
    const { newObjectsForSave, objectsForUnlink } = this.state;
    const newLinkedObjectIds = newObjectsForSave.map(
      ({ object_id }) => object_id
    );
    const action_types = ["objects"];
    let deleteMode = false;

    dispatch(
      updateEntityRequest(
        deleteMode,
        action_types,
        setLeadId,
        {
          ...data.entity,
          newLinkedObjectIds,
          objectsForUnlink,
        },
        router,
        () => {
          dispatch(leadgenObjectsByOwnerRequest(setLeadId, router));
          dispatch(getObjectsAmount(setLeadId, "entity"));
        }
      )
    );
    this.setState({
      editMode: false,
      newObjectsForSave: [],
      objectsForUnlink: [],
    });
  };

  handleRemoveObject = () => {
    const { dispatch, router, currentEntity, updateEntityData } = this.props;
    const objects =
      currentEntity.entity &&
      currentEntity.entity.objects &&
      currentEntity.entity.objects !== undefined
        ? currentEntity.entity.objects
        : [];
    const { deleteObjectID } = this.state;
    const { model, id } = router.params;

    dispatch(
      leadgenDeleteObjectRequest(deleteObjectID, router, () => {
        if (id && model === "entity") {
          const objIDs = objects
            .map(({ object_id }) => object_id)
            .filter((id) => id !== deleteObjectID);
          dispatch(fetchLeadgenOwnersRequest(objIDs));
          return updateEntityData();
        } else router.push("/leadgen");
      })
    );
  };

  handleEditObject = (id) => {
    const { dispatch } = this.props;
    dispatch(leadgenEditObjectDataRequest(id));
    this.setState({ openEditObjectMenu: true });
  };

  handleEditFavObject = (id, is_favorite) => {
    const { dispatch } = this.props;

    const { objects } = this.state;
    const action_types = ["object_is_favorite"];
    const newObjects = objects.map((i) =>
      i.object_id === id ? { ...i, is_favorite } : i
    );
    const objectData = {
      id,
      is_favorite,
    };
    dispatch(leadgenUpdateObjectRequest(action_types, objectData));
    this.setState({
      objects: newObjects,
      packedObjects: this.sortAndPackObjectsByCity(newObjects),
    });
  };

  eventStop = (e) => e.stopPropagation();
  comparing = (a, b) => b && a.slice(0, -3) === b.kadaster_id.slice(0, -3);
  showOnMaps = (address) => this.props.dispatch(showAddress(address));

  showPrevObjects = () => {
    const { ownerID, dispatch } = this.props;
    const { objects } = this.state;
    const firstItem = objects[0];
    dispatch(leadgenPrevObjectsByOwnerRequest(ownerID, firstItem));
  };

  showNextObjects = () => {
    const { ownerID, dispatch } = this.props;
    const { objects } = this.state;
    const lastItem = objects[objects.length - 1];
    dispatch(leadgenNextObjectsByOwnerRequest(ownerID, lastItem));
  };

  render() {
    const {
      classes,
      ownerID,
      objectIDsToHighlight,
      currentEntity,
      leadgen,
      updateEntityData,
      showMoreInfo,
      search,
    } = this.props;

    const {
      objects,
      newObjects,
      editMode,
      openEditObjectMenu,
      addObjectsDialog,
      deleteObjectDialog,
      packedObjects,
      mainHead,
      objectsAmount,
    } = this.state;
    const { requestObjects, lastId, firstId } = leadgen;
    const { getSettings } = search;
    const { blacklisted } = currentEntity;

    return (
      <div>
        <DialogWrapper
          isBlacklisted={blacklisted}
          open={this.state.isBlacklisted && !this.state.isConfirmed}
          handleClick={() => {
            this.handleClose();
          }}
          title="Entity is blacklisted"
          buttonText="Ok"
        />
        {requestObjects ? (
          <Spinner />
        ) : packedObjects && packedObjects.length ? (
          <React.Fragment>
            <div className={classes.header}>
              <Typography
                className={classes.headTitle}
                variant="body2"
                gutterBottom
              >
                <TooltipWrapper
                  title={
                    <React.Fragment>
                      <Typography variant="subtitle1">
                        Last Import PDF Date
                      </Typography>
                      <Typography>
                        {currentEntity && currentEntity.last_import_date
                          ? formatImportDate(currentEntity.last_import_date)
                          : "Not Set"}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span className={classes.mainTitle}>
                    {!editMode
                      ? objectsAmount !== 0
                        ? `Objects (${objectsAmount})`
                        : "Objects"
                      : "Edit objects"}
                    {ownerID && !editMode && (
                      <IconButton
                        onClick={() => this.openDialog("editMode")}
                        className={classes.editButton}
                      >
                        <Icon fontSize="small">edit_icon</Icon>
                      </IconButton>
                    )}
                    {ownerID && editMode && (
                      <IconButton
                        onClick={this.handleUnlinkAllObjects}
                        className={classes.editButton}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    )}
                  </span>
                </TooltipWrapper>
              </Typography>
              <TotalHead {...mainHead} />
            </div>
            {packedObjects.map(({ city, head, preparedObjectList }) => (
              <CollapsePanel
                key={city}
                open={
                  getSettings && getSettings.openPanelsByCities
                    ? getSettings.openPanelsByCities.includes(city)
                    : false
                }
                headBlock={
                  <div
                    className={classnames(classes.header, classes.subHeader)}
                  >
                    {editMode && (
                      <IconButton
                        onClick={(e) => {
                          this.eventStop(e);
                          this.handleUnlinkPartObjectsByCity(city);
                        }}
                        className={classes.editButton}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    )}
                    <Typography
                      className={classes.cityTitle}
                      variant="subtitle1"
                    >
                      {`${city} (${preparedObjectList.length})`}
                    </Typography>
                    <TotalHead showTitle={false} {...head} />
                  </div>
                }
                headClassName={classes.wrapperHead}
                panelClassName={classnames(
                  classes.wrapperPanel,
                  classes.highlightPackHead
                )}
                panelDetailsClassName={classes.wrapperPanelDetailsObjects}
              >
                <div className={classes.bodyCollapsePanel}>
                  {preparedObjectList.map(
                    (
                      {
                        object_id,
                        object_location,
                        object_name,
                        kadaster_url,
                        kadaster_id,
                        preparedUnitList,
                        is_favorite,
                        city_code,
                        plot_number,
                        section,
                        ...args
                      },
                      index
                    ) => (
                      <div key={object_id}>
                        <CollapsePanel
                          open={false}
                          showExpandIcon={false}
                          headBlock={
                            <div
                              className={classnames(
                                classes.header,
                                classes.subHeader
                              )}
                            >
                              <div
                                onClick={this.eventStop}
                                className={classes.buttonsPanel}
                              >
                                {is_favorite ? (
                                  <FavIconFull
                                    onClick={() =>
                                      this.handleEditFavObject(object_id, false)
                                    }
                                    className={classnames(
                                      classes.iconButton,
                                      classes.favIcon
                                    )}
                                  />
                                ) : (
                                  <FavIconEmpty
                                    onClick={() =>
                                      this.handleEditFavObject(object_id, true)
                                    }
                                    className={classnames(
                                      classes.iconButton,
                                      classes.favIcon
                                    )}
                                  />
                                )}
                                <Info
                                  onClick={() => {
                                    showMoreInfo(object_id);
                                    this.showOnMaps(object_location);
                                  }}
                                  className={classes.iconButton}
                                />
                                {editMode && (
                                  <TooltipWrapper
                                    title={
                                      <React.Fragment>
                                        <Typography variant="subtitle1">
                                          Unlink object
                                        </Typography>
                                      </React.Fragment>
                                    }
                                    placement="top"
                                  >
                                    <LinkIcon
                                      fontSize="small"
                                      onClick={() => {
                                        this.handleUnlinkObject(
                                          city,
                                          object_id
                                        );
                                      }}
                                    />
                                  </TooltipWrapper>
                                )}
                                {kadaster_url ? (
                                  <a
                                    href={kadaster_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <img
                                      className={classes.imgKadLink}
                                      src={kadIcon}
                                      alt="kadaster.nl"
                                    />
                                  </a>
                                ) : (
                                  <a
                                    href="https://kadaster-on-line.kadaster.nl/objadres.asp?init=J&z=ADRES"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <img
                                      className={classes.imgKadLink}
                                      src={kadIcon}
                                      alt="kadaster.nl"
                                    />
                                  </a>
                                )}
                                {city_code && plot_number && section && (
                                  <a
                                    href={`https://kadastralekaart.com/kaart/perceel/${city_code}/${section}/${plot_number}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <img
                                      className={classes.imgKadLink}
                                      src={kadastralekaartIcon}
                                      alt="kadaster.nl"
                                    />
                                  </a>
                                )}
                              </div>
                              <Typography
                                title={kadaster_id || null}
                                className={classes.objectTitle}
                              >
                                {object_name}
                              </Typography>
                              <Head {...args} />
                              <div
                                onClick={this.eventStop}
                                className={classnames(
                                  classes.buttonsPanel,
                                  classes.menuIcon
                                )}
                              >
                                <PopupState
                                  variant="popover"
                                  popupId="popup-icon-menu"
                                >
                                  {(popupState) => (
                                    <React.Fragment>
                                      <MoreVert
                                        className={classes.iconButton}
                                        {...bindTrigger(popupState)}
                                      />
                                      <Menu {...bindMenu(popupState)}>
                                        {editMode && (
                                          <TooltipWrapper
                                            title={
                                              <React.Fragment>
                                                <Typography variant="subtitle1">
                                                  Unlink object
                                                </Typography>
                                              </React.Fragment>
                                            }
                                            placement="right"
                                          >
                                            <MenuItem
                                              onClick={() => {
                                                popupState.close();
                                                this.handleUnlinkObject(
                                                  city,
                                                  object_id
                                                );
                                              }}
                                            >
                                              <LinkIcon fontSize="small" />
                                            </MenuItem>
                                          </TooltipWrapper>
                                        )}
                                        <TooltipWrapper
                                          title={
                                            <React.Fragment>
                                              <Typography variant="subtitle1">
                                                Edit object
                                              </Typography>
                                            </React.Fragment>
                                          }
                                          placement="right"
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              popupState.close();
                                              this.handleEditObject(object_id);
                                            }}
                                          >
                                            <Icon fontSize="small">
                                              edit_icon
                                            </Icon>
                                          </MenuItem>
                                        </TooltipWrapper>
                                        <TooltipWrapper
                                          title={
                                            <React.Fragment>
                                              <Typography variant="subtitle1">
                                                Remove object
                                              </Typography>
                                            </React.Fragment>
                                          }
                                          placement="right"
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              popupState.close();
                                              this.openDialog(
                                                "deleteObjectDialog",
                                                object_id
                                              );
                                            }}
                                          >
                                            <Delete fontSize="small" />
                                          </MenuItem>
                                        </TooltipWrapper>
                                      </Menu>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </div>
                            </div>
                          }
                          headClassName={classes.wrapperHead}
                          panelClassName={classnames(
                            classes.wrapperPanel,
                            classes.highlightPackItem,
                            objectIDsToHighlight &&
                              objectIDsToHighlight.includes(object_id) &&
                              classes.highlightObject
                          )}
                          panelDetailsClassName={
                            classes.wrapperPanelDetailsUnits
                          }
                          wrapperHeadClassName={classes.wrapperPanelHeadObjects}
                        >
                          <div className={classes.bodyCollapsePanel}>
                            {preparedUnitList.length ? (
                              preparedUnitList.map(
                                ({ street, ...args }, index) => (
                                  <div
                                    key={street + index}
                                    className={classnames(
                                      classes.header,
                                      classes.unitWrapper
                                    )}
                                  >
                                    <Typography className={classes.unitTitle}>
                                      {street}
                                    </Typography>
                                    <Head {...args} owner_count={" "} />
                                  </div>
                                )
                              )
                            ) : (
                              <Typography className={classes.unitTitle}>
                                NO UNITS
                              </Typography>
                            )}
                          </div>
                        </CollapsePanel>
                        {!this.comparing(
                          kadaster_id,
                          preparedObjectList[index + 1]
                        ) && <Divider className={classes.divider} />}
                      </div>
                    )
                  )}
                </div>
              </CollapsePanel>
            ))}
            {objects.length >= 100 && (
              <div className={classes.buttonGroup}>
                <IconButton
                  disabled={objects.some((obj) => obj.object_id === firstId)}
                  title="Prev"
                  onClick={this.showPrevObjects}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  disabled={objects.some((obj) => obj.object_id === lastId)}
                  title="Next"
                  onClick={this.showNextObjects}
                >
                  <ArrowForward />
                </IconButton>
              </div>
            )}
          </React.Fragment>
        ) : (
          <NoData
            text="No Objects"
            button={
              currentEntity && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    this.openDialog("editMode");
                  }}
                  className={classes.editButton}
                >
                  <Icon fontSize="small">edit_icon</Icon>
                </IconButton>
              )
            }
          />
        )}
        {editMode && (
          <React.Fragment>
            <Divider />
            <div className={classes.footer}>
              <Button
                className={classes.button}
                onClick={() => this.openDialog("addObjectsDialog")}
              >
                link object
              </Button>
              <Button className={classes.button} onClick={this.handleSave}>
                save
              </Button>
              <Button
                className={classes.button}
                onClick={this.handleResetObjects}
              >
                cancel
              </Button>
            </div>
          </React.Fragment>
        )}
        <DialogWrapper
          open={addObjectsDialog}
          onClose={() => this.closeDialog("addObjectsDialog")}
          handleClick={this.handleAddObjects}
          title="Link objects menu"
          buttonText="Ok"
        >
          <div className={classes.searchWrapper}>
            <Search
              addObject={this.handleAddObject}
              getObjIDsForIgnore={this.getObjIDsForIgnore}
            />

            {newObjects.map(
              ({ street_name, number, number_add, object_id }) => (
                <Grid
                  container
                  spacing={16}
                  key={object_id}
                  className={classes.searchedObjectContainer}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      className={classes.searchedObjectTitle}
                    >
                      {`${street_name || ""} ${number || ""} ${
                        number_add || ""
                      }`.trim()}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
          </div>
        </DialogWrapper>
        <DialogWrapper
          open={deleteObjectDialog}
          onClose={() => this.closeDialog("deleteObjectDialog")}
          handleClick={() => {
            this.handleRemoveObject();
            this.closeDialog("deleteObjectDialog");
          }}
          title="Delete Object"
          buttonText="Delete"
        >
          <Typography
            className={classes.deleteObjectDialogText}
            variant="subtitle1"
          >
            Are you sure you want to delete this object from the database?
          </Typography>
        </DialogWrapper>
        <EditObject
          open={openEditObjectMenu}
          onCloseDialog={() => this.closeDialog("openEditObjectMenu")}
          updateEntityData={updateEntityData}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 46px 8px 16px",
  },
  mainTitle: {
    fontSize: 26,
    display: "flex",
    paddingTop: 8,
    justifyContent: "center",
  },
  subHeader: {
    padding: 0,
  },
  unitWrapper: {
    padding: "2px 0",
  },
  headTitle: {
    width: "100%",
    display: "flex",
  },
  cityTitle: {
    width: "100%",
    display: "flex",
    marginLeft: 22,
  },
  objectTitle: {
    width: "100%",
    display: "flex",
    marginLeft: 10,
  },
  unitTitle: {
    width: "100%",
    display: "flex",
    marginLeft: 80,
  },
  buttonsPanel: {
    display: "flex",
    alignItems: "center",
  },
  bodyCollapsePanel: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  highlightPackHead: {
    "&:hover": {
      backgroundColor: theme.palette.primary[400],
    },
  },
  highlightPackItem: {
    "&:hover": {
      backgroundColor: "#f3f3f3",
    },
  },
  wrapperHead: {
    width: "100%",
    paddingRight: "22px !important",
  },
  wrapperPanel: {
    margin: 0,
    position: "initial",
  },
  wrapperPanelDetailsObjects: {
    padding: "8px 0 24px 12px",
  },
  wrapperPanelHeadObjects: {
    padding: "0 0 0 14px",
  },
  wrapperPanelDetailsUnits: {
    padding: "8px 46px 24px 12px",
  },
  iconButton: {
    color: "inherit",
    fontSize: 20,
    "&:hover": {
      color: theme.palette.primary[500],
    },
  },
  highlightObject: {
    backgroundColor: "#f7f7f7",
  },
  spacer: {
    marginRight: 12,
  },
  editButton: {
    color: "inherit",
    padding: 4,
    marginLeft: 4,
  },
  link: {
    textDecoration: "none",
    display: "flex",
    color: "inherit",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  imgKadLink: {
    height: 20,
    width: 20,
    marginLeft: 4,
    "&:hover": {
      outline: "4px solid rgba(0,0,0,0.2)",
    },
  },
  deleteObjectDialogText: {
    marginTop: 24,
  },
  searchWrapper: {
    minWidth: 550,
  },
  searchedObjectTitle: {
    marginTop: 8,
    width: 280,
  },
  searchedObjectContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },
  footer: {
    display: "flex",
    height: 48,
  },
  button: {
    width: "100%",
    borderRadius: 0,
  },
  favIcon: {
    marginRight: 2,
    fontSize: 22,
  },
  menuIcon: {
    marginLeft: 4,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary[500],
  },
  isBlacklisted: {
    backgroundColor: theme.palette.primary[700],
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 10px",
  },
});

const mapStateToProps = (state) => ({
  currentEntity: selectEntity(state),
  search: selectSearch(state),
  leadgen: selectLeadgen(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Objects));
