import React from "react";
import AlertService from "./alert.service";
import UserService from "./user.service";
import Typography from "@material-ui/core/Typography";

export default class ErrorHandler {
     static do = (error, router) => {

        console.log('ERRPR', error);

        const { response } = error;
        if (response.status === 401) {
            UserService.logout();
            router && router.push("login");
        }
        if (response.status === 403) {
            router && router.push("/dashboard");
        }
        if (response.status === 404) {
            router && router.push("/404");
        }
        AlertService.error(
            <Typography style={{ color: "inherit" }} variant="h6">
                {response.data.message}
            </Typography>,
        );
    };
}
