import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {clickQualifier} from "../../helpers/qualifier";

const TableWrapper = props => {
    const {
        columns,
        Head,
        Footer,
        hover,
        data,
        onRowClick = () => {
        },
        onRowSelect = () => {
        },
        classes,
        AddRows,
        selectedRow = {},
    } = props;

    return (
        <React.Fragment>
            {Head && <Head/>}
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map(({Header, headProps}, index) => (
                            <TableCell key={index} align="right" {...headProps}>
                                <Typography variant="body2" gutterBottom>
                                    {Header}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, rowIndex) => {
                        const styles = {};
                        if (
                            selectedRow.isSelected &&
                            selectedRow.object_id === row.num.object_id
                        ) {
                            //@ts-ignore
                            styles.backgroundColor = "rgba(0,0,0,0.14)";
                        }
                        //@ts-ignore
                        if (row.highlight) styles.borderLeft = "3px solid #607d9f";

                        return (
                            <React.Fragment key={rowIndex}>
                                <TableRow
                                    className={classes || ""}
                                    hover={hover}
                                    style={styles}
                                    onClick={clickQualifier(
                                        e => onRowSelect(e, rowIndex),
                                        e => onRowClick(e, rowIndex),
                                        300,
                                    )}
                                >
                                    {columns.map(({accessor, cellProps, Cell}, index) => (
                                        <TableCell key={index} {...cellProps}>
                                            {(accessor && (
                                                <Typography variant="body2" gutterBottom>
                                                    {Cell(row[accessor])}
                                                </Typography>
                                            )) ||
                                            Cell(rowIndex)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {AddRows ? AddRows[rowIndex] : <React.Fragment/>}
                            </React.Fragment>
                        );
                    })}
                </TableBody>
                {Footer && <Footer/>}
            </Table>
        </React.Fragment>
    );
};

TableWrapper.propTypes = {
    columns: PropTypes.array,
};

export default TableWrapper;
