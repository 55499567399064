import React from 'react';
import AlertService from '../../../common/services/alert.service';

export const handleSuccess = username => {
  AlertService.success(
    <div>
      <h4>Authorization successfully</h4>
      <div>Hello {username.toUpperCase()}!</div>
    </div>,
  );
};
