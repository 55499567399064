import { combineReducers } from "redux";
import {
    ADD_NEW_FILES_SUCCESS,
    SEND_NEW_FILES_SUCCESS,
    ADD_NEW_FILES_ERROR,
    SEND_NEW_FILES_ERROR,
} from "../../../common/constants";

const initialState = [];

const importDriveFiles = (state = initialState, action) => {
    switch (action.type) {
        case SEND_NEW_FILES_SUCCESS:
        case  ADD_NEW_FILES_SUCCESS:
            return action.files;

        default:
            return state;
    }
};

const importDriveFilesError = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_FILES_ERROR:
        case SEND_NEW_FILES_ERROR:
            return action.error;
        default:
            return state;
    }
};

const drive = combineReducers({
    importDriveFiles,
    importDriveFilesError,
});

export default drive;